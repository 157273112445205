import React, { useState, useEffect } from "react";
import ReactFileReader from 'react-file-reader';
import xlsxFile from 'read-excel-file';

import StudentRoster from './studentRoster';

import Step2 from '../../assets/images/twosteps_02.png';
import Step5 from '../../assets/images/steps_05.png';
import InfoIcon from '../../assets/images/icons/infoIcon.png';
import closePNG from '../../assets/images/icons/close.png';
import instructionImg from '../../assets/images/Instruction_CSV.png';
import CSV_Img from '../../assets/images/CSV-example_01.png';
import CSV_Img2 from '../../assets/images/CSV-example_02.png';
import CSV_Img3 from '../../assets/images/CSV-example_03.png';

import Modal from 'react-bootstrap4-modal';

import { useHistory } from "react-router-dom";
import "./AddClass.css";

const FifthImportStep = props => {
    let history = useHistory();
    const [selectedFile, setSelectedFile] = useState();

    const [subStep, setSubStep] = useState("A");
    const [students, setStudents] = useState([]);
    const method = "import";
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isInstructionModal, setIsInstructionModal] = useState(false);


    const setUploadedFile = files => {
        if ((files[0].name.indexOf("xls") != -1) || (files[0].name.indexOf("csv") != -1)) {
            setErrorMsg("")
            setSelectedFile(files[0]);
            setSubStep("B");
        }
        else {
            setErrorMsg("File type is not supported. Please select csv or excel file.")
            return;
        }
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const importStudents = () => {
        if (selectedFile.name.indexOf("xls") > 0 || selectedFile.name.indexOf("xlsx") > 0) {
            xlsxFile(selectedFile).then((rows) => {
                rows.splice(0, 1);
                rows.forEach((row) => {
                    if (row) {
                        if (validateEmail(row[0]) && row[1] && row[2]) {
                            let temp = {
                                email: row[0],
                                lastname: row[1],
                                firstname: row[2],
                            }
                            setStudents(students => [...students, temp])
                        } else {
                            setIsError(true);
                        }
                    }
                });
                setSubStep("C");
            });
        }
        else {
            var reader = new FileReader();
            reader.onload = (e) => {
                let jsonData = reader.result.split('\n');
                jsonData.splice(0, 1);
                jsonData.forEach((element) => {
                    const elementRaw = element.split(',');
                    if (element) {
                        if (validateEmail(elementRaw[0]) && elementRaw[1] && elementRaw[2]) {
                            let temp = {
                                email: elementRaw[0],
                                lastname: elementRaw[1],
                                firstname: elementRaw[2]
                            }
                            setStudents(students => [...students, temp])
                        } else {
                            setIsError(true);
                        }
                    }
                });
                setSubStep("C")
            }
            reader.readAsText(selectedFile);
        }
    }


    const changeStep = (value) => {
        props.backStep(value);
    }

    const resetImport = () => {
        setStudents([]);
        setSelectedFile(null);
        setIsError(false);
        setSubStep("A");
    }

    const finishHandler = () => {
        if (students.length !== 0)
            props.handleClassStudents(students);
        else
            setIsError(true);
    }

    const BackToTeacherDashboard = () => {
        history.push("/classdashboard/" + props.classDetails.classSectionId);
    }
    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Add your students" });
    }, [])

    return (
        <>
            <div>
                <div className="row" >
                    <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                        {props.classDetails === null && <img alt="" src={Step5} />}
                        {props.classDetails !== null && <img alt="" src={Step2} />}
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                        <span className="addnewclasshead">IMPORT CLASS LIST</span>
                    </div>
                    {subStep !== "C" &&
                        <div className="col-12" style={{ textAlign: "center", marginBottom: "64px" }}>
                            <span className="addnewclasstext">Select the file you want to import.</span>
                        </div>}
                    {subStep === "C" &&
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <span className="addnewclasstext">Here is the class list you’re importing. If it is the correct list, click Finish.</span>
                        </div>
                    }
                    <div className="col-12 " style={{ display: "inline-block" }}>

                        {subStep === 'A' &&
                            <div className="importBox" style={{ marginBottom: "64px" }}>
                                <ReactFileReader handleFiles={setUploadedFile} fileTypes={['.csv', '.xlsx']}>
                                    <button className='btn selectedFileBtn'>Select File</button>
                                </ReactFileReader>

                                <div style={{ marginTop: "10px" }}>
                                    <span className="fielTypesLabel">(CSV, EXCEL FILES ONLY)</span>
                                </div>

                                <div style={{ marginTop: "15px" }}>
                                    <img className="infoIconsa" onClick={() => { setIsInstructionModal(true) }} alt="info" src={InfoIcon} style={{ marginRight: "10px" }} />
                                    <a className="CSVTab" onClick={() => { setIsInstructionModal(true) }} style={{ cursor: "pointer" }} >How to create a CSV file</a>
                                </div>
                            </div>
                        }
                        {subStep === 'B' &&
                            < div className="importBox" style={{ paddingTop: "15px", marginBottom: "64px" }}>
                                <div style={{ marginTop: "15px" }}>
                                    <span className="CSVTab">Is this the file you want to import?</span>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <span className="importedFilename">{selectedFile.name}</span>
                                </div>
                                <div style={{ marginTop: "15px", marginBottom: "25px" }}>
                                    <button onClick={() => { setSubStep("A"); setSelectedFile(null) }} className='btn selectedFileBtn' style={{ marginRight: "15px" }}>Change file</button>
                                    <button onClick={importStudents} className='btn importFileBtn'>Import file</button>
                                </div>
                            </div>}


                        {subStep === 'C' &&
                            < div className="" style={{ paddingTop: "15px", paddingBottom: "25px", textAlign: "center" }}>
                                {
                                    <StudentRoster method={method} students={students} setRoster={(value) => {
                                        setStudents(value)
                                    }} />

                                }
                                <div style={{ marginTop: "25px" }}>
                                    <span className="wronglist">Wrong list? Click here to <a className="underlineLabel" onClick={resetImport}>import again.</a></span>
                                </div>
                            </div>}
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                        {isError && students.length !== 0 && < span style={{ color: "red" }}>All data is not imported, Please correct the data and upload again or you can proceed with this data.</span>}
                        {isError && students.length === 0 && < span style={{ color: "red" }}>All data is not in correct format, Please correct the data and upload again.</span>}
                        {errorMsg != "" && < span style={{ color: "red" }}>{errorMsg}</span>}
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginBottom: "135px" }}>
                        {props.classDetails === null && <button onClick={() => { changeStep(4) }} type="button" className="curve-bordered-btn" id="back" style={{ marginRight: "20px" }}>BACK</button>}
                        <button onClick={finishHandler} disabled={students.length === 0 || subStep !== "C"} type="button" className="curve-fill-btn" id="finish" >FINISH</button>
                        {props.classDetails === null && <button onClick={() => { changeStep(7) }} type="button" className="btn skipBtn" id="skip" >Skip step</button>}
                        {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                    </div>
                </div>
            </div>


            <Modal id="CSVModal" dialogClassName="Modaldialog" className="standardsModal" visible={isInstructionModal} onClickBackdrop={() => { setIsInstructionModal(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsInstructionModal(false) }}>
                        <img alt="close" className="closeModalPNG" src={closePNG} />
                    </div>
                    <div className="row padding7P">
                        <span className="csvHeadText">HoW to Create a CSV file</span>
                    </div>
                    <div className="row padding7P">
                        <span className="csvStep">STEP 1:</span>
                    </div>
                    <div className="row padding7P">
                        <span className="csvTextNormal">Create a spreadsheet with the following column headings: Email, Last Name, and First Name. Enter each student’s information in a separate row.
                            <br />
                            <strong>Note:</strong> You can use any spreadsheet program, such as Google Sheets, Microsoft Excel, or Libre Calc.</span>
                    </div>
                    <div className="row padding7P" style={{ marginTop: "20px" }}>
                        <img alt="" src={CSV_Img} style={{ margin: "auto" }} />
                    </div>
                    <div className="row padding7P">
                        <span className="csvStep">STEP 2:</span>
                    </div>
                    <div className="row padding7P">
                        <span className="csvTextNormal">
                            Save or download your spreadsheet as a CSV (comma delimited) file.
                            <br />
                            <strong>Note:</strong> In Microsoft Excel and Libre Calc, choose File, Save As. In Google Sheets, choose File, Download. Select the available CSV choice.</span>
                    </div>
                    <div className="row padding7P" style={{ marginTop: "20px" }}>
                        <img alt="" src={CSV_Img2} style={{ margin: "auto" }} />
                    </div>
                    <div className="row padding7P">
                        <span className="csvStep">Here is the Example of the exported CSV file:</span>
                    </div>
                    <div className="row padding7P" style={{ marginTop: "20px" }}>
                        <img alt="" src={CSV_Img3} style={{ margin: "auto" }} />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default React.memo(FifthImportStep);