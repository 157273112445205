import React from "react";
import "./SessionTypes.css";
import { SESSION_TYPE } from "../constants/CommonConstants";

const SessionTypes = props => {
    const { type, single } = props;

    let teacherStyle = "";
    let studentStyle = "muted";

    if (!single && type) {
        const types = Object.values(type);
        teacherStyle = types.filter(item => item === SESSION_TYPE.tf).length > 0 ? "" : "muted";
        studentStyle = types.filter(item => item === SESSION_TYPE.sg).length > 0 ? "" : "muted";
    }

    return (
        <>
            {single ?
                <div className="sessionTypes text-left" >
                    {type === SESSION_TYPE.tf
                        ? <span >Teacher<br /> Facilitated</span>
                        : <span >Student <br /><nobr>Self-Guided</nobr></span>
                    }

                </div> :
                <div className="sessionTypes">
                    <span className={teacherStyle}>Teacher Facilitated</span>
                    <div className="v-divider" style={{ margin: '0 10px' }} />
                    <span className={studentStyle}>Student <nobr>Self-Guided</nobr></span>
                </div>
            }
        </>
    )
}
export default React.memo(SessionTypes);