import { createContext } from 'react'

const UserContext = createContext({
    isLoggedIn: false,
    token: null,
    userId: null,
    email:null,
    firstname:null,
    lastname:null,
    role:[],
    strategy:null,
    login: () => { },
    logout: () => { }
});

export default UserContext