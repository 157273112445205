import React, { useState } from 'react';
import Modal from 'react-bootstrap4-modal';

import closePNG from '../../../assets/images/icons/close.png';
import { CSVLink } from "react-csv";

import "./ExportGrades.css";

const ExportGrades = props => {

    return (
        <>
            {
                props.exportData.data.length != 0 &&
                <Modal className="exportGradeModal" visible={props.isVisible} onClickBackdrop={() => { }}>
                    <div className="modal-body">
                        <div style={{ textAlign: "end" }} onClick={() => { props.setVisible(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>
                        {!props.isGradingCompleted ?
                            <div className="headerText">
                                <span>You haven’t finished grading, are you sure you want to export grades?</span>
                            </div> :
                            <div className="headerText">
                                <span>Export to</span>
                            </div>
                        }

                        {!props.isGradingCompleted ?
                            <div className="subheaderText">
                                <span>If yes, click export type. If no, click Cancel.</span>
                            </div> :
                            <div className="subheaderText">
                                <span>Choose a type of export:</span>
                            </div>
                        }

                        <CSVLink data={props.exportData.data} headers={props.exportData.headers} filename={props.exportData.fileName} className="">
                            <div style={{ textAlign: "center" }}>
                                <button style={{ marginTop: "20px" }} className="round-filled-btn-small" onClick={() => { props.setVisible(false) }}>CSV</button>
                            </div>
                        </CSVLink>

                        <div style={{ textAlign: "center", marginTop: "20px" }}>
                            <span className="cancel" onClick={() => { props.setVisible(false) }}>Cancel</span>
                        </div>

                    </div>
                </Modal>
            }

        </>
    );
};

export default React.memo(ExportGrades);