import React from "react";

const TabPanel = (props) => {
    const { children, value, id } = props;
    return (
        value === id && (
            <div
                className="tab-content"
                id={`class-tabpanel-${id}`}
                aria-labelledby={`class-tab-${id}`}
                key={`tabpanel-${id}`}>
                {children}
            </div>
        )
    );
}

export default TabPanel;