import React, { useState, useEffect, useContext, forwardRef, useRef, useImperativeHandle } from "react";
import "./StudentClassDetails.css";
import axios from "axios";
import {
    useHistory,
    useParams,
} from "react-router-dom";

import PhysicsIcon from "../../assets/images/icons/physics80X80.png";
import AlgebraIcon from "../../assets/images/icons/algebra80X80.png";
import HistoryIcon from "../../assets/images/icons/history80X80.png";
import UserContext from "../../shared/context/UserContext";
import history_1920 from "../../assets/images/history_1920SVG.svg";
import physics_1920 from "../../assets/images/physics_1920SVG.svg";
import algebra_1920 from "../../assets/images/algebra_1920SVG.svg";
import lockIcon from "../../assets/images/icons/whiteLock.png";
import Tour from "../../shared/components/tour/Tour";

import SessionsTab from "./SessionsTab";
import MyProjectTab from "./MyProjectTab";


const StudentClassDetails = forwardRef((props, ref) => {

    const auth = useContext(UserContext);
    const childRef = useRef();
    let param = useParams();
    let backImg;
    const classId = param.id;
    const studentID = auth.userId;

    const [studentData, setStudentData] = useState(null)
    const [classDetailsData, setClassDetailsData] = useState(null);
    const [isFreezed, setIsFreezed] = useState(false);
    const [studentRosterId, setStudentRosterId] = useState(null)
    const [currentLocation, setCurrentLocation] = useState(null);
    const [IsTourStart, setTourStart] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [shareId, setShareId] = useState();
    const [activeTab, setActiveTab] = useState('SESSIONS');
    const [isProjectSubmitted, setIsProjectSubmitted] = useState(false);
    const [district, setDistrict] = useState();

    const reSetRefresh = () => {
        setRefresh(refresh => !refresh);
    }
    let history = useHistory();

    let tourSteps = [];
    const sessions = [
        {
            target: ".tabDiv_multiple",
            content: "The Class Details page is divided into two tabs - Sessions and My Project. You can access everything you need for class from this page.",
            disableBeacon: true,
            placement: "left"
        },
        {
            target: ".boxSessionName",
            content: "The sessions for your class are listed on the Sessions tab. Sessions your teacher has unlocked for you are displayed in color. You can go into any unlocked session. Locked sessions are displayed in gray.",
            placement: "right"
        },
        {
            target: ".progressBarDiv",
            content: "You can see your progress through the entire course or how much you have complete in each session by looking at the Progress indicators.",
            placement: ""
        },
        {
            target: ".viewProjectRubricSpan",
            content: (
                <div>
                    See how your final project will be evaluated by clicking <b>View Project Rubric</b>. Align your final project to the expectations within this rubric for the highest score.
                </div>
            ),
            placement: "left"
        },
        {
            target: ".seeMilestonesLabel",
            content: (
                <div>Curious what is covered in this session? Click <b>See Milestones</b> to view the list. </div>
            ),
            placement: "left"
        },
        {
            target: ".assignmentLabel",
            content: (
                <div>Your assessment score for each session is displayed. Want to see the questions with your answers? Click <b>Assessment</b>.</div>
            ),
            placement: "left"
        },
        {
            target: ".goBtnLabel",
            content: (
                <div>When you are ready to do your project work for the session, click <b>Go</b>.</div>
            ),
            placement: "left"
        },
        {
            target: ".SubmittedFinalProject",
            content: "The Class Details page is divided into two tabs - Sessions and My Project. You can access everything you need for class from this page.",
            placement: "right"
        },
        {
            target: ".finalScoreDiv",
            content: "If your teacher has evaluated your project, you will see your final score. ",
            placement: "right"
        },
        {
            target: ".ViewProjectRubricSpan",
            content: "If your teacher has evaluated your project, you will see your final score. ",
            placement: "left"
        }
    ];

    tourSteps = sessions;

    const project = [
        {
            target: ".tabDiv_multiple",
            content: "The Class Details page is divided into two tabs - Sessions and My Project. You can access everything you need for class from this page.",
            disableBeacon: true,
            placement: "left"
        },
        {
            target: ".SubmittedFinalProject",
            content: "The Class Details page is divided into two tabs - Sessions and My Project. You can access everything you need for class from this page.",
            placement: "right"
        },
        {
            target: ".finalScoreDiv",
            content: "If your teacher has evaluated your project, you will see your final score. ",
            placement: "right"
        },
        {
            target: ".ViewProjectRubricSpan",
            content: "If your teacher has evaluated your project, you will see your final score. ",
            placement: "left"
        }
    ];

    useEffect(() => {

        if (auth.token) {
            props.showLoader();
            axios.get("/studentRoster/getshareid/" + auth.userId + "/" + classId, {
                headers: { Authorization: "bearer " + auth.token }
            }).then(res => {
                setShareId(res.data._id)
            })
            axios.get("/classSection/getbyid/" + classId, {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {

                    if (res.data[0].isDeleted || res.data[0].isArchived) {
                        history.push('/studentdashboard');
                    }

                    setClassDetailsData(res.data[0]);

                    if (res.data[0].subject.toLowerCase() === "algebra")
                        backImg = algebra_1920;
                    if (res.data[0].subject.toLowerCase() === "physics")
                        backImg = physics_1920;
                    if (res.data[0].subject.toLowerCase() === "history")
                        backImg = history_1920;

                    props.amplitude.track("Visited Class Details", { teacher_user_id: res.data[0].teacher, subject: res.data[0].subject, page_section: (activeTab === "SESSIONS" ? "Sessions" : "My Project"), class_section_id: classId })
                })

            axios.get("/studentRoster/bystudentidandclassid/" + auth.userId + "/" + classId, {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    props.hideLoader()
                    setStudentRosterId(res.data._id)
                    setStudentData(res.data)
                    setIsFreezed(res.data.isFreezed);
                    setIsProjectSubmitted(res.data.isProjectSubmitted);
                    setCurrentLocation(res.data.currentLocation);
                })

            const districtID = localStorage.getItem("district");
            axios.get("/district/get-district-by-id/" + districtID)
                .then(res => {
                    setDistrict(res.data);
                })

            let flag = false;
            axios.get("/user/getusercontextualtourdata/" + auth.userId).then(res => {
                if (res.data.contextualTour.length > 0) {
                    let data = res.data.contextualTour.filter(val => val.pageName == 'StudentDetails');
                    if (data.length > 0) {
                        flag = false;
                    }
                    else {
                        flag = true;
                        getHelp();
                        axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'StudentDetails' } });
                    }
                }
                else {
                    flag = true;
                    getHelp();
                    axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'StudentDetails' } });
                }
            });


        }

    }, [refresh])

    useEffect(() => {
        if (activeTab == 'SESSIONS') {
            tourSteps = sessions;
        }
        else {
            tourSteps = project;
        }
    }, [activeTab])

    const getHelp = () => {
        childRef.current.getTour();
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    const checkIfProjectIsFreezed = () => {
        if (isFreezed) {
            return;
        }
        else {
            setActiveTab("MYPROJECT");
            props.amplitude.track("Visited My Project ", { teacher_user_id: classDetailsData.teacher, subject: classDetailsData.subject, class_section_id: classId });
        }

    }

    useEffect(() => {
        if (IsTourStart == true) {
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    return (
        <>
            {studentData && classDetailsData && <div style={{ backgroundColor: "#F2F2F9", paddingBottom: "40px" }}>

                <div className="row courseCoverImg" style={{ backgroundImage: `url(${classDetailsData.courseImage})` }}>
                    <div className="col-12">

                        <img alt="" src={classDetailsData.courseIcon} />

                        <span className="classSectionName" title={classDetailsData.name}>{(classDetailsData.name.length >= 70) ? `${classDetailsData.name.substr(0, 69)}...` : classDetailsData.name}</span>
                    </div>
                </div>
                <div className="row justify-content-center" style={{ paddingTop: "40px", paddingBottom: "7px" }}>
                    <div className="tabDiv_multiple" style={{ display: "inherit" }}>
                        <div className="" style={{ textAlign: "center", position: "relative" }}>
                            <span className={`${activeTab === "SESSIONS" ? "SelectedClassTab " : "ClassTab"}`} onClick={() => { setActiveTab("SESSIONS") }} style={{ marginRight: "50px" }}>Sessions</span>
                            {activeTab === "SESSIONS" && <div className="sessionSelected"></div>}
                        </div>
                        {isProjectSubmitted == false &&
                            <div className="" style={{ textAlign: "center", position: "relative" }}>
                                <span className={`DisabledClassTab`}>My Project</span>
                                <span style={{ padding: "4px 8px 7px 8px", background: "#9F9FAF", marginLeft: "10px", borderRadius: "20px" }}>
                                    <img alt="" src={lockIcon} />
                                </span>

                                {activeTab === "MYPROJECT" && <div className="projectSelected"></div>}
                            </div>
                        }
                        {isProjectSubmitted == true &&
                            <div className="" style={{ textAlign: "center", position: "relative" }}>
                                <span className={`${activeTab === "MYPROJECT" ? "SelectedClassTab " : "ClassTab"}`} onClick={() => { setActiveTab("MYPROJECT") }}>My Project</span>
                                {activeTab === "MYPROJECT" && <div className="projectSelected"></div>}
                            </div>
                        }
                    </div>
                </div>
                <div className="largeScreenDiv">
                    <div className="row studentContainer" >
                        <div className="col-12" style={{ textAlign: "center", padding: "0px" }}>
                            {
                                activeTab === 'SESSIONS' && <SessionsTab amplitude={props.amplitude} refreshCom={reSetRefresh} currentLocation={currentLocation} isFreezed={isFreezed} isProjectSubmitted={isProjectSubmitted} studentRosterId={studentRosterId} classId={classId} entryPoints={studentData.entryPoints} sessions={studentData.sessions} courseProgress={studentData.courseProgress} rubric={studentData.rubric} classSessions={classDetailsData.sessions} courseName={classDetailsData.courseName} sessionTypes={classDetailsData.sessionType} subject={classDetailsData.subject} teacherId={classDetailsData.teacher} districtName={district && district.name} />
                            }
                            {
                                activeTab === 'MYPROJECT' && <MyProjectTab amplitude={props.amplitude} rubric={studentData.rubric} projectQuestion={studentData.projectQuestion} subject={classDetailsData.subject} entryPoints={studentData.entryPoints} shareId={shareId} classId={classId} teacherId={classDetailsData.teacher} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tour ref={childRef} tourSteps={tourSteps} amplitude={props.amplitude} />
        </>
    )
})

export default React.memo(StudentClassDetails);