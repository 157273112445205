import React from "react";
import "./RadioButton.css";

const RadioButton = props => {
    const { name, checked, label, onChange, value, id } = props;

    return (
        <>
            <label className="radioContainer" >
                {label}
                <input type="radio" id={id ? id : name} name={name} defaultChecked={checked} value={value} />
                <span className="checkmark" ></span>
            </label>
        </>
    )
}
export default React.memo(RadioButton);