import React from "react";

import "./CSVTemplate.css";

import logo from '../../assets/images/spark_logo.svg'


function CSVTemplate() {

    return (
        <>
            <div style={{ backgroundColor: "#EFEFEF" }}>
                <div className="row header">
                    <div className="col-4">
                        <img src={logo} alt="spark" />
                    </div>
                </div>
                <div className="row" style={{ textAlign: "center", backgroundColor: "#C4C4C4", padding: "86px 0 58px 0" }}>
                    <div className="col" style={{ padding: "0", margin: "0" }}>
                        Steps to create a CSV file to import students in system.
                    </div>
                </div>
            </div>
        </>
    )
}

export default (CSVTemplate);