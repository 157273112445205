import React, { useState, useEffect, forwardRef } from "react";
import axios from "axios";
import $ from "jquery";

import { DEFAULT_VALUES } from "../constants/CommonConstants";
import MilestoneFlag from "../Icons/MilestoneFlag";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ClassTabHeader from "./ClassTabHeader";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';
import { OverlayTrigger } from "react-bootstrap";

const Dashboard = (props) => {
    const pageLength = DEFAULT_VALUES.pageLength;
    const [limit, setLimit] = useState(DEFAULT_VALUES.pageLength);

    const [sessionMaterials, setSessionMaterials] = useState();
    const [otherMaterials, setOtherMaterials] = useState();
    const [quickStartGuide, setQuickStartGuide] = useState();
    const [sessionResources, setSessionResources] = useState();
    const [totalSessions, setTotalSessions] = useState([1, 2, 3, 4, 5]);
    const [studentData, setStudentData] = useState([]);
    const [milestoneList, setMilestoneList] = useState([]);
    const [removeStudentId, setRemoveStudentId] = useState(null);
    const [showRemoveStudentConfirmation, setShowRemoveStudentConfirmation] = useState(false);
    const [oldClass, setOldClass] = useState(true);
    const [scrollLeftCount, setScrollLeftCount] = useState(0);
    const [scrollRightCount, setScrollRightCount] = useState(0);
    const [disableLeft, setDisableLeft] = useState("disabled");
    const [disableRight, setDisableRight] = useState("disabled");
    const [sessionsProgress, setSessionsProgress] = useState([]);
    const [progressList, setProgressList] = useState([]);
    let [finalWidth, setFinalWidth] = useState();

    useEffect(() => {
        props.currentSessionDetails && getSessionMaterials(props.currentSessionDetails);

    }, [props.currentSessionDetails]);

    useEffect(() => {
        updateStudentData();
    }, [props.studentsList])

    const getSessionMaterials = (session) => {
        let resourceDocs = session.resource;
        let milestones = [];
        if (session.task && session.task.length > 0) {
            milestones = session.task;
            setOldClass(false);
        }
        else {
            milestones = session.milestones;
            setOldClass(true);
        }

        let sessionDocs = resourceDocs.filter(item => item.name.includes("Session"));
        let otherMaterial = resourceDocs.filter(item => item.name.includes("T-Chart"));
        let quickStartGuide = resourceDocs.filter(item => item.name === "Quick Start Guide");
        let sessionRes = sessionDocs.concat(otherMaterial);

        setMilestoneList(milestones);
        setQuickStartGuide(quickStartGuide);
        setSessionResources(sessionRes);
        setSessionMaterials(sessionDocs);
        setOtherMaterials(otherMaterial);
    }

    useEffect(() => {
        calculateCourseProgress();
        updateArrows();
    }, [studentData, props.currentSessionId])

    const downloadAll = () => {

        sessionResources.forEach(element => {
            var a = document.createElement("a");
            a.setAttribute('href', element.link);
            a.setAttribute('download', element.link);
            a.click();
        });
    }

    const updateStudentData = () => {

        if (props.studentsList && props.studentsList.rosterData) {

            const student = props.studentsList.rosterData && props.studentsList.rosterData.map(item => {

                let info = props.studentsList.studentUserData.filter(i => i._id == item.studentId)[0];

                return { ...item, data: info }
            });
            student.sort(function (a, b) {
                var nameA = a.lastName.toUpperCase();
                var nameB = b.lastName.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
            setStudentData(student);
        }


    }

    const updateRosterData = (value, control, id) => {
        const newRosterData = [...props.studentsList.rosterData];
        const newStudentUserData = [...props.studentsList.studentUserData];
        let index = newRosterData.findIndex(x => x._id === id);
        const regex = /^[a-zA-Z-0-9 ,.'-]+$/i;
        regex.test(value);

        if (regex.test(value) && index > -1) {
            newRosterData[index][control] = value;

            props.setStudentsList({
                "rosterData": newRosterData,
                "studentUserData": newStudentUserData
            });
        }

    }

    const updateStudentInfo = (value, control, id) => {
        if (value !== "" && value !== null && value !== undefined) {
            axios.put("/studentRoster/update", { _id: id, [control]: value });
        }
    }

    const resetPassword = (emailId) => {
        axios.post("/user/forgotpassword", { email: emailId })
            .then(res => {

            })
    }

    const removeStudent = () => {
        if (removeStudentId) {
            axios.put("/studentRoster/delete", { _id: removeStudentId });

            const newRosterData = [...props.studentsList.rosterData];
            let deletedStudentInformation = newRosterData.filter(x => x._id === removeStudentId);
            let teacherId;
            if (deletedStudentInformation.length > 0) {
                teacherId = deletedStudentInformation[0].createdBy;
            }
            props.amplitude.track("Removed from Class", { teacher_user_id: teacherId, remove_class_method: "Manual", subject: props.subject, class_section_id: props.classId });

            const newStudentUserData = [...props.studentsList.studentUserData];
            let index = newRosterData.findIndex(x => x._id === removeStudentId);
            if (index > -1) {
                newRosterData.splice(index, 1);
                props.setStudentsList({
                    "rosterData": newRosterData,
                    "studentUserData": newStudentUserData
                });

                setShowRemoveStudentConfirmation(false);
                setRemoveStudentId(null);
                props.removeStudent && props.removeStudent(newRosterData);
            }
        }
    }

    const sortList = (item, key) => {
        const sortedItem = item.sort(function (a, b) {
            var itemA = a[key];
            var itemB = b[key];
            if (itemA < itemB) {
                return -1;
            }
            if (itemA > itemB) {
                return 1;
            }

            return 0;
        });
        return sortedItem;
    }

    const onScrollLeft = e => {
        setScrollLeftCount($('.task-container').scrollLeft() - 185);
        setScrollRightCount($('.task-container').scrollLeft() - 185)
        $('.task-container').scrollLeft($('.task-container').scrollLeft() - 185);

    };

    const onScrollRight = e => {
        setScrollRightCount($('.task-container').scrollLeft() + 185);
        $('.task-container').scrollLeft($('.task-container').scrollLeft() + 185);
    };

    useEffect(() => {
        window.addEventListener("resize", updateArrows);
    })

    useEffect(() => {
        let currentScroll = $('.task-container').scrollLeft();

        if (scrollRightCount > currentScroll) {
            setDisableRight("disabled");
        } else {
            setDisableRight("");
        }

        if (!currentScroll || currentScroll <= 0) {
            setDisableLeft("disabled");
        } else {
            setDisableLeft("");
        }

    }, [scrollLeftCount, scrollRightCount])

    const scrollZero = () => {
        $('.task-container').scrollLeft(0);
        setScrollLeftCount(0);
        setScrollRightCount(0);
    }

    const updateArrows = () => {
        if ($('.task-container').width() >= $('.task-list').width()) {
            setDisableRight("disabled");
        } else {
            setDisableRight("");
        }
        scrollZero();
        updateWidth();
    }

    useEffect(() => {
        setTimeout(function () {
            updateWidth();
        }, 10);
    }, [sessionsProgress])

    const updateWidth = () => {
        let navItems = 40;
        let grandParent = $('.roster-header-col').outerWidth();
        let parent = $('.task-container-header').width();
        let child = $('.progress-list').outerWidth();
        let estimateValue = child + navItems;

        if (parent > estimateValue && estimateValue !== finalWidth) {
            setFinalWidth(estimateValue);
            console.log("set Value", estimateValue)
        }

        if (grandParent < parent) {
            console.log("null", finalWidth)
            finalWidth && setFinalWidth();
        }

        if (estimateValue > grandParent) {
            if ($('.task-container').width() >= $('.task-list').width()) {
                setDisableRight("disabled");
            } else {
                setDisableRight("");
            }
        }
    }

    const calculateCourseProgress = () => {
        setFinalWidth();
        let sp = [0, 0, 0, 0, 0];
        let completedMilestones = [];
        let milestonesList = [];
        studentData.forEach(item => {
            let session = item.sessions;
            session.forEach(s => {
                sp[s.index - 1] = sp[s.index - 1] + s.sessionProgress;
                if (s.index == props.currentSessionId) {
                    let mList = [];
                    s.task.forEach(item => {
                        mList = [...mList, ...item.milestones];
                    });

                    milestonesList.push(mList);
                }
            })
        });

        milestonesList.forEach(item => {
            item.forEach(t => {
                let itemIndex = completedMilestones.findIndex(x => x.id == t.id);
                if (t.isCompleted == true) {
                    let obj = { id: t.id, count: 1 };
                    if (itemIndex >= 0) {

                        completedMilestones[itemIndex].count = completedMilestones[itemIndex].count + 1;
                    } else {
                        completedMilestones.push(obj);
                    }
                }
            })
        })


        let progressPercent = completedMilestones.map(item => {
            let progress = Math.round((item.count / studentData.length) * 100);
            return { ...item, progress };
        });

        let spPercentage = sp.map(sp => {
            return Math.round(sp / (studentData.length * 100) * 100);
        });

        setProgressList(progressPercent);
        setSessionsProgress(spPercentage);

    }

    return (
        <>
            <div className="hiddenObjectDashboard"></div>
            <ClassTabHeader {...props} otherMaterials={otherMaterials} sessionMaterials={sessionMaterials} quickStartGuide={quickStartGuide} downloadAll={downloadAll} />
            <div className="student-dashboard-list mt-3" id="studentRosterList">
                <div className="row assessment-header text-center text-uppercase font-weight-bold font-10">
                    <div className="col-2 session "></div>
                    {totalSessions.map(item => {
                        return <div className={props.currentSessionId == item ? "col-6 session roster-header-col active " : "col session in-active-session"} key={"session-header-" + item} style={{ maxWidth: finalWidth }}>
                            <span onClick={() => { props.changeSessionId(item); scrollZero(); }} className="pointer text-underline">{props.currentSessionId == item ? "Session" : "S"} {item}</span>
                        </div>
                    })
                    }

                </div>
                <div className="row text-center session-progress" >
                    <div className="col-2 d-flex align-items-center">
                        <span className="title" >Class Progress: {props.classDetails.courseProgress}%</span>
                    </div>

                    {
                        totalSessions.map(item => {

                            if (props.currentSessionId > item && props.currentSessionDetails && props.currentSessionDetails.index !== item) {
                                let showProgress = sessionsProgress[item - 1] >= 50 ? " show-progress" : "";
                                return <div className={props.currentSessionId == item ? "col-6 session active" : "col session" + showProgress} key={"blankItem" + item}></div>
                            }
                        })
                    }
                    <div className={"col-6 session active p-0 task-container-header"} style={{ maxWidth: finalWidth }} >
                        {
                            oldClass ?
                                <div className="row">
                                    {milestoneList.map(item => {
                                        return <div className="milestone-progress font-weight-bold col" key={"old-" + item.id}>
                                            #{item.id}
                                        </div>
                                    })}
                                </div> :
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className={progressList.length > 0 && progressList[0].progress >= 50 ? "task-gap show-progress" : "task-gap"}></div>
                                    <div className="task-container">
                                        <div className="progress-list d-flex">
                                            {milestoneList.map(item => {
                                                const milestone = item.milestones;

                                                return <div className="progress-item flex-row" key={item.title + item.id}>
                                                    {milestone.map(m => {
                                                        let pi = progressList && progressList.filter(p => p.id == m.id)[0];

                                                        let showProgress = pi && pi.progress >= 50 ? " show-progress" : "";
                                                        return <div className={"milestone-progress font-weight-bold" + showProgress} key={m.title + m.id}></div>
                                                    })}

                                                </div>
                                            })}
                                        </div>

                                    </div>
                                    <div className={sessionsProgress[props.currentSessionId - 1] == 100 || sessionsProgress[props.currentSessionId] >= 50 ? "task-gap show-progress" : "task-gap"}></div>
                                </div>
                        }

                    </div>

                    {
                        totalSessions.map(item => {
                            if (props.currentSessionId < item) {
                                let showProgress = sessionsProgress[item - 1] >= 50 ? " show-progress" : "";
                                return <div className={props.currentSessionId == item ? "col-6 session active" : "col session" + showProgress} key={"blankItem" + item} ></div>
                            }
                        })
                    }

                </div>


                <div className="row text-center" >
                    <div className="col-2"></div>
                    {
                        totalSessions.map(item => {
                            if (props.currentSessionId > item && props.currentSessionDetails && props.currentSessionDetails.index !== item) {
                                return <div className={props.currentSessionId == item ? "col-6 session active" : "col session in-active-session"} key={"blankItem" + item} ></div>
                            }
                        })
                    }
                    <div className={"col-6 session active p-0 task-container-header task-overlay-tooltip"} style={{ maxWidth: finalWidth }}>
                        {
                            oldClass ?
                                <div className="row">
                                    {milestoneList.map(item => {
                                        return <div className="milestone-flag font-weight-bold col" key={"old-" + item.id}>
                                            #{item.id}
                                        </div>
                                    })}
                                </div> :
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className={"milestone-nav " + disableLeft} style={{ transform: "rotateZ(180deg)" }} onClick={onScrollLeft}><ArrowForwardIosIcon className="font-16" /></div>
                                    <div className="task-container h-100" >
                                        <div className="task-list d-flex" style={{ height: "50px" }}>
                                            {milestoneList.map(item => {
                                                const milestone = item.milestones;
                                                return <div className="task-item flex-column justify-content-around" key={item.title + item.id}>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<span className="tooltiptext tooltip-top">
                                                            {item.label}
                                                        </span>}
                                                    >
                                                        <div id={"task-" + item.id} className="pointer task-title-list">{item.title}</div>
                                                    </OverlayTrigger>
                                                    <div className="d-flex align-self-center">
                                                        {milestone.map(m => {
                                                            return <><OverlayTrigger
                                                                placement="bottom"
                                                                overlay={
                                                                    <span className="tooltiptext tooltip-bottom">
                                                                        Milestone {m.title}
                                                                        <br />
                                                                        {m.label}
                                                                    </span>
                                                                }
                                                                key={m.title + m.id}
                                                            >
                                                                <div id={"milestone-" + m.id} className="milestone-flag font-weight-bold pointer task-title-list">{m.title}</div>
                                                            </OverlayTrigger>
                                                            </>
                                                        })}
                                                    </div>
                                                </div>
                                            })}
                                        </div>

                                    </div>
                                    <div className={"milestone-nav " + disableRight} onClick={onScrollRight}><ArrowForwardIosIcon className="font-16" /></div>
                                </div>
                        }

                    </div>

                    {
                        totalSessions.map(item => {
                            if (props.currentSessionId < item) {
                                return <div className={props.currentSessionId == item ? "col-6 session active" : "col session in-active-session"} key={"blankItem" + item} ></div>
                            }
                        })
                    }
                </div>

                {studentData.slice(0, limit).map(item => {
                    const sessions = sortList(item.sessions, "index");
                    const disableEdit = item.data.providers.length > 0 ? true : false;
                    let skip = sessions.length;
                    let milestoneFlag = 0;
                    let sessionFlag = 0;

                    sessions.forEach(item => {
                        if (oldClass) {
                            const milestones = item.milestones;
                            milestones.map(m => {
                                if (m.isCompleted === true) {
                                    milestoneFlag = m.id;
                                    sessionFlag = item.index;
                                }
                            });

                        }
                        else {
                            const tasks = item.task;
                            tasks.map(t => {
                                const milestones = t.milestones;
                                milestones.map(m => {
                                    if (m.isCompleted === true) {
                                        milestoneFlag = m.id;
                                        sessionFlag = item.index;
                                    }
                                });
                            });
                        }

                    });

                    return <>
                        <div className="row text-center font-12" key={item._id} >
                            <div className="col-2 text-left d-flex align-items-center ">
                                <span className="roster-name text-underline pointer font-14 edit-student-overlay" data-toggle="collapse" data-parent="#studentRosterList" data-target={`#${"student-roster-" + item._id}`} >
                                    {item.lastName.toLowerCase() + ", " + item.firstName.toLowerCase()}
                                </span>
                            </div>

                            {sessions.map((session, index) => {
                                const tasks = session.task;

                                const milestones = session.milestones;
                                if (oldClass) {
                                    return <div className={props.currentSessionId == session.index ? "col-6 session active p-0" : "col session in-active-session"} key={"rosterAnswer-" + index} style={{ maxWidth: finalWidth }}>
                                        {props.currentSessionId == session.index
                                            ?
                                            <div className="row h-100">
                                                {milestones.map(milestone => {
                                                    return <div className="milestone-flag col" key={"milestone-" + milestone.id}>
                                                        {milestoneFlag === milestone.id && sessionFlag === session.index &&
                                                            <MilestoneFlag className="text-success" />
                                                        }
                                                    </div>
                                                })}
                                            </div>
                                            :
                                            <div>
                                                {sessionFlag === session.index && <MilestoneFlag className="text-success" />}
                                            </div>
                                        }
                                    </div>
                                }
                                else {
                                    return <div className={props.currentSessionId == session.index ? "col-6 session active p-0 " : "col session in-active-session"} key={"rosterFlag-" + index} style={{ maxWidth: finalWidth }}>

                                        {props.currentSessionId == session.index
                                            ? <div className="d-flex align-items-center justify-content-between w-100">
                                                <div className="task-gap"></div>
                                                <div className="task-container">
                                                    {<div className="task-list d-flex h-100">
                                                        {tasks.map(t => {
                                                            const milestones = t.milestones;
                                                            return <div className="task-item" key={"task" + t.id}>

                                                                {milestones.map(milestone => {
                                                                    return <div className="milestone-flag" key={"milestone-" + milestone.id} >
                                                                        {milestoneFlag === milestone.id && sessionFlag === session.index &&
                                                                            <MilestoneFlag className="text-success" />
                                                                        }
                                                                    </div>
                                                                })}

                                                            </div>
                                                        })}
                                                    </div>
                                                    }

                                                </div>
                                                <div className="task-gap"></div>
                                            </div>
                                            :
                                            sessionFlag === session.index && <MilestoneFlag className="text-success" />
                                        }

                                    </div>
                                }
                            })}

                            {
                                totalSessions.map(item => {
                                    if (skip < item) {
                                        return <div className={props.currentSessionId == item ? "col-6 session active" : "col session in-active-session"} key={"blank-item" + item} style={{ maxWidth: finalWidth }}></div>
                                    }
                                })
                            }

                            <div className="row col-12 collapse edit-student-roster py-3" data-parent="#studentRosterList" id={"student-roster-" + item._id}>
                                <div className="input-form-group col-md-3 p-0">
                                    <label className="input-label">EMAIL</label>
                                    <input type="text" className="form-control px-0" name="emailId" value={item.emailId} onChange={(e) => updateRosterData(e.target.value, "emailId", item._id)} readOnly />
                                </div>
                                <div className="input-form-group col-md-3 p-0">
                                    <label>Last Name</label>
                                    <input disabled={disableEdit} type="text" className="form-control px-0" name="lastName" maxLength='40' value={item.lastName} onChange={(e) => updateRosterData(e.target.value, "lastName", item._id)} onBlur={(e) => updateStudentInfo(e.target.value, "lastName", item._id)} />
                                </div>
                                <div className="input-form-group col-md-3 p-0">
                                    <label>First Name</label>
                                    <input disabled={disableEdit} type="text" className="form-control px-0" name="firstName" maxLength='40' value={item.firstName} onChange={(e) => updateRosterData(e.target.value, "firstName", item._id)} onBlur={(e) => updateStudentInfo(e.target.value, "firstName", item._id)} />
                                </div>

                                <div className="col-md-12 controls p-0 pb-2 pt-4">
                                    {!disableEdit && <button className="btn btn-outline-primary btn-pill btn-text-normal mr-5 mb-3" onClick={() => resetPassword(item.emailId)}>
                                        Reset Password
                                    </button>}
                                    <button className="btn btn-outline-danger btn-pill btn-text-normal mb-3" onClick={() => { setRemoveStudentId(item._id); setShowRemoveStudentConfirmation(true); }}>
                                        Remove student
                                    </button>
                                </div>
                            </div>
                        </div>

                    </>
                })}

            </div>

            <div className="row show-more-button">
                {studentData.length > pageLength && studentData.length !== limit &&
                    <button onClick={() => { setLimit(studentData.length) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE ALL</button>
                }
                {studentData.length > pageLength && studentData.length === limit &&
                    <button onClick={() => { setLimit(pageLength) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE LESS</button>
                }

                {props.IsTourStart && <button type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE ALL</button>}
            </div>


            <ConfirmationBox
                isVisible={showRemoveStudentConfirmation}
                setVisible={(value) => { setShowRemoveStudentConfirmation(value) }}
                confirmationHeader="Confirm deletion"
                confirmationText="Are you sure you want to delete this student?"
                handleOK={removeStudent}
                okLabel="CONFIRM"
                cancelLabel="Cancel"
            />
        </>
    )
}

export default React.memo(Dashboard);