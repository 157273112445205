import React, { useReducer, useEffect, forwardRef, useRef, useImperativeHandle, useState } from "react";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import {
  Link, useLocation
} from "react-router-dom";
import "./Tour.css";
import $ from "jquery";

const TOUR_STEPS = [];

const INITIAL_STATE = {
  key: new Date(), // This field makes the tour to re-render when we restart the tour
  run: false,
  continuous: true,
  loading: false,
  stepIndex: 0,
  steps: TOUR_STEPS
};

// Reducer will manage updating the local state
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "START":
      return { ...state, run: true };
    case "RESET":
      return { ...state, stepIndex: 0 };
    case "STOP":
      return { ...state, run: false };
    case "NEXT_OR_PREV":
      return { ...state, ...action.payload };
    case "RESTART":
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date()
      };
    case "RE-START":
      localStorage.setItem("customStepNumber", (parseInt(localStorage.getItem("customStepNumber"))) + 2);
      return {
        ...state,
        stepIndex: parseInt(localStorage.getItem("customStepNumber")),
        run: true,
        loading: false,
        key: new Date()
      };
    default:
      return state;
  }
};

// Tour component
const Tour = forwardRef((props, ref) => {
  INITIAL_STATE.steps = props.tourSteps;
  const location = useLocation();

  // Tour state is the state which control the JoyRide component
  const [tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

  const [IsNextStep, setNextStep] = useState(false);
  useEffect(() => {
    // Auto start the tour if the tour is not viewed before
    if (!localStorage.getItem("tour")) {
      dispatch({ type: "START" });
    }

  }, []);

  useImperativeHandle(ref, () => ({

    getTour() {
      localStorage.setItem("tour", "0");
      dispatch({ type: "RESTART" });
    },
    nextStep() {
      setTimeout(() => {
        dispatch({ type: "RE-START" });
      }, 100)
    }
  }));

  // Set once tour is viewed, skipped or closed
  const setTourViewed = () => {
    localStorage.setItem("tour", "1");
  };

  const callback = data => {
    const { action, index, type, status } = data;
    if (data.step.target !== undefined && data.step.target === '.hiddenObject') {
      setTimeout(() => {
        $('.react-joyride__spotlight').css('display', "none");
      }, 100)
    }
    else {
      setTimeout(() => {
        $('.react-joyride__spotlight').css('display', "block");
      }, 100)
    }
    if (
      // If close button clicked, then close the tour
      action === ACTIONS.CLOSE ||
      // If skipped or end tour, then close the tour
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      if (action === ACTIONS.CLOSE) {
        props.amplitude.track("Visited Tool-Tip", { page_name: location.pathname, tool_tip_id: index, completed: "FALSE" });
      }
      if ((status === STATUS.SKIPPED && tourState.run)) {
        props.amplitude.track("Visited Tool-Tip", { page_name: location.pathname, tool_tip_id: index, completed: "FALSE" });
      }
      if (status === STATUS.FINISHED && type === "tour:end") {
        props.amplitude.track("Visited Tool-Tip", { page_name: location.pathname, tool_tip_id: index, completed: "TRUE" });
      }
      setTourViewed();
      dispatch({ type: "STOP" });
    }
    else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      // Check whether next or back button click and update the step.
      var el1 = document.querySelectorAll('.collapse.show');
      var el2 = document.querySelectorAll('.studentInfo');
      if (el1.length > 0 && el2.length > 0) {
        el1[0].classList.remove('show');
        for (var i = 0; i < el2.length; i++) {
          el2[i].classList.remove('studentInfoDynamicClass');
          el2[i].classList.remove('showCourseProgress');
          el2[i].classList.remove('editStudentTourClassDiv');
          el2[i].classList.remove('currentSessionAssessment');
          el2[i].classList.remove('studentInfoDynamicClass');
        }
      }
      if (action === ACTIONS.PREV) {
        let indx = index - 1;
        localStorage.setItem("customStepNumber", indx);
      }
      else {
        localStorage.setItem("customStepNumber", index);
      }
      if (status === "running" && type === EVENTS.STEP_AFTER && action === "next") {
        props.amplitude.track("Visited Tool-Tip", { page_name: location.pathname, tool_tip_id: index, completed: "FALSE" });
      }
      dispatch({
        type: "NEXT_OR_PREV",
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
      });
    }
    else if (type === 'step:before') {
      if (action === 'prev') {
        let indx = index - 1;
        localStorage.setItem("customStepNumber", indx);
      }
    }
    else if (type === 'tour:end') {
      setTourViewed();
      dispatch({ type: "STOP" });
    }
  };

  const startTour = () => {
    // Start the tour manually
    dispatch({ type: "RESTART" });
  };

  return (
    <>

      <JoyRide
        {...tourState}
        callback={callback}
        showSkipButton={false}
        showOverlay={true}
        disableOverlay={false}
        styles={{
          tooltipContainer: {
            textAlign: "left"
          },
          buttonBack: {
            marginRight: 10
          }
        }}
        locale={{
          back: 'Back', close: 'Close', last: 'Close', next: 'Next', skip: 'Skip',
        }}
      />
    </>
  );
});

export default Tour;
