import React from 'react';
import { Route, Redirect, useLocation, NavLink } from 'react-router-dom';
import "./admin.css";

const AdminLayout = ({ children, ...rest }) => {
    function PrivateRoute({ children, ...rest }) {
        let query = new URLSearchParams(useLocation().search);
        let user = JSON.parse(localStorage.getItem('userData'));
        let email = "";
        let token = "";

        if (user !== null) {
            email = user.email;
            token = user.token;
        }
        else {
            token = query.get("token");
        }

        if (rest.path == "/classreport" && email.indexOf('adeptus.in') == -1) {
            token = null;
        }

        if (user.roles.map(item => item.name).includes("Admin")) {
            console.log("is admin user");
        } else {
            token = null;
        }

        return (
            <Route
                {...rest}
                render={({ location }) =>
                    (token !== undefined && token !== null && token !== "") ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location }
                                }}
                            />
                        )
                }
            />
        );
    }

    return (
        <div className="d-flex" id="wrapper">
            <div className="bg-light border-right" id="sidebar-wrapper">
                <div className="sidebar-heading font-weight-bold">Spark Admin</div>
                <div className="list-group list-group-flush">
                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action bg-light" to='/admin'>Dashboard</NavLink>
                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action bg-light" to='/admin/classreport'>Class Reports</NavLink>
                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action bg-light" to='/admin/delete-test-data'>Delete Test Data</NavLink>
                </div>
            </div>

            <div id="page-content-wrapper">
                <div className="container-fluid fixed-container overflow-auto py-3">
                    <PrivateRoute>
                        {children}
                    </PrivateRoute>
                </div>
            </div>
        </div>
    )
}

const AdminLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={matchProps => (
            <AdminLayout>
                <Component {...matchProps} />
            </AdminLayout>
        )} />
    )
};

export default React.memo(AdminLayoutRoute);