import React, { useState, useEffect, useContext } from "react";
import {
    useHistory, useLocation
} from "react-router-dom";
import axios from "axios";
import UserContext from "../../shared/context/UserContext";
import {
    browserName, osVersion, osName, fullBrowserVersion, deviceType
} from "react-device-detect";

export function SSORedirection(props) {
    props.showLoader();

    const auth = useContext(UserContext);
    let history = useHistory();
    let query = new URLSearchParams(useLocation().search);
    const token = query.get("token");
    let provider = query.get("provider");
    const path = query.get("path");
    const type = query.get("type");
    const userid = query.get("userid");
    const [IpAddr, setIpAddr] = useState('');

    useEffect(() => {

        if (token !== null) {
            axios.get("/user/getuserbytoken", {
                headers: { Authorization: "bearer " + token }
            }).then(res => {
                if (provider !== null) {
                    if (provider.toLowerCase().indexOf("google") > -1) {
                        provider = "Google Account";
                    }
                    else if (provider.toLowerCase().indexOf("clever") > -1) {
                        localStorage.setItem('IsCleverSync', '0');
                        provider = "Clever Account";
                    }
                    else if (provider.toLowerCase().indexOf("canvas") > -1) {
                        provider = "Canvas Account";
                    }
                }

                let ssoUserData = res.data;
                auth.login(res.data._id, token, res.data.emailId, res.data.firstName, res.data.lastName, res.data.roles, provider);
                localStorage.setItem("district", res.data.district);

                if (path == 'createaccount') {
                    localStorage.setItem('SSORedirect', "true");
                    history.push('/' + path);
                }
                else {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                    axios.get("/user/get-state-district-name-by-userid/" + res.data._id).then(res1 => {
                        localStorage.setItem("stateDistrict", JSON.stringify(res1.data));
                        axios.post("user/audit", { userId: res.data._id, email: res.data.emailId, role: res.data.roles[0].name, signinMethod: provider, platform: deviceType, osName: osName, osVersion: osVersion, browser: browserName, browserVersion: fullBrowserVersion, loginTimestamp: new Date(), ipAddress: IpAddr }).then(res => {
                            history.push('/' + path);
                            if (provider !== null && provider == "Clever Account" && ssoUserData && (ssoUserData.isWelcomePageVisited == undefined || ssoUserData.isWelcomePageVisited == false)) {
                                props.amplitude.track("Registered Successfully", { page_name: "" });
                            }
                            props.amplitude.track("Signed In Successfully", { page_name: "" });
                        })
                    })
                }

            })
        }

        if (type !== null && type === 'newuser') {
            axios.get('/user/getuserbyid/' + userid).then((res) => {
                auth.login(res.data.userData._id, '', res.data.userData.emailId, res.data.userData.firstName, res.data.userData.lastName, res.data.userData.roles, '');
                localStorage.setItem("state", res.data.userData.state);
                localStorage.setItem("district", res.data.userData.district);
                history.push('/' + path);
            })

        }

        if (localStorage.getItem('IpAddress') !== null) {
            setIpAddr(localStorage.getItem('IpAddress').IPv4);
        }
    }, []);


    return (
        <></>
    )
}

export default React.memo(SSORedirection);