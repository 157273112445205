import React, { useState, forwardRef } from "react";

const ImportCourseXML = forwardRef((props, ref) => {
    const [selectedFile, setSelectedFile] = useState();
    const [error, setError] = useState();

    const convertXml = () => {
        if (selectedFile) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(selectedFile, "text/xml");
            var jsonText = structuredJson(xmlToJson(xmlDoc));
        } else {
            setError("select XML file first");
        }
    }

    const selectFile = (event) => {
        event.preventDefault();
        setSelectedFile(event.target.files[0])
        const input = document.getElementById('xmlfile')
        var reader = new FileReader();
        reader.readAsText(input.files[0], "UTF-8");
        reader.onload = function (evt) {
            setSelectedFile(evt.target.result);
        }
    }

    const structuredJson = (jsonString) => {
        const jsonObject = jsonString.metadata;
        const general = jsonObject.general;
        const rubric = jsonObject.rubric;
        const rubricCriteria = rubric.rubricCriteria;
        const sessions = jsonObject.sessions.session;
        const standards = jsonObject.LearningStandards.StateStandard;
        const resource = jsonObject.resources.resource;

        let course = {
            name: general.courseName,
            description: general.courseDescription,
            subject: general.courseSubject,
            version: parseFloat(general.courseVersion),
            isActive: true,
            totalSessions: sessions.length,
            rubric: {
                topText: rubric.rubricTopText,
                bottomText: "",
                documentURL: rubric.rubricPDFDownload,
                maxScore: 20,
                rubricCriteria: rubricCriteria.criteria.map(criteria => {
                    return {
                        title: criteria.title,
                        index: parseInt(criteria.index),
                        topText: criteria.topText,
                        bottomText: criteria.bottomText,
                        items: criteria.item.map(items => {
                            return {
                                title: items.title,
                                score: parseInt(items.score),
                                text: items.text
                            }
                        })
                    }
                })
            },
            sessions: sessions.map(singleSession => {
                const assessment = singleSession.assessment && singleSession.assessment.assessmentQuestion;
                const milestones = singleSession.milestones;
                let milestone = milestones.milestone;
                milestone = milestone.constructor.name === "Array" ? milestone : [{ id: milestone.id, text: milestone.text }];

                return {
                    index: parseInt(singleSession.index),
                    name: singleSession.sessionName,
                    milestoneBullets: milestones.milestoneBullets || "",
                    milestones: milestone.map(item => {
                        return {
                            id: parseInt(item.id),
                            name: item.text
                        }
                    }),
                    assessment: assessment && {
                        totalQuestions: assessment.length,
                        assessmentQuestion: assessment.map(item => {
                            return {
                                id: parseInt(item.id),
                                randomized: Boolean(item.randomized),
                                allowedChoices: parseInt(item.allowedChoices),
                                prompt: item.prompt,
                                imageUrl: item.image,
                                choices: item.choices.choice.map(choice => {
                                    return {
                                        choice: {
                                            id: parseInt(choice.id),
                                            text: choice.text,
                                            isCorrect: Boolean(choice.isCorrect)
                                        },
                                        feedbackCorrect: item.feedbackCorrect,
                                        feedbackIncorrect: item.feedbackIncorrect
                                    }
                                }),
                            }
                        })
                    },
                    resource: resource.filter(item => item.sessions.split(",").includes(singleSession.index)).map(items => {
                        return {
                            id: parseInt(items.id),
                            icon: items.icon,
                            states: items.states,
                            name: items.label,
                            link: items.link,
                        }
                    })
                }
            }),
            standards: standards.map(standards => {
                return {
                    states: standards.state,
                    title: standards.Title,
                    subTitle: standards.Subtitle,
                    description: standards.Description,
                    standards: standards.Standards.Standard.map(item => {
                        let Objectives = item.Objectives;
                        Objectives = item.Objectives.Objective.constructor.name === "Array" ? Objectives : { Objective: [{ Title: Objectives.Objective.Title, Description: Objectives.Objective.Description }] };
                        return {
                            title: item.Title,
                            Objectives: Objectives.Objective.map(objective => {
                                return {
                                    title: objective.Title,
                                    description: objective.Description
                                }
                            })
                        }
                    })
                }
            })
        }

        document.getElementById("courseJson").innerHTML = JSON.stringify(course, null, '\t');
        return course;
    }

    const xmlToJson = (xml) => {
        var obj = {};

        if (xml.nodeType == 1) {
            if (xml.attributes.length > 0) {
                obj = {};
                for (var j = 0; j < xml.attributes.length; j++) {
                    var attribute = xml.attributes.item(j);
                    obj[attribute.nodeName] = attribute.nodeValue;
                }
            }
        } else if (xml.nodeType == 3) {
            obj = xml.nodeValue;
        }

        var textNodes = [].slice.call(xml.childNodes).filter(function (node) {
            return node.nodeType === 3;
        });

        if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
            obj = [].slice.call(xml.childNodes).reduce(function (text, node) {
                return text + node.nodeValue;
            }, "");
        } else if (xml.hasChildNodes()) {
            for (var i = 0; i < xml.childNodes.length; i++) {
                var item = xml.childNodes.item(i);
                var nodeName = item.nodeName;

                if (item.nodeType === 4) {
                    JSON.stringify(obj) === '{}' ? obj = item.nodeValue : obj["text"] = item.nodeValue;
                } else if (typeof obj[nodeName] == "undefined") {
                    obj[nodeName] = xmlToJson(item);
                } else {
                    if (typeof obj[nodeName].push == "undefined") {
                        var old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(xmlToJson(item));
                }
            }
        }
        return obj;
    }

    return (
        <>
            <div className="minscreenheight">
                <div className="row p-5">

                    <div className="col-12">
                        <div className="form-group">
                            <label>Select course XML File</label>
                            <input type="file" className="form-control" name="file" onChange={selectFile} id="xmlfile" />
                            <label style={{ color: 'red' }}>{error}</label>
                        </div>

                        <button className="btn btn-info" onClick={convertXml}>Convert</button>

                    </div>
                    <div className="col-12 pt-4">
                        <textarea id="courseJson" className="form-control" rows="20"></textarea>
                    </div>
                </div>
            </div>
        </>
    )
})
export default React.memo(ImportCourseXML);