import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../shared/context/UserContext";
import Step4 from '../../assets/images/steps_04.png';
import "./AddClass.css";
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { SESSION_TYPE, DEFAULT_SESSIONS } from "../constants/CommonConstants";
import RadioButton from "../customInputs/RadioButton";
import axios from "axios";

const SessionSelection = props => {
    let history = useHistory();
    const auth = useContext(UserContext);
    const [selectedSession, setSelectedSession] = useState(DEFAULT_SESSIONS);
    const [init, setInit] = useState(false);

    const continueHandler = () => {
        props.handleSessionType(selectedSession);
    }

    const backHandler = () => {
        props.backStep(3);
    }

    const skipHandler = () => {
        props.amplitude.track("Clicked Skip on Add a Class Wizard", { page_section: "Select your session type" });
        props.handleSessionType(selectedSession);
    }

    const BackToTeacherDashboard = () => {
        history.push("/classdashboard/" + props.classDetails.classSectionId);
    }

    const onChangeSession = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        selectedSession[id] = value;
        setSelectedSession(selectedSession);
    }

    useEffect(() => {
        setSelectedSession({
            "1": "teacher",
            "2": "teacher",
            "3": "teacher",
            "4": "teacher",
            "5": "teacher"
        });


        const classSectionId = props && props.classSectionId;
        classSectionId && axios.get("/classSection/get-session-type/" + classSectionId).then(res => {
        });

    }, [init])
    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Select your session type" });
    }, [])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col" >
                        <div className="row justify-content-center px-5" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                {props.classDetails === null && <img src={Step4} />}
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">SELECT YOUR SESSION TYPE</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px", maxWidth: '998px', textAlign: "left" }}>
                                <p>Sessions can be taught live or asynchronously. Live sessions are teacher-facilitated, supporting in-person or online teaching. Asynchronous sessions are student self-guided, supporting many types of distance learning and allowing students to move at their own pace.</p>

                                <p>If you are facilitating a class and something arises or you have run out of time, you can easily change your session type at any time during the course.  Students do not lose any progress and will continue where you left off.</p>

                                <p>Select any sessions that you want to be student self-guided.</p>
                            </div>
                            <div className="col-12 p-3" style={{ maxWidth: '900px' }} onChange={onChangeSession}>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr><td className="no-left-border" />
                                            <td className="no-left-border" align="center">Session 1</td>
                                            <td align="center">Session 2</td>
                                            <td align="center">Session 3</td>
                                            <td align="center">Session 4</td>
                                            <td align="center" className="no-right-border">Session 5</td>
                                        </tr>
                                        <tr >
                                            <th className="text-right no-left-border">{SESSION_TYPE.teacher} <br />(Default)</th >
                                            <td align="center" >
                                                <RadioButton name="sessionOne" id="1" checked={selectedSession[1] === "teacher"} value={SESSION_TYPE.tf} />
                                            </td>
                                            <td align="center">
                                                <RadioButton name="sessionTwo" id="2" checked={selectedSession[2] === "teacher"} value={SESSION_TYPE.tf} />
                                            </td>
                                            <td align="center">
                                                <RadioButton name="sessionThree" id="3" checked={selectedSession[3] === "teacher"} value={SESSION_TYPE.tf} />
                                            </td>

                                            <td align="center">
                                                <RadioButton name="sessionFour" id="4" checked={selectedSession[4] === "teacher"} value={SESSION_TYPE.tf} />
                                            </td>

                                            <td align="center" className="no-right-border">
                                                <RadioButton name="sessionFive" id="5" checked={selectedSession[5] === "teacher"} value={SESSION_TYPE.tf} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-right no-left-border">{SESSION_TYPE.student}</th >
                                            <td align="center">
                                                <RadioButton name="sessionOne" id="1" checked={selectedSession[1] === "student"} value={SESSION_TYPE.sg} />
                                            </td>
                                            <td align="center">
                                                <RadioButton name="sessionTwo" id="2" checked={selectedSession[2] === "student"} value={SESSION_TYPE.sg} />
                                            </td>
                                            <td align="center">
                                                <RadioButton name="sessionThree" id="3" checked={selectedSession[3] === "student"} value={SESSION_TYPE.sg} />
                                            </td>

                                            <td align="center">
                                                <RadioButton name="sessionFour" id="4" checked={selectedSession[4] === "student"} value={SESSION_TYPE.sg} />
                                            </td>

                                            <td align="center" className="no-right-border">
                                                <RadioButton name="sessionFive" id="5" checked={selectedSession[5] === "student"} value={SESSION_TYPE.sg} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px", fontSize: '14px' }}>
                                <p><span className="font-weight-bold">Note:</span> If you skip this step, all sessions are set to Teacher Facilitated. You can also change this setting later.</p>
                            </div>
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center" }}>
                                {props.classDetails === null && <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>}
                                <button type="button" className="curve-fill-btn" id="continue" onClick={continueHandler} >CONTINUE</button>
                                {props.classDetails === null && <span className="skipBtn" onClick={skipHandler} >Skip Step</span>}
                                {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(SessionSelection);