import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap4-modal';

import closePNG from '../../../assets/images/icons/close.png';
import dummyImg from '../../../assets/images/placeholderImg.png';

import normmalOption from '../../../assets/images/icons/OffRadioButton.png';
import correctIcon from '../../../assets/images/icons/correctRadioButton.png';
import wrongIcon from '../../../assets/images/icons/incorrectRadioBtton.png';


import "./AssessmentBox.css";

const AssessmentBox = props => {

    const [assessmentQuestions, setAssessmentQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    useEffect(() => {
        if (props.assessment) {
            setAssessmentQuestions(props.assessment.assessmentQuestion);
            setSelectedQuestion(props.assessment.assessmentQuestion[0]);
        }
    }, [props])

    const handlerQuestion = (id) => {
        setSelectedQuestion(assessmentQuestions.filter(x => x.id === id)[0]);
    }

    const prevNext = (value) => {
        let currentIndex = assessmentQuestions.findIndex(x => x.id === selectedQuestion.id)
        if (value === 1)
            setSelectedQuestion(assessmentQuestions[currentIndex + 1]);
        else
            setSelectedQuestion(assessmentQuestions[currentIndex - 1]);
    }

    return (
        <>
            {
                selectedQuestion && <Modal id="SAQModal" dialogClassName="Modaldialog" className="SAQModal" visible={props.isshow} onClickBackdrop={() => { props.showmodal(false) }}>
                    <div className="modal-body">
                        <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { props.showmodal(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>

                        <div className="row">
                            <div className="col-10" style={{ textAlign: "left" }} >
                                <div style={{ marginBottom: "15px" }}>
                                    <span className="SAQ-head">{props.sessionName} Assessment Questions</span>
                                </div>
                                <div className="SAQ-question" >
                                    <span dangerouslySetInnerHTML={{ __html: selectedQuestion.prompt }}></span>
                                </div>
                            </div>
                        </div>


                        {selectedQuestion.imageUrl && <div className="row" style={{ marginTop: "25px" }}>
                            <div className={`${selectedQuestion.imageUrl == "" ? "col-12 " : "col-6"}`}>
                                {selectedQuestion.randomized && selectedQuestion.choices
                                    .map((a) => ({ sort: Math.random(), value: a }))
                                    .sort((a, b) => a.sort - b.sort)
                                    .map((item, index) => {

                                        return <div key={item.value._id}
                                            className={`row ${item.value.choice.isCorrect ? "correctBorder" : (selectedQuestion.answer.findIndex(x => x.id == item.value.choice.id) != -1 ? (item.value.choice.isCorrect ? "correctBorder" : "wrongBorder") : "normalBorder")}`}
                                            style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                            <div className="col-1 SAQ-optionQ" >
                                                {item.value.choice.isCorrect ? <img alt="" src={correctIcon} /> : (selectedQuestion.answer.findIndex(x => x.id == item.value.choice.id) != -1 ? (item.value.choice.isCorrect ? <img alt="" src={correctIcon} /> : <img alt="" src={wrongIcon} />) : <img alt="" src={normmalOption} />)}
                                            </div>
                                            <div className="col-11 SAQ-optiontext" style={{ textAlign: "left" }}>
                                                <span dangerouslySetInnerHTML={{ __html: item.value.choice.text }}></span>
                                            </div>
                                        </div>
                                    })}

                                {!selectedQuestion.randomized && selectedQuestion.choices
                                    .map((item, index) => {

                                        return <div key={item._id}
                                            className={`row ${item.choice.isCorrect ? "correctBorder" : (selectedQuestion.answer.findIndex(x => x.id == item.choice.id) != -1 ? (item.choice.isCorrect ? "correctBorder" : "wrongBorder") : "normalBorder")}`}
                                            style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                            <div className="col-1 SAQ-optionQ" >
                                                {item.choice.isCorrect ? <img alt="" src={correctIcon} /> : (selectedQuestion.answer.findIndex(x => x.id == item.choice.id) != -1 ? (item.choice.isCorrect ? <img alt="" src={correctIcon} /> : <img alt="" src={wrongIcon} />) : <img alt="" src={normmalOption} />)}
                                            </div>
                                            <div className="col-11 SAQ-optiontext" style={{ textAlign: "left" }}>
                                                <span dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                            </div>
                                        </div>
                                    })}
                            </div>
                            {selectedQuestion.imageUrl != "" &&
                                <div className="col-6" style={{ padding: "0px", textAlign: "end" }}>
                                    <img alt="placeholder" src={selectedQuestion.imageUrl || dummyImg} style={{ boxShadow: "-4px 4px #009DDB", width: "425px", height: "425px" }} />
                                </div>}
                        </div>}

                        {!selectedQuestion.imageUrl && <div className="row" style={{ marginTop: "25px", marginBottom: "30px" }}>
                            <div className="col-12">
                                <div className="row">

                                    {selectedQuestion.randomized && selectedQuestion.choices
                                        .map((a) => ({ sort: Math.random(), value: a }))
                                        .sort((a, b) => a.sort - b.sort)
                                        .map((item, index) => {

                                            return <div key={item.value._id}
                                                className={`col-5 ${item.value.choice.isCorrect ? "correctBorder" : (selectedQuestion.answer.findIndex(x => x.id == item.value.choice.id) != -1 ? (item.value.choice.isCorrect ? "correctBorder" : "wrongBorder") : "normalBorder")}`}
                                                style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                                <div className="row">
                                                    <div className="col-1 SAQ-optionQ" >
                                                        {item.value.choice.isCorrect ? <img alt="" src={correctIcon} /> : (selectedQuestion.answer.findIndex(x => x.id == item.value.choice.id) != -1 ? (item.value.choice.isCorrect ? <img alt="" src={correctIcon} /> : <img alt="" src={wrongIcon} />) : <img alt="" src={normmalOption} />)}
                                                    </div>
                                                    <div className="col-11 SAQ-optiontext" style={{ textAlign: "left" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: item.value.choice.text }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}

                                    {!selectedQuestion.randomized && selectedQuestion.choices
                                        .map((item, index) => {

                                            return <div key={item._id}
                                                className={`col-5 ${item.choice.isCorrect ? "correctBorder" : (selectedQuestion.answer.findIndex(x => x.id == item.choice.id) != -1 ? (item.choice.isCorrect ? "correctBorder" : "wrongBorder") : "normalBorder")}`}
                                                style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                                <div className="row">
                                                    <div className="col-1 SAQ-optionQ" >
                                                        {item.choice.isCorrect ? <img alt="" src={correctIcon} /> : (selectedQuestion.answer.findIndex(x => x.id == item.choice.id) != -1 ? (item.choice.isCorrect ? <img alt="" src={correctIcon} /> : <img alt="" src={wrongIcon} />) : <img alt="" src={normmalOption} />)}
                                                    </div>
                                                    <div className="col-11 SAQ-optiontext" style={{ textAlign: "left" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                </div>
                            </div>
                        </div>}
                        <div className="row" style={{ marginTop: "30px", marginBottom: "15px" }} >
                            <div className="col-12" style={{ textAlign: "left" }}>
                                <span onClick={() => { prevNext(-1) }} style={{ marginRight: "15px" }} className={`${selectedQuestion.id !== assessmentQuestions[0].id ? "enabledPage" : "disabledPage"}`} >Previous</span>
                                {assessmentQuestions.map((item, index) => {
                                    return <span onClick={() => { handlerQuestion(item.id) }} key={item.id} style={{ margin: "5px", padding: "0 7px", cursor: "pointer" }} className={`${selectedQuestion.id === item.id ? "selectedPageBorder" : ""}`}>{index + 1}</span>
                                })
                                }<span onClick={() => { prevNext(1) }} style={{ margin: "15px" }} className={`${selectedQuestion.id !== assessmentQuestions[assessmentQuestions.length - 1].id ? "enabledPage" : "disabledPage"}`}>Next</span>
                            </div>
                        </div>
                    </div>
                </Modal>
            }


        </>
    );
};

export default React.memo(AssessmentBox);