import React, { useEffect, useRef, useState, useContext } from "react";
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import { useForm } from "react-hook-form";
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';

import "./ClassReport.css";
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom";

const ClassReport = props => {
    const { ExportCSVButton } = CSVExport;
    const { SearchBar } = Search;
    const { register, handleSubmit, watch, errors } = useForm();
    const [classData, setClassData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [createdDate, setCreatedDate] = useState("");
    const [toCreatedDate, setToCreatedDate] = useState(new Date);
    const [modifiedDate, setModifiedDate] = useState("");
    const [sessionIndex, setSessionIndex] = useState(null);
    const [percentage, setPercentage] = useState(null);
    const [IsFilter, setFilter] = useState(false);
    const columns = [{
        dataField: '_id',
        text: 'Class ID',
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'name',
        text: 'Class Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'courseName',
        text: 'Course Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'teacherName',
        text: 'Teacher Name',
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'teacherEmail',
        text: 'Teacher Email',
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'teacherId',
        text: 'Teacher ID',
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'teacherDistrictId',
        text: 'Teacher District',
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'mode',
        text: 'Mode',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '200px', textAlign: 'center' };
        }
    }, {
        dataField: 'progress',
        text: 'Progress',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '200px', textAlign: 'center' };
        }
    }, {
        dataField: 'StudentCount',
        text: 'Student Count',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '200px', textAlign: 'center' };
        }
    }, {
        dataField: 'currentSession',
        text: 'Current Session',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '200px', textAlign: 'center' };
        }
    }, {
        dataField: 'createdOn',
        text: 'Created On',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }, {
        dataField: 'modifiedOn',
        text: 'Modified On',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '350px', textAlign: 'center' };
        }
    }];

    const column1 = [{
        dataField: '_id',
        text: 'Student ID'
    }, {
        dataField: 'firstName',
        text: 'First Name'
    }, {
        dataField: 'lastName',
        text: 'Last Name'
    }, {
        dataField: 'emailId',
        text: 'Email'
    }, {
        dataField: 'classSectionId',
        text: 'className Section Id'
    }, {
        dataField: 'courseProgress',
        text: 'Course Progress'
    }, {
        dataField: 'studentId',
        text: 'UserId'
    }]
    useEffect(() => {
        let filter = { createdDate1: "", createdDate2: "", modifiedDate: "", sessionIndex: "", percentage: "" };
        if (createdDate != "" && createdDate != undefined && createdDate != null)
            filter.createdDate1 = createdDate;

        if (toCreatedDate != "" && toCreatedDate != undefined && toCreatedDate != null)
            filter.createdDate2 = toCreatedDate;

        if (modifiedDate != "" && modifiedDate != undefined && modifiedDate != null)
            filter.modifiedDate = modifiedDate;

        if (sessionIndex != "" && sessionIndex != undefined && sessionIndex != null)
            filter.sessionIndex = sessionIndex;

        if (percentage != "" && percentage != undefined && percentage != null)
            filter.percentage = percentage;

        if (filter.sessionIndex != undefined && filter.sessionIndex != null) {
            axios.post("/classSection/getclassreportsessionwise", filter)
                .then(res => {
                    setClassData(res.data);
                })
        }
        else {
            axios.post("/classSection/getclassreport", filter)
                .then(res => {
                    setClassData(res.data);
                })
        }
    }, [IsFilter]);



    const expandRow = {

        renderer: row => (
            <div>
                <BootstrapTable
                    keyField='_id'
                    data={studentData}
                    columns={column1}
                />
            </div>
        ), showExpandColumn: true,
        onExpand: (row, isExpand, rowIndex, e) => {
            axios.post("/studentRoster/getByClassSectionId", { classSectionId: row._id })
                .then(res => {
                    setStudentData(res.data.result.rosterData);
                })
        }
    };

    const filterData = (data) => {
        let flag = false;

        if (data.createdDate != "") {
            setCreatedDate(data.createdDate);
            flag = true;
        }

        if (data.toCreatedDate != "") {
            setToCreatedDate(data.toCreatedDate);
            flag = true;
        }

        if (data.modifiedDate != "") {
            setModifiedDate(data.modifiedDate);
            flag = true;
        }

        if (data.sessionIndex != "") {
            setSessionIndex(parseInt(data.sessionIndex));
        }
        if (data.percentage != "") {
            setPercentage(parseInt(data.percentage));
        }

        if (flag == true) {
            setFilter(!IsFilter);
        }
    }

    return (
        <>
            <div className="col-md-12">
                <h4>Class Reports</h4>
            </div>
            <form className="signinform" onSubmit={handleSubmit(filterData)}>
                <div className="row">
                    <div className="col-md form-group">
                        <label htmlFor="created" >Created Date From:</label>
                        <input type="date" className="form-control" placeholder="Created Date" id="created" name="createdDate" ref={register({})} />
                    </div>
                    <div className="col-md form-group">
                        <label htmlFor="created" > Created Date To:</label>
                        <input type="date" className="form-control" placeholder="Created Date" id="created" name="toCreatedDate" ref={register({})} />
                    </div>

                    <div className="col-md form-group">
                        <label htmlFor="modified">modified Date :</label>
                        <input type="date" className="form-control" placeholder="modified Date" id="modified" name="modifiedDate" ref={register({})} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md form-group">
                        <label htmlFor="sessionIndex">Session Index :</label>
                        <input type="text" className="form-control" placeholder="session Index" id="sessionIndex" name="sessionIndex" ref={register({})} />
                    </div>
                    <div className="col-md form-group">
                        <label htmlFor="percentage">Percentage :</label>
                        <input type="text" className="form-control" placeholder="percentage" id="percentage" name="percentage" ref={register({})} />
                    </div>

                </div>
                <div className="row">
                    <div className="form-group col-md">
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </form>

            <ToolkitProvider
                keyField='_id' data={classData} columns={columns} exportCSV
            >
                {
                    props => (
                        <div className="col-md">
                            <ExportCSVButton {...props.csvProps} className="btn btn-outline-primary">Export CSV!!</ExportCSVButton>
                            <hr />
                            <BootstrapTable {...props.baseProps} expandRow={expandRow} />
                        </div>
                    )
                }
            </ToolkitProvider>

        </>
    )
}

export default ClassReport;