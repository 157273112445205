import React, { useState, useEffect, useContext } from 'react';
import UserContext from "../../shared/context/UserContext";
import axios from "axios";

import BootstrapTable from 'react-bootstrap-table-next';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import SelectSearch from 'react-select-search';
import Modal from "react-bootstrap4-modal";

const DeleteTestData = props => {
    const auth = useContext(UserContext);
    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);

    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [userState, setUserState] = useState("");
    const [userDistrict, setUserDistrict] = useState("");
    const [userType, setUserType] = useState("Teacher");
    const [searchKeyword, setSearchKeyword] = useState();
    const [selectedUserList, setSelectedUserList] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [softDeleteConfirm, setSoftDeleteConfirm] = useState(false);
    const [listDeleteConfirm, setListDeleteConfirm] = useState(false);


    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [disableDelete, setDisableDelete] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState("");

    const userTypeList = [{ name: "Teacher", value: "Teacher" }, { name: "Student", value: "Student" }];

    useEffect(() => {
        getUsersList();
        axios.get("/state/").then(res => {
            if (res.data.stateData.length > 0) {
                let list = [];
                res.data.stateData.forEach(element => {
                    list.push({
                        name: element.name,
                        value: element._id
                    });
                });
                list.sort((a, b) => {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
                setStateOptions(list);

            }
        })

    }, [])

    useEffect(() => {
        if (userState !== "") {
            getDistrictList(userState);
        }
    }, [userState]);

    const getUsersList = () => {

        axios.get(process.env.REACT_APP_APIURL + "/test-data/get-all", {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                setUserList(res.data.userData);
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        applyFilter();
    }, [userList]);

    useEffect(() => {
        applyFilter();
    }, [userState, userDistrict, userType, searchKeyword])

    const parseDate = (item) => {
        const dateItem = new Date(item);
        return dateItem.getDate() + "-" + (dateItem.getMonth() + 1) + "-" + dateItem.getFullYear();
    }

    const getDistrictList = (value) => {
        setUserDistrict("");
        if (value) {
            axios.get("/district/getbystate/" + value)
                .then(res => {
                    if (res.data.length > 0) {
                        let districts = [];
                        res.data.forEach(element => {
                            districts.push({
                                name: element.name,
                                value: element._id
                            });
                        });

                        districts.sort((a, b) => {
                            var nameA = a.name.toUpperCase();
                            var nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0;
                        });

                        let index1 = districts.findIndex(x => x.name == "Other");
                        let temp = districts.splice(index1, 1)[0];
                        districts.push(temp);
                        setDistrictOptions(districts);
                        if (localStorage.getItem('district') !== undefined) {
                            setUserDistrict(localStorage.getItem('district'));
                            localStorage.removeItem("district");
                        }
                    }
                })
        }
    }

    const selectUser = (e, item) => {
        const type = item.roles[0].name;
        const id = item._id;
        if (e.target.checked) {
            selectedUserList.push(id);
        } else {
            let temp = selectedUserList.findIndex(x => x == id);
            selectedUserList.splice(temp, 1);
        }

        if (selectedUserList.length > 0) {
            setDisableDelete(false);
        } else {
            setDisableDelete(true);
        }
        console.log(selectedUserList)
    }

    const deleteUser = (item, soft) => {
        const type = item.roles[0].name;
        const id = item._id;
        setSelectedUserId(id);

        if (soft) {
            setSoftDeleteConfirm(true);
        } else {
            setDeleteConfirm(true);
        }

    }

    const tempDeleteUserById = () => {

        axios.put(process.env.REACT_APP_APIURL + "/test-data/delete-user", { id: selectedUserId, temp: true }, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                getUsersList();
                setSoftDeleteConfirm(false);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setSoftDeleteConfirm(false);
                console.log(error)
            })
    }

    const deleteUserById = () => {

        axios.put(process.env.REACT_APP_APIURL + "/test-data/delete-user", { id: selectedUserId, temp: false }, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                getUsersList();
                setDeleteConfirm(false);
                setDeleteSuccess(true);
            })
            .catch(error => {
                setDeleteConfirm(false);

                console.log(error)
            })
    }

    const deleteUserList = () => {
        axios.put(process.env.REACT_APP_APIURL + "/test-data/delete-users", { ids: selectedUserList }, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                getUsersList();
                setDeleteSuccess(true);
                setListDeleteConfirm(false);

            })
            .catch(error => {
                setListDeleteConfirm(false);
                console.log(error)
            })
    }

    const applyFilter = () => {

        let lUserType = userType;
        let lUserState = userState;
        let lUserDistrict = userDistrict;
        let lSearchKeyword = searchKeyword;

        let filterd = lUserState ? userList.filter(item =>
            item.state[0] == lUserState) : userList;

        filterd = lUserDistrict ? filterd.filter(item =>
            item.district == lUserDistrict) : filterd;

        filterd = filterd.filter(item =>
            (lUserType && item.roles[0].name == lUserType) &&
            (item.emailId.includes(lSearchKeyword ? lSearchKeyword : "@")));

        setFilteredUserList(filterd);
    }

    const clearFilter = () => {
        setUserState("");
        setUserDistrict("");
        setSearchKeyword("");
    }

    const column = ['', 'User ID', 'User', 'Role', "Created On", "Update On", "Action"]
    return (
        <>

            <div className="row">
                <div className="col-md-12 pb-3">
                    <h4>Delete Test Data</h4>
                    Filters
                </div>
                <div className="col-md">
                    <SelectSearch className="select-search stateSelectBox" options={stateOptions} search value={userState} onChange={(e) => setUserState(e)} placeholder="State" />
                </div>
                <div className="col-md">
                    <SelectSearch disabled={!Boolean(userState)} className="select-search districtSelectBox" options={districtOptions} search value={userDistrict} onChange={(e) => setUserDistrict(e)} placeholder="District" />
                </div>
                <div className="col-md">
                    <SelectSearch className="select-search" options={userTypeList} value={userType} onChange={(e) => setUserType(e)} placeholder="Type" />
                </div>
                <div className="col-md">
                    <input type="search" className="form-control" value={searchKeyword} placeholder="search" onChange={(e) => setSearchKeyword(e.target.value)} />
                </div>
                <div className="col-md-2">
                    <div className="d-flex justify-content-between">
                        <button className="btn btn-outline-primary mr-2" onClick={() => clearFilter()} >Clear</button>
                        <button className="btn btn-outline-warning" style={{ color: 'orange' }} disabled={disableDelete} onClick={() => setListDeleteConfirm(true)} ><nobr>Delete data</nobr></button>
                    </div>
                </div>
            </div>

            <div className="row py-4 m-auto">

                <div className="col-md-12">

                    <table className="table">
                        <thead>
                            <tr>
                                {column.map((item) => { return <th > {item}</th> })}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUserList.map((row, index) => {
                                return <tr >
                                    <td>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <input type="checkbox" id={'uesr-' + index} onClick={(e) => selectUser(e, row)} disabled={row.isDeleted} />
                                            <span className="ml-3">{(index + 1)}</span>
                                        </div>
                                    </td>
                                    <td> {row._id}</td>
                                    <td> {row.firstName} {row.lastName} ({row.emailId})</td>
                                    <td> {row.roles[0].name}</td>
                                    <td>{parseDate(row.createdOn)}</td>
                                    <td>{parseDate(row.modifiedOn)}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <button className="btn btn-outline-warning p-1 mr-2" onClick={() => { deleteUser(row, true) }}><DeleteIcon titleAccess="Delete" /></button>
                                            <button className="btn btn-outline-danger p-1" onClick={() => { deleteUser(row, false) }}><DeleteForeverIcon titleAccess="Delete Forever" /></button>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal id="DeleteConfirm" dialogClassName="modal-dialog-centered" className="emailSent" visible={deleteConfirm} >
                <div className="modal-header">
                    <span className="d-flex align-items-center font-weight-bold ">
                        <DeleteIcon className="mr-2 text-danger" />
                        Delete
                    </span>
                </div>
                <div className="modal-body"  >
                    This action will delete user data and it will not be retrieved again..
                </div>
                <div className="modal-footer">
                    <button className="btn btn-sm" onClick={() => { setDeleteConfirm(false) }}>Cancel</button>
                    <button className="btn btn-sm btn-primary" onClick={() => { deleteUserById() }}>
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal id="softDeleteConfirm" dialogClassName="modal-dialog-centered" className="emailSent" visible={softDeleteConfirm} >
                <div className="modal-header">
                    <span className="d-flex align-items-center font-weight-bold">
                        <DeleteIcon className="mr-2 text-danger" />
                        Delete
                    </span>
                </div>
                <div className="modal-body"  >
                    This action will delete the user's data.
                </div>
                <div className="modal-footer">
                    <button className="btn btn-sm" onClick={() => { setSoftDeleteConfirm(false) }}>Cancel</button>
                    <button className="btn btn-sm btn-primary" onClick={() => { tempDeleteUserById() }}>
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal id="listDeleteConfirm" dialogClassName="modal-dialog-centered" className="emailSent" visible={listDeleteConfirm} >
                <div className="modal-header">
                    <span className="d-flex align-items-center font-weight-bold">
                        <DeleteIcon className="mr-2 text-danger" />
                        Delete
                    </span>
                </div>
                <div className="modal-body">
                    This action will delete the all selected user's data.
                </div>

                <div className="modal-footer">
                    <button className="btn btn-sm" onClick={() => { setListDeleteConfirm(false) }}>Cancel</button>
                    <button className="btn btn-sm btn-primary" onClick={() => { deleteUserList() }}>
                        Delete
                    </button>
                </div>
            </Modal>

            <Modal id="deleteSuccess" dialogClassName="modal-dialog-centered" className="emailSent" visible={deleteSuccess} >
                <div className="modal-header">
                    <span className="d-flex align-items-center text-success font-weight-bold">
                        <CheckCircleOutlineIcon className="mr-2" />
                        Successfully deleted
                    </span>
                </div>
                <div className="modal-body">
                    Delete deleted successfully!
                </div>
                <div className="modal-footer">
                    <button className="btn btn-sm btn-outline-primary px-4" onClick={() => { setDeleteSuccess(false) }}>Ok</button>
                </div>
            </Modal>
        </>
    );
}

export default React.memo(DeleteTestData);