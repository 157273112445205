import React, { useState, useEffect, useContext, forwardRef, useRef, useImperativeHandle } from "react";
import "./ClassDetails.css";
import axios from "axios";
import {
    useParams
} from "react-router-dom";
import Tour from "../../shared/components/tour/Tour";
import SelectSearch from 'react-select-search';
import UserContext from "../../shared/context/UserContext";
import Dashboard from "./Dashboard";
import Assessment from "./Assesments";
import Facilitation from "./Facilitation";
import Gradebook from "./Gradebook";
import Projects from "./Projects";
import ProfessionalLearning from "./ProfessionalLearning";
import Switch from "../../shared/components/switch/Switch";
import DownIcon from '../../assets/images/icons/Down.png';
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';

import ExportGrades from '../../shared/components/exportGrades/ExportGrades';
import ChangeSession from '../SessionType/ChangeSession'
import { DEFAULT_SESSIONS } from "../constants/CommonConstants";

import ClassDetailsHeader from "./ClassDetailsHeader";

const ClassDetails = forwardRef((props, ref) => {
    const child_Ref = useRef();
    const childRef = useRef();
    const dashboardTourSteps = [
        {
            target: ".hiddenObject",
            content: (
                <div>
                    Welcome to you Class detail page! On this page you can facilitate your class, see your students' progress in real time, check their assessments results, and grade their work.
                    <br />
                    <br />
                    <strong>Let’s take a look around..... </strong>
                </div>
            ),
            disableBeacon: true,
            placement: "center"
        },
        {
            target: ".sessionDropdown",
            content: "Your class is divided into sessions. To change the displayed session, select the desired session from the drop-down list.",
            placement: "right"
        },
        {
            target: ".sessionSwitch",
            content: (
                <div>
                    <p>In <b>Teacher-Facilitated</b> sessions, each session is locked until you allow your students access. When you’re ready to unlock a session for all the students on your roster, slide the Release Assessment toggle to the right.</p>

                    <p>For <b>Student Self-Guided</b> sessions, the session assessment is automatically released for the entire class. You can lock or unlock this assessment for individual students from the Student Roster.</p>
                </div>
            ),
            placement: "left"
        },
        {
            target: ".lessonPlanMaterial",
            content: (
                <div>Everything you need to prep and teach a session is gathered in the Lesson Plan Materials section. To download copies, click the individual document or select <strong>Download all</strong>. </div>
            ),
            placement: "right"
        },
        {
            target: ".viewLearningStandardLabel",
            content: (
                <div>We know learning standards matter. To view the standards to which this course is aligned, click Learning Standards. </div>
            ),
            placement: "right"
        },
        {
            target: ".viewSessionAssessmentQue",
            content: (
                <div>Formative assessments enable you to gauge students’ comprehension of each session’s content. You can use the results to provide appropriate teaching support during the next session. To view this session’s formative assessment questions, click <strong>View Session Assessment Questions</strong>.</div>
            ),
            placement: "right"
        },
        {
            target: ".dashboardStudentRoster",
            content: "The students currently enrolled in your class are displayed in the class roster. Click the up/down arrows to sort by student name.",
            placement: "top"
        },
        {
            target: ".seeAllBtn",
            content: (
                <div>To view the entire class list, click <strong>See All</strong>. Click <strong>See Less</strong> to hide the list after viewing it.</div>
            ),
            placement: "right"
        },
        {
            target: ".addStudentLable",
            content: (
                <div>To add a new student to the class, click <strong>Add Students</strong>.</div>
            ),
            placement: "right"
        },
        {
            target: ".syncStudentLable",
            content: (
                <div>To sync students to the class, click <strong>Sync Students</strong>.</div>
            ),
            placement: "right"
        },
        {
            target: '.editStudentIcon',
            content: (
                <div>
                    Click on the edit icon
                </div>
            ),
            disableOverlayClose: true,
            placement: 'bottom',
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                },
                options: {
                    zIndex: 10000,
                },
            }
        },
        {
            target: ".editStudentTourClassDiv",
            content: "To edit a student’s email address or first and last name, or to reset the student’s password or remove the student from the course, click the edit icon.",
            placement: "right"
        },
        {
            target: ".statusCol",
            content: (
                <div>To add a new student to the class, click <strong>Add Students</strong>.</div>
            ),
            disableBeacon: false,
            placement: "right"
        },
        {
            target: '.progress',
            content: (
                <div>
                    Click on the progress icon
                </div>
            ),
            disableBeacon: false,
            disableOverlayClose: true,
            placement: 'bottom',
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                },
                options: {
                    zIndex: 10000,
                },
            }
        },
        {
            target: ".showCourseProgress",
            content: "The Course Progress column shows student progress in their project. Click the up/down arrows to sort by course progress. For details broken out by session, click the Course Progress percentage.",
            placement: "top"
        },
        {
            target: '.sessionPreviousAssessmentTourClass',
            content: (
                <div>
                    Click on the progress icon
                </div>
            ),
            disableBeacon: false,
            disableOverlayClose: true,
            placement: 'bottom',
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                },
                options: {
                    zIndex: 10000,
                },
            }
        },
        {
            target: ".previousSessionAssessment",
            content: "The Previous Session Performance column displays student performance on formative assessment questions from previous sessions. Click the up/down arrows to sort by performance. To view question result details, click the Previous Session Performance score.",
            placement: "right",
            disableBeacon: false,
        },
        {
            target: ".releaseAssessmentCol",
            content: (
                <div>
                    <p>To make the formative assessment available or not available to an individual student, slide the Release Assessment toggle to the right. Click the up/down arrows to sort by assessment status.
                    </p>
                    <p>
                        <strong>Tip:</strong> On the session’s Facilitation page, there is a button to release the assessment to all students with a single click.
                    </p>
                </div>
            ),
            placement: "left"
        },
        {
            target: '.sessionAssessmentTourClassDiv',
            content: (
                <div>
                    Click on the progress icon
                </div>
            ),
            disableBeacon: false,
            disableOverlayClose: true,
            placement: 'bottom',
            spotlightClicks: true,
            styles: {
                buttonNext: {
                    display: 'none',
                },
                options: {
                    zIndex: 10000,
                },
            }
        },
        {
            target: ".currentSessionAssessment",
            content: "The Current Session Assessment column displays student performance on this session’s formative assessment questions. Click the up/down arrows to sort by performance. To view question result details, click the Current Session Assessment score.",
            placement: "top",
            disableBeacon: false
        }
    ];
    const facilitationSteps = [
        {
            target: ".facilitationTab",
            content: "Your Facilitation page holds a variety of tools you can use while you are teaching a session. ",
            disableBeacon: true,
            placement: "right"
        },
        {
            target: ".myProjectDiv",
            content: (
                <div>You can create a new project to use for testing or class demonstrations. Click <strong>My Project</strong>.</div>
            ),
            placement: "right"
        },
        {
            target: ".releaseAssessmentDiv",
            content: (
                <div>To make this session’s formative assessment questions available to all students, click <strong>Release Assessment</strong>. </div>
            ),
            placement: "left"
        },
        {
            target: ".timerDiv",
            content: "The timer allows you to pace instruction to the times listed in the Agenda of your Lesson Details document.",
            placement: "left"
        },
        {
            target: ".sessionJourney",
            content: "View your students’ progress in their project work. Under Lesson Journey, when 80% of your students have completed a project milestone, it registers as complete. ",
            placement: "top"
        }
    ];
    const projectSteps = [
        {
            target: ".projectTab",
            content: "Your Projects page is the central location from which you’ll view and assess your students’ submitted projects. ",
            disableBeacon: true,
            placement: "right"
        },
        {
            target: ".statusCol",
            content: "You can quickly view which students have submitted their final projects for your review. Click the up/down arrows to sort by submission status.",
            placement: "right"
        },
        {
            target: ".unfreezeProjectCol",
            content: "Students can’t edit their projects after they have submitted them for review. If a student mistakenly submits their project, you can send it back to them by sliding the Unfreeze Project toggle to the right. ",
            placement: "left"
        },
        {
            target: ".projectCol",
            content: (
                <div>When you’re ready to score a student’s project, click <strong>Score Project</strong>. Click the up/down arrows to sort by projects ready for grading.</div>
            ),
            placement: "left"
        },
        {
            target: ".scoreCol",
            content: "You can view the student’s raw numeric score, calculated from the project rubric. Click the up/down arrows to sort projects by score.",
            placement: "left"
        },
        {
            target: ".achievedCol",
            content: "You also see the student’s scored level. Click the up/down arrows to sort projects by level.",
            placement: "left"
        },
        {
            target: ".goToStudentProjectBtn",
            content: "To view the student’s submitted project, click Go to student project.",
            placement: "right"
        },
        {
            target: ".rubricProjectTabHead",
            content: "You can scroll through each rubric criteria by clicking the tabs.",
            placement: "top"
        },
        {
            target: ".rubricInnerBody",
            content: "The graded level score for each criteria is highlighted.",
            placement: "top"
        }
    ];
    const gradebookSteps = [
        {
            target: ".gradebookTab",
            content: "Your Gradebook is where you’ll find a summary of each student’s graded formative assessments for each session and their final project grade. ",
            placement: "right"
        },
        {
            target: ".gradebook-headers",
            content: "Click the up/down arrows to sort by student name.",
            placement: "top"
        }
    ];

    const tourSteps = dashboardTourSteps;
    const [sessionList, setSessionList] = useState([]);
    const [dropdownSessionList, setDropdownSessionList] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [currentSession, setCurrentSession] = useState(0);
    const [selectedsessionItem, setSelectedsessionItem] = useState(null);
    const auth = useContext(UserContext);
    const [value, setValue] = useState(false);
    let param = useParams();
    const [classId, setClassId] = useState(param.id);
    const [sectionId, setSectionId] = useState();
    const [mode, setMode] = useState();
    const [classDetails, setClassDetails] = useState(null);
    const [boxMessage, setBoxMessage] = useState('');
    const [IsSessionUnlock, setSessionUnlock] = useState(false);
    const [IsTourStart, setTourStart] = useState(false);
    const [sessionType, setSessionType] = useState(DEFAULT_SESSIONS);
    const [studentRosterID, setStudentRosterID] = useState("");
    const [district, setDistrict] = useState();
    const [sessionMaterials, setSessionMaterials] = useState([]);
    const [otherSessionMaterials, setOtherSessionMaterials] = useState([]);
    const [newRoster, setNewRoster] = useState({});

    function startWebsocket() {
        const URL = process.env.REACT_APP_WEBSOCKETURL.replace('http', 'ws');
        let webSocketClient = new WebSocket(URL);

        webSocketClient.onopen = function () {
            webSocketClient.send(JSON.stringify(
                [{
                    request: 'SUBSCRIBE',
                    channel: "studentRoster" + auth.userId,
                    message: 'assessmentSubmitted'
                },
                ]));

            webSocketClient.onmessage = function (event) {
                let data = JSON.parse(event.data);
                data.channel == 'studentRoster' + auth.userId && updateStudentRoster(data);
            };
        };

        webSocketClient.onclose = function () {
            webSocketClient = null
            setTimeout(startWebsocket, 5000)
        }

    }

    useEffect(() => {
        startWebsocket();
    }, [auth.userId]);


    const selectStyle = {
        backgroundImage: "url('" + DownIcon + "')"
    }

    const [activeTab, setActiveTab] = useState('');
    const [students, setStudents] = useState({
        rosterData: [],
        studentUserData: []
    });
    const [facilitationStaticData, setFacilitationStaticData] = useState(null);
    const [PFStaticData, setPFStaticData] = useState(null);

    const setTab = (value) => {
        setActiveTab(value);
    }

    const [isExport, setIsExport] = useState(false);
    const [exportData, setExportData] = useState({ data: [], headers: [], fileName: "" });
    const [canvasData, setCanvasData] = useState({ data: [], headers: [], fileName: "" });
    const [isGradingCompleted, setIsGradingCompleted] = useState(false);

    const [releaseMsg, setReleaseMsg] = useState(false);


    const updateStudentRoster = (data) => {
        setNewRoster(data);
    }

    const openGradingModal = () => {
        props.amplitude.track("Clicked Class Details Button", { button_name: "Export Grades", session_id: selectedSession.index, session_type: classDetails.sessionType[selectedSession.index], subject: classDetails.subject, class_section_id: classId });

        if (students.rosterData.length == 0) {
            setReleaseMsg(true);
        }
        else {
            let filename = "Course-" + classDetails.courseName + ".csv";
            let iscomplete = true;
            let headers = [
                { label: "Student", key: "Student" },
                { label: "Spark ID", key: "SparkID" },
                { label: "Spark Login ID", key: "SparkLoginID" },
                { label: "SIS User ID*", key: "SISUserID*" },
                { label: "Project Score (MAX:" + students.rosterData[0].rubric.maxScore + ")", key: "TotalScored" }
            ];


            for (let i = 0; i < students.rosterData[0].sessions.length; i++) {
                if (i == 4) {
                    continue;
                }
                let tempObj = {
                    label: students.rosterData[0].sessions[i].name + " (" + students.rosterData[0].sessions[i].assessment.totalQuestions + ")",
                    key: students.rosterData[0].sessions[i].name
                }
                headers.push(tempObj);
            }

            let data = [];

            students.rosterData.forEach((item, index) => {
                if (!item.rubric.isGraded) { iscomplete = false }
                let temp = {};
                temp["Student"] = item.lastName + ", " + item.firstName;
                temp["SparkID"] = item._id;
                temp["SparkLoginID"] = item.emailId;
                temp["SISUserID"] = "";
                temp["TotalScored"] = item.rubric.totalScored;
                item.sessions.forEach((x, i) => {
                    temp[x.name] = x.assessment.totalCorrectAnswers;
                })
                data.push(temp);
            })
            setExportData({ headers: headers, data: data, fileName: filename })

            //canvas data
            let canvasFilename = "Course-" + classDetails.courseName + "-canvas.csv";
            let canvasHeaders = [
                { label: "Student", key: "Student" },
                { label: "ID", key: "ID" },
                { label: "SIS User ID*", key: "SISUserID" },
                { label: "SIS Login ID*", key: "SISLoginID" },
                { label: "Section", key: "Section" },
            ];

            for (let i = 0; i < students.rosterData[0].sessions.length; i++) {
                let tempObj = {
                    label: students.rosterData[0].sessions[i].name,
                    key: students.rosterData[0].sessions[i].name
                }
                canvasHeaders.push(tempObj);
            }
            canvasHeaders.push({ label: "Current Score (entire course)", key: "CurrentScore" })
            canvasHeaders.push({ label: "Final Score (entire course)", key: "FinalScore" })

            let canvasdata = [];
            let maxPointsRow = {
                Student: "Points Possible",
                ID: "",
                SISUserID: "",
                SISLoginID: "",
                Section: "",
                CurrentScore: "",
                FinalScore: ""
            }
            students.rosterData.forEach((item, index) => {
                let temp = {};
                temp["Student"] = item.lastName + ", " + item.firstName;
                temp["ID"] = item._id;
                temp["SISUserID"] = "";
                temp["SISLoginID"] = "";
                temp["Section"] = item.name;
                temp["CurrentScore"] = item.rubric.totalScored;
                temp["FinalScore"] = item.rubric.totalScored;
                item.sessions.forEach((x, i) => {
                    temp[x.name] = x.assessment.totalCorrectAnswers;
                    maxPointsRow[x.name] = x.assessment.totalQuestions
                })
                canvasdata.push(temp);
            })

            canvasdata.unshift(maxPointsRow)

            setCanvasData({ headers: canvasHeaders, data: canvasdata, fileName: canvasFilename })

            setIsGradingCompleted(iscomplete);
            setIsExport(true);
        }
    }


    const [refreshPage, setRefreshPage] = useState(null)

    const hitRefreshPage = (tab, prevTab) => {
        setRefreshPage(tab);
        setTimeout(() => {
            setRefreshPage(prevTab);
        }, 50);
    }

    const removeStudent = (rosterData) => {
        setStudents({ ...students, rosterData });
    }

    const getHelp = () => {
        child_Ref.current.getTour();
    }

    const setTourNextStep = () => {
        child_Ref.current.nextStep();
    }

    useEffect(() => {
        trackPageVisit(activeTab || "Dashboard");

        let flag = false;
        if (auth.token) {
            props.showLoader();
            axios.get("/user/getusercontextualtourdata/" + auth.userId).then(res => {
                if (res.data.contextualTour.length > 0) {
                    let data = res.data.contextualTour.filter(val => val.pageName == 'ClassDetails');
                    if (data.length > 0) {
                        flag = false;
                    }
                    else {
                        flag = true;
                        axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'ClassDetails' } });
                    }
                }
                else {
                    flag = true;
                    axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'ClassDetails' } });
                }
            });
            axios.get("/classSection/getbyid/" + classId)
                .then(res => {
                    setClassDetails(res.data[0]);

                    if (res.data && res.data[0].courseName.toLowerCase().indexOf("history") > -1) {
                        setStudentRosterID('5f23003e7c2bea65b990c2b1');
                    }

                    if (res.data && res.data[0].courseName.toLowerCase().indexOf("physics") > -1) {
                        setStudentRosterID('5f2300b97c2bea65b990c2d5');
                    }
                    if (res.data && res.data[0].courseName.toLowerCase().indexOf("algebra") > -1) {
                        setStudentRosterID('5f2300787c2bea65b990c2cd');
                    }

                    setSectionId(res.data[0].cleverClassSectionId);
                    setMode(res.data[0].mode);
                    setSessionType(res.data[0].sessionType || DEFAULT_SESSIONS);
                    if (res.data[0].sessions.length > 0) {
                        setSessionList(res.data[0].sessions);
                        let list = [];
                        res.data[0].sessions.forEach(element => {
                            list.push({
                                name: element.name,
                                value: element.index.toString()
                            });
                        })
                        setDropdownSessionList(list);
                        if (selectedsessionItem == null) {
                            if (res.data[0].currentSession) {
                                setSelectedsessionItem(res.data[0].currentSession.toString());
                            }
                            else {
                                setSelectedsessionItem(res.data[0].sessions[0].index.toString());
                            }
                        }
                    }
                    if (flag) {
                        getHelp();
                    }
                    props.hideLoader();
                })

            axios.post("/studentRoster/getByClassSectionId", { classSectionId: classId })
                .then(res => {
                    setStudents({
                        rosterData: res.data.result.rosterData,
                        studentUserData: res.data.result.studentUserData
                    });
                    if (!refreshPage)
                        setActiveTab('DASHBOARD');
                    else
                        setActiveTab(refreshPage)
                    props.hideLoader();
                })

            axios.get("/staticContent/ProfessionalLearning", {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    setPFStaticData(res.data.Data);
                })
            const districtID = localStorage.getItem("district");
            axios.get("/district/get-district-by-id/" + districtID)
                .then(res => {
                    setDistrict(res.data);
                })

            axios.get("/staticContent/facilitation", {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    setFacilitationStaticData(res.data.Data);
                })
        }
    }, [refreshPage]);

    const trackPageVisit = (page_section) => {
        props.amplitude.track("Teacher Page Visits", { class_section_id: classId, page_name: "Class Details", page_section });
    }

    const getClassSection = () => {
        axios.get("/classSection/getbyid/" + classId)
            .then(res => {
                setClassDetails(res.data[0]);
                setSectionId(res.data[0].cleverClassSectionId);
                setMode(res.data[0].mode);
                setSessionType(res.data[0].sessionType);
                if (res.data[0].sessions.length > 0) {
                    setSessionList(res.data[0].sessions);
                    let list = [];
                    res.data[0].sessions.forEach(element => {
                        list.push({
                            name: element.name,
                            value: element.index.toString()
                        });
                    })
                    setDropdownSessionList(list);
                    if (selectedsessionItem == null) {
                        if (res.data[0].currentSession) {
                            setSelectedsessionItem(res.data[0].currentSession.toString());
                        }
                        else {
                            setSelectedsessionItem(res.data[0].sessions[0].index.toString());
                        }
                    }

                    setValue(res.data[0].sessions.filter(x => x.index === parseInt(parseInt(selectedsessionItem)))[0].isUnlocked);
                }
            }).then(res => {
                axios.post("/studentRoster/getByClassSectionId", { classSectionId: classId })
                    .then(res => {
                        setStudents({
                            rosterData: res.data.result.rosterData,
                            studentUserData: res.data.result.studentUserData
                        });

                        if (activeTab) {
                            setActiveTab(activeTab);
                        }

                        props.hideLoader();
                    })
            }).catch(errror => {
                props.hideLoader();
            })
    }

    useEffect(() => {
        if (selectedsessionItem !== null) {
            const session = sessionList.filter(x => x.index === parseInt(selectedsessionItem))[0];
            const materialData = Array.from(session.resource);
            const otherMaterial = materialData.splice(3);
            setSessionMaterials(materialData);
            setOtherSessionMaterials(otherMaterial);

            setSelectedSession(session);
            setValue(session.isUnlocked);
            if (session.isUnlocked === true) {
                setCurrentSession(parseInt(selectedsessionItem));
            } else {
                getClassSection();
            }

            if (activeTab !== '') {
                setActiveTab("DASHBOARD");
            }
            if (activeTab) {
                setActiveTab(activeTab);
            }
        }
    }, [selectedsessionItem]);

    useEffect(() => {
        if (IsTourStart == true) {
            child_Ref.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    const LaunchFacilitaionHandler = () => {
        props.amplitude.track("Clicked Class Details Button", { button_name: "Launch Facilitation", session_id: selectedSession.index, session_type: classDetails.sessionType[selectedSession.index], subject: classDetails.subject, class_section_id: classId });
        setActiveTab("FACILITATION");
    }

    const setStudentSessionData = () => {
        props.amplitude.track("Clicked Class Details Button", { button_name: "Unlock Session", session_id: selectedSession.index, session_type: classDetails.sessionType[selectedSession.index], subject: classDetails.subject, class_section_id: classId });

        if (value == false) {
            if (parseInt(selectedsessionItem) - classDetails.currentSession === 1) {
                props.showLoader();
                axios.put("/studentRoster/addsession", { classSectionId: classId, sessionIndex: selectedSession.index, flag: !value }).then(res => {
                    axios.post("/studentRoster/getByClassSectionId", { classSectionId: classId })
                        .then(res => {
                            setStudents({
                                rosterData: res.data.result.rosterData,
                                studentUserData: res.data.result.studentUserData
                            });
                            let tempselectedSession = selectedSession;
                            if (tempselectedSession.isUnlocked === false) {
                                tempselectedSession.isUnlocked = true;
                                setSelectedSession(tempselectedSession);
                            }

                            setCurrentSession(selectedSession.index);
                            if (childRef.current && childRef.current.sessionUnlocked) {

                                childRef.current.sessionUnlocked();
                            }
                            props.hideLoader();
                        });
                    axios.get("/classSection/getbyid/" + classId)
                        .then(res => {
                            setClassDetails(res.data[0]);
                            setSessionList(res.data[0].sessions);
                        });
                });

                setValue(!value);

                axios.patch("/classSection/updateclasssectiondate/" + classId,
                    {
                        currentSession: selectedSession.index
                    })
                    .then(response => {
                    })
                    .catch(err => {
                        console.log("failed to add class section :", err);
                    })
            }
            else {
                setBoxMessage('This session can not be unlocked as the previous session is not unlocked yet.');
                setSessionUnlock(true);
            }
        }
        else {
            setBoxMessage('The session once unlocked can not be locked again. ');
            setSessionUnlock(true);
            setValue(true);
        }
    }

    const updateSessionType = (value) => {
        props.showLoader();
        sessionType[selectedsessionItem] = value;
        setSessionType(sessionType);
        props.amplitude.track("Clicked Class Details Button", { button_name: "Change Session Type", session_id: selectedSession.index, session_type: classDetails.sessionType[selectedSession.index], subject: classDetails.subject, class_section_id: classId });
        if (classId) {
            axios.patch("/classSection/updateclasssectionsessiontype/" + classId,
                {
                    sessionType: sessionType
                })
                .then(response => {
                    if (value === "student" && parseInt(selectedsessionItem) - classDetails.currentSession === 1) {
                        setStudentSessionData();
                    } else {
                        getClassSection();
                    }

                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
    }

    const handleStudentData = (value) => {
        setStudents({
            rosterData: value.rosterData,
            studentUserData: value.studentUserData
        });
    }
    const axiosInstance = axios.create();
    const canvasExport = () => {

        let courseID = students.rosterData[0].sis_course_id;
        let courseSectionID = students.rosterData[0].sis_section_id;
        let districtID = students.rosterData[0].district;
        let assessmentIDs = [];
        axios.get("/canvasMapping/getCanvasMapping/" + districtID)
            .then(result => {
                let course = result.data.courses.filter(x => x.canvasCourseID == courseID)

                axiosInstance.defaults.baseURL = result.data.canvasBaseURL;
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + result.data.canvasAccessToken;

                if (course.length == 0) {
                    classDetails.sessions.forEach((item, key, arr) => {
                        let assessmentName = "";
                        let maxscore = 0;
                        if (item.assessment && item.assessment.assessmentQuestion.length != 0) {
                            maxscore = item.assessment.totalQuestions;
                            assessmentName = "Session " + item.index + " Formative Assessment"
                        }
                        else {
                            maxscore = students.rosterData[0].rubric.maxScore;
                            assessmentName = "Project Culminating Assessment"
                        } axiosInstance.post(`/courses/sis_course_id:${courseID}/assignments?assignment[name]=${assessmentName}&assignment[published]=true&assignment[points_possible]=${maxscore}`, {})
                            .then(response => {
                                assessmentIDs.push(response.data.id);
                                if (key === arr.length - 1) {
                                    pushGradesToCanvas(assessmentIDs, courseID);
                                    addCourseToCanvasMapping(result.data._id, {
                                        canvasCourseId: courseID,
                                        canvasSectionId: courseSectionID,
                                        sparkCourseId: null,
                                        assignment1Id: assessmentIDs[0],
                                        assignment2Id: assessmentIDs[1],
                                        assignment3Id: assessmentIDs[2],
                                        assignment4Id: assessmentIDs[3],
                                        projectAssignmentId: assessmentIDs[4]
                                    })
                                }
                            })
                    })
                }
                else {
                    assessmentIDs.push(course[0].assignment1ID)
                    assessmentIDs.push(course[0].assignment2ID)
                    assessmentIDs.push(course[0].assignment3ID)
                    assessmentIDs.push(course[0].assignment4ID)
                    assessmentIDs.push(course[0].projectAssignmentID)
                    pushGradesToCanvas(assessmentIDs, courseID);
                }

                setIsExport(false);
            })

    }
    const addCourseToCanvasMapping = (id, course) => {

        axios.put("/canvasMapping/addAssignments/" + id, course)
            .then(res => {
            })
    }

    const pushGradesToCanvas = (assessmentIDs, courseID) => {
        let counter = 0;
        classDetails.sessions.forEach(session => {
            let str = "/courses/sis_course_id:" + courseID + "/assignments/" + assessmentIDs[counter++] + "/submissions/update_grades?"
            students.rosterData.forEach(x => {
                let filteredSession = x.sessions.filter(k => k.index == session.index);
                if (filteredSession.length != 0) {
                    if (filteredSession[0].assessment && filteredSession[0].assessment.assessmentQuestion.length != 0)
                        str += "grade_data[sis_user_id:" + x.sis_user_id + "][posted_grade]=" + filteredSession[0].assessment.totalCorrectAnswers + "&";
                    else
                        str += "grade_data[sis_user_id:" + x.sis_user_id + "][posted_grade]=" + x.rubric.totalScored + "&";
                }
            })
            if (!str.endsWith("/update_grades?")) {
                str = str.slice(0, -1);
                axiosInstance.post(str,
                    {})
                    .then(response => {

                    })
            }
        })
    }



    return (
        <>
            <div className="hiddenObject"></div>
            {classDetails &&

                <div className="">
                    <ClassDetailsHeader classDetails={classDetails}
                        dropdownSessionList={dropdownSessionList}
                        updateSessionType={updateSessionType}
                        activeTab={activeTab} setTab={(item) => setTab(item)}
                        selectedsessionItem={selectedsessionItem}
                        setSelectedsessionItem={(item) => setSelectedsessionItem(item)}
                        updateSessionType={updateSessionType} openGradingModal={openGradingModal}
                        studentsList={students}
                        amplitude={props.amplitude}
                        disableExport={students && students.rosterData.length === 0} />

                    {activeTab === 'DASHBOARD' && selectedSession &&
                        <div className="d-flex align-items-center p-0 col mb-4" >
                            <span className="sessionDropdown mr-3" style={{ display: "inline-block" }}>
                                <SelectSearch options={dropdownSessionList} search value={selectedsessionItem} onChange={setSelectedsessionItem} placeholder="Session" />
                            </span>
                            <ChangeSession type={classDetails.sessionType && classDetails.sessionType[selectedsessionItem]} updateSessionType={updateSessionType} />
                            <span className="sessionSwitch  ml-3" style={{ display: "inline-block", position: "relative", top: "10px" }}>
                                <Switch
                                    isOn={value}
                                    handleToggle={() => setStudentSessionData()}
                                    sizeClass="lg"
                                    isLocked={true}
                                    id="classdetails"
                                />
                            </span>

                        </div>}

                    {
                        activeTab === 'DASHBOARD' && selectedSession &&
                        <Dashboard teacherId={classDetails.teacher} classSection={classDetails.name} sessionStatus={value} unlockSession={() => setStudentSessionData()} courseName={classDetails.courseName} amplitude={props.amplitude} ref={childRef} sessionList={sessionList} selectedSession={selectedSession} standards={classDetails.standards[0]} currentSessionIndex={selectedSession.index} hideLoader={props.hideLoader} showLoader={props.showLoader} hideLoaderCleverText={props.hideLoaderCleverText} showLoaderCleverText={props.showLoaderCleverText} classId={classId} sectionId={sectionId} mode={mode} classCode={classDetails.classCode} facilitationHandler={LaunchFacilitaionHandler} students={students} hitRefreshPage={hitRefreshPage} newRoster={newRoster} setTourNextStep={setTourNextStep} handleStudentData={handleStudentData} subject={classDetails.subject} courseIcon={classDetails.courseIcon} removeStudent={removeStudent} otherSessionMaterials={otherSessionMaterials} sessionMaterials={sessionMaterials} sessionTypes={classDetails.sessionType} dropdownSessionList={dropdownSessionList} updateSessionType={updateSessionType} setSelectedsessionItem={setSelectedsessionItem} />
                    }
                    {
                        activeTab === 'ASSESSMENTS' && selectedSession &&
                        <Assessment classDetails={classDetails} dropdownSessionList={dropdownSessionList} sessionList={sessionList} selectedSession={selectedSession} selectedsessionItem={selectedsessionItem} setSelectedsessionItem={setSelectedsessionItem} students={students} setStudents={setStudents} />
                    }

                    {
                        activeTab === 'FACILITATION' && selectedSession &&
                        <Facilitation teacherId={classDetails.teacher} ref={childRef} classSection={classDetails.name} courseName={classDetails.courseName} amplitude={props.amplitude} facilitationStaticData={facilitationStaticData} classId={classId} currentSessionIndex={selectedSession.index} sessions={classDetails.sessions || []} studentRoster={students.rosterData} entryPoints={classDetails.entryPoints} IsSessionUnlocked={selectedSession.isUnlocked} sessionName={selectedSession.name} materials={selectedSession.resource} assessment={selectedSession.assessment} standards={classDetails.standards[0]} classCode={classDetails.classCode} hideLoader={props.hideLoader} showLoader={props.showLoader} hitRefreshPage={hitRefreshPage} setTourNextStep={setTourNextStep} subject={classDetails.subject} courseIcon={classDetails.courseIcon} studentRosterID={studentRosterID} sessionTypes={classDetails.sessionType} districtName={district && district.name} getClassSection={getClassSection} otherSessionMaterials={otherSessionMaterials} sessionMaterials={sessionMaterials} courseId={classDetails.courseId} />
                    }
                    {
                        activeTab === 'GRADEBOOK' &&
                        <Gradebook teacherId={classDetails.teacher} sessions={classDetails.sessions} studentRoster={students.rosterData} hideLoader={props.hideLoader} showLoader={props.showLoader} currentSessionIndex={selectedSession.index} hitRefreshPage={hitRefreshPage} setTourNextStep={setTourNextStep} amplitude={props.amplitude} sessionTypes={classDetails.sessionType} classId={classId} />
                    }
                    {
                        activeTab === 'PROJECTS' && classDetails &&
                        <Projects teacherId={classDetails.teacher} courseName={classDetails.courseName} studentRoster={students.rosterData} classId={classId} currentSessionIndex={selectedSession.index} sessionName={selectedSession.name} hideLoader={props.hideLoader} showLoader={props.showLoader} hitRefreshPage={hitRefreshPage} setTourNextStep={setTourNextStep} mode={mode} sessionTypes={classDetails.sessionType} subject={classDetails.subject} districtName={district && district.name} amplitude={props.amplitude} />
                    }
                    {
                        activeTab === 'PROFESSIONALLEARNING' &&
                        <ProfessionalLearning teacherId={classDetails.teacher} PFStaticData={PFStaticData} courseName={classDetails.courseName} classId={classId} currentSessionIndex={selectedSession.index} sessions={classDetails.sessions || []} entryPoints={classDetails.entryPoints} sessionName={selectedSession.name} materials={selectedSession.resource} assessment={selectedSession.assessment} standards={classDetails.standards[0]} classCode={classDetails.classCode} subject={classDetails.subject} courseIcon={classDetails.courseIcon} learningURL="http://localhost:3000/" hideLoader={props.hideLoader} showLoader={props.showLoader} hitRefreshPage={hitRefreshPage} setTourNextStep={setTourNextStep} sessionTypes={classDetails.sessionType} districtName={district && district.name} courseId={classDetails.courseId} amplitude={props.amplitude} />

                    }
                </div>

            }
            <Tour ref={child_Ref} tourSteps={tourSteps} amplitude={props.amplitude} />
            <ConfirmationBox
                isVisible={IsSessionUnlock}
                setVisible={(value) => { setSessionUnlock(value) }}
                confirmationHeader={boxMessage}
                confirmationText=""
                handleOK={() => { setSessionUnlock(false) }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />

            <ExportGrades
                isVisible={isExport}
                setVisible={(value) => { setIsExport(value) }}
                exportData={exportData}
                canvasExport={canvasExport}
                canvasData={canvasData}
                isGradingCompleted={isGradingCompleted}
                isCanvas={students.rosterData.length && students.rosterData[0].sis_user_id}
            />

            <ConfirmationBox
                isVisible={releaseMsg}
                setVisible={(value) => { setReleaseMsg(value) }}
                confirmationHeader="Student Roster data is not available to export grades."
                confirmationText=""
                handleOK={() => { setReleaseMsg(false); }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />

        </>
    )
});

export default ClassDetails