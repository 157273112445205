import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MilestoneFlag = (props) => (
    <SvgIcon {...props}>
        <path d="m15.792 8c-0.04958-0.039367-0.0785-0.0992-0.0785-0.1625s0.02892-0.12313 0.0785-0.1625l4.99-3.9908c0.43417-0.34833 0.40417-0.66667 0.3475-0.83333-0.056667-0.16667-0.23417-0.43417-0.79083-0.43417h-13.344c-0.23012 0-0.41667 0.18655-0.41667 0.41667v10c0 0.23008 0.18654 0.41667 0.41667 0.41667h13.344c0.55667 0 0.735-0.27083 0.79083-0.4325 0.05583-0.16167 0.08333-0.48333-0.3475-0.83333z" fill="#009ee1" strokeWidth=".83333" />
        <path d="m4.0887 2c-0.69036 0-1.25 0.55964-1.25 1.25v17.5c0 0.69033 0.55964 1.25 1.25 1.25 0.69036 0 1.25-0.55967 1.25-1.25v-17.5c0-0.69036-0.55964-1.25-1.25-1.25z" fill="#009ee1" strokeWidth=".83333" />
    </SvgIcon>

);

export default MilestoneFlag;
