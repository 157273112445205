import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
    Link, useLocation
} from "react-router-dom";
import footerLogo from "../../../assets/images/footer-logo.svg";
import logo from '../../../assets/images/spark-logo-corored.svg';
import SparkIcon from '../../../assets/images/SparkIcon.png';
import close from '../../../assets/images/icons/close.png';
import "./Footer.css";
import UserContext from "../../../shared/context/UserContext";
import Modal from "react-bootstrap4-modal";
import { useForm } from "react-hook-form";
import correct from '../../../assets/images/icons/correct.svg';
import shareIcon from '../../../assets/images/icons/shareIcon.svg';


function Footer(props) {
    const auth = useContext(UserContext);
    const location = useLocation();
    const [helpLink, setHelpLink] = useState();
    const [privacyPolicy, setPrivacyPolicy] = useState();
    const [contactSupportLink, setContactSupport] = useState();
    const [privacyPolicyLink, setPrivacyPolicyLink] = useState();
    const [projectSparkLink, setProjectSpark] = useState();
    const [emailTemplate, setEmailTemplate] = useState();
    const [termtext, setTermtext] = useState();
    const [showShareSparkEmail, setShowShareSparkEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [isPolicyModal, setIsPolicyModal] = useState(false);
    const [IsTermModal, setIsTermModal] = useState(false);
    const [copyRightText, setCopyRightText] = useState();

    const { register, handleSubmit, setValue, getValues, watch, errors } = useForm({
        mode: "onBlur", defaultValues: {
            email: '',
        }
    });

    useEffect(() => {
        axios.get("/staticContent/Footer")
            .then(res => {
                setHelpLink(res.data.Data.Help);
                setPrivacyPolicy(res.data.Data.PrivacyPolicy)
                setContactSupport(res.data.Data.ContactSupport);
                setProjectSpark(res.data.Data.ProjectSpark)
                setTermtext(res.data.Data.TermsOfUse);
                setCopyRightText(res.data.Data.copyRightText);
                setPrivacyPolicyLink(res.data.Data.privacyPolicyLink);
            })

        axios.get("/emailTemplate/getsharesparkviaemailtemplate")
            .then(res => {
                setEmailTemplate(res.data)
            })
    }, []);

    const sendMail = data => {
        const email = data.email;

        axios.get("/emailTemplate/sharesparkviaemail/" + email)
            .then(res => {
                setEmailSent(true);
                setShowShareSparkEmail(false);
                setValue('email', '');
            })
            .catch(error => {
                console.log(error.message);
                setShowShareSparkEmail(false);
                setValue('email', '');
            })

    }

    const closeShareProjectDialog = () => {
        setShowShareSparkEmail(false);
        setValue('email', '');
    }

    const startTour = () => {
        props.amplitude.track("Clicked Help Button", { page_name: location.pathname });
        props.startTour();
    }

    return (
        <>
            {props.showFooter && <div className="row footer">
                <div className="col-12 col-sm-9">
                    <div className="row align-items-center" style={{ fontSize: "14px", color: "#4C4B5E" }}>
                        {auth.role && (auth.role[0].name == "Teacher" || auth.role[0].name == "Student") &&
                            <span className="mobileViewFooterlink mobileWelcome" style={{ paddingRight: "20px" }}><a className="footerLink tourHelper" onClick={startTour} rel="noopener noreferrer" style={{ cursor: "pointer" }}>Help</a></span>
                        }

                        <span className="mobileViewFooterlink" style={{ paddingRight: "20px" }}><a className="footerLink" href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">Privacy Policy</a></span>
                        <span className="mobileViewFooterlink" style={{ paddingRight: "20px" }}><a className="footerLink" href={contactSupportLink} rel="noopener noreferrer" target="_blank">Contact Support</a></span>
                        {auth.role && (auth.role[0].name == "Teacher" || auth.role[0].name == "Student") &&
                            <span className="mobileViewFooterlink" style={{ paddingRight: "20px" }}><Link className="footerLink" to="/welcomepage">Take a Tour</Link></span>
                        }
                        {emailTemplate && auth.role && auth.role[0].name == "Teacher" &&
                            <span className="mobileWelcome" style={{ paddingLeft: "40px" }}>
                                <a className="footerLink spark-button-outline" rel="noopener noreferrer" onClick={() => { props.amplitude.track("Shared Project Spark", { page_name: location.pathname }); setShowShareSparkEmail(true) }}><img src={shareIcon} className="icon" />Share Project Spark</a>
                            </span>}
                    </div>
                    <div className="row nonMobileCopyright" style={{ marginTop: "40px" }}>
                        <span className="footerCopyright" dangerouslySetInnerHTML={{ __html: copyRightText }}></span>
                    </div>
                </div>
                <div className="col-12 col-sm-3 mobileViewFooterImg" style={{ padding: "0", textAlign: "right" }}>
                    <img src={footerLogo} alt="ProjectSpark" />
                </div>

                <div className="col-12 col-sm-3 mobileViewCopyright" >
                    <span className="footerCopyright" dangerouslySetInnerHTML={{ __html: copyRightText }}></span>
                </div>
            </div>
            }
            <Modal id="ShareSparkEmailSent" dialogClassName="modal-dialog-centered" className="emailSent" visible={emailSent} >
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row row d-flex align-items-center flex-column">
                        <span className="text-success-msg d-flex p-3">
                            <img src={correct} alt="sent" width="45px" className="pr-3" /> SUCCESS
                        </span>
                        <p>Email sent successfully.</p>
                        <button className="btn btn-sm sparkButton" onClick={() => { setEmailSent(false) }}>Close</button>
                    </div>
                </div>
            </Modal>

            <Modal id="ShareSparkEmail" className="shareProjectDialog" visible={showShareSparkEmail} onClickBackdrop={closeShareProjectDialog}>
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row d-flex justify-content-end">
                        <img src={close} alt="close" className="footerLink" onClick={closeShareProjectDialog} />
                    </div>

                    <div className="row p-4">
                        <div className="col-6">
                            <img className="mb-3" src={logo} alt="sparklogo" />
                            <p className="shareProjectDialogText">
                                I’m using Project Spark in my classroom, and my students are finding it a great way to learn our core subject material within the context of a Problem-Based Learning (PBL) computer science project. They currently have courses for Algebra I, Physics, and U.S. History. I’ve included details about Project Spark below.
                            </p>
                            <p className="shareProjectDialogText">
                                Project Spark believes learners from all socioeconomic and cultural backgrounds deserve an engaging introduction to computer science (CS) that inspires them to explore new and exciting professional opportunities. By providing educators with standards-aligned digital learning experiences that enrich their curriculum, we spark student connections between CS and their coursework, passions and future.
                            </p>
                        </div>
                        <div className="col-6">
                            <span className="shareProjectDialogHeading">STUDENT OPPORTUNITIES</span>
                            <ul className="shareProjectDialogText pl-3 p-0 pt-2 mb-4" >
                                <li>Build confidence in learning CS and engineering practices.</li>
                                <li>Discover current and emerging technologies.</li>
                                <li>Apply CS as a means of creation and self-expression.</li>
                                <li>Understand how CS can support personal passions and professional goals.</li>
                            </ul>

                            <span className="shareProjectDialogHeading">EDUCATOR BENEFITS</span>
                            <ul className="shareProjectDialogText pl-3 p-0 pt-2 mb-4" >
                                <li>Offer dual learning in a core subject area and a CS skill or technology.</li>
                                <li>Equip teachers with curricular materials that inspire innovative  applications for CS in the classroom.</li>
                                <li>Deliver rigorous plug-and-play lessons through project-based learning.</li>
                                <li>Balance student independence and teacher facilitation through a blended learning approach.</li>
                            </ul>

                        </div>
                        <form className="col row p-4 m-3 d-flex justify-content-around align-items-center" onSubmit={handleSubmit(sendMail)} style={{ background: "#F2F2F9", borderRadius: "4px" }}>
                            <span className="col shareProjectDialogHeading"><nobr>Share Project Spark<br /> via email</nobr></span>
                            <div className="col form-group">
                                <label style={{ fontSize: "11px", color: "#9F9FAF" }}>EMAIL</label>
                                <input type="text" className="form-control" name="email" id="emailInput" ref={register({
                                    required: true, pattern: /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/i
                                })} />
                                {errors.email && errors.email.type === 'required' && <p className="errMsg">This field is required</p>}
                                {errors.email && errors.email.type === 'pattern' && <p className="errMsg">Enter valid emails.</p>}
                            </div>
                            <div className="col" style={{ textAlign: "center", }}>
                                <button type="submit" className="curve-fill-btn">SHARE</button>
                            </div>
                        </form>
                    </div>

                </div>
            </Modal>

            <Modal id="policyModal" dialogClassName="Modaldialog" className="policyModal" visible={isPolicyModal} onClickBackdrop={() => { setIsTermModal(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsPolicyModal(false) }}>
                        <img alt="close" className="closeModalPNG" src={close} />
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="policyHeader" style={{ marginTop: "5px" }}>

                                <img alt="icon" src={SparkIcon} style={{ width: "60xp", height: "60px" }} />


                            </div>
                        </div>
                        <div className="col-10">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <button type="button" className={`curve-fill-btn`} onClick={() => { setIsPolicyModal(false) }}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal id="termModal" dialogClassName="Modaldialog" className="termModal" visible={IsTermModal} onClickBackdrop={() => { setIsTermModal(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsTermModal(false) }}>
                        <img alt="close" className="closeModalPNG" src={close} />
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="policyHeader" style={{ marginTop: "5px" }}>

                                <img alt="icon" src={SparkIcon} style={{ width: "60xp", height: "60px" }} />
                            </div>
                        </div>
                        <div className="col-10">
                            <div dangerouslySetInnerHTML={{ __html: termtext }}></div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <button type="button" className={`curve-fill-btn`} onClick={() => { setIsTermModal(false) }}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export default React.memo(Footer);