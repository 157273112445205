import React, { useState } from 'react';
import "./ConfirmationBox.css";
import Modal from 'react-bootstrap4-modal';

import ClearIcon from '@material-ui/icons/Clear';

const ConfirmationBox = props => {


    return (
        <>
            <Modal className={`confirmationModal ${props.customClass}`} visible={props.isVisible} onClickBackdrop={() => { props.setVisible(false) }}>
                <div className="modal-body">
                    <div className="text-right">
                        <span className="close-btn btn btn-outline-primary btn-pill" onClick={() => { props.setVisible(false) }}>
                            <ClearIcon />
                        </span>
                    </div>
                    <div className="headerText">
                        {props.confirmationHeader}
                    </div>
                    <div className="subheaderText">
                        {props.confirmationText}
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <button className="round-filled-btn-small" onClick={props.handleOK}>{props.okLabel}</button>
                    </div>
                    {!props.hideCancel && <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <span className="cancel" onClick={() => { props.setVisible(false) }}>{props.cancelLabel}</span>
                    </div>}

                </div>
            </Modal>
        </>
    );
};

export default React.memo(ConfirmationBox);