import React, { useState, useEffect, useContext, forwardRef, useRef, useImperativeHandle } from "react";
import SelectSearch from 'react-select-search';
import "./ProfilePage.css";
import axios from "axios";
import { ERROR_STRINGS } from "../constants/ErrorStrings"
import $ from "jquery";
import { useForm } from "react-hook-form";

import DownIcon from '../../assets/images/icons/Down.png';

import UserContext from "../../shared/context/UserContext";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';
import Tour from "../../shared/components/tour/Tour";

import RouteGuard from "../commonComponents/RouteGuard";
import { useHistory } from "react-router";

const ProfilePage = forwardRef((props, ref) => {
    const childRef = useRef();
    const tourSteps = [
        {
            target: ".profileHeader",
            content: (
                <div>
                    The <strong>Profile</strong> page allows you to make changes to your Project Spark account information.
                </div>
            ),
            disableBeacon: true,
            placement: "center"
        },

    ];
    const auth = useContext(UserContext);
    const [profileUpdated, setProfileUpdated] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userState, setUserState] = useState("");
    const [userDistrict, setUserDistrict] = useState("");
    const [dummyPass, setDummyPass] = useState("dummypass");
    const [confirmPass, setConfirmPass] = useState("dummypass");
    const [errorMsg, setErrorMsg] = useState("");
    const [isError, setIsError] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [subHeaderText, setSubHeaderText] = useState("");
    const [confirmation, setConfirmation] = useState(false);
    const [PasswordValidationMessage, setPasswordValidationMessage] = useState('');
    const [ConfPasswordValidationMessage, setConfPasswordValidationMessage] = useState('');

    const [confirmationHeader, setConfirmationHeader] = useState("");
    const [confirmationText, setConfirmationText] = useState("");
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [IsTourStart, setTourStart] = useState(false);
    const { register, handleSubmit, setValue, getValues, watch, errors } = useForm({
        mode: "onBlur", defaultValues: {
            name: '',
            lastname: '',
            email: '',
            pwd: 'Temp@123!#Temp@123!#Temp@123!#',
            confpwd: 'Temp@123!#Temp@123!#Temp@123!#'
        }
    });



    useEffect(() => {

        let mounted = true;
        axios.get("/state/")
            .then(res => {
                if (mounted) {

                    if (res.data.stateData.length > 0) {
                        let states = [];
                        res.data.stateData.forEach(element => {
                            states.push({
                                name: element.name,
                                value: element._id
                            });
                        });

                        states.sort((a, b) => {
                            var nameA = a.name.toUpperCase();
                            var nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0;
                        });

                        setStateOptions(states);
                    }
                }
            })
            .catch(err => {
                console.log("err in getting states: ", err);
            })
        axios.get("/staticContent/Profile", {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                setSubHeaderText(res.data.Data.subHeader);
                setConfirmationHeader(res.data.Data.confirmationHeader);
                setConfirmationText(res.data.Data.confirmationText);
            })
        return () => mounted = false;
    }, [], () => { });

    const logout = () => {
        auth.logout();
    }

    const getDistrictList = (value) => {
        axios.get("/district/getbystate/" + value)
            .then(res => {
                if (res.data.length > 0) {
                    let districts = [];
                    res.data.forEach(element => {
                        districts.push({
                            name: element.name,
                            value: element._id
                        });
                    });

                    districts.sort((a, b) => {
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    });

                    setDistrictOptions(districts);
                    let dfg = userDistrict;

                }
            })
    }

    useEffect(() => {
        if (auth.token) {
            props.showSpinner(true);

            axios.get("/user/getuserprofile/" + auth.userId,
                {
                    headers: { Authorization: "bearer " + auth.token }
                })
                .then(res => {
                    setUserEmail(res.data.email);
                    setUserFirstName(res.data.firstname);
                    setUserLastName(res.data.lastname);
                    setUserState(res.data.state[0]);
                    setUserDistrict(res.data.district);
                    setValue('name', res.data.firstname);
                    setValue('lastname', res.data.lastname);
                    setValue('email', res.data.email);

                    if (res.data.contextualTour.length > 0) {
                        let tourData = res.data.contextualTour.filter(val => val.pageName == 'Profile');
                        if (tourData.length == 0) {
                            childRef.current.getTour();
                            axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'Profile' } });
                        }
                    }
                    else {
                        childRef.current.getTour();
                    }
                    props.showSpinner(false);
                    const userData = JSON.parse(localStorage.getItem('userData'));

                    const user_role = userData.roles && userData.roles[0].name || "User";

                    props.amplitude.track("Visited Profile Page", { page_name: "" });
                })
                .catch(errror => {
                    console.log("error getting user profile")
                })
        }
    }, [auth]);

    const trackButton = (button_name) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const user_role = userData.roles && userData.roles[0].name || "User";

        props.amplitude.track(user_role + " Button Clicks", { page_name: "Profile Page", button_name });

    }
    useEffect(() => {
        if (userState !== "") {
            getDistrictList(userState);
        }
    }, [userState]);

    const getHelp = () => {
        childRef.current.getTour();
    }

    const handleEmailChange = (event) => {
        setUserEmail(event.target.value);
    }
    const handleFirstNameChange = (event) => {
        setUserFirstName(event.target.value);
        setIsError(false);
    }
    const handleLastNameChange = (event) => {
        setUserLastName(event.target.value);
        setIsError(false);
    }
    const handleDistrictChange = (event) => {
        setUserDistrict(event.target.value);
    }
    const handleDummyPassChange = (event) => {
        setDummyPass(event.target.value);
    }
    const handleConfirmPassChange = (event) => {
        setConfirmPass(event.target.value);
    }
    const openConfirmationModal = () => {
        setConfirmation(true);
    }
    const deleteMyData = () => {
        let updateData = {
            isDeleted: true
        };

        axios.patch("/user/updateprofile/" + auth.userId, updateData, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                props.amplitude.track("Deleted Data", { page_name: "" });
                setSuccessMsg("User profile is deleted.")
                setIsSuccess(true);
                auth.logout();
            })
            .catch(errror => {
                setErrorMsg("User profile is not deleted. Please try again.")
                setIsError(true);
            })
        setConfirmation(false);
    }
    const updateUserProfile = (data) => {
        props.showSpinner(true);
        let updateData = {
            firstName: data.name,
            lastName: data.lastname,
            state: userState,
            district: userDistrict
        };
        if (auth.strategy === "Custom Account" && data.confpwd && data.confpwd !== "Temp@123!#Temp@123!#Temp@123!#" && (data.pwd === data.confpwd))
            updateData.password = data.confpwd;

        axios.patch("/user/updateprofile/" + auth.userId, updateData, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                auth.updateUserData(data.name, data.lastname);
                props.showSpinner(false);
                setSuccessMsg("Changes to your profile saved successfully.")
                setIsSuccess(true);
                localStorage.setItem("district", updateData.district);
                axios.get("/user/get-state-district-name-by-userid/" + auth.userId).then(res => {
                    localStorage.setItem("stateDistrict", JSON.stringify(res.data));
                })
                setProfileUpdated(false);
            })
            .catch(errror => {
                setErrorMsg("User profile is not updated. Please try again.")
                setIsError(true);
                setProfileUpdated(false);
            })
    }
    const selectStyle = {
        backgroundImage: "url('" + DownIcon + "')"
    }

    const validatePassword = val => {
        let regex1 = /^((?=.*\d)(?=.*[a-zA-Z0-9])(?=.*\W).{8,})$/;
        let rex1 = regex1.test(val);
        let pass1 = $("#pwdInput").val();
        let confpass2 = $("#confpwdInput").val();
        if (pass1 === '') {
            setPasswordValidationMessage("This field is required");
            return "This field is required";
        }
        else if (pass1 !== '' && rex1 === true) {
            if (confpass2 !== '') {
                $('#confpwdInput').focus();
                $('#confpwdInput').blur();
            }
            else {
                return true;
            }
        }
        else if (rex1 === false) {
            setPasswordValidationMessage("Minimum 8 characters with at least 1 special character needed");
            return "Minimum 8 characters with at least 1 special character needed";
        }
    }

    const validateConfirmPassword = val => {
        let regex2 = /^((?=.*\d)(?=.*[a-zA-Z0-9])(?=.*\W).{8,})$/;
        let rex2 = regex2.test(val);
        let pass2 = $('#pwdInput').val();
        let confpass2 = $("#confpwdInput").val();

        if (rex2 === false) {
            $("#confpwdInput").siblings('.okIcon').css('display', 'none');
            setConfPasswordValidationMessage("Minimum 8 characters with at least 1 special character needed");
            return "Minimum 8 characters with at least 1 special character needed";
        }
        else if (pass2 !== '' && pass2 !== confpass2) {
            $("#confpwdInput").siblings('.okIcon').css('display', 'none');
            setConfPasswordValidationMessage("Password & confirm password should be same");
            return "Password & confirm password should be same";
        }
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    return (
        <>
            <RouteGuard when={profileUpdated} onConfirm={handleSubmit(updateUserProfile)} />
            <form onSubmit={handleSubmit(updateUserProfile)} onChange={() => { setProfileUpdated(true) }}>
                <div className="mb-5 p-0">
                    <div className="profileHeader">Profile</div>
                    <Tour ref={childRef} tourSteps={tourSteps} amplitude={props.amplitude} />
                    {auth.strategy && auth.strategy.toLowerCase().indexOf("google") > -1 && <div style={{ marginTop: "10px" }}>Google Account</div>}
                    {auth.strategy && auth.strategy.toLowerCase().indexOf("clever") > -1 && <div style={{ marginTop: "10px" }}>Clever Account</div>}
                    {auth.strategy && auth.strategy.toLowerCase().indexOf("canvas") > -1 && <div style={{ marginTop: "10px" }}>Canvas Account</div>}
                    <div className="profileSubHeader">{subHeaderText}</div>
                </div>
                <div className="row profilePage">
                    <div className="form-group col-md-4">
                        <label>EMAIL</label>
                        <input type="text" disabled className="form-control" name="email" id="emailInput" readOnly ref={register({ required: true })} />
                        {errors.email && errors.email.type === 'required' && <p className="errMsg">{ERROR_STRINGS.requiredField}</p>}
                    </div>

                    <div className="form-group col-md-4">
                        <label>First Name</label>
                        <input disabled={auth.strategy !== "Custom Account"} type="text" className="form-control" maxLength='40' name="name" id="nameInput" ref={register({ required: true, pattern: /^[a-zA-Z0-9 ,.'-]+$/i })} />
                        {errors.name && errors.name.type === 'required' && <p className="errMsg">{ERROR_STRINGS.requiredField}</p>}
                        {errors.name && errors.name.type === 'pattern' && <p className="errMsg">{ERROR_STRINGS.nameSpecialCharsError}</p>}


                    </div>

                    <div className="form-group col-md-4">
                        <label>Last Name</label>
                        <input disabled={auth.strategy !== "Custom Account"} type="text" className="form-control" name="lastname" id="lastnameInput" maxLength='40' ref={register({ required: true, pattern: /^[a-zA-Z0-9 ,.'-]+$/i })} />
                        {errors.lastname && errors.lastname.type === 'required' && <p className="errMsg">{ERROR_STRINGS.requiredField}</p>}
                        {errors.lastname && errors.lastname.type === 'pattern' && <p className="errMsg">{ERROR_STRINGS.nameSpecialCharsError}</p>}

                    </div>

                    <div className="form-group col-md-4">
                        <label>STATE</label>
                        <SelectSearch className="select-search stateSelectBox" options={stateOptions} search value={userState} onChange={(item) => { setUserState(item); setProfileUpdated(true) }} placeholder="State" />
                    </div>
                    <div className="form-group col-md-4">
                        <label>DISTRICT</label>
                        <SelectSearch className="select-search districtSelectBox" options={districtOptions} search value={userDistrict} onChange={(item) => { setUserDistrict(item); setProfileUpdated(true) }} placeholder="District" />
                    </div>

                    {auth.strategy === "Custom Account" && <div className="w-100">
                        <div className="row col-12">
                            <div className="form-group col-md-4">
                                <label>Password</label>
                                <input type="password" className="form-control" name="pwd" id="pwdInput" ref={register({ required: true, validate: validatePassword })} />
                                {errors.pwd && errors.pwd.type === 'required' && <p className="errMsg">{ERROR_STRINGS.requiredField}</p>}
                                {errors.pwd && errors.pwd.message === "Minimum 8 characters with at least 1 special character needed" && <p className="errMsg">{PasswordValidationMessage}</p>}
                            </div>
                            <div className="form-group col-md-4">
                                <label>confirm Password</label>
                                <input type="password" className="form-control" name="confpwd" id="confpwdInput" ref={register({ required: true, validate: validateConfirmPassword })} />
                                {errors.confpwd && errors.confpwd.type === 'required' && <p className="errMsg">{ERROR_STRINGS.requiredField}</p>}
                                {errors.confpwd && (errors.confpwd.message === "Minimum 8 characters with at least 1 special character needed" || errors.confpwd.message === "Password & confirm password should be same") && <p className="errMsg">{ConfPasswordValidationMessage}</p>}
                            </div>
                        </div>
                    </div>}
                    <div className="col-12 d-flex py-2" style={{ marginTop: "10px", marginBottom: "30px" }}>
                        <button type="submit" className="btn btn-outline-primary btn-pill b-2" style={{ textTransform: "unset" }}>Save changes</button>
                        <button className="btn btn-link btn-text" onClick={() => { trackButton("Delete My Data"); setConfirmation(true) }}>Delete my data</button>
                    </div>

                </div>
            </form>

            <ConfirmationBox
                isVisible={confirmation}
                setVisible={(value) => { setConfirmation(value) }}
                confirmationHeader={confirmationHeader}
                confirmationText={confirmationText}
                handleOK={deleteMyData}
                okLabel="DELETE DATA"
                cancelLabel="Cancel" />
        </>
    )
})

export default React.memo(ProfilePage);