import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const History = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.66667 16.6667H3.04896V3.66666H1.66667V16.6667Z" fill="#009EE1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.2844 11.2646C17.2173 11.2551 17.147 11.2447 17.0721 11.2356C16.8109 11.2018 16.504 11.1705 16.1514 11.1494C15.0381 11.0814 13.4659 11.1084 11.4357 11.4289C9.47455 11.737 7.97428 11.7523 6.9511 11.6843C6.58324 11.6608 6.27632 11.626 6.0313 11.5909C5.94606 11.5792 5.86827 11.5662 5.79859 11.5544V5.58673C6.84414 5.74063 8.80042 5.87272 11.6502 5.42535C14.5765 4.96302 16.4583 5.15303 17.2844 5.29684V11.2646ZM18.1854 4.07771C18.0924 4.04842 15.8512 3.35801 11.4356 4.05363C7.40836 4.68645 5.32958 4.08194 5.31143 4.07608L4.41594 3.79725V12.6255L4.90177 12.7765C4.96529 12.7963 5.99496 13.107 7.97424 13.107C8.96308 13.107 10.1892 13.029 11.6502 12.7999C15.7005 12.1602 17.7498 12.7696 17.7663 12.7748L18.6667 13.0625V4.2316L18.1854 4.07771Z" fill="#009EE1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.95122 6.45968V9.0658C7.2442 9.08337 7.58159 9.09573 7.96338 9.09573C8.90231 9.09573 10.1063 9.02578 11.5512 8.80161V6.18671L11.4721 6.19907C9.44777 6.51727 7.92352 6.52248 6.95122 6.45968Z" fill="#009EE1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11.4718 9.56083C9.44777 9.87903 7.9232 9.88424 6.95122 9.82144V10.7464C7.2442 10.7647 7.58159 10.7764 7.96338 10.7764C8.91527 10.7764 10.1407 10.7048 11.6137 10.4725C13.6578 10.1501 15.1844 10.1461 16.1515 10.2089V9.2833C15.8591 9.26573 15.5204 9.25369 15.1377 9.25369C14.1855 9.25369 12.9575 9.32657 11.4718 9.56083Z" fill="url(#paint0_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4722 6.05671V6.98756C14.0892 6.78681 15.3246 6.79137 16.1514 6.84505V5.91876C15.86 5.90119 15.523 5.8898 15.1415 5.8898C14.4113 5.8898 13.5187 5.9321 12.4722 6.05671Z" fill="url(#paint1_linear)" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.4722 7.73885V8.671C14.0892 8.46928 15.3246 8.47318 16.1514 8.52654V7.60155C15.8578 7.58333 15.5175 7.57129 15.1321 7.57129C14.4035 7.57129 13.5142 7.61424 12.4722 7.73885Z" fill="url(#paint2_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="11.1632" y1="11.2003" x2="11.5303" y2="8.99745" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F94C4A" />
                <stop offset="0.242105" stopColor="#FF5C55" />
                <stop offset="0.735644" stopColor="#FFB850" />
                <stop offset="1" stopColor="#FEE04D" />
            </linearGradient>
            <linearGradient id="paint1_linear" x1="14.1566" y1="7.29319" x2="14.6063" y2="5.79606" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F94C4A" />
                <stop offset="0.242105" stopColor="#FF5C55" />
                <stop offset="0.735644" stopColor="#FFB850" />
                <stop offset="1" stopColor="#FEE04D" />
            </linearGradient>
            <linearGradient id="paint2_linear" x1="14.1566" y1="8.97717" x2="14.6078" y2="7.47782" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F94C4A" />
                <stop offset="0.242105" stopColor="#FF5C55" />
                <stop offset="0.735644" stopColor="#FFB850" />
                <stop offset="1" stopColor="#FEE04D" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

export default History;
