import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom"
import UserContext from "../../shared/context/UserContext";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import ExternalSSO from '../../shared/components/externalSSO/ExternalSSO';

import "./WelcomePage.css";

import logo from '../../assets/images/spark-logo-corored.svg';
import mobileD from '../../assets/images/mobile-decive.png';
import devicerotate from '../../assets/images/device-rotate.png';
import browser from '../../assets/images/browser.png';
import Modal from 'react-bootstrap4-modal';

import {
    browserName, osVersion, osName, fullBrowserVersion, deviceType
} from "react-device-detect";


const WelcomePage = props => {

    let resolutionWidth = window.screen.width;
    let resolutionHeight = window.screen.heigh;
    let query = new URLSearchParams(useLocation().search);
    const isError = query.get("isError");
    const [showResolutionMsg, setShowResolutionMsg] = useState(false);
    const [resolutionCode, setResolutionCode] = useState("");
    const [welcomeTitle, setWelcomeTitle] = useState();
    const [welcomeMsg, setWelcomeMsg] = useState();
    const [IsError, setError] = useState(isError);
    const auth = useContext(UserContext);
    let history = useHistory();

    window.addEventListener("orientationchange", function (event) {
        if (event.target.screen.orientation.angle) {
            setShowResolutionMsg(false)
        }
    });

    useEffect(() => {

        if ((browserName == "Edge" && parseInt(fullBrowserVersion.split('.')[0]) < 80) || (browserName == "IE")) {
            setResolutionCode("browser")
            setShowResolutionMsg(true);
        }

        if (resolutionWidth < 768) {
            setResolutionCode("device")
            setShowResolutionMsg(true);
        }
        if (resolutionWidth >= 768 && resolutionWidth < 1024) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setResolutionCode("orientation")
                setShowResolutionMsg(true);
            }
        }

        props.showLoader();
        axios.get("/staticContent/Welcome")
            .then(res => {
                props.hideLoader();
                setWelcomeTitle(res.data.Data.Title);
                setWelcomeMsg(res.data.Data.Message)
            })
    }, []);

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }

    return (
        <>
            {!showResolutionMsg && <div className="minscreenheightOut" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)" }}>
                <div className="row header">
                    <div className="col-4">
                        <img src={logo} alt="spark" onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="row margin0" style={{ textAlign: "center", backgroundColor: "#ffffff", padding: "96px 0 75px 0" }}>
                    <div className="col" style={{ padding: "0", margin: "0" }}>
                        <div className="row justify-content-center" >
                            <div className="col-12">
                                <span className="welcomeMsg">{welcomeTitle}</span>
                            </div>
                        </div>
                        <div className="row justify-content-center" style={{ marginTop: "15px" }}>
                            <div className="col-12">
                                <span className="welcomeDesc">
                                    {welcomeMsg}
                                </span>
                            </div>
                        </div>
                        <div className="row justify-content-center" style={{ marginTop: "48px" }}>
                            <div className="col-12">
                                <Link to="/createaccount" style={{ color: "#fff" }}><button className="curve-fill-btn" style={{ width: "175px", height: "50px", padding: "13px 20px" }}>
                                    GET STARTED</button></Link>
                            </div>
                        </div>
                        <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                            <div className="col-12">
                                <Link to="/signin" style={{ color: "#009DDB" }}><button className="curve-bordered-btn" style={{ width: "175px", height: "50px" }} >
                                    SIGN IN</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
                {IsError !== null &&
                    <div className="row" style={{ backgroundColor: '#fff' }}>
                        {IsError === '1' && <div className="col-12" style={{ textAlign: 'center', color: '#DF2222', paddingTop: "10px", paddingBottom: "10px" }}>A user with same email ID already exists in Spark as a Teacher. Please contact Spark Administrator if this is an error.</div>}
                        {IsError === '0' && <div className="col-12" style={{ textAlign: 'center', color: '#DF2222', paddingTop: "10px", paddingBottom: "10px" }}>A user with same email ID already exists in Spark as a Student. Please contact Spark Administrator if this is an error.</div>}
                    </div>
                }
                {/*<div className="row justify-content-center" style={{ marginTop: "45px", textAlign: "center" }}>*/}
                {/*    <div className="col-12" style={{ padding: "0", position: "relative" }}>*/}
                {/*        <div className="straightLine"></div>*/}
                {/*        <span className="signLabel">Or sign in using your account on:</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="row justify-content-center welcomefooter" >*/}
                {/*    <div className="col-12" style={{ padding: "0" }}>*/}
                {/*        <ExternalSSO />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>}


            <Modal id="deviceError" className="deviceError" visible={showResolutionMsg} onClickBackdrop={() => { }}>
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row">
                        {resolutionCode == "device" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={mobileD} />
                                </div>
                                <div className="col-12">
                                    <span className="oops">OOPS!</span>
                                    <span className="oopsline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Your device is not supported at the moment.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">for a better experience please use a large screen device such as tablet, Notebook or above.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">If you are using school device please check with your teacher or support staff.</span>
                            </div>
                        </div>}



                        {resolutionCode == "orientation" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={devicerotate} />
                                </div>
                                <div className="col-12">
                                    <span className="snap">SNAP!</span>
                                    <span className="snapline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Turn to landscape mode for a better experience.</span>
                            </div>
                        </div>}


                        {resolutionCode == "browser" &&
                            <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                                <div className="row" >
                                    <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                        <img alt="" src={browser} />
                                    </div>
                                    <div className="col-12">
                                        <span className="oops">OOPS!</span>
                                        <span className="browserline"></span>
                                    </div>
                                </div>
                                <div className="row" >
                                    <span className="deviceErrorHeadT">We are sorry, but your browser is not compatible. Please use a more up to date browser.</span>
                                </div>
                                <div className="row" style={{ marginTop: "25px" }}>
                                    <span style={{ margin: "auto", fontWeight: "bold", fontSize: "9px", lineHeight: "16px", textAlign: "center", letterSpacing: "0.9px", textTransform: "uppercase", color: "#4C4B5E" }}>If you are using a school device please check with you teacher or support staff.</span>
                                </div>

                            </div>}

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default React.memo(WelcomePage);