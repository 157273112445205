import React, { useState, useEffect, useContext, useRef } from "react";
import Logo from "../../assets/images/spark-logo-corored.svg";
import ExternalSSO from '../../shared/components/externalSSO/ExternalSSO';
import './CreateAccount.css';
import { useForm } from "react-hook-form";
import $ from "jquery";
import RemoveIcon from "../../assets/images/icons/Cancel.png";
import OkIcon from "../../assets/images/icons/Checkmark.png";
import message from "../../assets/config/message.json";
import DownIcon from '../../assets/images/icons/Down.png';
import axios from "axios";
import { ERROR_STRINGS } from "../constants/ErrorStrings";
import { MONTH_LIST } from "../constants/CommonConstants";
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'


import {
    browserName, osVersion, osName, fullBrowserVersion, deviceType
} from "react-device-detect";
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom";
import SelectSearch from 'react-select-search';
import UserContext from "../../shared/context/UserContext";
import Modal from "react-bootstrap4-modal";

import SparkIcon from '../../assets/images/SparkIcon.png';
import close from '../../assets/images/icons/close.png';
import RadioButton from "../customInputs/RadioButton";

export function CreateAccount(props) {
    const auth = useContext(UserContext);
    let history = useHistory();
    let storedData = JSON.parse(localStorage.getItem('userData'));
    const selectStyle = {
        backgroundImage: "url('" + DownIcon + "')"
    }
    const [obj, setObj] = useState({});
    let formObj = {
        firstname: '',
        lastname: '',
        email: '',
        birthDay: '',
        birthYear: ''
    };

    const [IpAddr, setIpAddr] = useState('');
    const [state, setState] = useState([]);
    const [district, setDistrict] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [formData, setFormData] = useState(formObj);
    const [IsCustomStrategy, setStrategy] = useState(true);
    const [Messages, setMessages] = useState(message);
    const [PasswordValidationMessage, setPasswordValidationMessage] = useState('');
    const [ConfPasswordValidationMessage, setConfPasswordValidationMessage] = useState('');
    const [stateOptions, setStateOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [userState, setUserState] = useState("");
    const [userDistrict, setUserDistrict] = useState("");
    const [IsStateSelected, setStateSelected] = useState(false);
    const [IsDistrictSelected, setDistrictSelected] = useState(false);
    const [isPolicyModal, setIsPolicyModal] = useState(false);
    const [IsTermModal, setIsTermModal] = useState(false);
    const [IsTermPolicyChecked, setTermPolicyChecked] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState();
    const [termtext, setTermtext] = useState();
    const [monthOptions, setMonthOptions] = useState(MONTH_LIST);
    const [IsMonthSelected, setMonthSelected] = useState(true);
    const [birthMonth, setBirthMonth] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [birhtYear, setBirhtYear] = useState("");
    const [IsDobValid, setDobValid] = useState(true);
    const [DobDialog, setDobDialog] = useState(false);
    const [selectedRole, setSelectedRole] = useState();
    const [studentAge, setStudentAge] = useState(13);
    const [acceptTermError, setAcceptTermError] = useState("");
    const [birthMonthError, setBirthMonthError] = useState(false);
    const [target, setTarget] = useState(null);
    const [passwordTooltip, setPasswordTooltip] = useState(false);
    const [privacyPolicyLink, setPrivacyPolicyLink] = useState();
    const [termsOfUseLink, setTermsOfUseLink] = useState();
    const [dataProcessingAddendumLink, setDataProcessingAddendumLink] = useState();
    const [formSubmited, setFormSubmited] = useState(false);
    const ref = useRef(null);




    useEffect(() => {

        props.amplitude.track("Visited Create Account Page", { page_name: "" });

        let SSORedirect = localStorage.getItem('SSORedirect');

        if (storedData !== null && SSORedirect !== "true") {
            auth.clearUserData();
            localStorage.setItem('SSORedirect', "false");
            history.go(0)
        }
        props.hideLoader();
        if (storedData !== null) {
            if (storedData.strategy == '') {
                setStrategy(true);
            }
            else {
                setStrategy(false);
            }
            setValue('name', storedData.firstname);
            setValue('lastname', storedData.lastname);
            setValue('email', storedData.email);
            if (storedData.roles !== undefined && storedData.roles.length > 0) {
                setValue('roles', storedData.roles[0].name);
            }
            setTimeout(() => {
                $('#emailInput').focus();
                $('#emailInput').blur();
                $("#emailInput").prop("disabled", true);
                $('#nameInput').focus();
                $('#nameInput').blur();
                $('#lastnameInput').focus();
                $('#lastnameInput').blur();
                $('#birthDate').focus();
                $('#birthDate').blur();
                $('#birthYear').focus();
                $('#birthYear').blur();
            }, 200);
        }

        if (localStorage.getItem('IpAddress') !== null) {
            setIpAddr(localStorage.getItem('IpAddress').IPv4);
        }
        $(".removeIcon").click(function () {
            $(this).siblings("input").val('');
        });

        axios.get("/state/").then(res => {

            if (res.data.stateData.length > 0) {
                let list = [];
                res.data.stateData.forEach(element => {
                    list.push({
                        name: element.name,
                        value: element._id
                    });
                });
                list.sort((a, b) => {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    return 0;
                });
                setStateOptions(list);
                if (localStorage.getItem('state') !== undefined) {
                    setUserState(localStorage.getItem('state'));
                    localStorage.removeItem("state");
                }
            }
        })

    }, []);

    const getDistrictList = (value) => {
        if (value) {
            axios.get("/district/getbystate/" + value)
                .then(res => {
                    if (res.data.length > 0) {
                        let districts = [];
                        res.data.forEach(element => {
                            districts.push({
                                name: element.name,
                                value: element._id
                            });
                        });

                        districts.sort((a, b) => {
                            var nameA = a.name.toUpperCase();
                            var nameB = b.name.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0;
                        });

                        let index1 = districts.findIndex(x => x.name == "Other");
                        let temp = districts.splice(index1, 1)[0];
                        districts.push(temp);
                        setDistrictOptions(districts);
                        if (localStorage.getItem('district') !== undefined) {
                            setUserDistrict(localStorage.getItem('district'));
                            localStorage.removeItem("district");
                        }
                    }
                })
        }
    }

    useEffect(() => {
        axios.get("/staticContent/Footer")
            .then(res => {
                setPrivacyPolicy(res.data.Data.PrivacyPolicy);
                setTermtext(res.data.Data.TermsOfUse);
                setPrivacyPolicyLink(res.data.Data.privacyPolicyLink);
                setTermsOfUseLink(res.data.Data.termsOfUseLink);
                setDataProcessingAddendumLink(res.data.Data.dataProcessingAddendumLink);
            })
        if (userState !== "") {
            getDistrictList(userState);
        }
        setStateSelected(true);
    }, [userState]);

    const { register, handleSubmit, setValue, getValues, watch, errors } = useForm({
        mode: "onBlur", defaultValues: {
            name: formData.firstname,
            lastname: formData.lastname,
            email: formData.email
        }
    });

    const changeState = (value) => {
        console.log(value);
        setUserState(value);
        setStateSelected(true);
        setFormSubmited(false);
    }

    const changeDistrict = (value) => {
        console.log(value);
        setUserDistrict(value);
        setDistrictSelected(true);
        setFormSubmited(false);
    }

    const AvailableRoles = ["Teacher", "Student"];

    const onSubmit = data => {
        console.log(userState);
        console.log(userDistrict);

        setFormSubmited(true);

        if (!IsStateSelected) {
            return;
        }
        if (!IsDistrictSelected) {
            return;
        }
        if (birthMonthError) {
            return;
        }

        if (!IsTermPolicyChecked) {
            setAcceptTermError(ERROR_STRINGS.requiredField);
            return;
        }

        if (selectedRole === "Student" && !validateDob()) {
            setDobDialog(true);
            return;
        }


        props.showLoader();
        var date = new Date();
        if (IsCustomStrategy === true) {
            const user = {
                firstName: data.name,
                lastName: data.lastname,
                emailId: data.email,
                state: userState,
                district: userDistrict,
                roles: [{
                    name: data.roles,
                    priority: 1
                }],
                passwordHash: data.password,
                createdOn: date,
                modifiedOn: date
            }
            props.showLoader();
            axios.post("/user/signup", user)
                .then(res => {
                    localStorage.setItem("district", userDistrict);
                    auth.login(res.data.userId, res.data.token, res.data.email, res.data.firstName, res.data.lastName, res.data.roles, 'Custom Account');
                    let user_id = res.data.userId;
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                    axios.post("user/audit", { userId: res.data.userId, email: res.data.email, role: res.data.roles[0].name, signinMethod: "Create Account", platform: deviceType, osName: osName, osVersion: osVersion, browser: browserName, browserVersion: fullBrowserVersion, loginTimestamp: new Date(), ipAddress: IpAddr }).then(res => {
                        axios.get("/user/get-state-district-name-by-userid/" + user_id).then(res1 => {
                            localStorage.setItem("stateDistrict", JSON.stringify(res1.data));
                            if (window.screen.width > 767) {
                                history.push("/welcomepage");
                            } else {
                                history.push("/mobileWelcome");
                            }
                            props.amplitude.track("Registered Successfully", { page_name: "" });
                            props.hideLoader();
                        })
                    })
                })
        }
        else {
            const user = {
                email: data.email,
                state: userState,
                district: userDistrict,
                modifiedOn: date,
                roles: [{
                    name: data.roles,
                    priority: 1
                }],
            }
            const userData = JSON.parse(localStorage.getItem('userData'));

            axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
            axios.put("/user/updateuser", user)
                .then(res => {
                    userData.roles = user.roles;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    localStorage.setItem("district", userDistrict);

                    auth.login(userData.userId, userData.token, userData.email, userData.firstname, userData.lastname, userData.roles, storedData.strategy);
                    let user_id = userData.userId;
                    axios.post("user/audit", { userId: userData.userId, email: userData.email, role: userData.roles[0].name, signinMethod: storedData.strategy, platform: deviceType, osName: osName, osVersion: osVersion, browser: browserName, browserVersion: fullBrowserVersion, loginTimestamp: new Date(), ipAddress: IpAddr }).then(res => {
                        axios.get("/user/get-state-district-name-by-userid/" + user_id).then(res1 => {
                            localStorage.setItem("stateDistrict", JSON.stringify(res1.data));
                            if (window.screen.width > 767) {
                                history.push("/welcomepage");
                            } else {
                                history.push("/mobileWelcome");
                            }
                            props.amplitude.track("Registered Successfully", { page_name: "" });
                            props.hideLoader();
                        })
                    })
                })
        }
    };

    const validateFirstName = val => {
        let regex = /^[a-zA-Z0-9 ,.'-]+$/i;
        let rex = regex.test(val);
        if ($("#nameInput").val() !== '') {
            if (rex === true) {
                $("#nameInput").css('border-color', '#23A45D');
                $("#nameInput").siblings('.okIcon').css('display', 'inline');
                return true;
            }
            else {
                $("#nameInput").css('border-color', '#DF2222');
                $("#nameInput").siblings('.okIcon').css('display', 'none');
                return 'invalid';
            }
        } else {
            $("#nameInput").siblings('.okIcon').css('display', 'none');
            return false;
        }
    }

    const validateLastName = val => {
        let regex = /^[a-zA-Z-0-9 ,.'-]+$/i;
        let rex = regex.test(val);
        if ($("#lastnameInput").val() !== '') {
            if (rex === true) {
                $("#lastnameInput").css('border-color', '#23A45D');
                $("#lastnameInput").siblings('.okIcon').css('display', 'inline');
                return true;
            }
            else {
                $("#lastnameInput").css('border-color', '#DF2222');
                $("#lastnameInput").siblings('.okIcon').css('display', 'none');
                return 'invalid';
            }
        } else {
            $("#lastnameInput").siblings('.okIcon').css('display', 'none');
            return false;
        }
    }

    const validateEmail = async (val) => {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let rex = regex.test(val);
        if (rex === true) {
            let user = await axios.post("/user/getuserbyemail", { email: val });
            if (IsCustomStrategy == true && user.data.email === val) {
                $("#emailInput").siblings('.okIcon').css('display', 'none');
                return 'Email already exist';
            }
            else {
                $("#emailInput").css('border-color', '#23A45D');
                $("#emailInput").siblings('.okIcon').css('display', 'inline');
                return true;
            }
        }
        else {
            $("#emailInput").siblings('.okIcon').css('display', 'none');
            return 'Please enter valid email';
        }
    }

    const validatePassword = val => {
        let regex1 = /^((?=.*\d)(?=.*[a-zA-Z0-9])(?=.*\W).{8,})$/;
        let rex1 = regex1.test(val);
        let pass1 = $("#pwdInput").val();
        let confpass2 = $("#confirmpwdInput").val();
        if (pass1 === '') {
            $("#pwdInput").siblings('.okIcon').css('display', 'none');
            setPasswordValidationMessage("This field is required");
            return "This field is required";
        }
        else if (pass1 !== '' && rex1 === true) {
            $("#pwdInput").css('border-color', '#23A45D');
            $("#pwdInput").siblings('.okIcon').css('display', 'inline');
            if (confpass2 !== '') {
                $('#confirmpwdInput').focus();
                $('#confirmpwdInput').blur();
            }
            else {
                return true;
            }
        }
        else if (rex1 === false) {
            $("#pwdInput").siblings('.okIcon').css('display', 'none');
            setPasswordValidationMessage("Minimum 8 characters with at least 1 number and 1 special character needed");
            return "Minimum 8 characters with at least 1 number and 1 special character needed";
        }
    }

    const validateConfirmPassword = val => {
        let regex2 = /^((?=.*\d)(?=.*[a-zA-Z0-9])(?=.*\W).{8,})$/;
        let rex2 = regex2.test(val);
        let pass2 = $('#pwdInput').val();
        let confpass2 = $("#confirmpwdInput").val();
        if (confpass2 === '') {
            $("#confirmpwdInput").siblings('.okIcon').css('display', 'none');
            return false;
        }
        else if (rex2 === false) {
            $("#confirmpwdInput").siblings('.okIcon').css('display', 'none');
            setConfPasswordValidationMessage("Minimum 8 characters with at least 1 number and 1 special character needed");
            return "Minimum 8 characters with at least 1 number and 1 special character needed";
        }
        else if (pass2 !== '' && pass2 === confpass2) {
            $("#confirmpwdInput").css('border-color', '#23A45D');
            $("#confirmpwdInput").siblings('.okIcon').css('display', 'inline');
            return true;
        }
        else if (pass2 !== '' && pass2 !== confpass2) {
            $("#confirmpwdInput").siblings('.okIcon').css('display', 'none');
            setConfPasswordValidationMessage("Password & confirm password should be same");
            return "Password & confirm password should be same";
        }
        else if (pass2 === '') {
            $("#confirmpwdInput").css('border-color', '#23A45D');
            $("#confirmpwdInput").siblings('.okIcon').css('display', 'inline');
            return true;
        }
    }

    const validateRoles = _ => {
        const values = getValues();

        if (values === "Student") {
            setDobValid(false);
        }

        if (values.roles !== '') {
            return true
        }
        else {
            return false;
        }

    };
    const changeRole = (e) => {
        setSelectedRole(e.target.value);
        if (e.target.value === "Student") {
            setDobValid(false);
        } else {
            setDobValid(true);
            setStudentAge(13);
        }
    }

    const changeBirthMonth = (value) => {
        setBirthMonth(value);
        setBirthMonthError(false);
    }



    const validateBirthDate = (value) => {
        value = parseInt(value);
        let regex = /^[0-9]+$/i;
        if (value && !regex.test(value)) {
            return false;
        }
        setBirthDay(value.toString());
        if (value < 1 || value > 31) {
            console.log("inValid Date");
            return "inValid Date";
        }

    }

    const validateBirthYear = (value) => {
        value = parseInt(value);

        let regex = /^[0-9]+$/i;
        if (value && !regex.test(value)) {
            return false;
        }
        setBirhtYear(value.toString());

        if (value > new Date().getFullYear() || (value > 100 && parseInt(value.toString()[0] + value.toString()[1] + value.toString()[2])) > 210) {
            return "inValid Year";
        } else if (value.toString().length < 4) {
            return "Enter Year";
        }

    }

    const handleDateChange = (event) => {

        const id = event.target.id;
        const value = event.target.value;

        let regex = /^[0-9]+$/i;
        if (value && !regex.test(value)) {
            return false;
        }

        id === "birthDate" && value <= 31 && setBirthDay(value);
        id === "birthYear" && value <= new Date().getFullYear() && setBirhtYear(value);
    }

    const validateDob = () => {

        if (birthDay && birthDay.length >= 1 && birhtYear && birhtYear.length === 4 && birthMonth) {
            const birthDate = birthMonth + " " + birthDay + ", " + birhtYear;
            var DOB = new Date(birthDate);
            var today = new Date(Date.now());
            today.setHours(0, 0, 0, 0);
            DOB.setHours(0, 0, 0, 0);

            var diff_ms = today - DOB.getTime();

            var age_dt = new Date(diff_ms);
            const age = Math.abs(age_dt.getUTCFullYear() - 1970);

            console.log("Age", age);
            setStudentAge(parseInt(age));
            if (parseInt(age) >= 13) {
                setDobValid(true);
                return true;
            } else {
                return false
            }
        }

        birthMonth ? setBirthMonthError(false) : setBirthMonthError(true);
        return false;
    }


    const handleFormObj = _ => {
        storedData = JSON.parse(localStorage.getItem('userData'));
        setValue('name', storedData.firstname);
        setValue('lastname', storedData.lastname);
        setValue('email', storedData.email);
        setStrategy(false);
        $('#emailInput').focus();
        $('#emailInput').blur();
        $("#emailInput").prop("disabled", true);
        $('#nameInput').focus();
        $('#nameInput').blur();
        $('#lastnameInput').focus();
        $('#lastnameInput').blur();
        $('#birthDate').focus();
        $('#birthDate').blur();
        $('#birthYear').focus();
        $('#birthYear').blur();
    }

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }


    const onTermPolicyChecked = (e) => {
        setTermPolicyChecked(e.target.checked);
        setAcceptTermError("");
    }

    const passwordInfo = (e) => {
        setTarget(e.target);
        setPasswordTooltip(true);
    }

    return (
        <>
            <div className="mobileDiv" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)", paddingBottom: "30px" }}>
                <div className="row header">
                    <div className="col-12">
                        <img src={Logo} onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{ padding: "0px" }}>
                        <div className="createAccountContainer">
                            <div className="row">
                                <div className="col-12 createAccHeader">
                                    Create Account
                                </div>

                                {/*<div className="col-12 createAccSubHeader" style={{ marginBottom: "20px" }}>*/}
                                {/*    Sign in using your account on:*/}
                                {/*</div>*/}
                                {/*<div className="col-12">*/}
                                {/*    <ExternalSSO changeFormObj={handleFormObj} />*/}
                                {/*</div>*/}
                                {/*<div className="col-12 createAccSubHeader" style={{ marginTop: "27px" }}>*/}
                                {/*    Or enter your information below*/}
                                {/*</div>*/}

                                <div className="col-12">

                                    <form className="createAccountForm" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group createAccRoleSelection" onChange={changeRole}>
                                            I’m a {AvailableRoles.map((role, i) => (

                                                <label className="custom-radio-container" key={`${i}${role}`}>{role}
                                                    <input type="radio" name="radio" value={role} name={`roles`} ref={register({ validate: validateRoles })} />
                                                    <span className="checkmark"></span></label>
                                            ))}
                                            {errors.roles && errors.roles.type === 'validate' && <span className="errorSpan">{Messages.CreateAccount[2].RequiredField.Message}</span>}
                                        </div>
                                        <div className="form-group">
                                            <label>EMAIL</label>
                                            {errors.email && errors.email.type === 'required' && <p></p>}
                                            {errors.email && errors.email.message === 'Email already exist' && <p></p>}
                                            {errors.email && errors.email.message === 'Please enter valid email' && <p></p>}
                                            <input type="text" className="form-control" name="email" id="emailInput" ref={register({ required: true, validate: validateEmail })} />
                                            {errors.email && errors.email.type === 'required' && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                            {errors.email && errors.email.message === 'Email already exist' && <p>Email already exists</p>}
                                            {errors.email && errors.email.message === 'Please enter valid email' && <p>Please enter valid email</p>}
                                            <span className="form-control-feedback removeIcon"><img src={RemoveIcon} /></span>
                                            <span className="form-control-feedback okIcon"><img src={OkIcon} /></span>
                                        </div>
                                        <div className="form-group">
                                            <label>FIRST NAME</label>
                                            {errors.name && errors.name.type === 'required' && <p></p>}
                                            <input type="text" className="form-control" name="name" id="nameInput" maxLength='100' ref={register({ required: true, validate: validateFirstName })} />
                                            {errors.name && errors.name.type === 'required' && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                            {errors.name && errors.name.message === 'invalid' && <p>{ERROR_STRINGS.nameSpecialCharsError}</p>}
                                            {errors.name && (errors.name.type === 'required' || errors.name.message === 'invalid')
                                                ? <span className="form-control-feedback removeIcon"><img src={RemoveIcon} /></span>
                                                : <span className="form-control-feedback okIcon"><img src={OkIcon} /></span>
                                            }
                                        </div>
                                        <div className="form-group">
                                            <label>LAST NAME</label>
                                            {errors.lastname && errors.lastname.type === 'required' && <p></p>}
                                            <input type="text" className="form-control" id="lastnameInput" name="lastname" maxLength='40' ref={register({ required: true, validate: validateLastName })} />
                                            {errors.lastname && errors.lastname.type === 'required' && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                            {errors.lastname && errors.lastname.message === 'invalid' && <p>{ERROR_STRINGS.nameSpecialCharsError}</p>}
                                            {errors.lastname && (errors.lastname.type === 'required' || errors.lastname.message === 'invalid')
                                                ? <span className="form-control-feedback removeIcon"><img src={RemoveIcon} /></span>
                                                : <span className="form-control-feedback okIcon"><img src={OkIcon} /></span>
                                            }
                                        </div>
                                        {selectedRole && selectedRole === "Student" &&
                                            <div className="form-group">
                                                <label>DATE OF BIRTH</label>
                                                <div className="d-flex mb-1">
                                                    <div className="form-group d-flex flex-column m-0 pr-1">
                                                        <SelectSearch className="select-search stateSelectBox" options={monthOptions} search value={birthMonth} onChange={changeBirthMonth} placeholder="Month" />
                                                        {birthMonthError && <p>Select Month</p>}
                                                    </div>
                                                    <div className="form-group d-flex flex-column m-0 px-1" style={{ maxWidth: '95px' }}>
                                                        <label className="m-0">BIRTHDAY (DD)</label>
                                                        {errors.birthDate && errors.birthDate.type === 'required' && <p></p>}
                                                        <input type="text" className="form-control" id="birthDate" name="birthDate" maxLength='2' style={{ height: '30px' }} ref={register({ required: true, validate: validateBirthDate })} />
                                                        {errors.birthDate && errors.birthDate.message && <p>{errors.birthDate.message}</p>}

                                                    </div>
                                                    <div className="form-group d-flex flex-column m-0 pl-1" style={{ maxWidth: '85px' }}>
                                                        <label className="m-0">YEAR (YYYY)</label>
                                                        {errors.birthYear && errors.birthYear.type === 'required' && <p></p>}
                                                        <input type="text" className="form-control" id="birthYear" name="birthYear" maxLength='4' style={{ height: '30px' }} ref={register({ required: true, validate: validateBirthYear })} />
                                                        {errors.birthYear && errors.birthYear.message && <p>{errors.birthYear.message}</p>}
                                                    </div>
                                                </div>
                                                {(birthMonthError || (errors.birthDate && errors.birthDate.type === 'required') || (errors.birthYear && errors.birthYear.type === 'required')) && <p>Date of birth is required.</p>}
                                            </div>
                                        }
                                        <div className="form-group">
                                            <label>STATE</label>
                                            <SelectSearch className="select-search stateSelectBox" options={stateOptions} search value={userState} onChange={changeState} placeholder="State" required={true} />
                                            {formSubmited && !Boolean(userState) && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}

                                        </div>
                                        <div className="form-group">
                                            <label>DISTRICT</label>
                                            <SelectSearch disabled={!Boolean(userState)} className="select-search districtSelectBox" options={districtOptions} search value={userDistrict} onChange={changeDistrict} placeholder="District" />
                                            {formSubmited && Boolean(userState) && !Boolean(userDistrict) && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                        </div>
                                        {(() => {
                                            if (IsCustomStrategy) {
                                                return (
                                                    <>
                                                        <div className="form-group password-form-group">
                                                            <label>PASSWORD</label>
                                                            {errors.password && errors.password.type === 'required' && <p></p>}
                                                            {errors.password && errors.password.message === "Minimum 8 characters with at least 1 number and 1 special character needed" && <p></p>}
                                                            <input type="password" className="form-control" name="password" id="pwdInput" autoComplete="false" onClick={passwordInfo} onFocus={passwordInfo} onBlur={e => { setPasswordTooltip(false) }} ref={register({ validate: validatePassword, required: true })} />
                                                            {errors.password && errors.password.type === 'required' && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                                            {errors.password && errors.password.message === "Minimum 8 characters with at least 1 number and 1 special character needed" && <p>{PasswordValidationMessage}</p>}
                                                            {errors.password && (errors.password.type === 'required' || errors.password.message === "Minimum 8 characters with at least 1 number and 1 special character needed")
                                                                ? <span className="form-control-feedback removeIcon"><img src={RemoveIcon} /></span>
                                                                : <span className="form-control-feedback okIcon"><img src={OkIcon} /></span>}
                                                            <Overlay
                                                                show={passwordTooltip}
                                                                target={target}
                                                                placement="bottom-start"
                                                                container={ref.current}
                                                                containerPadding={10}
                                                            >
                                                                <Popover className={"password-container"} id="password-info-container">
                                                                    <Popover.Title as="h3">YOUR PASSWORD SHOULD HAVE AT LEAST 8 CHARACTERS AND MUST INCLUDE:</Popover.Title>
                                                                    <Popover.Content>
                                                                        <ul>
                                                                            <li>letters</li>
                                                                            <li>numbers</li>
                                                                            <li>at least one special character</li>
                                                                        </ul>
                                                                    </Popover.Content>
                                                                </Popover>
                                                            </Overlay>
                                                        </div>

                                                        <div className="form-group  confirmpassword-form-group">
                                                            <label>CONFIRM PASSWORD</label>
                                                            {errors.confirmpassword && errors.confirmpassword.type === 'required' && <p></p>}
                                                            {errors.confirmpassword && (errors.confirmpassword.message === "Minimum 8 characters with at least 1 number and 1 special character needed" || errors.confirmpassword.message === "Password & confirm password should be same") && <p></p>}
                                                            <input type="password" className="form-control" name="confirmpassword" id="confirmpwdInput" ref={register({ required: true, validate: validateConfirmPassword })} />
                                                            {errors.confirmpassword && errors.confirmpassword.type === 'required' && <p>{Messages.CreateAccount[2].RequiredField.Message}</p>}
                                                            {errors.confirmpassword && (errors.confirmpassword.message === "Minimum 8 characters with at least 1 number and 1 special character needed" || errors.confirmpassword.message === "Password & confirm password should be same") && <p>{ConfPasswordValidationMessage}</p>}
                                                            {errors.confirmpassword && (errors.confirmpassword.type === 'required' || errors.confirmpassword.message === "Minimum 8 characters with at least 1 number and 1 special character needed")
                                                                ? <span className="form-control-feedback removeIcon"><img src={RemoveIcon} /></span>
                                                                : <span className="form-control-feedback okIcon"><img src={OkIcon} /></span>}

                                                        </div>
                                                    </>
                                                )
                                            }
                                        })()}
                                        <div className="d-flex justify-content-between">
                                            <label className="checkbox-container">
                                                <input type="checkbox" onClick={onTermPolicyChecked} value={!IsTermPolicyChecked} />
                                                <span className="checkbox-checkmark"></span>
                                            </label>
                                            <span className="createAccFooter text-left" style={{ "padding": "0", "paddingLeft": "5px", }}>
                                                By clicking here, you are agreeing to Project Spark’s <a className="footerLink" href={termsOfUseLink} rel="noopener noreferrer" target="_blank">Terms of Use</a> & <a className="footerLink" href={dataProcessingAddendumLink} rel="noopener noreferrer" target="_blank">Data Processing Addendum</a> and <a className="footerLink" href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                                            </span>
                                        </div>

                                        <p>{acceptTermError}</p>
                                        <div className="form-group" style={{ textAlign: "center", marginTop: "50px" }}>
                                            <button type="submit" className={`${IsTermPolicyChecked == false ? "disabledCreateBtn" : ""} curve-fill-btn`}>SUBMIT</button>
                                        </div>
                                        <div className="createAccFooter disclosure">
                                            By clicking “Submit”, you agree to receive electronic communications from Discovery Education with marketing about Project Spark. You may withdraw your consent and unsubscribe from these marketing emails at any time by following the unsubscribe information provided in the email. For more information on how Discovery Education handles your personal information, please carefully read our <a className="footerLink" href={privacyPolicyLink} rel="noopener noreferrer" target="_blank">Privacy Policy</a>. Our handling of your provided information is subject the <a className="footerLink" href={termsOfUseLink} rel="noopener noreferrer" target="_blank">Terms of Use</a> & <a className="footerLink" href={dataProcessingAddendumLink} rel="noopener noreferrer" target="_blank">Data Processing Addendum</a>, where applicable, and Privacy Policy, as linked above.
                                        </div>
                                    </form>
                                </div>
                                <div className="col-12 createAccFooter" style={{ marginTop: "20px", marginBottom: "44px" }}>
                                    Already have an account? <span className="underlineAtag" ><Link to="/signin">Sign in here</Link></span>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal id="ageModal" dialogClassName="Modaldialog modal-dialog-centered" className="ageModal" visible={DobDialog} onClickBackdrop={() => { setDobDialog(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setDobDialog(false) }}>
                        <img alt="close" className="closeModalPNG" src={close} />
                    </div>
                    <div className="row align-items-center flex-column justify-content-center px-5 py-2">
                        <p className="ageDialogTitle">Thank you for your interest in Project Spark!</p>
                        <span className="ageDialogSeperator"></span>
                        <p className="ageDialogMessage">Unfortunately we cannot create a Project Spark account for you due to our system's minimum age restriction.</p>
                        <button className="btn btn-sm btn-info sparkButton" onClick={() => { setDobDialog(false) }}>OK</button>
                    </div>
                </div>
            </Modal>

            <Modal id="policyModal" dialogClassName="Modaldialog" className="policyModal" visible={isPolicyModal} onClickBackdrop={() => { setIsTermModal(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsPolicyModal(false) }}>
                        <img alt="close" className="closeModalPNG" src={close} />
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="policyHeader" style={{ marginTop: "5px" }}>
                                <img alt="icon" src={SparkIcon} style={{ width: "60xp", height: "60px" }} />
                            </div>
                        </div>
                        <div className="col-10">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <button type="button" className={`curve-fill-btn`} onClick={() => { setIsPolicyModal(false) }}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal id="termModal" dialogClassName="Modaldialog" className="termModal" visible={IsTermModal} onClickBackdrop={() => { setIsTermModal(false) }}>
                <div className="modal-body">
                    <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsTermModal(false) }}>
                        <img alt="close" className="closeModalPNG" src={close} />
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="policyHeader" style={{ marginTop: "5px" }}>
                                <img alt="icon" src={SparkIcon} style={{ width: "60xp", height: "60px" }} />
                            </div>
                        </div>
                        <div className="col-10">
                            <div dangerouslySetInnerHTML={{ __html: termtext }}></div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "25px", marginBottom: "25px" }}>
                        <div className="col-12" style={{ textAlign: "center" }}>
                            <button type="button" className={`curve-fill-btn`} onClick={() => { setIsTermModal(false) }}>CLOSE</button>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default React.memo(CreateAccount);