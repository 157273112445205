import React, { useState, useEffect, useContext, forwardRef, useRef, useImperativeHandle } from "react";

import {
    useHistory,
    useLocation
} from "react-router-dom";
import axios from "axios";
import UserContext from "../../shared/context/UserContext";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';

import "./StudentDashboard.css";
import Tour from "../../shared/components/tour/Tour";
import rightArrow from '../../assets/images/icons/Right.png';
import PhysicsIcon from "../../assets/images/physics_big.png";
import HistoryIcon from "../../assets/images/history_big.png";
import AlgebraIcon from "../../assets/images/algebra_big.png";
import History from "../../assets/images/history.png";
import AllThreeGif from "../../assets/images/AnimatedLogo.gif";
import mobileD from '../../assets/images/mobile-decive.png';
import devicerotate from '../../assets/images/device-rotate.png';
import browser from '../../assets/images/browser.png';
import Modal from 'react-bootstrap4-modal';

import { browserName, osVersion, osName, fullBrowserVersion, deviceType } from "react-device-detect";

import SessionTypes from "../SessionType/SessionTypes";

const StudentDashboard = forwardRef((props, ref) => {

    let resolutionWidth = window.screen.width;
    let resolutionHeight = window.screen.heigh;

    const [showResolutionMsg, setShowResolutionMsg] = useState(false);
    const [resolutionCode, setResolutionCode] = useState("");

    window.addEventListener("orientationchange", function (event) {
        if (event.target.screen.orientation.angle) {
            setShowResolutionMsg(false)
        }
    });

    let history = useHistory();
    const auth = useContext(UserContext);
    const childRef = useRef();
    const TourSteps = [
        {
            target: ".studentMyClass",
            content: "Welcome to your My Classes page where you have easy access to all your Project Spark classes! ",
            disableBeacon: true,
            placement: "right"
        },
        {
            target: ".classCard",
            content: (
                <div>
                    Each class you are registered for is displayed on the page. Simply click <b>Go to Class</b> to navigate to a class details page.
                </div>
            ),
            placement: "left"
        },
        {
            target: ".codeSectionElement",
            content: (
                <div>Is a class not listed? No problem! Ask your teacher for the class code, enter it here and click <b>Go</b>.</div>
            ),
            placement: "top"
        }
    ]

    const [classCode, setClassCode] = useState('');
    const [classList, setClassList] = useState([]);
    const [IsTourStart, setTourStart] = useState(false);
    const [IsTourVisble, setTourVisibility] = useState(false);
    const [addedPopup, setAddedPopup] = useState(false);
    const [addedMsg, setAddedMsg] = useState("");


    useEffect(() => {
        if ((browserName == "Edge" && parseInt(fullBrowserVersion.split('.')[0]) < 80) || (browserName == "IE")) {
            setResolutionCode("browser")
            setShowResolutionMsg(true);
        }

        if (resolutionWidth < 768) {
            setResolutionCode("device")
            setShowResolutionMsg(true);
        }
        if (resolutionWidth >= 768 && resolutionWidth < 1024) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setResolutionCode("orientation")
                setShowResolutionMsg(true);
            }
        }
        props.amplitude.track("Visited My Classes", { page_name: "" });
        let flag = false;
        if (auth.token) {
            axios.get("/user/getusercontextualtourdata/" + auth.userId).then(res => {
                if (res.data.contextualTour.length > 0) {
                    let data = res.data.contextualTour.filter(val => val.pageName == 'Dashboard');
                    if (data.length > 0) {
                        flag = false;
                    }
                    else {
                        flag = true;
                        axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'Dashboard' } });
                    }
                }
                else {
                    flag = true;
                    axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'Dashboard' } });
                }
                getClassSections(flag);
            });
        }
    }, [auth]);

    const getClassSections = (flag) => {
        props.showLoader();
        axios.get("/studentRoster/getbyclasssectionbystudentid/" + auth.userId)
            .then(res => {
                setClassList(res.data.result);
                if (flag == true) {
                    setTourVisibility(true);
                    childRef.current.getTour();
                }
                props.hideLoader();
            })

    }

    const getClassSectionByClassCode = (e) => {
        e.preventDefault()
        props.showLoader();
        props.amplitude.track("Clicked Enter Code GO Button", {});
        if (checkIfAlreadyAdded()) {
            props.hideLoader();
            setAddedMsg("You are already enrolled in this class. Please check the class code and try again.");
            setAddedPopup(true);
        }
        else {
            axios.post("/studentRoster/getclasssectionbyclasscode", { firstname: auth.firstname, lastname: auth.lastname, classCode: classCode })
                .then(res => {
                    if (!res.data) {
                        setClasscodeerror("The class code is not valid. Please check the class code and try again.");

                        props.hideLoader();
                    }
                    else {
                        axios.get("/studentRoster/getbyclasssectionbystudentid/" + auth.userId)
                            .then(res => {
                                let teacherId;
                                if (res && res.data && res.data.result && res.data.result.length > 0) {
                                    teacherId = res.data.result.filter(x => x.classCode == classCode);
                                    if (teacherId.length > 0) {
                                        teacherId = teacherId[0].id;
                                    }
                                }
                                props.amplitude.track("Joined Class", { teacher_user_id: teacherId, join_class_method: "Class Code", subject: "" });
                                setClassList(res.data.result);
                                setClassCode('');
                                setAddedMsg("Your enrollment in the class was successful.");
                                setAddedPopup(true);
                                props.hideLoader();
                            })

                    }
                }).catch(err => {

                    setClasscodeerror("The class code is not valid. Please check the class code and try again.");
                    props.hideLoader();
                });
        }
    }

    const checkIfAlreadyAdded = () => {

        let temp = classList.filter(x => x.classCode.toLowerCase() == classCode.toLowerCase());
        if (temp.length > 0)
            return true;
        else
            return false;

    }

    const goToDetails = (id) => {
        history.push('/studentClass/' + id);
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourVisibility(true);
            setTourStart(true);
        }

    }));

    const checkOrder = (index) => {
        index = index + 1;
        let res = index % 3;
        if (res == 0) {
            return 'historyCard';
        }
        else if (res == 1) {
            return 'physicsCard';
        }
        else {
            return 'algebraCard';
        }
    }

    const [classcodeerror, setClasscodeerror] = useState("");

    return (
        <>
            <div style={{ background: "#F2F2F9" }}>
                <div className="largeScreenDiv" style={{ display: "flex", minHeight: "54vh" }}>
                    <div className="leftSide">
                        <div className="verticalLine-1"></div>
                        <div className="leftside-div">
                            <div style={{ marginTop: "80px" }}><img src={AllThreeGif} /></div>
                            <div className="studentMyClass">
                                <div className="myClassesLabel">My Classes</div>
                                <div className="myClassesDesc">Here’s your list of Project Spark classes. Don’t see a class listed? Ask your teacher for the class Code and enter it below.</div>
                            </div>
                        </div>
                    </div>
                    <div className="row rightSide">
                        {classList.map((item, index) => {
                            const types = Object.values(item.sessionType);
                            let ShowCurrentSession = types.some(item => item === "teacher");
                            return (
                                <div key={index} className="innerDiv" style={{ zIndex: "1" }}>

                                    <div className={`py-2 d-flex justify-content-between flex-column classCard ${checkOrder(index)} `} >
                                        <div><img alt="icon" src={item.courseIcon} /></div>
                                        <div className="classNameLabel trucateClassNameLabel" style={{ paddingLeft: "10px", paddingRight: "10px", }} title={item.name}> {item.name}</div>
                                        <div className="classNameLabel">{item.subject}</div>
                                        <div className="studentCurrentSession">
                                            {item.currentSession !== undefined && item.currentSession !== null && ShowCurrentSession && <span>CURRENT SESSION {item.currentSession}</span>}
                                            {(item.currentSession === null || item.currentSession === undefined) && !isNaN(new Date(item.startDate)) && <span>Start date: {new Date(item.startDate).getMonth() + 1}/{new Date(item.startDate).getDate()}</span>}
                                        </div>
                                        <SessionTypes type={item.sessionType} />
                                        <div className="goToClassButton" onClick={() => { goToDetails(item.id) }}>Go to Class <img src={rightArrow} /></div>
                                    </div>
                                </div>
                            )
                        })}
                        {resolutionWidth < 1920 && (classList.length > 1 && classList.length % 2 === 1) && <div className="verticalLine-2" style={{ right: "255px" }}></div>}
                        {resolutionWidth < 1920 && (classList.length > 1 && classList.length % 2 === 0) && ((parseInt(classList.length / 2) !== 1 && <div className="verticalLine-2" style={{ right: "255px", height: "328px", zIndex: "0" }}></div>) || (parseInt(classList.length / 2) === 1 && <div className="verticalLine-2" style={{ right: "100px", height: "330px" }}></div>))}
                        {resolutionWidth < 1920 && (classList.length === 0 || classList.length === 1) && <div className="verticalLine-3" style={{ left: "550px", height: "456px" }}></div>}
                        {resolutionWidth >= 1920 && (classList.length === 1) && <div className="verticalLine-3" style={{ right: "-465px" }}></div>}
                        {resolutionWidth >= 1920 && (classList.length === 0) && <div className="verticalLine-3" style={{ right: "-865px" }}></div>}
                        {resolutionWidth >= 1920 && (classList.length > 1 && classList.length % 3 === 1) && ((parseInt(classList.length / 3) !== 0 && <div className="verticalLine-2" style={{ right: "320px" }}></div>) || (parseInt(classList.length / 3) === 0 && <div className="verticalLine-2" style={{ right: "-175px", height: "405px" }}></div>))}
                        {resolutionWidth >= 1920 && (classList.length > 1 && classList.length % 3 === 2) && ((parseInt(classList.length / 3) !== 0 && <div className="verticalLine-2" style={{ right: "320px" }}></div>) || (parseInt(classList.length / 3) === 0 && <div className="verticalLine-2" style={{ right: "-175px", height: "405px" }}></div>))}
                        {resolutionWidth >= 1920 && (classList.length > 1 && classList.length % 3 === 0) && <div className="verticalLine-2" style={{ right: "100px", height: "360px" }}></div>}
                    </div>
                </div>

                <div className="codeSecion">
                    <div className="codeSectionElement">
                        <div className="codeSectionDesc">Don’t see your class? Add the class code:</div>
                        <form onSubmit={getClassSectionByClassCode}>
                            <div className="codeSectionInput">

                                <input type="search" id="classCodeInput" placeholder="ENTER CODE..." value={classCode} onChange={(e) => { setClasscodeerror(""); setClassCode(e.target.value.toUpperCase()) }} />
                                <div className="ClassSreachGoBtn" onClick={getClassSectionByClassCode}>Go</div>

                            </div>
                        </form>
                        {classcodeerror !== "" && <div className="errorinclasscode">{classcodeerror}</div>}
                    </div>
                </div>
            </div>

            {
                IsTourVisble &&
                <Tour ref={childRef} tourSteps={TourSteps} amplitude={props.amplitude} />
            }
            <ConfirmationBox
                isVisible={addedPopup}
                setVisible={(value) => { setAddedPopup(value) }}
                confirmationHeader={addedMsg}
                confirmationText=""
                handleOK={() => { setAddedPopup(false); }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />

            <Modal id="deviceError" className="deviceError" visible={showResolutionMsg} onClickBackdrop={() => { }}>
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row">
                        {resolutionCode == "device" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={mobileD} />
                                </div>
                                <div className="col-12">
                                    <span className="oops">OOPS!</span>
                                    <span className="oopsline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Your device is not supported at the moment.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">for a better experience please use a large screen device such as tablet, Notebook or above.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">If you are using school device please check with your teacher or support staff.</span>
                            </div>
                        </div>}



                        {resolutionCode == "orientation" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={devicerotate} />
                                </div>
                                <div className="col-12">
                                    <span className="snap">SNAP!</span>
                                    <span className="snapline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Turn to landscape mode for a better experience.</span>
                            </div>
                        </div>}


                        {resolutionCode == "browser" &&
                            <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                                <div className="row" >
                                    <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                        <img alt="" src={browser} />
                                    </div>
                                    <div className="col-12">
                                        <span className="oops">OOPS!</span>
                                        <span className="browserline"></span>
                                    </div>
                                </div>
                                <div className="row" >
                                    <span className="deviceErrorHeadT">We are sorry, but your browser is not compatible. Please use a more up to date browser.</span>
                                </div>
                                <div className="row" style={{ marginTop: "25px" }}>
                                    <span style={{ margin: "auto", fontWeight: "bold", fontSize: "9px", lineHeight: "16px", textAlign: "center", letterSpacing: "0.9px", textTransform: "uppercase", color: "#4C4B5E" }}>If you are using a school device please check with you teacher or support staff.</span>
                                </div>

                            </div>}

                    </div>
                </div>
            </Modal>
        </>
    )
})

export default React.memo(StudentDashboard);