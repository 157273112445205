import React, { useState, useEffect } from "react";
import StepFinal from '../../assets/images/steps_final.png';
import CopyPaste from '../../assets/images/icons/copy-paste.png';
import InfoIcon from '../../assets/images/icons/information.png';
import "./AddClass.css";
import {
    Link
} from 'react-router-dom';

const FinalStep = props => {

    const copyToClipboard = () => {
        var copyText = document.getElementById("copiedInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied: " + copyText.value;
    }

    const outFunc = () => {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    }

    const changeCopiedVal = () => { }

    useEffect(() => {
        props.amplitude.track("Created Class", { class_creation_method: "Manual", class_section_id: props.classSectionId });
        props.amplitude.track("Visited Add Class", { class_section_id: props.classSectionId, page_section: "Class Created" });
    }, [])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col" >
                        <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                <img src={StepFinal} />
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">CONGRATS!</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px", marginBottom: "30px" }}>
                                <b style={{ textTransform: "uppercase" }}>{props.classname}</b> has been created.
                            </div>
                            <div className="col-12" >
                                <div className="classCodeCard">
                                    <span className="infoIcon" style={{ display: 'none' }}><img src={InfoIcon} /></span>
                                    <div className="codeLabel"><span>Class Code: </span><span> <input type="text" id="copiedInput" value={props.classcode} onChange={changeCopiedVal} /></span></div>
                                    <p className="classCodeDesc">Don’t need to use the code right away? No need to write it down. This unique course code is also available on the class dashboard.</p>
                                    <div className="copyPasteLabel" style={{ marginTop: "13px" }}>
                                        <span><img src={CopyPaste} style={{ marginRight: "5px" }} /> Click here to </span>
                                        <span className="tooltipDiv">
                                            <span className="underlineLabel" onClick={copyToClipboard} onMouseOut={outFunc}>
                                                <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>
                                                copy code
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "42px" }}>
                                <Link className="curve-fill-btn final-btn" id="final" to={`/classdashboard/${props.classSectionId}`} >GO TO CLASS DASHBOARD</Link>

                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "23px" }}>
                                <Link className="curve-bordered-btn final-btn final-btn-2" id="addAnotherClass" to="/addClass">ADD ANOTHER CLASS</Link>
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "23px", marginBottom: "56px" }}>
                                <div className="goToMyClassLabel"><Link to="/teacherdashboard" style={{ color: "inherit" }}>Go to My Classes</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(FinalStep);