import React, { useEffect, useState } from "react";
import { SESSION_TYPE, SESSION_STATUS } from "../constants/CommonConstants";

import IFrame from "../../shared/components/iFrameBox/IFrameBox";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import Modal from 'react-bootstrap4-modal';


const ClassTabHeader = (props) => {
    const [classDetails, setClassDetails] = useState();
    const [isSessionUnlocked, setIsSessionUnlocked] = useState(false);


    const [showExampleProject, setShowExampleProject] = useState(false);
    const [showMyProject, setShowMyProject] = useState(false);

    const [standards, setStandards] = useState();
    const [isStandardModal, setIsStandardModal] = useState(false);
    const [user, setUser] = useState();
    const [studentRosterId, setStudentRosterId] = useState("");

    useEffect(() => {
        let classInfo = props.classDetails;
        setClassDetails(classInfo);
        setStandards(classInfo.standards && classInfo.standards[0]);
        setUser(props.auth);
        classInfo.subject && updateStudentRoster(classInfo.subject);

    }, [props.classDetails]);


    useEffect(() => {
        setIsSessionUnlocked(props.isSessionUnlocked);
    }, [props.isSessionUnlocked]);

    const updateStudentRoster = (subject) => {
        if (subject.toLowerCase() === "history") {
            setStudentRosterId('61142cd969c344011ac09572');
        }

        if (subject.toLowerCase() === "physics") {
            setStudentRosterId('61142cc869c344011ac09527');
        }
        if (subject.toLowerCase() === "algebra") {
            setStudentRosterId('61142cd269c344011ac0954c');
        }
    }

    const mergerTwoIds = (a, b) => {
        const BYTECOUNT = 24;
        let res = "";
        for (let i = 0; i < BYTECOUNT; i += 4) {
            res = ("000" + (parseInt(a.slice(-i - 4, -i || a.length), 16) ^ parseInt(b.slice(-i - 4, -i || b.length), 16)).toString(16)).slice(-4) + res;
        }
        return res;
    }

    const trackButton = (btn_name) => {
        props.amplitude.track("Clicked Class Details Button", { class_section_id: props.classId, page_section: "Facilitation", button_name: btn_name, session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex], subject: props.subject });
    }


    return (
        <>
            <div className="row m--15">
                <div className="col-md-3 " style={{ minWidth: "350px" }}>
                    <div className="s-card py-2 px-4 session-tooltip">
                        <div className="d-flex align-items-center justify-content-between py-2" style={{ fontSize: '16px', borderBottom: "1px solid #C7C7D4" }}>
                            <span className="title-text">Session:</span>
                            <div className="dropdown w-100" style={{ maxWidth: "220px" }}>
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {props.currentSessionId}
                                    <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                </div>
                                <div className="dropdown-menu w-100 mt-1" aria-labelledby="dropdownMenuButton" >
                                    {props.sessionList && props.sessionList.map(sessionItem => {
                                        return <span className="dropdown-item py-2 px-3" key={"session-" + sessionItem.value} onClick={() => props.changeSessionId(sessionItem.value)}>{sessionItem.name}</span>
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between py-2" style={{ fontSize: '16px', borderBottom: "1px solid #C7C7D4" }}>
                            <span className="title-text">Modality:</span>
                            <div className="dropdown w-100" style={{ maxWidth: "220px" }}>
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {SESSION_TYPE[props.sessionType[props.currentSessionId]]}
                                    <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                </div>
                                <div className="dropdown-menu w-100 mt-1" aria-labelledby="dropdownMenuButton" >
                                    <span className="dropdown-item py-2 px-3" onClick={() => props.changeSessionType("teacher")}>
                                        {SESSION_TYPE.teacher}
                                    </span>
                                    <span className="dropdown-item py-2 px-3" onClick={() => props.changeSessionType("student")}>
                                        {SESSION_TYPE.student}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between py-2" style={{ fontSize: '16px' }}>
                            <span className="title-text">Status:</span>
                            <div className="dropdown w-100" style={{ maxWidth: "220px" }}>
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {SESSION_STATUS[isSessionUnlocked]}
                                    <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                </div>
                                <div className="dropdown-menu w-100 mt-1" aria-labelledby="dropdownMenuButton" >
                                    <button className="dropdown-item py-2 px-3" onClick={() => props.unLockSession(false)} disabled={Boolean(!isSessionUnlocked)}>
                                        {SESSION_STATUS.false}
                                    </button>
                                    <button className="dropdown-item py-2 px-3" onClick={() => props.unLockSession(true)} disabled={Boolean(isSessionUnlocked)}>
                                        {SESSION_STATUS.true}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-md-3">
                    <div className="s-card py-2 px-4 session-materials  example-project-tooltip">
                        <div className="d-flex align-items-center justify-content-between "
                            style={{ fontSize: '14px', borderBottom: "1px solid #C7C7D4", padding: "11px 0px" }}
                        >
                            <span className="font-weight-bold">Examplar Projects</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between "
                            style={{ fontSize: '14px', padding: "7px 0px" }}
                        >
                            <span className="SM-filename" onClick={() => setShowExampleProject(true)} >
                                See Completed Project
                            </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between "
                            style={{ fontSize: '11px', padding: "7px 0px" }}
                        >
                            <span className="SM-filename" onClick={() => setShowMyProject(true)} >
                                See Session as a Student
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="s-card py-2 px-4 session-materials materials-tooltip">
                        <div className="d-flex align-items-center justify-content-between "
                            style={{ fontSize: '14px', borderBottom: "1px solid #C7C7D4", padding: "11px 0px" }}
                        >
                            <span className="font-weight-bold">Lesson Plan Materials</span>
                            <div className="download-all" onClick={props.downloadAll} >Download All</div>
                        </div>

                        <div className="row">
                            <div className="col-6 p-0">
                                {props.sessionMaterials && props.sessionMaterials.map((item, index) => {
                                    return <div key={item.name} style={{ padding: "7px 0px" }}>
                                        <a href={item.link} download={item.link} className="SM-filename" >
                                            {item.name}
                                        </a>
                                    </div>
                                })
                                }

                                {props.quickStartGuide && props.quickStartGuide.map((item, index) => {

                                    return <div key={item.name} style={{ padding: "7px 0px" }}>
                                        <a href={item.link} download={item.link} className="SM-filename" >
                                            {item.name}
                                        </a>
                                    </div>

                                })
                                }
                            </div>
                            <div className="col-6 p-0">
                                {props.otherMaterials && props.otherMaterials.map((item, index) => {

                                    return <div key={item.name} style={{ padding: "7px 0px" }}>
                                        <a href={item.link} download={item.link} className="SM-filename" >
                                            {item.name}
                                        </a>
                                    </div>

                                })
                                }
                                <span className="SM-filename" onClick={() => { setIsStandardModal(true) }} style={{ padding: "7px 0px" }}>Lesson Standards</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {classDetails && classDetails.entryPoints && showMyProject &&
                <IFrame
                    mode="normal"
                    courseName={classDetails.courseName}
                    userDetails={{ userID: user.userId, email: user.email, username: user.firstname }}
                    studentRosterID={mergerTwoIds(user.userId, classDetails.courseId)}
                    isFreezed={null}
                    sessionDetails={{ sessionName: props.currentSessionDetails.name, sessionIndex: props.currentSessionId }}
                    sessions={[]}
                    isVisible={showMyProject}
                    title="My Project"
                    setVisible={(value) => { setShowMyProject(value) }}
                    iframeURL={classDetails.entryPoints.educatorSandboxEntry}
                    sessionTypes={classDetails.sessionTypes}
                    subject={classDetails.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={classDetails._id}
                    teacherId={classDetails.teacher} />
            }

            {classDetails && showExampleProject &&
                <IFrame
                    mode="demo"
                    courseName={classDetails.courseName}
                    userDetails={{ userID: user.userId, email: user.email, username: user.firstname }}
                    studentRosterID={studentRosterId}
                    isFreezed={null}
                    sessionDetails={{ sessionName: props.currentSessionDetails.name, sessionIndex: props.currentSessionId }}
                    sessions={[]}
                    isVisible={showExampleProject}
                    title="Example Project"
                    setVisible={(value) => { setShowExampleProject(value) }}
                    iframeURL={classDetails.entryPoints.demoProjectEntry}
                    sessionTypes={classDetails.sessionTypes}
                    subject={classDetails.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={classDetails._id}
                    teacherId={classDetails.teacher} />
            }
            {standards &&
                <Modal id="standardModal" dialogClassName="Modaldialog modal-dialog-centered" className="standardsModal" visible={isStandardModal} onClickBackdrop={() => { setIsStandardModal(false) }}>
                    <div className="modal-body">
                        <div className="text-right">
                            <span className="close-btn btn btn-outline-primary btn-pill" onClick={() => { setIsStandardModal(false) }}>
                                <ClearIcon />
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <div className="standardHeader" style={{ marginTop: "5px" }}>
                                    <img alt="icon" src={props.classDetails.courseIcon} style={{ width: "60xp", height: "60px" }} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="standardHeader">
                                    <span>{standards.title}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-1 col-10">
                                <div >
                                    <span className="S-Header-sub" dangerouslySetInnerHTML={{ __html: standards.subTitle }}></span>
                                    <p className="S-text" dangerouslySetInnerHTML={{ __html: standards.description }}></p>
                                </div>
                                {
                                    standards.standards.length > 0 && standards.standards.map((item, i) => {
                                        return <div key={"standard-" + i}>
                                            <div className="S-color-header">
                                                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                            </div>
                                            {
                                                item.Objectives.map((x, index) => {
                                                    return <div key={"objective-" + index}>
                                                        <span className="S-normal-header" dangerouslySetInnerHTML={{ __html: x.title }}></span>
                                                        <p className="S-text" dangerouslySetInnerHTML={{ __html: x.description }}></p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    )
}

export default (ClassTabHeader);