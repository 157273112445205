import React, { useContext, useState, useEffect } from "react";
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from "axios";

import { useForm } from "react-hook-form";

import "./resetPassword.css";
import UserContext from "../../shared/context/UserContext";

import logo from '../../assets/images/spark_logo.svg';

function ResetPassword(props) {
    const auth = useContext(UserContext);
    let history = useHistory();
    const axiosInstance = axios.create();

    const { register, handleSubmit, watch, errors } = useForm({ mode: "onBlur" })

    const [confirmPass, setConfirmPass] = useState();
    const [newPass, setNewPass] = useState();
    const [flag, setFlag] = useState(false);
    const [subHeaderText, setSubHeaderText] = useState("");
    const [email, setEmail] = useState();

    const changePassword = (event) => {
        axiosInstance.patch(process.env.REACT_APP_APIURL + "/user/changepasswordbyemail", { emailId: email, password: newPass }, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                setFlag(true);
            })
            .catch(error => {
                console.log(error)
            })

    }

    const newPasswordChangeHandler = (event) => {
        setNewPass(event.target.value);
    }
    const confirmPasswordChangeHandler = (event) => {
        setConfirmPass(event.target.value);
    }
    const checkPassword = () => {
        return newPass === confirmPass;
    }

    const emailChangeHandler = (event) => {
        setEmail(event.target.value);
    }

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }

    const validateEmail = async (val) => {
        let user = await axios.post("/user/getuserbyemail", { email: val });
        if (user.data.email !== val) {
            return 'Entered email is not present in the system';
        }
    }

    return (
        <>
            <div className="resetpasswordBox minscreenheightResetPass" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)" }}>
                <div style={{ width: "640px", margin: "auto", backgroundColor: "#fff" }}>
                    <div className="row " style={{ textAlign: "center" }}>
                        <div className="col">
                            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                                <div className="col-12">
                                    <span className="forgotpassMsg">RESET PASSWORD</span>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ marginTop: "60px" }}>
                                <div className="col-12">
                                    <form className="signinform" onSubmit={handleSubmit(changePassword)}>
                                        <div className="form-group" style={{ margin: "0 150px", textAlign: "left" }}>
                                            <label>EMAIL</label>
                                            <input type="text" className="form-control" name="email" id="emailInput" onChange={emailChangeHandler} ref={register({ required: true, validate: validateEmail, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                                            {errors.email && errors.email.type === 'pattern' && <p style={{ color: "red", fontSize: "13px" }}>Please enter valid email</p>}
                                            {errors.email && errors.email.type === 'required' && <p style={{ color: "red", fontSize: "13px" }}>This field is required</p>}
                                            {errors.email && errors.email.message === 'Entered email is not present in the system' && <p style={{ color: "red", fontSize: "13px" }}>Entered email is not present in the system</p>}
                                        </div>
                                        <div className="form-group" style={{ margin: "20px 150px", textAlign: "left" }}>
                                            <label>New Password</label>
                                            <input type="password" className="form-control" id="newPassword" onChange={newPasswordChangeHandler} />
                                        </div>
                                        <div className="form-group" style={{ margin: "20px 150px", textAlign: "left" }}>
                                            <label>Confirm Password</label>
                                            <input type="password" className="form-control" name="confirmPassword" onChange={confirmPasswordChangeHandler} ref={register({ required: true, validate: checkPassword })} />
                                            {errors.confirmPassword && errors.confirmPassword.type === "required" && <span style={{ color: "red" }}>This field is required</span>}
                                            {errors.confirmPassword && errors.confirmPassword.type === "validate" && < span style={{ color: "red" }}> Password does not match.</span>}
                                        </div>
                                        <div className="form-group" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px" }}>
                                            <button type="submit" className="curve-fill-btn">RESET PASSWORD</button>
                                        </div>
                                        {flag && <div style={{ margin: "20px auto" }}><span>Password changed successfully.</span>
                                        </div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ResetPassword);