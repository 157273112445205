import React, { useState, useEffect } from 'react'
import { Prompt } from 'react-router-dom'
import Modal from "react-bootstrap4-modal";
import { useHistory } from "react-router-dom";
import ClearIcon from '@material-ui/icons/Clear';

const RouteLeavingGuard = (props) => {
    let history = useHistory();
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [lastLocation, setLastLocation] = useState(null);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);


    const showModal = (location) => {
        setModalVisible(true);
        setLastLocation(location);
    }

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation) => {
        if (!confirmedNavigation) {
            showModal(nextLocation);
            return false
        }
        return true
    }

    const handleConfirmNavigationClick = () => {
        setConfirmedNavigation(true);
        setModalVisible(false);
    };

    return (
        <>
            <Prompt when={props.when} message={handleBlockedNavigation} />

            <Modal dialogClassName="modal-dialog-centered modal-sm" className="" visible={modalVisible} onClickBackdrop={() => closeModal()}>
                <div className="modal-body p-2">
                    <div className="text-right">
                        <span className="close-btn btn btn-outline-primary btn-pill" onClick={() => { handleConfirmNavigationClick() }}>
                            <ClearIcon />
                        </span>
                    </div>
                    <div className="confirm-dialog-body px-3 py-5 text-center">
                        Do you want to<br />save changes?
                    </div>
                    <div className="d-flex align-items-center flex-column mb-3">
                        <button className="btn btn-primary btn-pill mb-2 btn-dialog-ok" onClick={() => { { setModalVisible(false); props.onConfirm() } }}>Save</button>
                        <button className="btn btn-link btn-dialog-cancel" onClick={() => handleConfirmNavigationClick()}>Cancel</button>
                    </div>
                </div>

            </Modal>
        </>
    )

}

export default RouteLeavingGuard