//import axios from "axios";
//const config = require("../../assets/config/Config.json");

//const amplitude = require('amplitude-js');
//const analytics = amplitude.getInstance();
let userContext = {};
let user_id = "";
let userDetails = {};

// analytics.init('01aa1c28bfd9c104267c31e1f0ceb919');
export function init() {
    let api_key = '2497034b8159d5eefa67a27c7609e5a6';

    if (process != undefined && process.env != undefined && process.env.REACT_APP_APPURL.includes("dev.myprojectspark.com")) {
        api_key = '01aa1c28bfd9c104267c31e1f0ceb919';
    } else if (process != undefined && process.env != undefined && process.env.REACT_APP_APPURL.includes("stg.myprojectspark.com")) {
        api_key = '20b7caef67048ff9a14d05e1763728f4';
    } else if (process != undefined && process.env != undefined &&  process.env.REACT_APP_APPURL.includes("portal.myprojectspark.com")) {
        api_key = '1117940e2c84345f2395c34b7d3ab09a';
    }

  //  analytics.init(api_key, null, { saveEvents: true, includeUtm: true, includeReferrer: true });
   console.log("analytics.init");
}

export function setUserId(userId) {
   // analytics.setUserId(userId);
    user_id = userId;

    console.log("analytics.setUserId");
}

//export function setUserContext(user) {
export function setUserContext(user,userData,stateDistrict) {
    const user_role = userData.roles && userData.roles[0].name || "";
    const user_login_method = (userData.strategy === "Custom Account" ? "Spark Account" : userData.strategy) || "";
    let state = "";
    let district = "";
    if(stateDistrict != null){
        state = stateDistrict.state;
        district = stateDistrict.district;
    }
    userDetails = {
        user_id: userData.userId || "",
        user_login_method:user_login_method,
        user_state: state || "",
        user_district: district || "",
        user_role:user_role
    };

   // analytics.setUserProperties(userDetails);
    userContext = { ...user };

    console.log("analytics.setUserContext");
}

export function track(event, dataObject) {
    const trackData = { ...dataObject, user_id }
    console.log("analytics.track");
 //   logEvent(event, trackData);
};

export function trackWithCallback(event, dataObject, callback) {
    const trackData = { ...dataObject, user_id }
    console.log("analytics.trackWithCallback");
    //logEvent(event, trackData, callback);
};

function logEvent(event, dataObject, callback) {
    const trackData = { ...dataObject }
  //  analytics.setUserId(user_id);

    if (typeof callback == "function") {
      //  analytics.logEvent(event, trackData, callback());
    } else {
      //  analytics.logEvent(event, trackData);
    }
}


