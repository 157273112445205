
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Resources = (props) => (
    <SvgIcon  {...props}>
        <path d="m10.287 16.521c-0.08954 7.6e-5 -0.17834-0.0161-0.26207-0.04786-1.9674-0.78081-3.2517-2.6918-3.2314-4.8084 0.036796-2.8606 2.3459-5.171 5.2064-5.2095 2.8606 0.036378 5.1714 2.3452 5.2102 5.2057 0.02241 2.1171-1.2604 4.0296-3.2276 4.8122-0.08371 0.03175-0.17251 0.04793-0.26207 0.04786zm5.4351-4.8593c-0.050362-2.0344-1.6877-3.6718-3.7221-3.7221-2.0342 0.050378-3.6713 1.6872-3.7221 3.7214-0.019932 1.4379 0.80717 2.7532 2.1117 3.3583 0.02393 0.01056 0.04981 0.01603 0.07596 0.01595h0.60162c0.1049 0 0.1899-0.085 0.1899-0.1899v-1.4828c1.52e-4 -0.05044-0.01975-0.09883-0.05545-0.13445l-1.2154-1.2154c-0.18794-0.18793-0.26133-0.46185-0.19254-0.7186 0.068791-0.25668 0.26931-0.45722 0.52604-0.52604 0.25673-0.06874 0.53065 0.0046 0.71858 0.19256l0.82723 0.82723c0.07437 0.07399 0.19454 0.07399 0.26891 0l0.83103-0.82875c0.29056-0.29056 0.76152-0.29056 1.0521 0 0.29056 0.29056 0.29056 0.76152 0 1.0521l-1.2154 1.2154c-0.0354 0.03585-0.0553 0.08409-0.05545 0.13445v1.4835c0 0.1049 0.085 0.18991 0.1899 0.18991h0.60086c0.02613 7.6e-5 0.05203-0.0054 0.07596-0.01595 1.3029-0.60572 2.1286-1.9201 2.1087-3.3568z" clipRule="evenodd" fillRule="evenodd" />
        <path d="m12 5.9071c0.41073 0 0.74367-0.33295 0.74367-0.74367v-1.4197c0-0.41072-0.33294-0.74367-0.74367-0.74367-0.41073 0-0.74367 0.33295-0.74367 0.74367v1.4197c0 0.19724 0.07832 0.38639 0.21778 0.52586 0.13947 0.13947 0.32861 0.21781 0.52589 0.21781z" />
        <path d="m5.5219 11.858c0.2715 0.0087 0.52616-0.13119 0.66447-0.365 0.13831-0.23381 0.13831-0.52437 0-0.7581-0.13831-0.23381-0.39298-0.37368-0.66447-0.36501h-1.7038c-0.40167 0.01283-0.72065 0.34214-0.72065 0.74406 0 0.40184 0.31898 0.73121 0.72065 0.74405z" />
        <path d="m6.6393 7.5494c0.18793 0.18793 0.46186 0.26132 0.71858 0.19254 0.25672-0.068791 0.45725-0.26932 0.52604-0.52604 0.068791-0.25673-0.0046109-0.53065-0.19254-0.71858l-1.4205-1.4197c-0.2925-0.27697-0.75234-0.27069-1.0372 0.014144-0.28484 0.28484-0.29111 0.74467-0.014144 1.0372z" />
        <path d="m17.734 11.114c-2.28e-4 0.1975 0.07817 0.38695 0.21778 0.52657 0.13969 0.13969 0.32914 0.21809 0.52664 0.21786h1.7038c0.40169-0.01284 0.72065-0.34221 0.72065-0.74405 0-0.40192-0.31896-0.73122-0.72065-0.74406h-1.7038c-0.19735-2.05e-4 -0.38672 0.07806-0.52634 0.21756-0.13962 0.13947-0.21809 0.32876-0.21809 0.52611z" />
        <path d="m16.835 7.7666c0.19712 3.494e-4 0.38627-0.077831 0.52566-0.21725l1.4197-1.4205c0.19644-0.186 0.27635-0.464 0.20874-0.72593-0.06761-0.26193-0.27217-0.46648-0.53409-0.53412-0.26199-0.067637-0.53994 0.012298-0.72597 0.20874l-1.4205 1.4197c-0.21262 0.21278-0.27612 0.53269-0.16089 0.81055s0.3865 0.45893 0.68731 0.45878z" />
        <path d="m14.232 18.428c0-0.19735-0.07847-0.38665-0.21809-0.52611-0.13962-0.13954-0.32899-0.21778-0.52634-0.21756h-2.9747c-0.26568 0-0.51119 0.14175-0.64404 0.37184-0.13284 0.23009-0.13284 0.51358 0 0.74367 0.13285 0.23009 0.37835 0.37184 0.64404 0.37184h0.61529c0.1049 0 0.1899 0.085 0.1899 0.1899v0.89483c0 0.41073 0.33294 0.74367 0.74367 0.74367 0.41073 0 0.74367-0.33294 0.74367-0.74367v-0.89483c0-0.1049 0.085-0.1899 0.18991-0.1899h0.49223c0.19735 2.28e-4 0.38672-0.07809 0.52634-0.21756s0.21809-0.32876 0.21809-0.52611z" />
    </SvgIcon >
);

export default Resources;
