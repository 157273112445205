import React, { useContext, useEffect } from "react";
import UserContext from "../../shared/context/UserContext";
import "./MobileWelcome.css";
import logo from '../../assets/images/spark-logo-corored.svg'

function MobileWelcome(props) {
    const auth = useContext(UserContext);
    useEffect(() => {
        props.amplitude.track("User Page Visits", { page_name: "Mobile View Welcome" })
    }, []);

    return (
        <>
            <div className="mobileWelcomeBack">
                <div className="row header margin0">
                    <div className="col-4">
                        <img src={logo} alt="spark" />
                    </div>
                </div>
                <div className="row justify-content-center mobileWelcomeHead">
                    <span>WELCOME {auth.lastname}, {auth.firstname}!</span>
                </div>
                <div className="mobileBox">
                    <div className="row justify-content-center mobileNormalText" >
                        <div className="col-12">
                            <span>Thank you for joining <strong style={{ color: "#009DDB" }}>Project Spark!</strong></span>
                        </div>
                    </div>

                    <div className="row justify-content-center mobileMediumText">
                        <div className="col-12">
                            <span>For full access, please <strong>use a large screen device</strong> such as a tablet, Notebook or above.</span>
                        </div>
                    </div>

                    <div className="row justify-content-center mobileSmallText" >
                        <div className="col-12">
                            <span>If you are using a school device please check with your teacher or support staff.</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default (MobileWelcome);