import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import axios from "axios";
import { DEFAULT_VALUES } from "../constants/CommonConstants";
import config from '../../assets/config/Config.json';

import Algebra from '../Icons/Algebra';
import History from '../Icons/History';
import Physics from '../Icons/Physics';

const ClassSectionList = props => {

    const [csList, setCsList] = useState([]);
    const [classSectionDispalyList, setClassSectionDispalyList] = useState([]);
    const [itemCount, setItemCount] = useState(DEFAULT_VALUES.classListCount);
    const [ShowMoreText, setShowMoreText] = useState("Show More");
    const [csListCount, setCsListCount] = useState(0);

    useEffect(() => {
        getClassSectionList();
    }, [props.userId, props.updateSideBar]);

    useEffect(() => {
        let list = [...csList];
        list.splice(itemCount);
        let items = list;
        setClassSectionDispalyList(items);
    }, [itemCount, csList]);

    const getClassSectionList = () => {
        axios.get("/classSection/sidebarmenu/" + props.userId)
            .then(res => {
                let courseList = res.data.filter(item => item.isArchived === false);
                setCsList(courseList);
                setCsListCount(courseList.length);
                props.setUpdateSideBar(false);
            }).catch(err => {
                console.log("csList error", err);
            });
    }

    const toggleShowMore = () => {
        if (itemCount > DEFAULT_VALUES.classListCount) {
            setItemCount(DEFAULT_VALUES.classListCount);
            setShowMoreText("Show More");
        } else {
            setItemCount(csList.length);
            setShowMoreText("Show Less");
        }
    }

    return (
        <>
            <div className="m-0 px-3 menu-text">
                {classSectionDispalyList.map((item, index) => {
                    if (!item.isArchived) {
                        return (
                            <NavLink exact={true} activeClassName='is-active' className="class-name-list list-group-item d-flex align-items-center px-0" to={'/classdashboard/' + item._id} key={"nav-" + index}>
                                {item.subject === "Algebra" && <Algebra className="mr-2 flex-shrink-0" height={25} width={25} />}
                                {item.subject === "Physics" && <Physics className="mr-2 flex-shrink-0" height={25} width={25} />}
                                {item.subject === "History" && <History className="mr-2 flex-shrink-0" height={25} width={25} />}

                                <span className="line-clamp">{item.name}</span>
                            </NavLink>);
                    }
                })}
            </div>
            {csListCount > DEFAULT_VALUES.classListCount && <span className="small-text menu-text text-uppercase text-left list-group-item" onClick={() => toggleShowMore()}>{ShowMoreText}</span>}
        </>
    )
}

export default ClassSectionList;