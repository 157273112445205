import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Step1 from '../../assets/images/steps_01.png';
import "./AddClass.css";
import { ERROR_STRINGS } from "../constants/ErrorStrings"

const FirstStep = props => {

    const [classname, setClassname] = useState(props.classname);
    const [counter, setCounter] = useState(0);
    const { register, handleSubmit, errors } = useForm({
        mode: "onBlur", defaultValues: {
            classname: classname
        }
    })

    const classnameChangeHandler = (event) => {
        setClassname(event.target.value);
    }

    const continueHandler = (data) => {
        props.handleClassname(data.classname);
    }

    const checkLimit = () => {
        return classname.length <= 60;
    }
    return (
        <>
            <div>
                <div className="row" >
                    <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                        <img src={Step1} alt="" />
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                        <span className="addnewclasshead">ADD NEW CLASS</span>
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginBottom: "64px" }}>
                        <span className="addnewclasstext">Enter a name for your class, then click Continue.</span>
                    </div>
                    <form style={{ width: "100%" }} className="firstStepForm" onSubmit={handleSubmit(continueHandler)}>

                        <div className="col-12" style={{ textAlign: "center", maxWidth: "850px", margin: "0 auto 65px auto" }}>
                            <input onChange={classnameChangeHandler} type="text" className="form-control classname" name="classname" id="classname" placeholder="ENTER NAME"
                                ref={register({ required: true, validate: checkLimit, pattern: /^[\w \\/ \s,.'-]+$/i })} />
                            <label className="maxcharacterlabel">max character count 60</label>
                        </div>
                        {errors.classname && errors.classname.type === "required" &&
                            <div style={{ color: "red", textAlign: "center", position: "relative", top: "-20px" }}>Please enter the name to continue.</div>}
                        {errors.classname && errors.classname.type === "validate" &&
                            < div style={{ color: "red", textAlign: "center", position: "relative", top: "-20px" }}>The max character limit is 60.</div>}
                        {errors.classname && errors.classname.type === 'pattern' &&
                            < div style={{ color: "red", textAlign: "center", position: "relative", top: "-20px" }}>{ERROR_STRINGS.nameSpecialCharsError}</div>}

                        <div className="col-12" style={{ textAlign: "center", marginBottom: "135px" }}>
                            <button type="submit" className="curve-fill-btn" id="continue" >CONTINUE</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default React.memo(FirstStep);