import React, { useEffect, useRef, useState, useContext } from "react";
import axios from 'axios'
import "./LandingPage.css";
import {
    Link,
    useHistory,
    useLocation
} from "react-router-dom";
import UserContext from "../../shared/context/UserContext";

import Logo from '../../assets/images/spark-logo-corored.svg';
import DisabledLeftIcon from '../../assets/images/disabledcircularleft.png';
import LeftIcon from '../../assets/images/Circularleft.png';
import RightIcon from '../../assets/images/Circularright.png';
import RightBtn from '../../assets/images/icons/Right.png';
import Carousel from 'nuka-carousel';
import mobileD from '../../assets/images/mobile-decive.png';
import devicerotate from '../../assets/images/device-rotate.png';
import browser from '../../assets/images/browser.png';
import Modal from 'react-bootstrap4-modal';

import {
    browserName, osVersion, osName, fullBrowserVersion, deviceType
} from "react-device-detect";

import Teacher_01 from '../../assets/images/Teacher/Teacher_01.gif';
import Teacher_02 from '../../assets/images/Teacher/Teacher_02.gif';
import Teacher_03 from '../../assets/images/Teacher/Teacher_03.gif';
import Teacher_04 from '../../assets/images/Teacher/Teacher_04.gif';
import Teacher_05 from '../../assets/images/Teacher/Teacher_05.gif';
import Teacher_06 from '../../assets/images/Teacher/Teacher_06.gif';
import Teacher_07 from '../../assets/images/Teacher/Teacher_07.gif';
import Teacher_08 from '../../assets/images/Teacher/Teacher_08.gif';
import Teacher_09 from '../../assets/images/Teacher/Teacher_09.gif';
import Teacher_10 from '../../assets/images/Teacher/Teacher_10.gif';
import Teacher_11 from '../../assets/images/Teacher/Teacher_11.gif';
import Teacher_12 from '../../assets/images/Teacher/Teacher_12.gif';
import Teacher_13 from '../../assets/images/Teacher/Teacher_13.gif';
import Teacher_14 from '../../assets/images/Teacher/Teacher_14.gif';
import Teacher_15 from '../../assets/images/Teacher/Teacher_15.gif';

import Student_01 from '../../assets/images/Student/Student_01.gif';
import Student_02 from '../../assets/images/Student/Student_02.gif';
import Student_03 from '../../assets/images/Student/Student_03.gif';
import Student_04 from '../../assets/images/Student/Student_04.gif';
import Student_05 from '../../assets/images/Student/Student_05.gif';
import Student_06 from '../../assets/images/Student/Student_06.gif';
import Student_07 from '../../assets/images/Student/Student_07.gif';
import Student_08 from '../../assets/images/Student/Student_08.gif';

const LandingPage = props => {
    const auth = useContext(UserContext);
    const [Firstname, setFirstname] = useState('');
    const [Role, setRole] = useState('');
    const [IpAddr, setIpAddr] = useState('');
    const [LandingPageSubHeader, setLandingPageSubHeader] = useState('');
    let history = useHistory();
    let query = new URLSearchParams(useLocation().search);
    const token = query.get("token");
    let provider = query.get("provider");
    const isMountedRef = useRef(null);


    let resolutionWidth = window.screen.width;
    let resolutionHeight = window.screen.heigh;

    const [showResolutionMsg, setShowResolutionMsg] = useState(false);
    const [resolutionCode, setResolutionCode] = useState("");
    window.addEventListener("orientationchange", function (event) {
        if (event.target.screen.orientation.angle) {
            setShowResolutionMsg(false)
        }
    });


    useEffect(() => {


        if ((browserName == "Edge" && parseInt(fullBrowserVersion.split('.')[0]) < 80) || (browserName == "IE")) {
            setResolutionCode("browser")
            setShowResolutionMsg(true);
        }

        if (resolutionWidth < 768) {
            setResolutionCode("device")
            setShowResolutionMsg(true);
        }
        if (resolutionWidth >= 768 && resolutionWidth < 1024) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setResolutionCode("orientation")
                setShowResolutionMsg(true);
            }
        }



        props.showLoader();
        axios.get("/staticContent/LandingPage")
            .then(res => {
                setLandingPageSubHeader(res.data.Data.subHeader);
                props.hideLoader();
            })

        if (auth.token) {
            setFirstname(auth.firstname);
            setRole(auth.role[0].name);
            axios.put("/user/updateuser", { email: auth.email, isWelcomePageVisited: true });
        }
    }, []);

    function getLastSlide(currentslide, count) {
        if (auth.role[0].name === "Teacher") {
            history.push("/teacherdashboard");
        }
        else if (auth.role[0].name === 'Student') {
            history.push("/studentdashboard");
        }
        props.amplitude.track("Visited Take a Tour", { page_name: "", slide_id: currentslide, completed: "TRUE" });
    }

    const redirectToHome = _ => {
        if (auth.token) {
            if (auth.role[0].name === "Teacher") {
                history.push("/teacherdashboard");
            }
            else if (auth.role[0].name === 'Student') {
                history.push("/studentdashboard");
            }
        }
        else {
            history.push('/signin');
        }
    }

    const onNextClicked = (currentSlide, count) => {
        if (currentSlide !== count) {
            props.amplitude.track("Visited Take a Tour", { page_name: "", slide_id: currentSlide, completed: "FALSE" });
        }
    }

    return (
        <>
            {!showResolutionMsg && <div className="minscreenheight" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)" }}>
                <div className="row header">
                    <div className="col-8">
                        <div style={{ width: "188px", height: "50px", cursor: "pointer" }} onClick={redirectToHome}><img src={Logo} /></div>
                    </div>
                    <div className="col-4" style={{ marginTop: "5px", paddingRight: "39px" }}>
                        <div className="skipTourBtn" style={{ float: "right", padding: "7px", cursor: "pointer" }} onClick={getLastSlide}><span style={{ color: "#009DDB", marginRight: "9px" }}>Skip tour</span><span><img src={RightBtn} /></span></div>
                    </div>
                </div>
                <div className="row landingPageContainer largeScreenDiv">
                    <div className="col-12" style={{ marginTop: "40px" }}>
                        <div className="landingPageHeader" title={`WELCOME ${Firstname}`}>WELCOME {(Firstname.length >= 35) ? `${Firstname.substr(0, 34)}...` : Firstname}!</div>
                        <p className="landingPageSubHeader">{LandingPageSubHeader}</p>
                    </div>
                    <div className="col-12" style={{ marginTop: "21px" }}>
                        {Role === 'Teacher' &&
                            <Carousel className="welcomeSliderImg" transitionMode="fade"
                                renderCenterLeftControls={({ previousSlide, currentSlide, slideCount }) => (
                                    <div onClick={previousSlide}>
                                        {currentSlide + 1 === 1 && <span><img src={DisabledLeftIcon} /></span>}
                                        {currentSlide + 1 !== 1 && <span style={{ cursor: "pointer" }}><img src={LeftIcon} /></span>}
                                    </div>
                                )}
                                renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
                                    <div onClick={(event) => { (currentSlide + 1 === slideCount) ? (getLastSlide(currentSlide + 1, slideCount)) : nextSlide(); onNextClicked((currentSlide + 1), slideCount); }}>
                                        <span style={{ cursor: "pointer" }}><img src={RightIcon} /></span>
                                    </div>
                                )}>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_01} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_02} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_03} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_04} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_05} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_06} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_07} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_08} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_09} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_10} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_11} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_12} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_13} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_14} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Teacher_15} /></div>
                            </Carousel>
                        }
                    </div>
                    <div className="col-12">
                        {Role === 'Student' &&
                            <Carousel className="welcomeSliderImg" transitionMode="fade"
                                renderCenterLeftControls={({ previousSlide, currentSlide, slideCount }) => (
                                    <div onClick={previousSlide}>
                                        {currentSlide + 1 === 1 && <span><img src={DisabledLeftIcon} /></span>}
                                        {currentSlide + 1 !== 1 && <span style={{ cursor: "pointer" }}><img src={LeftIcon} /></span>}
                                    </div>
                                )}
                                renderCenterRightControls={({ nextSlide, currentSlide, slideCount }) => (
                                    <div onClick={(event) => { (currentSlide + 1 === slideCount) ? getLastSlide(currentSlide + 1, slideCount) : nextSlide(); onNextClicked((currentSlide + 1), slideCount); }}>
                                        <span style={{ cursor: "pointer" }}><img src={RightIcon} /></span>
                                    </div>
                                )}>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_01} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_02} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_03} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_04} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_05} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_06} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_07} /></div>
                                <div className="sliderImageContainer" style={{ padding: "25px 0" }}><img src={Student_08} /></div>
                            </Carousel>
                        }
                    </div>
                </div>
            </div>}

            <Modal id="deviceError" className="deviceError" visible={showResolutionMsg} onClickBackdrop={() => { }}>
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row">
                        {resolutionCode == "device" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={mobileD} />
                                </div>
                                <div className="col-12">
                                    <span className="oops">OOPS!</span>
                                    <span className="oopsline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Your device is not supported at the moment.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">for a better experience please use a large screen device such as tablet, Notebook or above.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">If you are using school device please check with your teacher or support staff.</span>
                            </div>
                        </div>}



                        {resolutionCode == "orientation" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={devicerotate} />
                                </div>
                                <div className="col-12">
                                    <span className="snap">SNAP!</span>
                                    <span className="snapline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Turn to landscape mode for a better experience.</span>
                            </div>
                        </div>}


                        {resolutionCode == "browser" &&
                            <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                                <div className="row" >
                                    <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                        <img alt="" src={browser} />
                                    </div>
                                    <div className="col-12">
                                        <span className="oops">OOPS!</span>
                                        <span className="browserline"></span>
                                    </div>
                                </div>
                                <div className="row" >
                                    <span className="deviceErrorHeadT">We are sorry, but your browser is not compatible. Please use a more up to date browser.</span>
                                </div>
                                <div className="row" style={{ marginTop: "25px" }}>
                                    <span style={{ margin: "auto", fontWeight: "bold", fontSize: "9px", lineHeight: "16px", textAlign: "center", letterSpacing: "0.9px", textTransform: "uppercase", color: "#4C4B5E" }}>If you are using a school device please check with you teacher or support staff.</span>
                                </div>

                            </div>}

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default LandingPage;