import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import UserContext from "../../shared/context/UserContext";
import Footer from '../../shared/components/footer/SidebarFooter';
import "./index.css";

import DashboardIcon from '@material-ui/icons/Dashboard';
import ProfessionalLearning from '../Icons/ProfessionalLearning';
import MyClasses from '../Icons/MyClasses';
import Resources from '../Icons/Resources';
import Profile from '../Icons/Profile';

import ClassSectionList from './ClassSectionList';
import SparkLoader from "../../assets/images/spark_loader.gif";


function TeacherLayout(props) {
    const auth = useContext(UserContext);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [loadSubComp, setLoadSubComp] = useState(false);
    const [showSpinner, setShowSpinner] = useState(props.loading || false);
    const [updateSideBar, setUpdateSideBar] = useState(false);

    useEffect(() => {
        props.showFooter(false);
        setSidebarToggled(!props.expanded);
        setLoadSubComp(true);
    }, []);


    const toggleSidebar = () => {
        setSidebarToggled(!sidebarToggled);
    }


    const sidebarCollapsed = classNames({
        'collapsed': !props.expanded,
    });

    const isActive = classNames({
        'is-active': props.isActive,
    })

    let hideContent = showSpinner ? " hidden" : "";

    return (
        <div className="d-flex layout-wrapper" id="wrapper">
            <div className={"d-flex justify-content-between flex-column sidebar-wrapper " + sidebarCollapsed} id="sidebar">
                <div className="list-group list-group-flush">
                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action " to='/teacherdashboard'>
                        <span className="d-flex align-items-center "><DashboardIcon fontSize="large" /><font className={"ml-1 menu-text"}>Dashboard</font></span>
                    </NavLink>
                    <div className={"list-group-item " + isActive} >
                        <nobr><span className="d-flex align-items-center "><MyClasses fontSize="large" /><font className={"ml-1 menu-text"}>My Classes</font></span></nobr>
                    </div>

                    {loadSubComp && <ClassSectionList userId={auth.userId} updateSideBar={updateSideBar} setUpdateSideBar={setUpdateSideBar} />}

                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action " to='/resources'>
                        <span className="d-flex align-items-center "><Resources fontSize="large" /><font className={"ml-1 menu-text"}>Resources</font></span>
                    </NavLink>
                    <NavLink exact={true} activeClassName='is-active' className="list-group-item list-group-item-action " to='/profile'>
                        <span className="d-flex align-items-center "><Profile fontSize="large" /><font className={"ml-1 menu-text"}>Profile</font></span>
                    </NavLink>
                </div>
                <div className="sidebar-footer menu-text">
                    {loadSubComp && <Footer {...props} />}
                </div>
            </div>

            <div className={"page-content-wrapper " + sidebarCollapsed}>
                {showSpinner &&
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <img src={SparkLoader} />
                    </div>
                }

                <div className={"container-fluid  overflow-auto py-3" + hideContent} >
                    {React.cloneElement(props.children, { updateSideBar: setUpdateSideBar, showSpinner: setShowSpinner })}
                </div>

            </div>
        </div>
    )
};

export default TeacherLayout;