import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const MyClasses = (props) => (
    <SvgIcon {...props}>
        <path d="m18.375 7.5c1.2427 0 2.25 1.0074 2.25 2.25v9c0 1.2427-1.0073 2.25-2.25 2.25h-7.5c-1.1754 0-2.1527-0.9048-2.2432-2.0768-0.0077925-0.0975-0.088942-0.1728-0.18675-0.17325h-0.195c-1.1754 0-2.1527-0.9048-2.2432-2.0768-0.0074475-0.09765-0.088808-0.17318-0.18675-0.17325h-0.195c-1.2426 0-2.25-1.0073-2.25-2.25v-9c0-1.2426 1.0074-2.25 2.25-2.25h7.5c1.1754 3.65e-5 2.1527 0.90482 2.2432 2.0768 0.0071 0.09783 0.08865 0.1735 0.18675 0.17325h0.195c1.1754 3.75e-5 2.1527 0.90482 2.2432 2.0768 0.007125 0.09783 0.08865 0.1735 0.18675 0.17325zm-13.5 6.75c0 0.41422 0.33579 0.75 0.75 0.75h0.1875c0.049792 2.25e-4 0.097598-0.0195 0.1328-0.05467 0.035205-0.035255 0.0549-0.08303 0.054698-0.13283v-7.3125c0-1.2426 1.0074-2.25 2.25-2.25h5.4052c0.06082 4.5e-5 0.1179-0.029438 0.15308-0.07908 0.03525-0.04965 0.0441-0.11327 0.02393-0.17067-0.1059-0.2998-0.38933-0.50027-0.70726-0.50025h-7.5c-0.41421 0-0.75 0.33579-0.75 0.75zm3.375 3c-0.41421 0-0.75-0.33578-0.75-0.75v-9c0-0.41421 0.33579-0.75 0.75-0.75h7.5c0.31792-2.25e-5 0.60135 0.20044 0.70725 0.50025 0.02018 0.057397 0.01133 0.12102-0.02392 0.17067-0.03518 0.049642-0.092255 0.079125-0.15308 0.07908h-5.4052c-1.2426 0-2.25 1.0074-2.25 2.25v7.3125c2.025e-4 0.0498-0.019493 0.09757-0.054697 0.13282-0.035205 0.03517-0.08301 0.0549-0.1328 0.05467zm10.125 2.25c0.41422 0 0.75-0.33578 0.75-0.75v-9c0-0.41422-0.33578-0.75-0.75-0.75h-7.5c-0.41422 0-0.75 0.33578-0.75 0.75v9c0 0.41422 0.33578 0.75 0.75 0.75z" clipRule="evenodd" fillRule="evenodd" />
        <path d="m14.414 10.247c-0.24308-0.19348-0.59692-0.15391-0.79125 0.0885l-0.975 1.2203c-0.03308 0.04185-0.0825 0.06758-0.13575 0.0705-0.05332 0.0029-0.10545-0.01703-0.14325-0.05475l-0.1575-0.1575c-0.1413-0.14618-0.35048-0.20468-0.54712-0.15315-0.19665 0.0516-0.3501 0.20528-0.40155 0.40192-0.05138 0.19672 0.0074 0.40582 0.15368 0.54698l0.75 0.75c0.11408 0.11122 0.26948 0.16958 0.42855 0.16088s0.3072-0.08362 0.40845-0.20662l1.5-1.875c0.0933-0.11685 0.13628-0.26595 0.11955-0.41452-0.01672-0.14854-0.09187-0.28434-0.2088-0.37748z" />
        <path d="m17.438 11.625h-1.5c-0.31065 0-0.5625 0.25185-0.5625 0.5625s0.25185 0.5625 0.5625 0.5625h1.5c0.31065 0 0.5625-0.25185 0.5625-0.5625s-0.25185-0.5625-0.5625-0.5625z" />
        <path d="m14.414 14.372c-0.24308-0.1935-0.59692-0.1539-0.79125 0.0885l-0.975 1.2202c-0.03308 0.04185-0.0825 0.06758-0.13575 0.0705-0.05332 0.0029-0.10545-0.01703-0.14325-0.05475l-0.1575-0.1575c-0.1413-0.14618-0.35048-0.20468-0.54712-0.15315-0.19665 0.0516-0.3501 0.20528-0.40155 0.40192-0.05138 0.19672 0.0074 0.40582 0.15368 0.54698l0.75 0.75c0.11408 0.11122 0.26948 0.16958 0.42855 0.16088s0.3072-0.08363 0.40845-0.20662l1.5-1.875c0.0933-0.11685 0.13628-0.26595 0.11955-0.41452-0.01672-0.1485-0.09187-0.28432-0.2088-0.37748z" />
        <path d="m18 16.312c0-0.31065-0.25185-0.5625-0.5625-0.5625h-1.5c-0.31065 0-0.5625 0.25185-0.5625 0.5625s0.25185 0.5625 0.5625 0.5625h1.5c0.31065 0 0.5625-0.25185 0.5625-0.5625z" />
    </SvgIcon>
);

export default MyClasses;
