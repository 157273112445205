import React, { useState, useEffect } from 'react';
import SelectSearch from 'react-select-search';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import closePNG from '../../assets/images/icons/close.png';
import dummyImg from '../../assets/images/placeholderImg.png';
import Modal from "react-bootstrap4-modal";
import { DEFAULT_VALUES } from "../constants/CommonConstants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Assessments = props => {
    const pageLength = DEFAULT_VALUES.pageLength;
    const [limit, setLimit] = useState(DEFAULT_VALUES.pageLength);
    const [assesmentResult, setAssesmentResult] = useState([]);
    const [currentSession, setCurrentSession] = useState("1");
    const [studentData, setStudentData] = useState([]);
    const [totalSessions, setTotalSessions] = useState([1, 2, 3, 4]);
    const [dropdownSessionList, setDropdownSessionList] = useState([]);
    const [showGraphicModal, setShowGraphicsModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [selectedSession, setSelectedSession] = useState(props.selectedSession);

    useEffect(() => {
        let dropdownSessionList = props.dropdownSessionList;
        dropdownSessionList = dropdownSessionList.filter(item => item.value !== "5")
        setDropdownSessionList(dropdownSessionList);

        if (props.selectedsessionItem === "5") {
            setCurrentSession("4");
            const session = props.sessionList[parseInt(props.selectedsessionItem) - 2];
            setSelectedSession(session);

        } else {
            setCurrentSession(props.selectedsessionItem);
            const session = props.sessionList[parseInt(props.selectedsessionItem) - 1];
            setSelectedSession(session);
        }


        updateStudentData();
        currentSessionResult();

    }, [props.classDetails]);

    useEffect(() => {
        updateStudentData();
        currentSessionResult();
    }, [props.students])

    const updateCurrentSession = (item) => {
        const session = props.sessionList[parseInt(item) - 1];
        setCurrentSession(item);
        setSelectedSession(session);
        props.setSelectedsessionItem(item);
        currentSessionResult(item);
    }

    const currentSessionResult = (sessionIndex = props.selectedsessionItem) => {
        const rosterData = props.students.rosterData;
        let totalStudents = 0;
        let totalCorrectAnswers = [0, 0, 0];
        let hasSession = false;
        let isAssessmentSubmitted = false;

        rosterData.map(item => {
            const sessions = item.sessions.filter(item => item.index == sessionIndex)[0];
            hasSession = sessions ? true : false;
            const assessment = sessions && sessions.assessment;
            const assessmentQuestion = assessment && assessment.assessmentQuestion;
            isAssessmentSubmitted = assessment && assessment.isAssessmentSubmitted;
            isAssessmentSubmitted && assessmentQuestion && assessmentQuestion.map((answer, index) => {
                totalCorrectAnswers[index] = answer.answer[0] && answer.answer[0].isCorrect ? totalCorrectAnswers[index] + 1 : totalCorrectAnswers[index] + 0;
            })

            isAssessmentSubmitted && totalStudents++;
        });

        let result = hasSession && totalStudents > 0 && totalCorrectAnswers.map(correctAnswer => {
            let correct = (correctAnswer / totalStudents) * 100 || 0;
            correct = Math.round(correct);

            let incorrect = 100 - correct || 0;
            return { correct, incorrect }
        })

        setAssesmentResult(result);
    }

    const updateStudentData = () => {
        const studentDetails = props.students.studentUserData;
        const student = props.students.rosterData.map(item => {
            let info = studentDetails.filter(i => i._id === item._id);

            return { ...item, ...info }
        });

        student.sort(function (a, b) {
            var nameA = a.lastName.toUpperCase();
            var nameB = b.lastName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });
        setStudentData(student);
    }

    const toggleOptions = (e) => {
        if (e.target.classList.contains("collapsed")) {
            e.target.textContent = "Show Answers";
        } else {
            e.target.textContent = "Hide Answers";
        }
    }

    const toggleGraphicDialog = (item) => {
        const question = selectedSession && selectedSession.assessment.assessmentQuestion.filter(q => q.id === item)[0];
        setSelectedQuestion(question);
    }

    return (
        <>
            <div className="hiddenObjectAssessments"></div>
            <div className="row">
                <div className="col-md-3 pl-0" style={{ maxWidth: "300px" }}>
                    <div className="s-card py-2 px-4">
                        <div className="d-flex align-items-center justify-content-between py-2 assessment-session-tooltip" style={{ fontSize: '16px' }}>
                            <span className="title-text">Session:</span>
                            <div className="dropdown w-100" style={{ maxWidth: "220px" }}>
                                <div className="dropdown-toggle d-flex align-items-center justify-content-between font-weight-bold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {currentSession}
                                    <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                </div>
                                <div className="dropdown-menu w-100 mt-2" aria-labelledby="dropdownMenuButton" >
                                    {dropdownSessionList && dropdownSessionList.map(sessionItem => {
                                        return <span className="dropdown-item py-2 px-3" key={"session-" + sessionItem.value} onClick={() => updateCurrentSession(sessionItem.value)}>{sessionItem.name}</span>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md"></div>
            </div>

            <div className="assesment-questions row mb-5 font-14" id="assesment-quistions">
                {selectedSession && selectedSession.assessment.assessmentQuestion.length > 0 && <>

                    <div className="col-10"></div>
                    <div className="col-1 text-center font-weight-bold">
                        Avg. Correct
                    </div>
                    <div className="col-1 text-center font-weight-bold">
                        Avg. Incorrect
                    </div>
                </>
                }

                {
                    selectedSession && selectedSession.assessment.assessmentQuestion.map((item, index) => {
                        const result = assesmentResult[index];
                        const pcCorrect = result && result.correct >= 85 ? "text-success" : "";
                        const pcInCorrect = result && result.incorrect >= 85 ? "text-danger" : "";

                        return <div className="w-100" key={item._id}  ><hr className="w-100" />
                            <div className="row w-100">
                                <div className="col row p-0">
                                    <div className="col font-weight-bold" style={{ minWidth: "110px" }}>
                                        Question {item.id}:
                                    </div>
                                    <div className="col-6">
                                        <span dangerouslySetInnerHTML={{ __html: item.prompt }} />
                                        <div className="pt-3 collapse" id={"assesment-question-" + item._id}>
                                            {
                                                item.choices.map(choice => {
                                                    return (
                                                        <p className="mb-1">
                                                            <span className={choice.choice.isCorrect ? "choice-options correct" : "choice-options"} >Option {choice.choice.id}: </span>
                                                            <span dangerouslySetInnerHTML={{ __html: choice.choice.text }} />
                                                        </p>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="col text-center">
                                        <button className="btn btn-link show-graphic-text font-12" data-toggle="collapse" data-parent="#assesment-quistions" data-target={`#${"assesment-question-" + item._id}`} onClick={(e) => toggleOptions(e)}>Show Answers</button>
                                    </div>

                                    <div className="col text-center">
                                        <button className="btn btn-link show-graphic-text font-12" disabled={!item.imageUrl} onClick={() => { toggleGraphicDialog(item.id); setShowGraphicsModal(true); }}>Show graphic</button>
                                    </div>
                                </div>
                                <div className={"col-1 font-weight-bold text-center " + pcCorrect}>
                                    {result ? result.correct : "0"}%
                                </div>
                                <div className={"col-1 font-weight-bold text-center " + pcInCorrect}>
                                    {result ? result.incorrect : "0"}%
                                </div>
                            </div>
                        </div>
                    })
                }

                {setSelectedQuestion && <Modal id="showGraphicsModal" dialogClassName="modal-dialog-centered modal-lg" className="" visible={showGraphicModal} onClickBackdrop={() => setShowGraphicsModal(false)}>
                    <div className="modal-body pl-4 pb-4">
                        <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setShowGraphicsModal(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>
                        <span className="font-weight-bold text-uppercase d-flex">Session {currentSession} Assessment</span>
                        <div className="row">
                            <div className="col font-16 p-0 d-flex flex-column justify-content-between">
                                <div>
                                    <span className="d-flex font-weight-bold py-3">Question {selectedQuestion.id}</span>
                                    <span className="d-flex flex-column text-left" dangerouslySetInnerHTML={{ __html: selectedQuestion.prompt }} />
                                </div>
                                <div className="d-flex justify-content-between align-items-center w-50 font-weight-bold font-14 pt-4">
                                    <span className={selectedQuestion.id === 1 ? "disabled-link" : "pointer"}
                                        onClick={() => { toggleGraphicDialog(selectedQuestion.id - 1) }}>
                                        Previous
                                    </span>

                                    {selectedSession && selectedSession.assessment.assessmentQuestion.map((page, index) => {
                                        let active = selectedQuestion.id === page.id ? " page-active " : "";
                                        return <span className={"pointer px-1" + active} onClick={() => { toggleGraphicDialog(page.id) }} key={"page-no" + index}>{index + 1}</span>
                                    })}

                                    <span className={selectedQuestion.id === selectedSession.assessment.assessmentQuestion.length ? "disabled-link" : "pointer"}
                                        onClick={() => { toggleGraphicDialog(selectedQuestion.id + 1) }} >
                                        Next
                                    </span>
                                </div>
                            </div>
                            <div className="col-6 pr-3">
                                {selectedQuestion.imageUrl &&
                                    <img src={selectedQuestion.imageUrl} onerror="this.onerror=null;this.src''" alt="Question Graphic" className="graphics-image" />
                                }
                            </div>
                        </div>
                    </div>
                </Modal>}

            </div>
            <div className="student-roster-list mt-3">
                <div className="row assessment-header text-center text-uppercase font-weight-bold font-10">
                    <div className="col-2 session"></div>
                    {totalSessions.map(item => {
                        return <div className={currentSession == item ? "col-5 session active" : "col session"} key={"session-header-" + item}>
                            <span onClick={() => { updateCurrentSession(item); }} className="pointer text-underline">Session {item}</span>
                        </div>
                    })
                    }
                </div>
                <div className="row text-center" >
                    <div className="col-2 text-left py-2 font-weight-bold">Assessment <br />Questions</div>

                    {
                        studentData[0] && studentData[0].sessions.map((session, index) => {
                            const assessment = session.assessment;
                            const assessmentQuestion = assessment.assessmentQuestion;

                            if (session.index !== 5) {
                                return <div className={currentSession == session.index ? "col-5 session active p-0" : "col session"} key={"session-" + index}>
                                    {currentSession == session.index
                                        && <div className="row h-100 font-12">
                                            {
                                                assessmentQuestion.map(answer => {
                                                    return <div className="assessment-answer col" key={"q-" + answer.id}>
                                                        {"Question " + answer.id}

                                                    </div>
                                                })
                                            }
                                        </div>

                                    }
                                </div>
                            }
                        })}

                    {
                        totalSessions.map(item => {
                            if (studentData[0] && studentData[0].sessions.length < item) {
                                return <div className={currentSession == item ? "col-5 session active" : "col session"} key={"blankItem" + item}></div>
                            }
                        })
                    }

                </div>

                {studentData.slice(0, limit).map(item => {
                    const sessions = item.sessions;
                    let skip = sessions.length;
                    return <div className="row text-center font-12" key={item._id}>
                        <div className="roster-name col-2 text-left d-flex align-items-center">{item.lastName.toLowerCase() + ", " + item.firstName.toLowerCase()}</div>

                        {sessions.map((session, index) => {
                            const assessment = session.assessment;
                            const assessmentQuestion = assessment.assessmentQuestion;
                            if (session.index !== 5) {
                                return <div className={currentSession == session.index ? "col-5 session active p-0" : "col session"} key={"rosterAnswer-" + index}>
                                    {currentSession == session.index
                                        ? <div className="row h-100">
                                            {
                                                assessmentQuestion.map(answer => {
                                                    return <div className="assessment-answer col" key={"answercheck-" + answer.id}>
                                                        {assessment.isAssessmentSubmitted
                                                            ? answer.answer[0].isCorrect ? <CheckCircleIcon className="text-success" /> : <CancelIcon className="text-danger" />
                                                            : ""
                                                        }
                                                    </div>
                                                })
                                            }
                                        </div>
                                        :
                                        <div>
                                            {assessment.isAssessmentSubmitted && assessment.totalCorrectAnswers + " / " + assessment.totalQuestions}
                                        </div>
                                    }
                                </div>
                            }
                        })}

                        {
                            totalSessions.map(item => {
                                if (skip < item) {
                                    return <div className={currentSession == item ? "col-5 session active" : "col session"} key={"blankItem" + item}></div>
                                }
                            })
                        }

                    </div>
                })}

            </div>
            <div className="row show-more-button">
                {studentData.length > pageLength && studentData.length !== limit &&
                    <button onClick={() => { setLimit(studentData.length) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE ALL</button>
                }
                {studentData.length > pageLength && studentData.length === limit &&
                    <button onClick={() => { setLimit(pageLength) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE LESS</button>
                }
            </div>
        </>
    );
}

export default React.memo(Assessments);