import React, { useState } from "react";

import axios from "axios";
import ReactDropzone from 'react-dropzone';
import xlsxFile from 'read-excel-file';

import "./ImportExcel.css";

function ImportExcel() {

    const [selectedFile, setSelectedFile] = useState();
    const [randomNumber, setRandomNumber] = useState();

    const [htmlURL, sethtmlURL] = useState();


    const handleSubmit = (event) => {
        event.preventDefault();
        setSelectedFile(event.target.files[0])
        const input = document.getElementById('userfile')
        xlsxFile(input.files[0]).then((rows) => {
        });
    }

    const uploadFile = () => {
        const data = new FormData()
        data.append('file', selectedFile)
        axios.post("/poc/loaduser", data, {
        })
            .then(res => {

            })
    }

    const onDrop = (acceptedFiles) => {
        xlsxFile(acceptedFiles[0]).then((rows) => {

        });
        setSelectedFile(acceptedFiles[0])
    }

    const genetrateCode = () => {
        setRandomNumber(Math.random().toString(36).slice(2));
    }

    const sendEmail = () => {
        axios.get("/poc/mailer")
            .then(res => {

            })
    }


    const getOAuthURL = () => {
        axios.get("/poc/redirect")
            .then(res => {

                window.open(res.data.url, "_target");
            })
    }

    return (
        <>
            <div className="row margin-t7" style={{ marginTop: "50px", borderBottom: "3px solid #eee" }}>
                <div className="offset-md-2 col-md-4" style={{ fontSize: "20px" }}>
                    <input type="file" name="file" onChange={handleSubmit} id="userfile" />
                </div>
                <div className="col-md-4" style={{ fontSize: "20px" }} id="dropArea">
                    <ReactDropzone onDrop={onDrop}>
                        {({ getRootProps }) => (
                            <div {...getRootProps()}>
                                <p>Drop files here, or click to select files</p>
                            </div>
                        )}
                    </ReactDropzone>
                </div>
                <div className="offset-md-5 col-md-7" style={{ fontSize: "20px", marginTop: "10px" }}>
                    <input type="button" value="Upload" name="upload" onClick={uploadFile} style={{ width: "25%" }} />
                </div>
            </div>


            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }}>
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" value="Send Email" name="Send Email" onClick={sendEmail} style={{ width: "25%" }} />
                </div>

            </div>

            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <div>{randomNumber}</div>
                </div>
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" value="Random" name="Random" onClick={genetrateCode} style={{ width: "25%" }} />
                </div>

            </div>

            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" value="Google Auth" onClick={getOAuthURL} style={{ width: "25%" }} />
                </div>
            </div>
        </>
    )
}

export default ImportExcel;