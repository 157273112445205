import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import UserContext from "../../shared/context/UserContext";
import IFrame from "../../shared/components/iFrameBox/IFrameBox"
import rightArrow from '../../assets/images/icons/Right.png';

import "./ClassDetails.css";

const ProfessionalLearning = props => {

    const auth = useContext(UserContext);
    const [header, setHeader] = useState(props.PFStaticData.heading || "");
    const [subHeader, setSubHeader] = useState(props.PFStaticData.subHeading || "");
    const [visibleExProject, setVisibleExProject] = useState(false);
    const [visible, setVisible] = useState(false);
    const openLink = () => {
        window.open("https://www.google.com", "_blank")
    }

    const mergerTwoIds = (a, b) => {
        const BYTECOUNT = 24;
        let res = "";
        for (let i = 0; i < BYTECOUNT; i += 4) {
            res = ("000" + (parseInt(a.slice(-i - 4, -i || a.length), 16) ^ parseInt(b.slice(-i - 4, -i || b.length), 16)).toString(16)).slice(-4) + res;
        }
        return res;
    }

    useEffect(() => {
        props.amplitude.track("Visited Class Details", { page_section: "Professional Learning", session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex], class_section_id: props.classId });
    }, []);
    return (
        <>
            <div className="row" style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
                <div className="col-12" style={{ padding: "15px 30px", marginBottom: "25px" }}>
                    <div className="row" style={{ marginBottom: "20px" }}>
                        <span className="SM-heading">Professional Learning</span>
                    </div>
                    <div className="row" style={{ marginBottom: "20px" }}>
                        <div className="col-12" style={{ padding: "0px", borderRight: "1px solid #F2F2F9", marginRight: "40px" }}>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <span className="small-black-heading">{header}</span>
                            </div>
                            <div className="row" style={{ marginBottom: "30px", paddingRight: "10px" }}>
                                <span className="medium-text">{subHeader}</span>
                            </div>
                            <div className="row">
                                <button type="submit" className="saveChangesBtn" style={{ width: "auto", height: "auto", padding: "7px 20px" }} onClick={() => { setVisibleExProject(true); props.amplitude.track("Clicked Class Details Button", { class_section_id: props.classId, session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex], button_name: "Launch Professional Learning", subject: props.subject }) }}>Launch Professional Learning
                                    <img alt="" src={rightArrow} style={{ width: "18px", height: "14px", verticalAlign: "sub", marginLeft: "10px" }} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {props.entryPoints && visibleExProject &&
                <IFrame
                    mode="pd"
                    courseName={props.courseName}
                    userDetails={{ userID: auth.userId, email: auth.email, username: auth.firstname }}
                    studentRosterID={mergerTwoIds(auth.userId, props.courseId)}
                    isFreezed={null}
                    sessionDetails={{ sessionName: props.sessionName, sessionIndex: props.currentSessionIndex }}
                    sessions={[]}
                    isVisible={visibleExProject}
                    title="Example Project"
                    setVisible={(value) => { setVisibleExProject(value) }}
                    iframeURL={props.entryPoints.demoProjectEntry}
                    sessionTypes={{ "1": "student", "2": "student", "3": "student", "4": "student", "5": "student" }}
                    subject={props.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={props.classId}
                    teacherId={props.teacherId} />
            }
        </>
    )
}

export default React.memo(ProfessionalLearning);