import React, { useState } from "react";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';


import $ from "jquery";
import "./AddClass.css";

let studentToDelete = [];
const StudentRoster = props => {

    console.log("props:: ", props);


    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirmDeleteAll, setConfirmDeleteAll] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(null);

    let students = props.students || [];

    const removeStudent = () => {

        if (currentIndex != null) {
            let tempArray = [...students];
            tempArray.splice(currentIndex, 1);
            students = [...tempArray];
            props.setRoster(students);
            setConfirmDelete(false);
            setCurrentIndex(null);
            var x = document.getElementsByClassName("studentCheckbox");
            for (let i = 0; i <= x.length - 1; i++) {
                x[i].checked = false;
            }
        }
    }

    const removeSelected = () => {
        setConfirmDeleteAll(false)
        studentToDelete.sort(function (a, b) { return b - a });
        studentToDelete.forEach((element, index) => {
            students.splice(parseInt(element), 1);
            if (index == (studentToDelete.length - 1)) {
                props.setRoster(students);
                studentToDelete = [];
            }
        });

    }

    const handleCheck = (e) => {
        if (e.target.checked) {
            studentToDelete.push(parseInt(e.target.value));
            console.log(studentToDelete);
        }
        else {
            let temp = studentToDelete.findIndex(x => x == parseInt(e.target.value));
            studentToDelete.splice(temp, 1);
            console.log(studentToDelete);
        }
    }

    return (
        <>
            <table style={{ margin: "auto" }}>
                <tbody>
                    <tr><th style={{ float: "left" }}>
    {students.length > 0 && <span onClick={() => { setConfirmDeleteAll(true) }} className="deleteBtn">Delete</span>}</th></tr>
                    {students.map((item, index) => {
                        return <tr key={item.email} style={{ borderBottom: "1px solid #F2F2F9" }}>
                            <th style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                <input className="studentCheckbox" type="checkbox" value={index} name="studentCheckbox" onChange={(e) => { handleCheck(e) }} />
                            </th>
                            {props.method === 'manual' && <th className="studentDetails email" style={{ paddingRight: "50px" }}>
                                {item.email}
                            </th>}
                            <th className="studentDetails" style={{ paddingRight: "30px", paddingTop: "7px", paddingBottom: "7px", }}>
                                {item.lastname}, {item.firstname}
                            </th>
                            {props.method === 'import' && <th className="studentDetails email" style={{ paddingRight: "50px" }}>
                                {item.email}
                            </th>}
                            <th>
                                <span className="deleteBtn" onClick={() => { setCurrentIndex(index); setConfirmDelete(true) }}>DELETE</span>
                            </th>
                        </tr>
                    })
                    }
                </tbody>
            </table>

            <ConfirmationBox
                isVisible={confirmDelete}
                setVisible={(value) => { setCurrentIndex(null); setConfirmDelete(value) }}
                confirmationHeader="Confirm deletion"
                confirmationText="Are you sure you want to delete this student?"
                handleOK={removeStudent}
                okLabel="CONFIRM"
                cancelLabel="Cancel" />

            <ConfirmationBox
                isVisible={confirmDeleteAll}
                setVisible={(value) => { setConfirmDeleteAll(value) }}
                confirmationHeader="Confirm deletion"
                confirmationText="Are you sure you want to delete this selected students?"
                handleOK={removeSelected}
                okLabel="CONFIRM"
                cancelLabel="Cancel" />
        </>
    )
}

export default React.memo(StudentRoster);