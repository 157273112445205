import React, { useState } from 'react';
import "./Switch.css";
import Locked from '../../../assets/images/icons/lock-5.png';
import Unlocked from '../../../assets/images/icons/lock-unlock-3.png';

const Switch = ({ isOn, handleToggle, sizeClass, isLocked, id }) => {
    return (
        <>
            <span className={`lockSpan ${sizeClass}`}>
                {isLocked && !isOn && <img className="locked" src={Locked} alt="" />}
                {isLocked && isOn && <img className="unlocked" src={Unlocked} alt="" />}
            </span>
            <input
                checked={isOn}
                onChange={handleToggle}
                className={`react-switch-checkbox ${sizeClass}`}
                id={id}
                type="checkbox"
            />
            <label
                className={`react-switch-label ${sizeClass}`}
                htmlFor={id}
                style={{ background: isOn && '#23A45D' }}

            >
                <span className={`react-switch-button ${sizeClass}`}
                    style={{ background: isOn && '#FFFFFF' }} />
            </label>
        </>
    );
};

export default Switch;