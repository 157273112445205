import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import axios from "axios";

function RedirectedURL() {

    const [userData, setuserData] = useState();
    const [courseData, setcourseData] = useState();

    let code = (new URL(window.location)).searchParams.get("code");

    useEffect(() => {
        axios.post("/poc/oauth/", { code: code })
            .then(res => {
                setuserData(res.data.data)
            })
    }, []);

    const getCourseDetails = () => {
        axios.post("/poc/getCourse", { code: code })
            .then(res => {
                setcourseData(res.data.data.data.courses)
            })
    }

    const getStudents = () => {
        axios.get("/poc/getStudents")
            .then(res => {
                setcourseData(res.data)
            })
    }


    const getCourseWork = () => {
        axios.get("/poc/getCourseWork")
            .then(res => {

                setcourseData(res.data)
            })
    }


    const getStudentSubmission = () => {
        axios.get("/poc/getStudentSubmissions")
            .then(res => {

                setcourseData(res.data)
            })
    }

    const updateSubmission = () => {
        axios.get("/poc/updateSubmission")
            .then(res => {

                setcourseData(res.data)
            })
    }

    let html = "";

    if (userData) {
        html = <><div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
            <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                <span>id: {userData.id}</span><br />
                <span>email: {userData.email}</span><br />
                <span>picture: {userData.picture}</span><br />
            </div>
        </div>
            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" onClick={getCourseDetails} value="get courses" />
                </div>
            </div>
            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" onClick={getStudents} value="get Students" />
                </div>
            </div>
            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" onClick={getCourseWork} value="get getCourseWork" />
                </div>
            </div>
            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" onClick={getStudentSubmission} value="get getStudentSubmission" />
                </div>
            </div>
            <div className="row margin-t7" style={{ margin: "30px auto", borderBottom: "3px solid #eee" }} >
                <div className="offset-md-2 col-md-12" style={{ fontSize: "20px" }}>
                    <input type="button" onClick={updateSubmission} value="get updateSubmission" />
                </div>
            </div>
        </>
    }
    return (
        html
    )
}

export default RedirectedURL;