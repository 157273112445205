import React, { useState, useEffect, useContext, forwardRef, useRef, useImperativeHandle } from "react";

import { useHistory, useLocation, NavLink } from "react-router-dom";
import axios from "axios";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';

import UserContext from "../../shared/context/UserContext";
import Tour from "../../shared/components/tour/Tour";
import "./TeacherDashboard.css";
import DownIcon from '../../assets/images/icons/Down.png';
import UpIcon from '../../assets/images/icons/Up.png';
import mobileD from '../../assets/images/mobile-decive.png';
import devicerotate from '../../assets/images/device-rotate.png';
import browser from '../../assets/images/browser.png';
import Modal from 'react-bootstrap4-modal';

import { browserName, fullBrowserVersion } from "react-device-detect";

import ProfessionalLearning from '../Icons/ProfessionalLearning';
import Resources from '../Icons/Resources';
import ClassSectionRow from './ClassSectionRow'
import NoClass from '../Icons/NoClass';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import config from '../../assets/config/Config.json';

const TeacherDashboard = forwardRef((props, ref) => {

    let resolutionWidth = window.screen.width;
    let resolutionHeight = window.screen.heigh;
    let location = useLocation();
    const [showResolutionMsg, setShowResolutionMsg] = useState(false);
    const [resolutionCode, setResolutionCode] = useState("");
    const [screenWidth, setScreenWidth] = useState(resolutionWidth);
    const auth = useContext(UserContext);

    const [classSectionData, setClassSectionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [IsGridView, setIsGridView] = useState(true);
    const [filterValue, setFilterValue] = useState('Active');
    const [sortValue, setSortValue] = useState('Newest');
    const [currentItemId, setCurrentItemId] = useState(false);
    const [confirmArchive, setConfirmArchive] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [IsTourVisible, setIsTourVisible] = useState(false);
    const [IsTourStart, setTourStart] = useState(false);
    const [confirmUnArchive, setConfirmUnArchive] = useState(false);
    const [updatedData, setUpdatedData] = useState({});
    const [tourSteps, setTourSteps] = useState([]);
    const [tourRequired, setTourRequired] = useState(false);

    window.addEventListener("orientationchange", function (event) {
        if (event.target.screen.orientation.angle) {
            setShowResolutionMsg(false)
        }
    });

    let history = useHistory();
    const childRef = useRef();
    const tourStepsBefore = [
        {
            target: ".hiddenObject",
            content: (
                <div>
                    Welcome to your My Classes dashboard! This is the central location where you will create and access all of your Project Spark classes.
                    <br />
                    <br />
                    <strong>Let’s take a look around. </strong>
                </div>
            ),
            disableBeacon: true,
            placement: "center"
        },
        {
            target: ".add-a-class",
            content: "To get started, click either of the Create a Class buttons.",
            placement: "top"
        },

        {
            target: ".sidebar-wrapper",
            content: "Use the menu on the left to navigate between the Dashboard, Classes, Resources, Profile and more.",
            placement: "right-start"
        }
    ];

    const tourStepsAfter = [
        {
            target: ".first-class-item-0",
            content: "Once you've created your class, you will get a class card which provides the name of the class, the overall progress, and the current session. You can also see the modality of the class (Teacher Facilitated and/or Student Self-Guided). Click on the card to see the class in more detail.",
            disableBeacon: true,
            placement: "right-start"
        },
        {
            target: ".order-by-filter",
            content: "You can change the way your classes are arranged on the page -- from newest to oldest, in alphabetical order, or by start date, etc. Simply select your preference from the Order by: drop-down list.",
            placement: "left-start"
        },
        {
            target: ".show-filter",
            content: "You can also toggle between your active and archived classes using the Show: drop-down list.",
            placement: "left-start"
        },
        {
            target: ".option-menu",
            content: "When you are finished with a class, simply right-click any existing class in order to archive or delete it.",
            placement: "left-start"
        },
        {
            target: ".profile-menu",
            content: "Make sure to keep your information up-to-date in your profile and stay secure by logging out when you’re not working. Click your name and select Profile or Logout.",
            placement: "left-start"
        },
    ];


    let query = new URLSearchParams(useLocation().search);
    const token = query.get("token");
    let provider = query.get("provider");

    useEffect(() => {
        props.amplitude.track("Visited My Classes ", { page_name: "Teacher Dashboard" });

        if ((browserName == "Edge" && parseInt(fullBrowserVersion.split('.')[0]) < 80) || (browserName == "IE")) {
            setResolutionCode("browser")
            setShowResolutionMsg(true);
        }

        if (resolutionWidth < 768) {
            setResolutionCode("device")
            setShowResolutionMsg(true);
        }
        if (resolutionWidth >= 768 && resolutionWidth < 1024) {
            if (window.matchMedia("(orientation: portrait)").matches) {
                setResolutionCode("orientation")
                setShowResolutionMsg(true);
            }
        }


        if (auth.token) {

            props.showSpinner(true);
            axios.get("/user/getusercontextualtourdata/" + auth.userId).then(res => {
                if (res.data.contextualTour.length > 0) {
                    let data = res.data.contextualTour.filter(val => val.pageName == 'TeacherDashboard');
                    if (data.length > 0) {

                        setTourRequired(false);
                    }
                    else {
                        setTourRequired(true);
                        axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'TeacherDashboard' } });
                    }
                }
                else {
                    setTourRequired(true);
                    axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': 'TeacherDashboard' } });
                }
                if (auth.strategy && auth.strategy.toLowerCase().indexOf("clever") > -1 && localStorage.getItem('IsCleverSync') == '0') {
                    localStorage.setItem('IsCleverSync', '1');
                    props.showLoaderText();
                    getCleverClassSections()
                }
                else {
                    getClassSections();
                }
            });
        }

    }, [auth]);



    const startWebsocket = () => {
        const URL = process.env.REACT_APP_WEBSOCKETURL.replace('http', 'ws');
        let webSocketClient = new WebSocket(URL);

        webSocketClient.onopen = function () {
            webSocketClient.send(JSON.stringify(
                [{
                    request: 'SUBSCRIBE',
                    channel: "classSection" + auth.userId,
                    message: 'percentage'
                },
                ]));

            webSocketClient.onmessage = function (event) {
                let data = JSON.parse(event.data);
                data.channel == 'classSection' + auth.userId && setUpdatedData(data.data);
            };
        };

        webSocketClient.onclose = function () {
            webSocketClient = null
            setTimeout(startWebsocket, 5000)
        }
    }


    useEffect(() => {
        startWebsocket();
    }, [auth.userId]);


    const getHelp = () => {
        childRef.current.getTour();
        setTourRequired(false);
    }

    const getClassSections = () => {
        props.showSpinner(true);
        axios.get("/classSection/getbyteacher/" + auth.userId)
            .then(res => {
                setClassSectionData(res.data);
                filterClassSections("Active", res.data);

                props.hideLoaderText();
                props.showSpinner(false);
                props.hideLoader();
            }).catch(err => {
                console.log("getbyteacher error", err);
            })
    }

    const getCleverClassSections = (flag) => {
        axios.get("/clever/getsectionswithstudents")
            .then(res => {
                getClassSections(flag);
            });
    }

    useEffect(() => {

        if (classSectionData.length > 0) {
            let csData = [...classSectionData];
            let objIndex = csData.findIndex((obj => obj._id === updatedData._id));
            csData[objIndex].courseProgress = updatedData.courseProgress
            setClassSectionData(csData);
            filterClassSections(filterValue, csData);
        }
    }, [updatedData]);


    useEffect(() => {
        sortClassSections(filteredData);
    }, [sortValue]);

    const filterClassSections = (value, data) => {
        let filterData = [data];
        if (value.toLowerCase() === "active") {
            props.amplitude.track("Visited My Classes", { page_section: "Active" });
            let filtered = data.filter(item => {
                return item.isActive === true;
            });
            filterData = [...filtered];
        }
        if (value.toLowerCase() === "archived") {
            props.amplitude.track("Visited My Classes", { page_section: "Archived" });
            let filtered = data.filter(item => {
                return item.isArchived === true;
            });
            filterData = [...filtered];
        }

        setFilteredData(filterData);
        setFilterValue(value);
        sortClassSections(filterData);
        tourRequired && getHelp();
    }

    const sortClassSections = (data) => {
        let sortedArray = [data];

        if (sortValue === "Newest") {
            props.amplitude.track("Visited My Classes", { ordered_by: "Newest" });
            let sorted = data.sort((a, b) => {
                var dateA = new Date(a.createdOn), dateB = new Date(b.createdOn);
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
            });
            sortedArray = [...sorted];

        }
        if (sortValue === "by Name") {
            props.amplitude.track("Visited My Classes", { ordered_by: "by Name" });
            let sorted = data.sort((a, b) => {
                var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase()
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            });
            sortedArray = [...sorted];
        }
        if (sortValue === "Oldest") {
            props.amplitude.track("Visited My Classes", { ordered_by: "Oldest" });
            let sorted = data.sort((a, b) => {
                var dateA = new Date(a.createdOn), dateB = new Date(b.createdOn);
                return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
            });

            sortedArray = [...sorted];
        }
        if (sortValue === "Start date") {
            props.amplitude.track("Visited My Classes", { ordered_by: "Start date" });
            let withoutDate = data.filter(x => { return !x.startDate });
            let witDate = data.filter(x => { return x.startDate });
            sortedArray = witDate.sort((a, b) => {
                var dateA = new Date(a.startDate), dateB = new Date(b.startDate);
                return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
            });
            sortedArray = [...sortedArray, ...withoutDate];
        }

        setFilteredData(sortedArray);
    }

    const archiveClassSection = () => {
        if (currentItemId) {
            axios.patch("/classSection/archive/" + currentItemId)
                .then(res => {
                    let mode;
                    let tmp = classSectionData.filter(x => x._id == currentItemId);
                    if (tmp.length > 0) {
                        if (tmp[0].mode == undefined) {
                            mode = "Manual";
                        }
                        else {
                            mode = "Auto rosteted";
                        }
                    }
                    props.amplitude.track("Archived Class", { class_section_id: currentItemId, class_creation_method: mode, subject: tmp[0].subject });
                    getClassSections();

                    props.updateSideBar(true);
                }).catch(err => {
                    console.log("archive error getting all sections");
                })
            setConfirmArchive(false)

        }
    }

    const unArchiveClassSection = () => {
        if (currentItemId) {
            axios.patch("/classSection/un-archive/" + currentItemId)
                .then(res => {
                    let mode;
                    let tmp = classSectionData.filter(x => x._id == currentItemId);
                    if (tmp.length > 0) {
                        if (tmp[0].mode == undefined) {
                            mode = "Manual";
                        }
                        else {
                            mode = "Auto rosteted";
                        }
                    }
                    props.amplitude.track("Unarchived Class", { class_section_id: currentItemId, class_creation_method: mode, subject: tmp[0].subject });
                    getClassSections();
                    props.updateSideBar(true);
                }).catch(err => {
                    console.log("un-archive error getting all sections");
                })
            setConfirmUnArchive(false)

        }
    }

    const deleteClassSection = () => {
        if (currentItemId) {
            axios.patch("/classSection/delete/" + currentItemId, {})
                .then(res => {
                    getClassSections();
                }).catch(err => {
                    console.log("delete error getting all sections");
                })
            setConfirmDelete(false);
            props.updateSideBar(true);

        }
    }

    const addClassSection = () => {
        props.amplitude.track("Clicked Add a Class Button", { button_type: "Card", page_name: location.pathname });
        props.amplitude.track("Visited Add Class", { page_section: "Add new class" });
        history.push("/addClass");
    }

    const goToDetails = (id) => {
        history.push('/classdashboard/' + id);
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    return (
        <><div className="hiddenObject"></div>
            <div className="d-flex">
                <div className="dashboard-content w-100">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="myClasses" style={{ display: "inline-block" }}>
                                <div className="classDashbordHeader">My Classes</div>
                                {auth.strategy && auth.strategy.toLowerCase().indexOf("clever") == -1 && <div className="classDashbordSubHeader">Click on a class below, or add a new class, to begin.</div>}
                                {auth.strategy && auth.strategy.toLowerCase().indexOf("clever") > -1 && <div className="classDashbordSubHeader">Following are your class sections from Clever. Click a class to continue.</div>}
                            </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end p-0 pb-3">

                            <div className="ml-3">
                                <div className="dropdown order-by-filter">
                                    <div className="dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span>Order:</span>
                                        <span className="d-flex align-items-center dropdown-button pointer ml-2">
                                            {sortValue}
                                            <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                        </span>
                                    </div>
                                    <div className="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton">
                                        <button className="dropdown-item" onClick={() => setSortValue("Newest")}>Newest</button>
                                        <button className="dropdown-item" onClick={() => setSortValue("by Name")}>by Name</button>
                                        <button className="dropdown-item" onClick={() => setSortValue("Oldest")}>Oldest</button>
                                        <button className="dropdown-item" onClick={() => setSortValue("Start date")}>Start date</button>
                                    </div>
                                </div>
                            </div>

                            <div className="ml-3">
                                <div className="dropdown show-filter">
                                    <div className="dropdown-toggle d-flex align-items-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span>Show:</span>
                                        <span className="d-flex align-items-center dropdown-button pointer ml-2">
                                            {filterValue}
                                            <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                        </span>
                                    </div>
                                    <div className="dropdown-menu mt-2" aria-labelledby="dropdownMenuButton">
                                        <button className="dropdown-item" onClick={() => filterClassSections("Active", classSectionData)}>Active</button>
                                        <button className="dropdown-item" onClick={() => filterClassSections("Archived", classSectionData)}>Archived</button>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <ClassSectionRow
                            userId={auth.userId}
                            filterValue={filterValue}
                            filteredData={filteredData}
                            setCurrentItemId={setCurrentItemId}
                            setConfirmArchive={setConfirmArchive}
                            setConfirmUnArchive={setConfirmUnArchive}
                            setConfirmDelete={setConfirmDelete}
                            updatedData={updatedData}
                        />

                        {(filteredData.length <= 0) && filterValue != "Archived" && auth.strategy && auth.strategy.toLowerCase().indexOf("clever") == -1 &&
                            <div className="col text-center empty-class">
                                <NoClass style={{ fontSize: "65px" }} />
                                <h4 className="mt-4 m-0 font-weight-bold font text-uppercase font-18">You don’t have <br />any classes!</h4>
                                <div className="bottom-mark" ></div>
                                <h6 className="mb-4 font-weight-bold">Do you want to create your first class?</h6>
                                <button className="add-a-class addClass d-flex align-items-center btn-pill d-flex mt-2 b-2" style={{ padding: "6px 16px" }} onClick={addClassSection}>
                                    Add a Class
                                    <AddIcon className="ml-2" />
                                </button>
                            </div>
                        }

                    </div>
                </div>
                <div className="right-info ">

                    <div className="right-info-box mb-4">
                        <h6 className="title-text"><Resources /> Resources</h6>
                        <p className="description-text">Access everything you need to prep and teach all of your Project Spark courses.</p>
                        <div className="text-center">
                            <NavLink exact={true} className="btn btn-primary btn-pill " to='/resources'>Go to resources</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {filteredData.length === 0 && <Tour ref={childRef} tourSteps={tourStepsBefore} amplitude={props.amplitude} />}
            {filteredData.length > 0 && <Tour ref={childRef} tourSteps={tourStepsAfter} amplitude={props.amplitude} />}

            <ConfirmationBox
                isVisible={confirmArchive}
                setVisible={(value) => { setCurrentItemId(0); setConfirmArchive(value) }}
                confirmationHeader="Confirmation for archival"
                confirmationText="Are you sure you want to archive this class section?"
                handleOK={archiveClassSection}
                okLabel="CONFIRM"
                cancelLabel="Cancel" />

            <ConfirmationBox
                isVisible={confirmUnArchive}
                setVisible={(value) => { setCurrentItemId(0); setConfirmUnArchive(value) }}
                confirmationHeader="Confirmation for unarchival"
                confirmationText="Are you sure you want to unarchive this class section?"
                handleOK={unArchiveClassSection}
                okLabel="CONFIRM"
                cancelLabel="Cancel" />

            <ConfirmationBox
                isVisible={confirmDelete}
                setVisible={(value) => { setCurrentItemId(0); setConfirmDelete(value) }}
                confirmationHeader="Confirm deletion"
                confirmationText="Are you sure you want to delete this class section?"
                handleOK={deleteClassSection}
                okLabel="CONFIRM"
                cancelLabel="Cancel" />

            <Modal id="deviceError" className="deviceError" visible={showResolutionMsg} onClickBackdrop={() => { }}>
                <div className="modal-body" style={{ paddingBottom: "30px", padding: "25px" }} >
                    <div className="row">
                        {resolutionCode == "device" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={mobileD} />
                                </div>
                                <div className="col-12">
                                    <span className="oops">OOPS!</span>
                                    <span className="oopsline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Your device is not supported at the moment.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">for a better experience please use a large screen device such as tablet, Notebook or above.</span>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT1">If you are using school device please check with your teacher or support staff.</span>
                            </div>
                        </div>}



                        {resolutionCode == "orientation" && <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                            <div className="row" >
                                <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    <img alt="" src={devicerotate} />
                                </div>
                                <div className="col-12">
                                    <span className="snap">SNAP!</span>
                                    <span className="snapline"></span>
                                </div>
                            </div>
                            <div className="row" >
                                <span className="deviceErrorHeadT">Turn to landscape mode for a better experience.</span>
                            </div>
                        </div>}


                        {resolutionCode == "browser" &&
                            <div className="col-12" style={{ padding: "0px", textAlign: "center" }}>
                                <div className="row" >
                                    <div className="col-12" style={{ marginBottom: "20px", marginTop: "30px" }}>
                                        <img alt="" src={browser} />
                                    </div>
                                    <div className="col-12">
                                        <span className="oops">OOPS!</span>
                                        <span className="browserline"></span>
                                    </div>
                                </div>
                                <div className="row" >
                                    <span className="deviceErrorHeadT">We are sorry, but your browser is not compatible. Please use a more up to date browser.</span>
                                </div>
                                <div className="row" style={{ marginTop: "25px" }}>
                                    <span style={{ margin: "auto", fontWeight: "bold", fontSize: "9px", lineHeight: "16px", textAlign: "center", letterSpacing: "0.9px", textTransform: "uppercase", color: "#4C4B5E" }}>If you are using a school device please check with you teacher or support staff.</span>
                                </div>

                            </div>}

                    </div>
                </div>
            </Modal>

        </>
    )
});

export default React.memo(TeacherDashboard)