import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import "./AddClass.css";
import 'react-calendar/dist/Calendar.css';
import Step3 from '../../assets/images/steps_03.png';

const ThirdStep = props => {

    const onChange = date => setStartDate(date);
    const [startDate, setStartDate] = useState(new Date());
    const continueHandler = () => {
        props.handleClassDate(startDate);
    }

    const backHandler = () => {
        props.backStep(2);
    }

    const skipHandler = () => {
        props.amplitude.track("Clicked Skip on Add a Class Wizard", { page_section: "Select your start date" });
        props.backStep(9);
    }

    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Select your start date" });
    },[])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col" >
                        <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                <img src={Step3} />
                            </div>
                            <div className="col-12 dateHeaderLabel" style={{ marginTop: "37px" }}>
                                SELECT YOUR START DATE
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px" }}>
                                When will <span className="className">{props.classname}</span> class start?
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "24px", marginBottom: "24px" }}>
                                <Calendar
                                    minDate={new Date()}
                                    onChange={onChange}
                                    value={startDate}
                                />
                            </div>
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center" }}>
                                <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>
                                <button type="button" className="curve-fill-btn" id="continue" onClick={continueHandler} >CONTINUE</button>
                                <span className="skipBtn" onClick={skipHandler} >Skip Step</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ThirdStep);