import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import "./Resources.css";
import axios from "axios";

import pdfIcon from '../../assets/images/icons/office-file-pdf.png';
import docIcon from '../../assets/images/icons/office-file-doc.png';
import pptIcon from '../../assets/images/icons/PPTIcon.png';
import folderIcon from '../../assets/images/icons/folder-download-alternate.png';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

import Tour from "../../shared/components/tour/Tour";
import IFrame from "../../shared/components/iFrameBox/IFrameBox";
import Modal from 'react-bootstrap4-modal';

const Resources = forwardRef((props, ref) => {
    const childRef = useRef();
    const tabList = [
        { id: 0, label: "ALGEBRA", value: "Algebra" },
        { id: 1, label: "PHYSICS", value: "Physics" },
        { id: 2, label: "US HISTORY", value: "History" },
    ];
    const [stateCode, setStateCode] = useState(JSON.parse(localStorage.getItem("stateDistrict")).code);
    const [districtName, setSistrictName] = useState(JSON.parse(localStorage.getItem("stateDistrict")).district);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("userData")));
    const [activeTab, setActiveTab] = useState("Algebra");
    const [selectedSubject, setSelectedSubject] = useState();
    const [selectedSession, setSelectedSession] = useState(1);
    const [subjectList, setSubjectList] = useState([]);
    const [studentRosterId, setStudentRosterId] = useState("61142cd269c344011ac0954c");
    const [materials, setMaterials] = useState([]);
    const [commonDocs, setCommonDocs] = useState([]);
    const [sessionMaterials, setSessionMaterials] = useState([]);
    const [showStandards, setShowStandards] = useState(false);
    const [standards, setStandards] = useState();
    const [showMyProject, setShowMyProject] = useState(false);
    const [showExampleProject, setShowExampleProject] = useState(false);

    const [IsTourStart, setTourStart] = useState(false);

    const tourSteps = [
        {
            target: ".hiddenObject",
            content: (<div>
                Welcome to the <strong>Resources</strong> page. Here you can find everything you need to prep and teach all of the Project Spark courses.
            </div>),
            disableBeacon: true,
            placement: "center"

        },
        {
            target: ".tab-list-tooltip",
            content: "Start by selecting a course.",
            placement: "right"
        },
        {
            target: ".resource-tooltip-material",
            content: "You can find all the materials on the left. Use the drop-down to download a specific session's materials.",
            disableBeacon: true,
            placement: "right-start"

        },
        {
            target: ".resource-tooltip-project",
            content: (<div>
                On the right you can create a new sample project to use for testing or class demonstrations by clicking <strong>My Project</strong>. You can see an example of a finished project by clicking <strong>Example Project</strong>.
            </div>),
            disableBeacon: true,
            placement: "left-start"

        }
    ]

    const getHelp = () => {
        childRef.current.getTour();
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [IsTourStart])
    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    useEffect(() => {
        getCourseList();
        let stateDistrict = JSON.parse(localStorage.getItem("stateDistrict"));
        let userData = JSON.parse(localStorage.getItem("userData"));
        setUser(userData);
        setStateCode(stateDistrict.code);
    }, []);

    useEffect(() => {
        if (subjectList.length > 0) {
            let selectedCourse = subjectList && subjectList.filter(item => item.subject === activeTab)[0];
            setSelectedSubject(selectedCourse);
            setSelectedSession(1);
            getCourseMaterials(selectedCourse, 1);
            updateStudentRoster(selectedCourse.subject);
        }

    }, [activeTab]);

    const updateStudentRoster = (subject) => {
        if (subject.toLowerCase() === "history") {
            setStudentRosterId('61142cd969c344011ac09572');
        }

        if (subject.toLowerCase() === "physics") {
            setStudentRosterId('61142cc869c344011ac09527');
        }
        if (subject.toLowerCase() === "algebra") {
            setStudentRosterId('61142cd269c344011ac0954c');
        }
    }

    const getCourseList = () => {
        props.showSpinner(true);
        axios.get("/course")
            .then(res => {

                const courseList = res.data.sort(function (a, b) {
                    var x = a.subject.toLowerCase();
                    var y = b.subject.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });

                let selectedCourse = courseList.filter(item => item.subject === activeTab)[0];
                setSelectedSubject(selectedCourse);
                getCourseMaterials(selectedCourse, selectedSession);
                setSubjectList(courseList);
                updateStudentRoster(selectedCourse.name);
                props.showSpinner(false);
            });

        axios.get("/user/getusercontextualtourdata/" + user.userId).then(res => {
            let flag = false;
            if (res.data.contextualTour.length > 0) {
                let data = res.data.contextualTour.filter(val => val.pageName == "resources");
                if (data.length > 0) {
                    flag = false;
                }
                else {
                    flag = true;
                    axios.put("/user/updateusercontextualtourdata", { _id: user.userId, contextualTour: { 'pageName': "resources" } });
                }
            }
            else {
                flag = true;
                axios.put("/user/updateusercontextualtourdata", { _id: user.userId, contextualTour: { 'pageName': "resources" } });
            }

            flag && getHelp();
        });

    }

    const getCourseMaterials = (course, sessionId) => {
        const session = course.sessions.filter(x => x.index === parseInt(sessionId))[0];
        let resource = session.resource;

        let stateWiseResource = resource.filter(item => item.states === stateCode);
        if (stateWiseResource.lenght <= 0) {
            stateWiseResource = resource.filter(item => item.states === "other");
        }

        let allDocs = resource.filter(item => item.states === "all");

        let resourceDocs = allDocs.concat(stateWiseResource);
        let sessionDocs = resourceDocs.filter(item => item.name.includes("Session"));
        let otherMaterial = resourceDocs.filter(item => !item.name.includes("Session") && !item.name.includes("Facilitation"));

        setMaterials(resourceDocs);
        setSessionMaterials(sessionDocs);
        setCommonDocs(otherMaterial);

        let standard = course.standards.filter(item => item.states === stateCode);
        if (standard.lenght <= 0) {
            standard = course.standards.filter(item => item.states === "other");
        }

        setStandards(standard[0]);
    }

    const downloadAll = () => {
        materials.forEach(element => {
            var a = document.createElement("a");
            a.setAttribute('href', element.link);
            a.setAttribute('download', element.link);
            a.click();
        });
    }

    const getIcon = (icon) => {

        if (icon === "ppt")
            return (<img alt="download - ppt" src={pptIcon} className="SM-fileIcon" />);
        else if (icon === "docx" || icon === "doc")
            return (<img alt="download - ppt" src={docIcon} className="SM-fileIcon" />);
        else if (icon === "pdf")
            return (<img alt="download - ppt" src={pdfIcon} className="SM-fileIcon" />);
        else
            return (<img alt="download - ppt" src={docIcon} className="SM-fileIcon" />);
    }


    const mergerTwoIds = (a, b) => {
        const BYTECOUNT = 24;
        let res = "";
        for (let i = 0; i < BYTECOUNT; i += 4) {
            res = ("000" + (parseInt(a.slice(-i - 4, -i || a.length), 16) ^ parseInt(b.slice(-i - 4, -i || b.length), 16)).toString(16)).slice(-4) + res;
        }
        return res;
    }

    return (
        <>
            <div className="hiddenObject"></div>
            <Tour ref={childRef} tourSteps={tourSteps} amplitude={props.amplitude} />
            <div className="resource-title">Resources</div>
            <div className="tab-list">
                <div className="tab-list-tooltip">
                    {tabList.map(item => {
                        let activeCLass = activeTab === item.value ? "active" : "";
                        return <div className={"customTabs " + item.label + "Tab"} onClick={() => { setActiveTab(item.value) }} key={"tabs-" + item.id}>
                            <div className={"tabLabel " + activeCLass} >{item.label}</div>
                            <div className={"activeTab " + activeCLass} ></div>
                        </div>
                    })}
                </div>
            </div>

            <div className="s-card p-4">
                <div className="row resource-tooltip">
                    <div className="col-md-7 resource-tooltip-material">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="resource-subHeading m-0">Course Materials</div>
                            {materials.length > 0 &&
                                <div className="download-all" onClick={downloadAll} >Download All</div>
                            }
                        </div>
                        <div className="flex-container px-3">
                            {commonDocs.map((item, index) => {
                                return <div className="doc-item px-3" key={"common-doc-item-" + index}>
                                    <a href={item.link} download={item.link} className="SM-filename" >
                                        {getIcon(item.icon)}
                                        {item.name}
                                    </a>
                                </div>

                            })}
                            <div className="doc-item px-3">
                                <span className="SM-filename" onClick={() => setShowStandards(true)}>
                                    {getIcon("pdf")}
                                    Core Content Standards
                                </span>
                            </div>
                        </div>
                        <div className="mb-3">
                            Lesson plan materials per session
                        </div>
                        <div className="session-materials-list">
                            {selectedSubject && selectedSubject.sessions.map((item, index) => {
                                let isActive = selectedSession === item.index ? " active " : "";
                                return <div className={"session-items" + isActive} key={"session-" + item.index} onClick={() => { getCourseMaterials(selectedSubject, item.index); setSelectedSession(item.index) }}>
                                    <div className="session-heading">
                                        Session {item.index}
                                        <ExpandMoreIcon fontSize="large" className="dropwdown-arrow" />
                                    </div>

                                    <div className="session-content">
                                        {sessionMaterials.map((item, index) => {
                                            return <div className="doc-item" key={"session-doc-item-" + index}>
                                                <a href={item.link} download={item.link} className="SM-filename" >
                                                    {getIcon(item.icon)}
                                                    {item.name}
                                                </a>
                                            </div>
                                        })}
                                    </div>

                                </div>
                            })}
                        </div>

                    </div>
                    <div className="br-1"></div>
                    <div className="col-md-4 resource-tooltip-project">
                        <div className="mb-5">
                            <div className="resource-subHeading">My Project</div>
                            <p>Bring up a blank project to experiment with or to use in the session for class demos.</p>
                            <button className="btn btn-outline-primary btn-pill b-2" style={{ textTransform: "none" }} onClick={() => setShowMyProject(true)}>
                                My Project <ArrowForwardIcon className="ml-2" />
                            </button>
                        </div>

                        <div className="mb-4">
                            <div className="resource-subHeading">Example Project</div>
                            <p>View a finished sample project for the course.</p>
                            <button className="btn btn-outline-primary btn-pill b-2" style={{ textTransform: "none" }} onClick={() => setShowExampleProject(true)}>
                                Example Project <ArrowForwardIcon className="ml-2" />
                            </button>
                        </div>
                    </div>

                </div>

            </div>

            {standards && <Modal id="standardModal" dialogClassName="Modaldialog modal-dialog-centered" className="standardsModal" visible={showStandards} onClickBackdrop={() => { setShowStandards(false) }}>
                <div className="modal-body">
                    <div className="text-right">
                        <span className="close-btn btn btn-outline-primary btn-pill" onClick={() => { setShowStandards(false) }}>
                            <ClearIcon />
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="standardHeader" style={{ marginTop: "5px" }}>
                                <img alt="icon" src={selectedSubject.courseIcon} style={{ width: "60xp", height: "60px" }} />
                            </div>
                        </div>
                        <div className="col-9">
                            <div className="standardHeader">
                                <span>{standards.title}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-1 col-10">
                            <div >
                                <span className="S-Header-sub" dangerouslySetInnerHTML={{ __html: standards.subTitle }}></span>
                                <p className="S-text" dangerouslySetInnerHTML={{ __html: standards.description }}></p>
                            </div>
                            {
                                standards.standards.length > 0 && standards.standards.map((item, i) => {
                                    return <div key={i}>
                                        <div className="S-color-header">
                                            <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                        </div>
                                        {
                                            item.Objectives.map((x, index) => {
                                                return <div key={index}>
                                                    <span className="S-normal-header" dangerouslySetInnerHTML={{ __html: x.title }}></span>
                                                    <p className="S-text" dangerouslySetInnerHTML={{ __html: x.description }}></p>
                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
            }

            {selectedSubject && showMyProject && <IFrame
                mode="normal"
                courseName={selectedSubject.name}
                userDetails={{ userID: user.userId, email: user.email, username: user.firstname }}
                studentRosterID={mergerTwoIds(user.userId, selectedSubject._id)}
                isFreezed={null}
                sessionDetails={{ sessionName: "Session " + selectedSession, sessionIndex: selectedSession }}
                sessions={[]}
                isVisible={showMyProject}
                title="My Project"
                setVisible={(value) => { setShowMyProject(value) }}
                iframeURL={selectedSubject.entryPoints.educatorSandboxEntry}
                sessionTypes={selectedSubject.sessionTypes}
                subject={selectedSubject.subject}
                districtName={districtName}
                amplitude={props.amplitude}
                classId={selectedSubject._id}
                teacherId={user.userId} />
            }

            {selectedSubject && showExampleProject &&
                <IFrame
                    mode="demo"
                    courseName={selectedSubject.name}
                    userDetails={{ userID: user.userId, email: user.email, username: user.firstname }}
                    studentRosterID={studentRosterId}
                    isFreezed={null}
                    sessionDetails={{ sessionName: "Session " + selectedSession, sessionIndex: selectedSession }}
                    sessions={[]}
                    isVisible={showExampleProject}
                    title="Example Project"
                    setVisible={(value) => { setShowExampleProject(value) }}
                    iframeURL={selectedSubject.entryPoints.demoProjectEntry}
                    sessionTypes={selectedSubject.sessionTypes}
                    subject={selectedSubject.subject}
                    districtName={districtName}
                    amplitude={props.amplitude}
                    classId={selectedSubject.classId}
                    teacherId={user.userId} />
            }

        </>

    )
})

export default Resources;