import React, { useEffect, useState } from "react";
import {
    useParams
} from "react-router-dom";
import axios from "axios";
import './ViewProject.css'

const ViewProject = (props) => {
    const [projectUrl, setProjectUrl] = useState('');
    let url;
    const [projectData, setProjectData] = useState(null);
    let param = useParams();
    const shareId = param.shareId;
    const [refresh, setRefresh] = useState(false);
    const [token, setToken] = useState(null);
    const [courseTitle, setCourseTitle] = useState();
    const [mode, setMode] = useState('shared');

    const reSetRefresh = () => {
        setRefresh(refresh => !refresh);
    }

    useEffect(() => {
        props.showLoader();
        if (shareId !== null) {
            axios.get("/studentRoster/getstudentprojecturl/" + shareId)
                .then(res => {
                    if (res !== null) {
                        url = res.data.path;
                    }
                    let student_id = res.data.userId;
                    let subject = res.data.courseName;
                    props.amplitude.track("Visited Shared Project", { teacher_user_id: res.data.teacher, class_section_id: res.data.classSectionId, student_id: student_id, subject: subject });
                    setCourseTitle(res.data.courseName);
                    setToken(res.data.token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                    axios.get('/studentRoster/getstudentshareproject/' + res.data.userId + '/' + res.data.classSectionId).then(res => {
                        setProjectData(res.data);
                        setProjectUrl(url);
                        props.hideLoader();
                    })
                }, (err) => {
                    console.log("err:", err);
                })
        }
        else {
            props.hideLoader();
        }
    }, [refresh]);

    const Spark = {};

    const courseName = () => {
        return new Promise(function (resolve, reject) {
            let coursename = courseTitle;
            if (coursename);
            else {
                coursename = "err";
                Error = "ERROR: Unable to fetch coursename, Please try again."
            }
            return resolve(coursename);
        });
    }

    const userID = () => {
        return new Promise(function (resolve, reject) {
            let userid = projectData.studentId;
            if (userid);
            else {
                userid = "err";
                Error = "ERROR: Unable to fetch user id, Please try again."
            }
            resolve(userid);
        })
    }

    const studentID = () => {
        return new Promise(function (resolve, reject) {

            let studentid = projectData.studentId;
            if (studentid);
            else {
                studentid = "err";
                Error = "ERROR: Unable to fetch student id, Please try again."
            }
            resolve(studentid);
        })

    }

    const userName = () => {
        return new Promise(function (resolve, reject) {

            let username = projectData.firstName;
            if (username);
            else {
                username = "err";
                Error = "ERROR: Unable to fetch username, Please try again."
            }

            resolve(username);
        })

    }

    const currentSession = () => {
        return new Promise(function (resolve, reject) {
            resolve(5);
        })

    }

    const isProjectLocked = () => {
        return new Promise(function (resolve, reject) {
            let value = projectData.isFreezed
            if (value !== undefined || value !== null);
            else {
                value = "err"
                Error = "ERROR: Unable to fetch project staus, Please try again."
            }
            resolve(value);
        })

    }

    const getCurrentMode = () => {
        return new Promise(function (resolve, reject) {
            if (mode)
                resolve(mode)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: mode is not set."
                resolve(false);
            }

        })
    }

    const getCurrentLocation = () => {

        return new Promise(function (resolve, reject) {
            if (projectData.currentLocation)
                resolve(projectData.currentLocation)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: mode is not set."
                resolve(false);
            }
        });
    }

    const getCourseData = (id) => {
        return new Promise(function (resolve, reject) {
            axios.get('/unNamedData/getCourseData/' + projectData._id, {}, {
                headers: {
                    Authorization: "bearer " + token
                }
            }
            ).then(res => {
                if (res.data)
                    resolve(res.data)
                else
                    resolve(null);
            })
                .catch(errror => {
                    console.log("something went wrong in getCourseData: " + errror)
                    Error = "ERROR: " + errror
                    resolve(null);
                })
        });
    }

    const getGlobalVariable = (id, name) => {
        return new Promise(function (resolve, reject) {
            axios.get('/unNamedData/getGlobalData/' + projectData._id + "/" + name, {}, {
                headers: {
                    Authorization: "bearer " + token
                }
            }
            ).then(res => {
                if (res.data.length > 0)
                    resolve(res.data[0].value)
                else
                    resolve(null)
            })
                .catch(errror => {
                    console.log("something went wrong in getGlobalVariable: " + errror)
                    Error = "ERROR: " + errror
                    resolve(null);
                })
        });
    }

    const getLastError = () => {
        return new Promise(function (resolve, reject) {
            if (Error !== "Error: ")
                resolve(Error)
            else
                resolve(null)
        })
    }

    const isPaused = () => {
    }

    const getAllSessionTypes = () => {
        return new Promise(function (resolve, reject) {
            let sessiontypes = projectData.sessionTypes;
            if (sessiontypes);
            else {
                sessiontypes = '';
                console.log("something went wrong : ")
                Error = "ERROR: SessionTypes are not available for this class."
            }
            resolve(sessiontypes);
        })
    }
    const setCurrentLocation = () => {
    }
    const setCourseData = () => {
    }
    const setGlobalVariable = () => {
    }
    const submitProjectAnswer = () => {
    }
    const submitAssessmentGrade = () => {
    }
    const showAssessment = () => {
    }
    const submitProjectGrade = () => {
    }
    const mileStoneCompleted = () => {
    }

    Spark.courseName = courseName;
    Spark.userID = userID;
    Spark.studentID = studentID;
    Spark.userName = userName;
    Spark.currentSession = currentSession;
    Spark.getCurrentMode = getCurrentMode;
    Spark.getCurrentLocation = getCurrentLocation;
    Spark.getCourseData = getCourseData;
    Spark.getGlobalVariable = getGlobalVariable;
    Spark.isProjectLocked = isProjectLocked;
    Spark.getLastError = getLastError;
    Spark.isPaused = isPaused;
    Spark.getAllSessionTypes = getAllSessionTypes;
    Spark.setCurrentLocation = setCurrentLocation;
    Spark.setCourseData = setCourseData;
    Spark.setGlobalVariable = setGlobalVariable;
    Spark.submitProjectAnswer = submitProjectAnswer;
    Spark.submitAssessmentGrade = submitAssessmentGrade;
    Spark.showAssessment = showAssessment;
    Spark.submitProjectGrade = submitProjectGrade;
    Spark.mileStoneCompleted = mileStoneCompleted;

    window.getSpark = () => { return Spark; }
    window.Spark = Spark;

    return (
        <>
            <iframe src={projectUrl}></iframe>
        </>
    )
}

export default React.memo(ViewProject)