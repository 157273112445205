import { useState, useCallback, useEffect } from 'react';
import axios from "axios";

let logoutTimer;

export const useAuth = () => {

    const [token, setToken] = useState(false);
    const [email, setEmail] = useState(false);
    const [firstName, setFirstName] = useState(false);
    const [lastName, setLastName] = useState(false);
    const [role, setRole] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userId, setUserId] = useState(false);
    const [strategy, setStrayegy] = useState(false);

    const login = useCallback((uid, token, email, firstname, lastname, roles, strategy, expirationDate) => {
        setToken(token);
        setEmail(email);
        setFirstName(firstname);
        setLastName(lastname);
        setRole(roles);
        setUserId(uid);
        setStrayegy(strategy);
        const tokenExpirationDate =
            expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        setTokenExpirationDate(tokenExpirationDate);
        localStorage.setItem(
            'userData',
            JSON.stringify({
                userId: uid,
                token: token,
                email: email,
                firstname: firstname,
                lastname: lastname,
                roles: roles,
                strategy: strategy,
                expiration: tokenExpirationDate.toISOString()
            })
        );
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        localStorage.removeItem('userData');
        localStorage.removeItem('district');
        localStorage.removeItem('stateDistrict');

        localStorage.clear();
        window.location.href = process.env.REACT_APP_APPURL + '/signin';
    }, []);


    const clearUserData = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        localStorage.removeItem('userData');
    }, []);

    const updateUserData = useCallback((firstname, lastname) => {
        setFirstName(firstname);
        setLastName(lastname);
        let userData = JSON.parse(localStorage.getItem('userData'));
        userData = { ...userData, firstname, lastname };
        localStorage.setItem('userData', JSON.stringify(userData));
    }, [])

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (
            storedData &&
            storedData.token
        ) {
            login(storedData.userId, storedData.token, storedData.email, storedData.firstname, storedData.lastname, storedData.roles, storedData.strategy, new Date(storedData.expiration));
        }
    }, [login]);

    useEffect(() => {
        axios.interceptors.request.use(request => {
            clearTimeout(logoutTimer);
            logoutTimer = setTimeout(logout, 1000 * 60 * 60 * 2);
            return request;
        }, error => {
            return Promise.reject(error)
        })
    }, [token, logout]);

    return { token, login, logout, userId, email, firstName, lastName, role, strategy, clearUserData, updateUserData };
};