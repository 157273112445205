import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";

import Export from "../Icons/Export"
import Button from 'react-bootstrap/Button'
import closePNG from '../../assets/images/icons/close.png';

import ExportGrades from '../../shared/components/exportGrades/ExportGrades';
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';
import Modal from 'react-bootstrap4-modal';

const ClassDetailsHeader = props => {
    const axiosInstance = axios.create();
    const tabList = [
        { id: 0, label: "CLASS", value: "DASHBOARD" },
        { id: 1, label: "ASSESSMENTS", value: "ASSESSMENTS" },
        { id: 2, label: "PROJECTS", value: "PROJECTS" },
        { id: 3, label: "GRADEBOOK", value: "GRADEBOOK" },
    ];

    const [classDetails, setClassDetails] = useState({});
    const [activeTab, setActiveTab] = useState(0);
    const [isExport, setIsExport] = useState(false);
    const [exportData, setExportData] = useState({ data: [], headers: [], fileName: "" });
    const [canvasData, setCanvasData] = useState({ data: [], headers: [], fileName: "" });
    const [isGradingCompleted, setIsGradingCompleted] = useState(false);
    const [releaseMsg, setReleaseMsg] = useState(false);
    const [showRubric, setShowRubric] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState(null);
    const [projectRubric, setProjectRubric] = useState({});
    const [disableRubric, setDisableRubric] = useState(true);

    useEffect(() => {
        const courseDetails = props.classDetails;
        setClassDetails(courseDetails);
    }, [props.classDetails]);

    useEffect(() => {

        let list = props.studentsList.rosterData || [];

        if (list.length > 0) {
            let rubric = list[0].rubric;
            setProjectRubric(rubric);
            setSelectedCriteria(rubric.rubricCriteria[0]);
            setDisableRubric(false);
        }
    }, [props.studentsList])

    const updateTab = (item) => {
        setActiveTab(item.id);
        props.setTab(item.value);
    }


    const openGradingModal = () => {
        props.amplitude.track("Clicked Class Details Button", { button_name: "Export Grades", session_id: props.currentSessionId, session_type: classDetails.sessionType[props.currentSessionId], subject: classDetails.subject, class_section_id: props.classId });
        console.log("Export grade");
        if (props.studentsList.rosterData.length == 0) {
            setReleaseMsg(true);
        }
        else {
            let filename = "Course-" + classDetails.courseName + ".csv";
            let iscomplete = true;
            let headers = [
                { label: "Student", key: "Student" },
                { label: "Spark ID", key: "SparkID" },
                { label: "Spark Login ID", key: "SparkLoginID" },
                { label: "SIS User ID*", key: "SISUserID*" },
                { label: "Project Score (MAX:" + props.studentsList.rosterData[0].rubric.maxScore + ")", key: "TotalScored" }
            ];


            for (let i = 0; i < props.studentsList.rosterData[0].sessions.length; i++) {
                if (i == 4) {
                    continue;
                }
                let tempObj = {
                    label: props.studentsList.rosterData[0].sessions[i].name + " (" + props.studentsList.rosterData[0].sessions[i].assessment.totalQuestions + ")",
                    key: props.studentsList.rosterData[0].sessions[i].name
                }
                headers.push(tempObj);
            }

            let data = [];

            props.studentsList.rosterData.forEach((item, index) => {
                if (!item.rubric.isGraded) { iscomplete = false }
                let temp = {};
                temp["Student"] = item.lastName + ", " + item.firstName;
                temp["SparkID"] = item._id;
                temp["SparkLoginID"] = item.emailId;
                temp["SISUserID"] = "";
                temp["TotalScored"] = item.rubric.totalScored;
                item.sessions.forEach((x, i) => {
                    temp[x.name] = x.assessment.totalCorrectAnswers;
                })
                data.push(temp);
            })
            setExportData({ headers: headers, data: data, fileName: filename })

            //canvas data
            let canvasFilename = "Course-" + classDetails.courseName + "-canvas.csv";
            let canvasHeaders = [
                { label: "Student", key: "Student" },
                { label: "ID", key: "ID" },
                { label: "SIS User ID*", key: "SISUserID" },
                { label: "SIS Login ID*", key: "SISLoginID" },
                { label: "Section", key: "Section" },
            ];

            for (let i = 0; i < props.studentsList.rosterData[0].sessions.length; i++) {
                let tempObj = {
                    label: props.studentsList.rosterData[0].sessions[i].name,
                    key: props.studentsList.rosterData[0].sessions[i].name
                }
                canvasHeaders.push(tempObj);
            }
            canvasHeaders.push({ label: "Current Score (entire course)", key: "CurrentScore" })
            canvasHeaders.push({ label: "Final Score (entire course)", key: "FinalScore" })

            let canvasdata = [];
            let maxPointsRow = {
                Student: "Points Possible",
                ID: "",
                SISUserID: "",
                SISLoginID: "",
                Section: "",
                CurrentScore: "",
                FinalScore: ""
            }
            props.studentsList.rosterData.forEach((item, index) => {
                let temp = {};
                temp["Student"] = item.lastName + ", " + item.firstName;
                temp["ID"] = item._id;
                temp["SISUserID"] = "";
                temp["SISLoginID"] = "";
                temp["Section"] = item.name;
                temp["CurrentScore"] = item.rubric.totalScored;
                temp["FinalScore"] = item.rubric.totalScored;
                item.sessions.forEach((x, i) => {
                    temp[x.name] = x.assessment.totalCorrectAnswers;
                    maxPointsRow[x.name] = x.assessment.totalQuestions
                })
                canvasdata.push(temp);
            })

            canvasdata.unshift(maxPointsRow)

            setCanvasData({ headers: canvasHeaders, data: canvasdata, fileName: canvasFilename })

            setIsGradingCompleted(iscomplete);
            setIsExport(true);
        }
    }


    const canvasExport = () => {

        let courseID = props.studentsList.rosterData[0].sis_course_id;
        let courseSectionID = props.studentsList.rosterData[0].sis_section_id;
        let districtID = props.studentsList.rosterData[0].district;
        let assessmentIDs = [];
        axios.get("/canvasMapping/getCanvasMapping/" + districtID)
            .then(result => {
                let course = result.data.courses.filter(x => x.canvasCourseID == courseID)

                axiosInstance.defaults.baseURL = result.data.canvasBaseURL;
                axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + result.data.canvasAccessToken;

                if (course.length == 0) {
                    classDetails.sessions.forEach((item, key, arr) => {
                        let assessmentName = "";
                        let maxscore = 0;
                        if (item.assessment && item.assessment.assessmentQuestion.length != 0) {
                            maxscore = item.assessment.totalQuestions;
                            assessmentName = "Session " + item.index + " Formative Assessment"
                        }
                        else {
                            maxscore = props.studentsList.rosterData[0].rubric.maxScore;
                            assessmentName = "Project Culminating Assessment"
                        } axiosInstance.post(`/courses/sis_course_id:${courseID}/assignments?assignment[name]=${assessmentName}&assignment[published]=true&assignment[points_possible]=${maxscore}`, {})
                            .then(response => {
                                assessmentIDs.push(response.data.id);
                                if (key === arr.length - 1) {
                                    pushGradesToCanvas(assessmentIDs, courseID);
                                    addCourseToCanvasMapping(result.data._id, {
                                        canvasCourseId: courseID,
                                        canvasSectionId: courseSectionID,
                                        sparkCourseId: null,
                                        assignment1Id: assessmentIDs[0],
                                        assignment2Id: assessmentIDs[1],
                                        assignment3Id: assessmentIDs[2],
                                        assignment4Id: assessmentIDs[3],
                                        projectAssignmentId: assessmentIDs[4]
                                    })
                                }
                            })
                    })
                }
                else {
                    assessmentIDs.push(course[0].assignment1ID)
                    assessmentIDs.push(course[0].assignment2ID)
                    assessmentIDs.push(course[0].assignment3ID)
                    assessmentIDs.push(course[0].assignment4ID)
                    assessmentIDs.push(course[0].projectAssignmentID)
                    pushGradesToCanvas(assessmentIDs, courseID);
                }

                setIsExport(false);
            })

    }

    const pushGradesToCanvas = (assessmentIDs, courseID) => {
        let counter = 0;
        classDetails.sessions.forEach(session => {
            let str = "/courses/sis_course_id:" + courseID + "/assignments/" + assessmentIDs[counter++] + "/submissions/update_grades?"
            props.studentsList.rosterData.forEach(x => {
                let filteredSession = x.sessions.filter(k => k.index == session.index);
                if (filteredSession.length != 0) {
                    if (filteredSession[0].assessment && filteredSession[0].assessment.assessmentQuestion.length != 0)
                        str += "grade_data[sis_user_id:" + x.sis_user_id + "][posted_grade]=" + filteredSession[0].assessment.totalCorrectAnswers + "&";
                    else
                        str += "grade_data[sis_user_id:" + x.sis_user_id + "][posted_grade]=" + x.rubric.totalScored + "&";
                }
            })
            if (!str.endsWith("/update_grades?")) {
                str = str.slice(0, -1);
                axiosInstance.post(str,
                    {})
                    .then(response => {
                    })
            }
        })
    }

    const addCourseToCanvasMapping = (id, course) => {

        axios.put("/canvasMapping/addAssignments/" + id, course)
            .then(res => {
            })
    }

    return (
        <>
            <div className="w-100">
                <div className="row w-100">
                    <div className="col-md p-0">
                        <span className="d-flex align-items-center">
                            <img alt="" src={classDetails.courseIcon} style={{ marginRight: "15px", width: "30px" }} />
                            <h4 className="m-0 font-weight-bold font-18">{classDetails.name}</h4>
                        </span>
                    </div>
                    <div className="col-md d-flex align-items-center justify-content-end text-uppercase p-0 add-student-tooltip" style={{ maxWidth: "335px" }}>
                        <span className="font-16 font-weight-bold">Class code: {classDetails.classCode}</span>
                        <span className="ml-4 addStudentLable">
                            <Link to={{ pathname: '/addClass', classDetails: { classSectionId: classDetails._id } }} className="btn btn-primary btn-pill font-weight-bold font-10"> Add a Student</Link>
                        </span>
                    </div>
                </div>
                <hr />
                <div className="row mb-4">
                    <div className="col-10 p-0">
                        {tabList.map(item => {
                            let activeCLass = activeTab === item.id ? "active" : "";
                            return <div className={"customTabs " + item.label + "Tab"} onClick={() => { updateTab(item) }} key={"tabs-" + item.id}>
                                <div className={"tabLabel " + activeCLass} >{item.label}</div>
                                <div className={"activeTab " + activeCLass} ></div>
                            </div>
                        })}

                    </div>

                    <div className="col-2 text-right p-0">
                        {activeTab === 3 &&
                            <Button disabled={props.disableExport} className="exportgrades p-0" onClick={openGradingModal} variant="">
                                <Export fontSize="large" className="mr-2" />
                                <span>Export Grades</span>
                            </Button>
                        }
                        {activeTab === 2 &&
                            <Button disabled={disableRubric} className="exportgrades p-0" onClick={() => setShowRubric(!showRubric)} variant="">
                                <span>View Rubric</span>
                            </Button>
                        }

                    </div>
                </div>
            </div>
            {
                !props.disableExport &&
                <>
                    <ExportGrades
                        isVisible={isExport}
                        setVisible={(value) => { setIsExport(value) }}
                        exportData={exportData}
                        canvasExport={canvasExport}
                        canvasData={canvasData}
                        isGradingCompleted={isGradingCompleted}
                        isCanvas={props.studentsList.rosterData && props.studentsList.rosterData[0].sis_user_id}
                    />

                    <ConfirmationBox
                        isVisible={releaseMsg}
                        setVisible={(value) => { setReleaseMsg(value) }}
                        confirmationHeader="Student Roster data is not available to export grades."
                        confirmationText=""
                        handleOK={() => { setReleaseMsg(false); }}
                        okLabel="OK"
                        cancelLabel="Cancel"
                        hideCancel={true} />
                </>
            }
            {
                selectedCriteria && <Modal id="rubricNotScored" className="rubricModal" visible={showRubric} onClickBackdrop={() => { setShowRubric(false) }}>
                    <div className="modal-body" >
                        <div className="row" style={{ textAlign: "end", justifyContent: 'flex-end' }} onClick={() => { setShowRubric(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} style={{ top: "0", right: "0", cursor: "pointer" }} />
                        </div>
                        <div className="row" style={{ width: "85%", marginBottom: "25px" }}>
                            <span className="small-black-heading" style={{ textAlign: "left" }}>
                                The rubric below will show the student's final scores once you have graded their project.
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{ padding: "0px", textAlign: "left" }}>
                                <div className="row" >
                                    {projectRubric.rubricCriteria.map((item) => {
                                        return <span key={item.index} className={` ${selectedCriteria.index === item.index ? "selectedCriteria1" : "criteria"}`}
                                            onClick={() => {
                                                setSelectedCriteria(item)
                                            }}>{item.title}</span>
                                    })}
                                </div>
                                <div className="row" style={{ backgroundColor: "#F2F2F9", padding: "5px" }}>
                                    <div className="col-12" style={{ padding: "25px 20px" }}>
                                        <span className="medium-text" dangerouslySetInnerHTML={{ __html: selectedCriteria.topText }}></span>
                                    </div>
                                    {selectedCriteria.items
                                        .sort((a, b) => b.score - a.score)
                                        .map((option, index) => {
                                            return <div key={index} className={`col-3 ${index !== selectedCriteria.items.length - 1 ? "criteria-rightBorder" : ""}`} >
                                                <div className="row" style={{ marginBottom: "10px" }}>
                                                    <span className="medium-text" style={{ fontWeight: "bold" }}>{option.title} ({option.score})</span>
                                                </div>
                                                <div className="row" style={{ fontSize: "12px" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
                                                </div>
                                            </div>
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default React.memo(ClassDetailsHeader);