import React, { useContext, useState, useEffect } from "react";
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from "axios";

import { useForm } from "react-hook-form";

import "./ChangePassword.css";
import UserContext from "../../shared/context/UserContext";

import logo from '../../assets/images/spark-logo-corored.svg';

function ChangePassword() {
    const auth = useContext(UserContext);
    let history = useHistory();
    const axiosInstance = axios.create();

    const { register, handleSubmit, watch, errors } = useForm()

    let userId = (new URL(window.location)).searchParams.get("id");
    let token = (new URL(window.location)).searchParams.get("token");

    const [confirmPass, setConfirmPass] = useState();
    const [newPass, setNewPass] = useState();
    const [flag, setFlag] = useState(false);
    const [subHeaderText, setSubHeaderText] = useState("");

    useEffect(() => {
        axios.get("/staticContent/ChangePassword")
            .then(res => {
                setSubHeaderText(res.data.Data.subHeader)
            })
    }, []);

    const changePassword = (event) => {
        axiosInstance.patch(process.env.REACT_APP_APIURL + "/user/changepassword", { id: userId, password: newPass }, {
            headers: { Authorization: "bearer " + token }
        })
            .then(res => {
                setFlag(true);
            })
            .catch(error => {
                console.log(error)
            })

    }

    const newPasswordChangeHandler = (event) => {
        setNewPass(event.target.value);
    }
    const confirmPasswordChangeHandler = (event) => {
        setConfirmPass(event.target.value);
    }
    const checkPassword = () => {
        return newPass === confirmPass;
    }

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }

    return (
        <>
            <div className="changepasswordBox minscreenheightOut" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)" }}>
                <div className="row header margin0" style={{ marginBottom: "60px" }}>
                    <div className="col-4">
                        <img src={logo} alt="spark" onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div style={{ width: "640px", margin: "auto", backgroundColor: "#fff" }}>
                    <div className="row " style={{ textAlign: "center" }}>
                        <div className="col">
                            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                                <div className="col-12">
                                    <span className="forgotpassMsg">CHANGE PASSWORD</span>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                                <div className="col-12">
                                    <span className="forgotpassDesc">
                                        {subHeaderText}
                                    </span>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                                <div className="col-12">
                                    <form className="signinform" onSubmit={handleSubmit(changePassword)}>
                                        <div className="form-group" style={{ margin: "0 150px", textAlign: "left" }}>
                                            <label>New Password</label>
                                            <input type="password" className="form-control" id="newPassword" onChange={newPasswordChangeHandler} />
                                        </div>
                                        <div className="form-group" style={{ margin: "20px 150px", textAlign: "left" }}>
                                            <label>Confirm Password</label>
                                            <input type="password" className="form-control" name="confirmPassword" onChange={confirmPasswordChangeHandler} ref={register({ required: true, validate: checkPassword })} />
                                            {errors.confirmPassword && errors.confirmPassword.type === "required" && <span style={{ color: "red" }}>This field is required</span>}
                                            {errors.confirmPassword && errors.confirmPassword.type === "validate" && < span style={{ color: "red" }}> Password does not match.</span>}
                                        </div>
                                        <div className="form-group" style={{ textAlign: "center", marginTop: "20px", marginBottom: "40px" }}>
                                            <button type="submit" className="curve-fill-btn">CHANGE PASSWORD</button>
                                        </div>
                                        {flag && <div style={{ margin: "20px auto" }}><span>Password changed successfully, Please sigin <Link to="/signin">here.</Link></span>
                                        </div>}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ChangePassword);