import React, { useState, useContext, useEffect } from "react";
import UserContext from "../../shared/context/UserContext";
import Step5 from '../../assets/images/steps_05.png';
import Step1 from '../../assets/images/twosteps_01.png';
import ManualImport from '../../assets/images/icons/checklist-pen.png';
import FileImport from '../../assets/images/icons/common-file-text-upload.png';
import CleverImport from '../../assets/images/icons/clever_icon.png';
import "./AddClass.css";
import $ from "jquery";
import { useHistory } from "react-router-dom";

const FourthStep = props => {
    let history = useHistory();
    const auth = useContext(UserContext);
    const [selectedCard, setSelectedCard] = useState('');
    const [isError, setIsError] = useState(false);
    const [disableContinue, setDisableContinue] = useState(true);

    const continueHandler = () => {
        if (selectedCard == '') {
            setIsError(true);
            setDisableContinue(true);
        }
        else if (selectedCard == 'manual') {
            props.handleClassRoster(5);
        }
        else if (selectedCard == 'file') {
            props.handleClassRoster(6);
        }
        else if (selectedCard == 'clever') {
            props.handleClassRoster(8);
        }
    }

    const backHandler = () => {
        props.backStep(9);
    }

    const skipHandler = () => {
        props.amplitude.track("Clicked Skip on Add a Class Wizard", { page_section: "Add your class roster" });
        props.backStep(7);
    }

    const manualHandler = () => {
        $(".importCard").removeClass("selectedImportCard");
        $(".manualImportCard").addClass("selectedImportCard");
        setSelectedCard('manual');
        setIsError(false);
        setDisableContinue(false);
    }

    const cleverHandler = () => {
        $(".importCard").removeClass("selectedImportCard");
        $(".cleverImportCard").addClass("selectedImportCard");
        setSelectedCard('clever');
        setIsError(false);
        setDisableContinue(false);
    }

    const importHandler = () => {
        $(".importCard").removeClass("selectedImportCard");
        $(".fileImportCard").addClass("selectedImportCard");
        setSelectedCard('file');
        setIsError(false);
        setDisableContinue(false);
    }

    const BackToTeacherDashboard = () => {
        history.push("/classdashboard/" + props.classDetails.classSectionId);
    }

    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Add your class roster" });
    }, [])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col" >
                        <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                {props.classDetails === null && <img src={Step5} />}
                                {props.classDetails !== null && <img src={Step1} />}
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">ADD YOUR CLASS ROSTER</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px" }}>
                                Choose a method for adding students to your <span className="className">{props.classname}</span>.
                            </div>
                            <div className="col-12" style={{ marginBottom: "60px", marginTop: "43px", textAlign: "center" }}>

                                <div className="importCard manualImportCard" style={{ marginRight: "30px" }} onClick={manualHandler}>
                                    <div style={{ marginTop: "21px" }}><img src={ManualImport} /></div>
                                    <div className="importCardLabel">Add Students Manually</div>
                                </div>
                                <div className="importCard fileImportCard" onClick={importHandler}>
                                    <div style={{ marginTop: "21px" }}><img src={FileImport} /></div>
                                    <div className="importCardLabel">Import Class List</div>
                                    <div className="importCardSubLabel">(CSV, Excel files only)</div>
                                </div>
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                {isError && < span style={{ color: "red" }}>Please choose a method to add student.</span>}
                            </div>
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center" }}>
                                {props.classDetails === null && <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>}
                                <button type="button" disabled={disableContinue} className="curve-fill-btn" id="continue" onClick={continueHandler} >CONTINUE</button>
                                {props.classDetails === null && <span className="skipBtn" onClick={skipHandler} >Or Use Class Code</span>}
                                {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(FourthStep);