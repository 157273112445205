import React, { useState, useEffect, useContext } from 'react';

const AdminDashboard = props => {

    return (
        <div className="d-flex align-items-center justify-content-center h-100">
            <h1 >Admin Dashboard</h1>
        </div>
    );
}

export default React.memo(AdminDashboard);