import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import UserContext from "../../shared/context/UserContext";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';

import SessionMaterials from '../classDetails/SessionMaterials';
import LaunchFacilitaion from '../classDetails/LaunchFacilitaion';
import IFrame from "../../shared/components/iFrameBox/IFrameBox";
import {
    useHistory
} from "react-router-dom";
import rightArrow from '../../assets/images/icons/Right.png';
import notStartedSession from '../../assets/images/icons/notStartedSession.png'
import notStartedMilestone from '../../assets/images/icons/notStartedMilestone.png'
import completedSession from '../../assets/images/icons/completedSession.png'
import currentSession from '../../assets/images/icons/currentSessionIcon.png'
import activeSessionIcon from '../../assets/images/icons/active_session_icon.png'
import activeMilstoneIcon from '../../assets/images/icons/active_milestone_icon.png'


import "./ClassDetails.css";

const Facilitation = props => {
    const auth = useContext(UserContext);

    const [releaseMsg, setReleaseMsg] = useState(false);

    const [classDetails, setClassDetails] = useState(props.classDetails || null);
    const [visibleExProject, setVisibleExProject] = useState(false);
    const [visibleMyProject, setVisibleMyProject] = useState(false);
    const [timeLineData, setTimeLineData] = useState(props.sessions || []);
    const [releaseConfirmation, setReleaseConfirmation] = useState(false);
    const [releaseAssessmentConfirmation, setReleaseAssessmentConfirmation] = useState(false);

    const [isMileStoneCompletedFlag, setIsMileStoneCompletedFlag] = useState(true);


    const isSessionCompleted = (index) => {

        let count = 0;
        timeLineData[index].milestones.forEach(item => {
            if (isMileStoneCompleted(item.id, timeLineData[index].index))
                count++;
        });

        if (count === timeLineData[index].milestones.length)
            return true
        else
            return false

    }

    const mergerTwoIds = (a, b) => {
        const BYTECOUNT = 24;
        let res = "";
        for (let i = 0; i < BYTECOUNT; i += 4) {
            res = ("000" + (parseInt(a.slice(-i - 4, -i || a.length), 16) ^ parseInt(b.slice(-i - 4, -i || b.length), 16)).toString(16)).slice(-4) + res;
        }
        return res;
    }

    const isMileStoneCompleted = (id, index) => {
        let count = 0;
        if (props.studentRoster.length !== 0) {
            if (props.studentRoster[0].sessions.length > 0 && props.studentRoster[0].sessions.length > index) {
                props.studentRoster.forEach(element => {
                    let temp = element.sessions[index].milestones.filter(x => x.id === id)
                    if (temp.length > 0 && temp[0].isCompleted)
                        count++;
                });
                if (count >= (props.studentRoster.length * 0.5))
                    return true;
                else
                    return false;
            }
            else
                return false;
        } else
            return false;

    }

    const releaseAssessmentAllClicked = () => {
        if (!props.sessions[props.currentSessionIndex - 1].isUnlocked) {
            setReleaseAssessmentConfirmation(true);
            return;
        }
        else {
            setReleaseConfirmation(true)
        }
    }

    const releaseAssessmentToAll = () => {

        setReleaseConfirmation(false);

        props.showLoader();
        if (props.studentRoster.length !== 0 && props.sessions[props.currentSessionIndex - 1].isUnlocked) {
            axios.patch("/studentRoster/releaseAssessmentAll/" + props.classId + "/" + props.currentSessionIndex, {}, {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    setReleaseMsg(true);
                    props.hitRefreshPage("FACILITATION", "FACILITATION");
                    props.getClassSection()
                })
                .catch(errror => {
                    props.hideLoader();
                })
        }
        else {
            props.hideLoader();
        }
    }

    const trackButton = (btn_name) => {
        props.amplitude.track("Clicked Class Details Button", { class_section_id: props.classId, page_section: "Facilitation", button_name: btn_name, session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex], subject: props.subject });
    }

    useEffect(() => {
        props.amplitude.track("Visited Class Details", { class_section_id: props.classId, page_section: "Facilitation", session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex] });
    }, []);
    return (
        <>
            <div className="row" style={{ backgroundColor: "#fff", borderRadius: "5px", marginBottom: "10px" }}>
                <div className="col-7" style={{ padding: "0px" }}>
                    <SessionMaterials courseIcon={props.courseIcon} subject={props.subject} classSection={props.classSection} sectionId={props.sectionId} courseName={props.courseName} amplitude={props.amplitude} standards={props.standards} sessionMaterials={props.sessionMaterials} otherSessionMaterials={props.otherSessionMaterials} materials={props.materials} assessment={props.assessment} sessionName={props.sessionName} classId={props.classId} currentSessionIndex={props.currentSessionIndex} sessionTypes={props.sessionTypes} />
                </div>
                <div className="col-5" style={{ marginTop: "20px", marginBottom: "20px", borderLeft: "1px solid #F2F2F9" }}>
                    <LaunchFacilitaion from="facilitation" classCode={props.classCode} strategy={auth.strategy} />
                </div>
            </div>
            <div className="row" style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
                <div className="col-12" style={{ padding: "15px 30px", marginBottom: "25px" }}>
                    <div className="row" style={{ marginBottom: "20px" }}>
                        <span className="SM-heading">Facilitation</span>
                    </div>
                    <div className="row row-eq-height" style={{ marginBottom: "20px" }}>
                        <div className="col-3 myProjectDiv" style={{ padding: "0px", borderRight: "1px solid #F2F2F9", marginRight: "40px" }}>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <span className="small-black-heading">{props.facilitationStaticData.myProject.title}</span>
                            </div>
                            <div className="row" style={{ marginBottom: "40px", paddingRight: "10px" }}>
                                <span className="medium-text">{props.facilitationStaticData.myProject.text}</span>
                            </div>
                            <div className="row" style={{ position: "absolute", bottom: "0" }}>
                                <button type="submit" className="saveChangesBtn" style={{ width: "auto", height: "auto", padding: "7px 20px" }} onClick={() => { trackButton("My Project"); setVisibleMyProject(true) }}>My Project
                                    <img alt="" src={rightArrow} style={{ width: "18px", height: "14px", verticalAlign: "sub", marginLeft: "10px" }} /></button>
                            </div>
                        </div>

                        <div className="col-3 exampleProjectDiv" style={{ position: "relative", padding: "0px", borderRight: "1px solid #F2F2F9", marginRight: "40px" }}>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <span className="small-black-heading">{props.facilitationStaticData.exProject.title}</span>
                            </div>
                            <div className="row" style={{ marginBottom: "40px", paddingRight: "10px" }}>
                                <span className="medium-text">{props.facilitationStaticData.exProject.text}</span>
                            </div>
                            <div className="row" style={{ position: "absolute", bottom: "0" }}>
                                <button type="submit" className="saveChangesBtn" style={{ width: "auto", height: "auto", padding: "7px 20px" }} onClick={() => { trackButton("Example Project"); setVisibleExProject(true) }}>Example Project
                                    <img alt="" src={rightArrow} style={{ width: "18px", height: "14px", verticalAlign: "sub", marginLeft: "10px" }} /></button>
                            </div>
                        </div>

                        <div className="col-3 releaseAssessmentDiv" style={{ position: "relative", padding: "0px", marginRight: "40px" }}>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <span className="small-black-heading">{props.facilitationStaticData.releaseAssessment.title}</span>
                            </div>
                            <div className="row" style={{ marginBottom: "40px", paddingRight: "10px" }}>
                                <span className="medium-text">{props.facilitationStaticData.releaseAssessment[props.sessionTypes[props.currentSessionIndex]]}</span>
                            </div>
                            <div className="row">
                                <button disabled={(props.IsSessionUnlocked == false) || (props.sessions && props.sessions[props.currentSessionIndex - 1].isAssessmentReleaseToAll) || props.studentRoster.length === 0 || props.assessment.assessmentQuestion.length === 0 || props.sessionTypes[props.currentSessionIndex] === "student"} type="submit" className="saveChangesBtn" style={{ width: "auto", height: "auto", padding: "7px 20px" }} onClick={() => { trackButton("Release Assessment"); setReleaseConfirmation(true) }}>Release Assessment
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "40px", marginBottom: "20px" }}>
                        <span className="SM-heading">Lesson Journey</span>
                    </div>
                    <div className="row" style={{ marginBottom: "30px" }}>
                        <div className="col-10" style={{ padding: "0px" }}>
                            <span className="medium-text">
                                {props.facilitationStaticData.lessonText}

                            </span>
                        </div>
                    </div>

                    <div className="row sessionJourney" style={{ marginBottom: "40px" }}>
                        <div className="col-12" style={{ borderTop: "1px solid #F2F2F9", padding: "0px", fontSize: "14px" }}>
                            <div className="row">

                                {timeLineData.length !== 0 && timeLineData.map((item, index) => {
                                    return <div key={item.index} className={`${item.name === props.sessionName ? "col-6 currentSession" : "col-md"}`} style={{ paddingBottom: '13px' }}>
                                        {
                                            item.name !== props.sessionName && <div className="sessionNameClass">
                                                {isSessionCompleted(index) ? <img style={{ position: "absolute", top: "-6px" }} alt="" src={completedSession} /> : <img style={{ position: "absolute", top: "-6px" }} alt="" src={notStartedSession} />}
                                                <br /><span>Session {item.index}</span></div>
                                        }
                                        {
                                            item.name === props.sessionName && <div className="row"  >
                                                {item.milestones.map((x) => {
                                                    return <div key={x.id} className="col-md milestoneNameClass" style={{ minHeight: "75px" }}>
                                                        {isMileStoneCompleted(x.id, index) ? <img style={{ position: "absolute", top: "-6px" }} alt="" src={completedSession} /> : <img style={{ position: "absolute", top: "-6px" }} alt="" src={notStartedMilestone} />}
                                                        <br /><span>{x.name}</span></div>
                                                })
                                                }
                                            </div>
                                        }
                                        {item.name === props.sessionName && <span className="currentSessionName" style={{ position: "absolute", width: "100%", textAlign: "center", left: "0" }}><img alt="" src={activeSessionIcon} /> <br />Session {item.index}</span>}
                                    </div>
                                })
                                }

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {props.entryPoints && visibleMyProject &&
                <IFrame
                    mode="normal"
                    courseName={props.courseName}
                    userDetails={{ userID: auth.userId, email: auth.email, username: auth.firstname }}
                    studentRosterID={mergerTwoIds(auth.userId, props.courseId)}
                    isFreezed={null}
                    sessionDetails={{ sessionName: props.sessionName, sessionIndex: props.currentSessionIndex }}
                    sessions={[]}
                    isVisible={visibleMyProject}
                    title="My Project"
                    setVisible={(value) => { setVisibleMyProject(value) }}
                    iframeURL={props.entryPoints.educatorSandboxEntry}
                    sessionTypes={props.sessionTypes}
                    subject={props.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={props.classId}
                    teacherId={props.teacherId} />
            }
            {props.entryPoints && visibleExProject &&
                <IFrame
                    mode="demo"
                    courseName={props.courseName}
                    userDetails={{ userID: auth.userId, email: auth.email, username: auth.firstname }}
                    studentRosterID={props.studentRosterID}
                    isFreezed={null}
                    sessionDetails={{ sessionName: props.sessionName, sessionIndex: props.currentSessionIndex }}
                    sessions={[]}
                    isVisible={visibleExProject}
                    title="Example Project"
                    setVisible={(value) => { setVisibleExProject(value) }}
                    iframeURL={props.entryPoints.demoProjectEntry}
                    sessionTypes={props.sessionTypes}
                    subject={props.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={props.classId}
                    teacherId={props.teacherId} />
            }


            <ConfirmationBox
                isVisible={releaseMsg}
                setVisible={(value) => { setReleaseMsg(value) }}
                confirmationHeader="The assessment is released for all students."
                confirmationText=""
                handleOK={() => { setReleaseMsg(false); props.hitRefreshPage("FACILITATION", "FACILITATION"); }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />

            <ConfirmationBox
                isVisible={releaseConfirmation}
                setVisible={(value) => { setReleaseConfirmation(value) }}
                confirmationHeader="Are you sure you want to release assessment to all students? This action cannot be reverted."
                confirmationText=""
                handleOK={() => { releaseAssessmentToAll() }}
                okLabel="CONFIRM"
                cancelLabel="Cancel"
                hideCancel={false} />

            <ConfirmationBox
                isVisible={releaseAssessmentConfirmation}
                setVisible={(value) => { setReleaseMsg(value) }}
                confirmationHeader="This action cannot be done as current session is not unlocked."
                confirmationText=""
                handleOK={() => { setReleaseAssessmentConfirmation(false); }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />
        </>
    )
}

export default React.memo(Facilitation);