import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Algebra = (props) => (
    <SvgIcon {...props} viewBox="0 0 20 20">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6893 15.6755C9.54071 15.6755 7.35737 15.5538 5.17198 15.3106C4.88777 15.2806 4.60493 15.2471 4.32107 15.2117V3.52048H2.33333V16.945C5.86954 17.4869 9.03704 17.6667 11.6893 17.6667C13.9905 17.6667 15.9043 17.5303 17.3332 17.3775V15.3723C15.9466 15.5292 14.0243 15.6755 11.6893 15.6755Z" fill="#009EE1" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.083 2.33334L11.0777 3.52027L11.47 5.17616L13.8581 4.61067L10.9786 9.23387L8.78978 6.95893L5.21028 12.2619V13.941C5.49381 13.9764 5.77665 14.0095 6.06119 14.0399L9.02184 9.65441L11.2665 11.9863L15.1963 5.6806L15.6881 7.56911L17.3333 7.14072L16.083 2.33334Z" fill="url(#paint0_linear)" />
        <defs>
            <linearGradient id="paint0_linear" x1="10.7604" y1="17.2991" x2="19.4599" y2="8.35033" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F94C4A" />
                <stop offset="0.242105" stopColor="#FF5C55" />
                <stop offset="0.735644" stopColor="#FFB850" />
                <stop offset="1" stopColor="#FEE04D" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

export default Algebra;
