export const SESSION_TYPE = {
    teacher: 'Teacher Facilitated',
    student: 'Student Self-Guided',
    tf: 'teacher',
    sg: "student"
};

export const SESSION_STATUS = {
    true: 'Unlocked',
    false: 'Locked',

};

export const DEFAULT_SESSIONS = {
    "1": SESSION_TYPE.tf,
    "2": SESSION_TYPE.tf,
    "3": SESSION_TYPE.tf,
    "4": SESSION_TYPE.tf,
    "5": SESSION_TYPE.tf
}

export const MONTH_LIST = [
    { name: "January", value: "January" },
    { name: "February", value: "February" },
    { name: "March", value: "March" },
    { name: "April", value: "April" },
    { name: "May", value: "May" },
    { name: "June", value: "June" },
    { name: "July", value: "July" },
    { name: "August", value: "August" },
    { name: "September", value: "September" },
    { name: "October", value: "October" },
    { name: "November", value: "November" },
    { name: "December", value: "December" }
]

export const DEFAULT_VALUES = {
    pageLength: 10,
    classListCount: 5
}