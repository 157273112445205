import React, { useContext, Children, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import SparkLoader from "./assets/images/spark_loader.gif";
import { withRouter } from "react-router";

import TakeTour from './components/poc/takeTour/takeTour'
import ImportExcel from "./components/poc/ImportExcel/ImportExcel"
import LoginWithGoogle from './components/poc/login/login';
import WelcomePage from './components/welcomePage/WelcomePage';
import ForgotPassword from './components/forgotPassword/ForgotPassword'
import SignIn from './components/signIn/SignIn';
import Header from './shared/components/header/Header';
import Footer from './shared/components/footer/Footer';

import GetStarted from './components/getStarted/GetStarted';
import ChangePassword from './components/changePassword/ChangePassword';
import LandingPage from './components/landingPage/LandingPage';
import ProfilePage from './components/profilePage/ProfilePage';
import TeacherDashboard from './components/teacherDashboard/TeacherDashboard';

import StudentDashboard from './components/studentDashboard/StudentDashboard';
import CSVTemplate from './components/CSVTemplate/CSVTemplate';

import DownloadFile from './components/download/downloadFile';
import DeleteTestData from './components/admin/DeleteTestData';
import AdminLayout from './components/admin/Index';
import AdminDashboard from './components/admin/AdminDashboard';

import TeacherLayout from './components/teacherLayout/TeacherLayout';
import Resources from './components/resources/Resources';


import './App.css';

import RedirectURL from "./components/poc/googleRedirected/redirectedURL";
import CreateAccount from './components/createAccount/CreateAccount';
import UserContext from "./shared/context/UserContext";
import { useAuth } from './shared/hooks/auth-hook';
import AddClass from "./components/addClass/AddClass";
import ClassDetails from "./components/classDetails/ClassDetails";
import StudentClass from "./components/studentClassDetails/StudentClassDetails";

import ClassSectionDetails from "./components/classDetails/ClassSectionDetails";

import $ from "jquery";
import { SSORedirection } from './components/ssoRedirection/SSORedirection';
import ViewProject from './components/viewProject/ViewProject';
import MobileWelcome from './components/mobileWelcome/MobileWelcome';
import ImportCourse from './components/importCourseXml';
import ResetPassword from './components/resetPassword/resetPassword';
import ClassReport from './components/classReport/ClassReport';
import { createBrowserHistory } from 'history';
import * as project from './shared/hooks/analytics';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import config from './assets/config/Config.json';

project.init();

const Main = withRouter(({ location }) => {
  const [showFooter, setShowFooter] = useState(true);
  const [showSpinner, setShowSpinner] = useState(false);

  const childRef = useRef();
  const history = createBrowserHistory();

  const userId = useAuth().userId;
  const useContext = useAuth();

  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData && userData.userId) {
    project.setUserId(userData.userId);
    const stateDistrict = JSON.parse(localStorage.getItem('stateDistrict'));
    project.setUserContext({ ...useContext, ...stateDistrict }, userData, stateDistrict);

  }

  const toggleLoader = item => {
    item == true ? showLoader() : hideLoader();
  }
  const showLoader = _ => {
    $(".bodyPart").hide();
    $('.loaderContainer').removeClass('loader--hide');
  }

  const hideLoader = _ => {
    $(".bodyPart").show();
    $('.loaderContainer').addClass('loader--hide');
  }

  const showLoaderText = _ => {
    $(".loaderText").show();
  }

  const hideLoaderText = _ => {
    $(".loaderText").hide();
  }

  const showLoaderCleverText = _ => {
    $(".loaderCleverText").show();
  }

  const hideLoaderCleverText = _ => {
    $(".loaderCleverText").hide();
  }

  const startTour = () => {
    childRef.current && childRef.current.getTourHelp();
    console.log(childRef);
  }



  function PrivateRoute({ children, ...rest }) {
    setShowFooter(true);
    let query = new URLSearchParams(useLocation().search);
    let token = JSON.parse(localStorage.getItem('userData'));
    let email = "";
    if (token !== null) {
      email = token.email;
      token = token.token;
    }
    else {
      token = query.get("token");
    }

    if (rest.path == "/classreport" && email.indexOf('adeptus.in') == -1) {
      token = null;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          (token !== undefined && token !== null && token !== "") ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  return (

    <div>
      <div className="loaderContainer loader--hide">
        <div className="loader">
          <div><img alt="" src={SparkLoader} /></div>
          <div className="loaderTextContainer">
            <p className="loaderText" style={{ "display": "none" }}>Please wait while we fetch your class section details from Clever.</p>
            <p className="loaderCleverText" style={{ "display": "none" }}>Please wait while we fetch the latest student roster from Clever.</p>
          </div>
        </div>
      </div>
      <div className="bodyPart">
        {
          location.pathname.toLowerCase() !== "/mobilewelcome" && location.pathname !== '/' && location.pathname !== '/csvtemplate' && location.pathname.toLowerCase() !== '/signin' && (location.pathname.indexOf("changePassword") < 0) && location.pathname.toLowerCase() !== '/getstarted' && location.pathname.toLowerCase() !== '/forgotpassword' && location.pathname.toLowerCase() !== '/createaccount' && location.pathname.toLowerCase() !== '/welcomepage' && location.pathname.indexOf('viewproject') == -1 && location.pathname.toLowerCase() !== '/redirection' && <Header amplitude={project} />
        }

        <Route exact path="/">
          <WelcomePage hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/taketour">
          <TakeTour hideLoader={hideLoader} showLoader={showLoader} />
        </Route>
        <Route exact path="/getStarted">
          <GetStarted hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/signin">
          <SignIn hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/forgotPassword">
          <ForgotPassword hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/csvtemplate" component={CSVTemplate} />
        <PrivateRoute exact path="/welcomepage">
          <LandingPage hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </PrivateRoute>
        <PrivateRoute exact path="/importcourse">
          <ImportCourse />
        </PrivateRoute>
        <Route exact path="/download">
          <DownloadFile />
        </Route>

        <PrivateRoute exact path="/classreport">
          <ClassReport hideLoader={hideLoader} showLoader={showLoader} />
        </PrivateRoute>

        <Route path="/import" exact >
          <ImportExcel />
        </Route>
        <Route path="/googleRedirect" >
          <RedirectURL />
        </Route>
        <Route path="/loginWithGoogle" component={LoginWithGoogle} />
        <Route exact path="/createaccount">
          <CreateAccount hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/redirection">
          <SSORedirection hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <PrivateRoute exact path="/studentdashboard">
          <StudentDashboard ref={childRef} hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </PrivateRoute>

        <PrivateRoute exact path="/studentClass/:id">
          <StudentClass ref={childRef} hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </PrivateRoute>
        <PrivateRoute exact path="/resetpassword">
          <ResetPassword hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </PrivateRoute>
        <Route exact path="/viewproject/:shareId">
          <ViewProject hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>
        <Route exact path="/mobileWelcome">
          <MobileWelcome hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
        </Route>


        <AdminLayout exact path="/admin" component={AdminDashboard} />
        <AdminLayout exact path="/admin/classreport" component={ClassReport} />
        <AdminLayout exact path="/admin/delete-test-data" component={DeleteTestData} />

        <PrivateRoute exact path="/profile">
          {userData && userData.roles.map(item => item.name).includes("Teacher") ?
            < TeacherLayout startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)}>
              <ProfilePage ref={childRef} hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
            </TeacherLayout> :
            <div class="minscreenheight px-5 pt-4">
              <ProfilePage ref={childRef} showSpinner={toggleLoader} amplitude={project} />
            </div>
          }

        </PrivateRoute>

        <PrivateRoute exact path="/teacherdashboard">
          <TeacherLayout startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)} expanded={true} >
            <TeacherDashboard ref={childRef} hideLoader={hideLoader} showLoader={showLoader} hideLoaderText={hideLoaderText} showLoaderText={showLoaderText} amplitude={project} />
          </TeacherLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/resources" >
          <TeacherLayout startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)}>
            <Resources ref={childRef} startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)} />
          </TeacherLayout>
        </PrivateRoute>

        <PrivateRoute exact path="/addClass">
          <TeacherLayout startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)}>
            <AddClass hideLoader={hideLoader} showLoader={showLoader} amplitude={project} />
          </TeacherLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/classdashboard/:id">
          <TeacherLayout startTour={startTour} amplitude={project} showFooter={(item) => setShowFooter(item)} isActive={true}>
            <ClassSectionDetails ref={childRef} hideLoader={hideLoader} showLoader={showLoader} hideLoaderCleverText={hideLoaderCleverText} showLoaderCleverText={showLoaderCleverText} amplitude={project} />
          </TeacherLayout>
        </PrivateRoute>

        {location.pathname.indexOf('viewproject') == -1 && <Footer startTour={startTour} amplitude={project} showFooter={showFooter} />}
      </div>
    </div >
  )
})

const App = () => {
  const { token, login, logout, userId, email, firstName, lastName, role, strategy, clearUserData, updateUserData } = useAuth();

  return (
    <Router>
      <Switch>
        <UserContext.Provider value={{
          isLoggedIn: !!token,
          token: token,
          userId: userId,
          email: email,
          firstname: firstName,
          lastname: lastName,
          role: role,
          strategy: strategy,
          login: login,
          logout: logout,
          clearUserData: clearUserData,
          updateUserData
        }}>
          <Main />
        </UserContext.Provider>
        <Redirect to="/" />
      </Switch>
    </Router >
  )
}

export default React.memo(App);
