import React, { useEffect, useState } from "react";
import axios from "axios";
import Step5 from '../../assets/images/steps_05.png';
import Step2 from '../../assets/images/twosteps_02.png';
import "./AddClass.css";
import { useForm } from "react-hook-form";
import $ from "jquery";
import { useHistory } from "react-router-dom";

const FifthCleverClassStep = props => {
    let history = useHistory();
    const { register, getValues, handleSubmit, errors } = useForm({ mode: "onBlur" });
    const [sections, setSection] = useState([]);
    const [IsImportClassRosterList, setImportClassRosterList] = useState(true);
    const [IsStudentRosterList, setStudentRosterList] = useState(false);
    const [cleverStudents, setCleverStudents] = useState([]);
    const [selectedCleverSectionId, setCleverSectionId] = useState();
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        props.showLoader();
        axios.get("/clever/getsections")
            .then(res => {
                setSection(res.data);
                props.hideLoader();
            })
    }, []);

    const onSubmit = data => {
        props.showLoader();
        setCleverSectionId(data.sections);
        axios.get("/clever/getstudentofsections/" + data.sections).then(res => {
            props.hideLoader();
            setStudentRosterList(true);
            setImportClassRosterList(false);
            setCleverStudents(res.data);
            props.hideLoader();
        })
    }

    const continueHandler = () => {
        if (cleverStudents.length !== 0) {
            let studentArr = [];
            cleverStudents.forEach(ele => {
                studentArr.push(
                    {
                        email: ele.data.email,
                        firstname: ele.data.name.first,
                        lastname: ele.data.name.last,
                        sis_user_id: ele.data.sis_id,
                        sis_section_id: selectedCleverSectionId,
                        district: ele.data.district,
                        sis_course_id: sections.course
                    }
                );
            })
            props.handleClassStudents(studentArr);
        }
        else {
            setIsError(true);
        }
    }

    const backHandler = () => {
        props.backStep(4);
    }

    const skipHandler = () => {
        props.backStep(7);
    }

    const BackToTeacherDashboard = () => {
        history.push("/classdashboard/" + props.classDetails.classSectionId);
    }

    const validateSection = _ => {
        const values = getValues();
        if (values.sections !== '') {
            return true
        }
        else {

            return false;
        }
    };

    const clverImport = () => {
        props.backStep(4);
    }

    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Import class list" });
    },[])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col manualImportForm" >
                        {IsImportClassRosterList == true && <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                {props.classDetails === null && <img src={Step5} />}
                                {props.classDetails !== null && <img src={Step2} />}
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">IMPORT CLASS LIST</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px", marginBottom: "30px" }}>
                                Select the class to import roster for.
                            </div>
                            {sections.length > 0 && <div className="col-12" style={{ textAlign: "center" }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="importClassListCard">
                                        <div className="row">
                                            {sections.map((item, index) => (
                                                <div key={index} className="col-12" style={{ marginBottom: "10px" }}>
                                                    <label className="radio-container">{item.data.name}
                                                        <input type="radio" value={item.data.id} name={`sections`} ref={register({ validate: validateSection })} />
                                                        <span className="sectionCheckmark"></span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        {errors.sections && errors.sections.type === 'validate' && <div><p style={{ position: "relative", bottom: "0" }}>Please select class to import roster</p></div>}
                                        <div className={`form-group btn-group ${sections.length <= 2 ? "margin-top-50" : "margin-top-15"}`}>
                                            <button type="submit" className="btn btn-primary addBtn" style={{ width: "152px" }}>Import Roster</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            }
                            {
                                sections.length == 0 && <div className="col-12" style={{ textAlign: "center", fontSize: "14px", color: "#000" }}>
                                    No class to import roster.
                                </div>
                            }
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center", marginTop: "28px" }}>
                                {props.classDetails === null && <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>}
                                <button type="button" className="curve-fill-btn" id="continue" style={{ color: "#9F9FAF", backgroundColor: "#F2F2F9", pointerEvents: "none" }}>FINISH</button>
                                {props.classDetails === null && <span className="skipBtn" onClick={skipHandler} >Skip Step</span>}
                                {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                            </div>
                        </div>
                        }
                        {IsStudentRosterList == true && <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                {props.classDetails === null && <img src={Step5} />}
                                {props.classDetails !== null && <img src={Step2} />}
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">IMPORT CLASS LIST</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px", marginBottom: "30px" }}>
                                Here is the class list you’re importing. If it is the correct list, click Finish.
                            </div>
                            <div className="col-12" style={{ textAlign: "center" }}>
                                <table style={{ margin: "auto" }}>
                                    <tbody>
                                        {cleverStudents.map((item, index) => {
                                            return <tr key={`${index}`} style={{ borderBottom: "1px solid #F2F2F9" }}>
                                                <th className="studentDetails" style={{ padding: "15px", paddingRight: "30px" }}>
                                                    {item.data.name.last}, {item.data.name.first}
                                                </th>
                                                <th className="studentDetails email" style={{ padding: "15px", paddingRight: "50px" }}>
                                                    {`${item.data.name.last.toLowerCase()}${item.data.name.first.toLowerCase()}@test.com`}
                                                </th>
                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {isError && cleverStudents.length === 0 && <div className="col-12" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                < span style={{ color: "red" }}>Data not found.</span>
                            </div>}
                            <div className="col-12" style={{ textAlign: "center", marginTop: "28px" }}>
                                Wrong list? Click here to <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={clverImport}>import again</span>.
                            </div>
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center", marginTop: "28px" }}>
                                {props.classDetails === null && <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>}
                                <button type="button" className="curve-fill-btn" id="continue" onClick={continueHandler} >FINISH</button>
                                {props.classDetails === null && <span className="skipBtn" onClick={skipHandler} >Skip Step</span>}
                                {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(FifthCleverClassStep);