import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom"

import axios from 'axios'
import UserContext from "../../shared/context/UserContext";

import ExternalSSO from "../../shared/components/externalSSO/ExternalSSO";

import "./GetStarted.css";

import logo from '../../assets/images/spark-logo-corored.svg'

function GetStarted(props) {
    const auth = useContext(UserContext);
    let history = useHistory();
    const [GetStartedMsg, setGetStartedMsg] = useState();
    const [GetStarted, setGetStarted] = useState();
    useEffect(() => {
        props.amplitude.track("Visited Get Started Page", { page_name: "" });

        props.showLoader();
        axios.get("/staticContent/GetStarted")
            .then(res => {
                props.hideLoader();
                setGetStarted(res.data.Data.header)
                setGetStartedMsg(res.data.Data.subHeader)
            })
    }, []);


    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }

    return (
        <>
            <div className="minscreenheightOut" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)", paddingBottom: "50px" }}>
                <div className="row header margin0">
                    <div className="col-4">
                        <img src={logo} alt="spark" onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="getStartedBox">
                    <div className="row justify-content-center getStartedMsgBox">
                        <div className="col-12">
                            <span className="getStartedMsg">{GetStarted}</span>
                        </div>
                        <div className="col-12" style={{ marginTop: "20px", paddingLeft: "60px", paddingRight: "60px" }}>
                            <p className="getStartedDesc" dangerouslySetInnerHTML={{
                                __html: GetStartedMsg
                            }}>
                            </p>
                        </div>
                    </div>

                    {/*<div className="row justify-content-center ssoMargin" >*/}
                    {/*    <div className="col-12" >*/}
                    {/*        <ExternalSSO />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row justify-content-center" style={{ margin: "35px auto 20px auto" }}>
                        <div className="col-12">
                            <span className="signupLabel">
                                {/*Or <Link style={{ color: "#4C4B5E" }} to="/createaccount">sign up using your email</Link>*/}
                                <Link style={{ color: "#4C4B5E" }} to="/createaccount">Sign up using your email</Link>
                            </span>
                        </div>
                    </div>

                    <div className="row" style={{ margin: "0px 30px 0px 30px" }}>
                        <div className="col-12 border-line"></div>
                    </div>
                    <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                        <div className="col-12">
                            <span className="alreadyAccMsg">Already have an account? <Link className="underlineLabel" style={{ color: "#4C4B5E" }} to="/signin">Sign In here.</Link> </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default (GetStarted);