import React, { useState, useEffect, forwardRef, useRef, useContext, useImperativeHandle } from "react"
import { useParams } from "react-router-dom";
import axios from "axios";
import { DEFAULT_SESSIONS } from "../constants/CommonConstants";

import Tour from "../../shared/components/tour/Tour";
import UserContext from "../../shared/context/UserContext";
import TabPanel from "../commonComponents/TabPanel";
import ClassDetailsHeader from "./ClassDetailsHeader";
import Dashboard from "./Dashboard";
import Assessment from "./Assesments";
import Projects from "./Projects";
import Gradebook from "./Gradebook";
import ConfirmationBox from '../../shared/components/confirmationBox/ConfirmationBox';

import config from '../../assets/config/Config.json';

const ClassSectionDetails = forwardRef((props, ref) => {
    const param = useParams();
    const childRef = useRef();
    const auth = useContext(UserContext);
    const [sessionType, setSessionType] = useState(DEFAULT_SESSIONS);
    const [classDetails, setClassDetails] = useState({});
    const [studentsList, setStudentsList] = useState({});
    const [classId, setClassId] = useState(param.id);
    const [activeTab, setActiveTab] = useState("DASHBOARD");
    const [currentSessionId, setCurrentSessionId] = useState(1);
    const [currentSessionDetails, setCurrentSessionDetails] = useState(null);
    const [isSessionUnlocked, setIsSessionUnlocked] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [classSessions, setClassSessions] = useState([]);
    const [district, setDistrict] = useState();
    const [mode, setMode] = useState();
    const [updateRoster, setUpdateRoster] = useState();
    const [reloadCount, setReloadCount] = useState(0);

    const [showWarning, setShowWarning] = useState(false);
    const [boxMessage, setBoxMessage] = useState('');
    const [updatedData, setUpdatedData] = useState({});

    const [IsTourStart, setTourStart] = useState(false);
    const [showTour, setShowTour] = useState(false);


    const tourSteps = {
        DASHBOARD: [
            {
                target: ".hiddenObject",
                content: (<div>Welcome to you Class detail page! On this page you can facilitate your class, see your students' progress in real time, check their assessments results, and grade their work.
                    <br /><br /><strong>Let’s take a look around. </strong></div>),
                disableBeacon: true,
                placement: "center"
            },
            {
                target: ".session-tooltip",
                content: (
                    <div>
                        <ul style={{ paddingLeft: "10px" }}>
                            <li>Use the <strong>Session</strong> drop-down to toggle between sessions.</li>
                            <li>The second drop-down will indicate the <strong>Modality</strong> of the session. You can use it to change how a session is run. Select Teacher Facilitated for synchronous live or online learning, or Student Self-Guided for asynchronous independent learning.</li>
                            <li>The last one is the <strong>Status</strong> of the session. Finally, make sure the Status drop-down is unlocked to give students full access to the session.</li>
                        </ul>
                    </div>
                ),
                disableBeacon: true,
                placement: "right-start"
            },
            {
                target: ".example-project-tooltip",
                content: (<div>See the <strong>Examplar Project</strong> to help you understand what students will work on and produce throughout their class. You can also view the class as a student to see what experience they are having.</div>),
                placement: "right-start"
            },
            {
                target: ".materials-tooltip",
                content: (<div>Everything you need to prepare for and teach a session is located in the <strong>Lesson Plan Materials</strong> section. Tow download copies, click the respective individual documents or select <strong>Download All.</strong></div>),
                placement: "left-start"
            },
            {
                target: ".add-student-tooltip",
                content: (<div>This is the unique code for the class. Provide it to students to enroll or you can add them manually by selecting the <strong>Add a Student</strong> button</div>),
                placement: "left-start"
            },
            {
                target: ".student-dashboard-list",
                content: "The Class Progress table will show you the progress of your students in real time as they complete tasks and milestones for each session. Click on the session number to move between sessions. The table also displays overall class progress. Use the arrows to see more tasks and milestones if needed.",
                placement: "top"
            },
            {
                target: ".task-overlay-tooltip",
                content: "Hover your mouse over each task and milestone to see more information. ",
                placement: "top"
            },
            {
                target: ".seeAllBtn",
                content: (<div>To view the entire class list, click <strong>See All</strong>. Click <strong>See Less</strong> to hide the list after viewing it.</div>),
                placement: "top"
            },
            {
                target: ".edit-student-overlay",
                content: "To edit a student's email address and name, remove a student from the course, or reset a password, click the student name .",
                placement: "top"
            },

        ],
        ASSESSMENTS: [
            {
                target: ".hiddenObject",
                content: (<div>On the Assessments Page you will see students' performance on the formative assessments.
                    <br /><br /><strong>Let’s take a look around. </strong></div>),
                disableBeacon: true,
                placement: "center"
            },
            {
                target: ".session-tooltip",
                content: (
                    <div>
                        Use the <strong>Session</strong> drop-down to toggle between sessions.
                    </div>
                ),
                disableBeacon: true,
                placement: "right-start"
            },
            {
                target: ".assesment-questions",
                content: "The list of assessment questions will show you the percent of correct and incorrect answers for each question.",
                placement: "top"
            },
            {
                target: ".student-roster-list",
                content: "This table shows in detail how your students answered each question. You can click on the session number to move between sessions.",
                placement: "top"
            },
        ],
        PROJECTS: [
            {
                target: ".hiddenObject",
                content: (<div>Welcome to your Projects. You can view and assess your students' submitted projects.
                    <br /><br /><strong>Let’s take a look around. </strong></div>),
                disableBeacon: true,
                placement: "center"
            },
            {
                target: ".statusCol",
                content: (
                    <div>
                        Under <strong>Status</strong>, you can view which students have submitted their final projects for review. Click the up/down arrows to sort by submission status.
                    </div>
                ),
                disableBeacon: true,
                placement: "right-start"
            },
            {
                target: ".unfreezeProjectCol",
                content: (
                    <div>
                        Students can’t edit their projects after they have submitted them for review. If a student mistakenly submits their project, you can send it back to them by sliding the <strong>Unfreeze Project</strong> toggle to Yes.
                    </div>
                ),
                disableBeacon: true,
                placement: "right-start"
            },
            {
                target: ".scoreCol",
                content: (
                    <div>
                        Under<strong> Score</strong>, you can view the calculated raw numeric scores of the students' final projects based on the scoring rubric. Click the up/down arrows to sort projects by score.
                    </div>
                ),
                disableBeacon: true,
                placement: "right-start"
            },
            {
                target: ".achievedCol",
                content: (
                    <div>
                        You can also see what score level each student has achieved. Click the up/down arrows to sort the projects by level.
                    </div>
                ),
                disableBeacon: true,
                placement: "left-start"
            },
            {
                target: ".projectCol",
                content: (
                    <div>
                        When you’re ready to score a student’s project, click the button <strong>Grade Project.</strong>
                        <br />
                        Click the up/down arrows to sort by projects ready for grading.
                    </div>
                ),
                disableBeacon: true,
                placement: "left-start"
            },
            {
                target: ".submitted-project-tooltip",
                content: (
                    <div>
                        <ul style={{ paddingLeft: "10px" }}>
                            <li>To view the student’s submitted project, click Go to student project.</li>
                            <li>You can scroll through each rubric criteria by clicking the tabs.</li>
                            <li>The level score for each criteria is highlighted.</li>
                        </ul>
                    </div>
                ),
                disableBeacon: true,
                placement: "top"
            },

        ],
        GRADEBOOK: [
            {
                target: ".hiddenObject",
                content: (<div>
                    You will find each student's graded formative assessment and their final project grade in the <strong>Gradebook.</strong>
                    <br />You can export their grades from this page.
                </div>),
                disableBeacon: true,
                placement: "center"
            }]
    };

    const getHelp = () => {
        childRef.current.getTour();
    }

    useEffect(() => {
        if (IsTourStart == true) {
            childRef.current.getTour();
            setTourStart(false);
        }
    }, [showTour, IsTourStart])

    useImperativeHandle(ref, () => ({

        getTourHelp() {
            setTourStart(true);
        }

    }));

    useEffect(() => {
        let flag = false;
        axios.get("/user/getusercontextualtourdata/" + auth.userId).then(res => {
            if (res.data.contextualTour.length > 0) {
                let data = res.data.contextualTour.filter(val => val.pageName == activeTab);
                if (data.length > 0) {
                    flag = false;
                }
                else {
                    flag = true;
                    axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': activeTab } });
                }
            }
            else {
                flag = true;
                axios.put("/user/updateusercontextualtourdata", { _id: auth.userId, contextualTour: { 'pageName': activeTab } });
            }

            flag && getHelp();
        });
    }, [classId, activeTab])



    useEffect(() => {
        getClassSection(classId);
        getStudentRoster(classId);
        loadDefaultData();
    }, [classId]);

    useEffect(() => {
        startWebsocket();
    }, [auth.userId]);

    useEffect(() => {
        let csDetails = classSessions.filter(item => parseInt(item.index) === parseInt(currentSessionId))[0];
        setIsSessionUnlocked(csDetails && csDetails.isUnlocked);
        setCurrentSessionDetails(csDetails);
    }, [currentSessionId]);

    useEffect(() => {

        setIsSessionUnlocked(currentSessionDetails && currentSessionDetails.isUnlocked);
    }, [currentSessionDetails])

    useEffect(() => {
        if (studentsList.rosterData && studentsList.rosterData.length > 0) {
            let csData = { ...studentsList };
            let rosterList = Array.from(csData.rosterData);

            let objIndex = rosterList.findIndex((obj => obj._id === updateRoster._id));

            rosterList[objIndex] = updateRoster;
            let studentRoster = { rosterData: rosterList, studentUserData: studentsList.studentUserData };
            setStudentsList(studentRoster);

        }
    }, [updateRoster])

    const startWebsocket = () => {
        const URL = process.env.REACT_APP_WEBSOCKETURL.replace('http', 'ws');

        let webSocketClient = new WebSocket(URL);

        webSocketClient.onopen = function () {

            webSocketClient.send(JSON.stringify(
                [{
                    request: 'SUBSCRIBE',
                    channel: "studentRoster" + auth.userId,
                    message: 'assessmentSubmitted'
                },
                {
                    request: 'SUBSCRIBE',
                    channel: "classSection" + auth.userId,
                    message: 'percentage'
                },
                ]));

            webSocketClient.onmessage = function (event) {
                let data = JSON.parse(event.data);

                data.channel == 'studentRoster' + auth.userId && setUpdateRoster(data.data);
                data.channel == 'classSection' + auth.userId && setUpdatedData(data.data);
            };
        };

        webSocketClient.onclose = function () {
            webSocketClient = null
            setTimeout(startWebsocket, 5000)
        }

    }

    useEffect(() => {
        if (classDetails._id === updatedData._id) {
            let csData = { ...classDetails };
            csData.courseProgress = updatedData.courseProgress
            setClassDetails(csData);
        }
    }, [updatedData]);

    const loadDefaultData = () => {
        const districtID = localStorage.getItem("district");
        axios.get("/district/get-district-by-id/" + districtID)
            .then(res => {
                setDistrict(res.data);
            })
    }

    const getClassSection = (id) => {
        props.showSpinner(true);
        axios.get("/classSection/getbyid/" + id)
            .then(res => {
                const data = res.data[0];
                setClassDetails(data);
                setSessionType(data.sessionType);
                setMode(data.mode);

                if (data.sessions.length > 0) {
                    setClassSessions(data.sessions);

                    let list = [];
                    data.sessions.forEach(element => {
                        list.push({
                            name: element.name,
                            value: element.index.toString()
                        });
                    });

                    setSessionList(list);
                    data.currentSession && reloadCount === 0 && setCurrentSessionId(data.currentSession);
                    let csDetails = data.sessions.filter(item => item.index === parseInt(currentSessionId))[0];
                    setCurrentSessionDetails(csDetails);
                    setReloadCount(reloadCount + 1);
                    getCurrentSession(data);
                }
                props.showSpinner(false);
            }).catch(error => {
                props.showSpinner(false);
                console.log(error);
            });
    }
    const getCurrentSession = (classDetails) => {
        const types = Object.values(classDetails.sessionType);
        let updateCurrentSession = types.some(item => item === "teacher");

        if (!updateCurrentSession) {
            setCurrentSessionId(1);
        }

    }

    const getStudentRoster = (id) => {
        axios.post("/studentRoster/getByClassSectionId", { classSectionId: id })
            .then(res => {
                setStudentsList({
                    rosterData: res.data.result.rosterData,
                    studentUserData: res.data.result.studentUserData
                });
            })
    }


    const updateSessionType = (value) => {
        props.showLoader();
        sessionType[currentSessionId] = value;
        setSessionType(sessionType);
        props.amplitude.track("Clicked Class Details Button", { button_name: "Change Session Type", session_id: currentSessionId, session_type: classDetails.sessionType[currentSessionId], subject: classDetails.subject, class_section_id: classId });
        if (classId) {
            axios.patch("/classSection/updateclasssectionsessiontype/" + classId,
                {
                    sessionType: sessionType
                })
                .then(response => {
                    if (value === "student" && parseInt(currentSessionId) - classDetails.currentSession === 1) {
                        unLockSession();
                    } else {
                        getClassSection(classId);
                    }

                    props.hideLoader();

                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
    }

    const unLockSession = (value) => {
        props.amplitude.track("Clicked Class Details Button", { button_name: "Unlock Session", session_id: currentSessionId, session_type: classDetails.sessionType[currentSessionId], subject: classDetails.subject, class_section_id: classId });

        if (isSessionUnlocked == false) {
            if (parseInt(currentSessionId) - classDetails.currentSession === 1) {
                props.showSpinner(true);
                axios.put("/studentRoster/addsession", { classSectionId: classId, sessionIndex: parseInt(currentSessionId), flag: !isSessionUnlocked }).then(res => {
                    getStudentRoster(classId);
                    getClassSection(classId);
                });

                axios.patch("/classSection/updateclasssectiondate/" + classId,
                    { currentSession: currentSessionId })
                    .then(response => { })
                    .catch(err => {
                        console.log("failed to add class section :", err);
                    })
            }
            else {
                setBoxMessage('This session can not be unlocked as the previous session is not unlocked yet.');
                setShowWarning(true);
            }
        }
        else {
            setBoxMessage('The session once unlocked can not be locked again. ');
            setShowWarning(true);

        }
    }



    return (
        <>
            <div className="hiddenObject"></div>
            <div className="class-details-wrapper">
                <ClassDetailsHeader
                    classDetails={classDetails}
                    setTab={setActiveTab}
                    currentSessionId={currentSessionId}
                    classId={classId}
                    studentsList={studentsList}
                    amplitude={props.amplitude}
                    disableExport={studentsList.rosterData && studentsList.rosterData.length === 0} />

                <TabPanel value={activeTab} id="DASHBOARD">
                    <Dashboard
                        classDetails={classDetails}
                        currentSessionDetails={currentSessionDetails}
                        currentSessionId={currentSessionId}
                        changeSessionId={setCurrentSessionId}
                        sessionType={sessionType}
                        changeSessionType={updateSessionType}
                        sessionList={sessionList}
                        isSessionUnlocked={isSessionUnlocked}
                        unLockSession={unLockSession}
                        amplitude={props.amplitude}
                        auth={auth}
                        districtName={district && district.name}
                        hideLoaderCleverText={props.hideLoaderCleverText}
                        showLoaderCleverText={props.showLoaderCleverText}
                        studentsList={studentsList}
                        setStudentsList={setStudentsList}
                        IsTourStart={IsTourStart}
                    />
                    <Tour ref={childRef} tourSteps={tourSteps[activeTab]} amplitude={props.amplitude} />
                </TabPanel>
                <TabPanel value={activeTab} id="ASSESSMENTS">
                    <Assessment classDetails={classDetails} dropdownSessionList={sessionList} sessionList={classSessions} selectedSession={currentSessionDetails} selectedsessionItem={currentSessionId} setSelectedsessionItem={setCurrentSessionId} students={studentsList} setStudents={setStudentsList} />
                    <Tour ref={childRef} tourSteps={tourSteps[activeTab]} amplitude={props.amplitude} />
                </TabPanel>
                <TabPanel value={activeTab} id="PROJECTS">
                    <Projects teacherId={classDetails.teacher} courseName={classDetails.courseName} studentRoster={studentsList.rosterData} classId={classId} currentSessionIndex={currentSessionId} sessionName={currentSessionDetails && currentSessionDetails.name} hideLoader={props.hideLoader} showLoader={props.showLoader} hitRefreshPage={setActiveTab} mode={mode} sessionTypes={classDetails.sessionType} subject={classDetails.subject} districtName={district && district.name} amplitude={props.amplitude} />
                    <Tour ref={childRef} tourSteps={tourSteps[activeTab]} amplitude={props.amplitude} />
                </TabPanel>
                <TabPanel value={activeTab} id="GRADEBOOK">
                    <Gradebook teacherId={classDetails.teacher} sessions={classDetails.sessions} studentRoster={studentsList.rosterData} hideLoader={props.hideLoader} showLoader={props.showLoader} currentSessionIndex={currentSessionId} hitRefreshPage={setActiveTab} amplitude={props.amplitude} sessionTypes={classDetails.sessionType} classId={classId} />
                    <Tour ref={childRef} tourSteps={tourSteps[activeTab]} amplitude={props.amplitude} />
                </TabPanel>
            </div>

            <ConfirmationBox
                isVisible={showWarning}
                setVisible={(value) => { setShowWarning(value) }}
                confirmationHeader={boxMessage}
                confirmationText=""
                handleOK={() => { setShowWarning(false) }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />


        </>
    )
})

export default ClassSectionDetails;
