import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";

const Login = () => {
    let history = useHistory();
    let SSOURL = process.env.REACT_APP_APIURL + "/user/auth/google";
    function handleSignInSubmit(event) {
        event.preventDefault(); window.open(process.env.REACT_APP_APIURL + "/users/auth/google", "_self");
    }


    return (
        <div className="main">
            <a href={SSOURL}>Sign In with Google</a>
        </div>
    );
};
export default Login;