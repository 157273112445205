import React, { useState, useEffect, useRef } from "react";

import { SESSION_TYPE } from "../constants/CommonConstants";
import RadioButton from "../customInputs/RadioButton"
import "./ChangeSession.css";
import Popover from 'react-bootstrap/Popover'
import teacherIcon from "../../assets/images/icons/teacher-f.svg"
import studentIcon from "../../assets/images/icons/student-f.svg"
import Overlay from 'react-bootstrap/Overlay'
import Button from "react-bootstrap/Button";

const ChangeSession = props => {
    const { type } = props;
    const [value, setValue] = useState();
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [init, setInit] = useState(false);
    const ref = useRef(null);

    const saveChanges = () => {
        setShow(false);
        props.updateSessionType(value);
    }

    const changeType = (e) => {
        setValue(e.target.value);
    }

    useEffect(() => {
        setValue(props.type || SESSION_TYPE.tf);
    }, [props.type])

    const handleClick = (event) => {
        setTarget(event && event.currentTarget);
        setShow(!show);
        setValue(props.type || SESSION_TYPE.tf);
    };

    const popover = (
        <Popover id="popover-basic" >
            <Popover.Title as="h3">Change session type:</Popover.Title>
            <Popover.Content>
                <div onChange={changeType} >
                    <div className="d-flex align-items-center py-2">
                        <RadioButton name="sessionType" checked={value === "teacher"} value="teacher" />
                        <span style={{ paddingLeft: '15px' }}>{SESSION_TYPE.teacher}</span>
                    </div>
                    <div className="d-flex align-items-center py-2">
                        <RadioButton name="sessionType" checked={value === "student"} value="student" />
                        <span style={{ paddingLeft: '15px' }}>{SESSION_TYPE.student}</span>
                    </div>
                </div>
                <div className="d-flex align-items-center py-2">
                    <Button className="btn btn-sm btn-info sessionSave" name="save" onClick={saveChanges}>SAVE</Button>
                    <Button variant="link" className="text-dark" onClick={handleClick}>Cancel</Button>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <>
            <div className="d-flex changeSession" onClick={handleClick}  >
                <img src={type && type === SESSION_TYPE.sg ? studentIcon : teacherIcon} className="pr-2" alt="" />
                {type && type === SESSION_TYPE.sg
                    ?
                    <span className="font-weight-bold "><u>Student <nobr>Self-Guided</nobr></u></span>
                    :
                    <span className="font-weight-bold"><u>{SESSION_TYPE.teacher}</u></span>
                }

            </div>
            <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref.current}
                rootClose
                onHide={() => setShow(false)}
                containerPadding={20}
            >
                {popover}
            </Overlay>

        </>

    )
}

export default (ChangeSession);