import React, { useState, useEffect, useContext } from 'react';
import UserContext from "../../shared/context/UserContext";

import PhysicsIcon from "../../assets/images/physics_small.png";
import HistoryIcon from "../../assets/images/history_small.png";
import AlgebraIcon from "../../assets/images/algebra_small.png";
import pdfIcon from '../../assets/images/icons/office-file-pdf.png';
import docIcon from '../../assets/images/icons/office-file-doc.png';
import pptIcon from '../../assets/images/icons/PPTIcon.png';
import folderIcon from '../../assets/images/icons/folder-download-alternate.png';
import closePNG from '../../assets/images/icons/close.png';
import dummyImg from '../../assets/images/placeholderImg.png';

import offRadio from '../../assets/images/icons/OffRadioButton.png';
import correctRadio from '../../assets/images/icons/correctRadioButton.png';
import incorrectRadio from '../../assets/images/icons/incorrectRadioBtton.png';


import Modal from 'react-bootstrap4-modal';

import './SessionMaterials.css'


const SessionMaterials = props => {
    const auth = useContext(UserContext);
    const [isStandardModal, setIsStandardModal] = useState(false);
    const [SAQModal, setSAQModal] = useState(false);
    const [assessmentQuestions, setAssessmentQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [standards, setStandards] = useState(props.standards);
    const [materialList, setMaterialList] = useState(props.materials || []);

    let totalQuestions = 0;

    let standardData = {
        title: "Physics Standards",
        subTitle: "Core Subject Standards",
        description: "HS-PS2-1: Analyze data to support the claim that Newton’s second law of motion describes the mathematical...",
        standards: [
            {
                title: "Standard",
                Objectives: [
                    {
                        title: "Science and Engineering Practice",
                        description: "Analyzing and Interpreting Data: Analyze data using tools..."
                    },
                    {
                        title: "Disciplinary Core Ideas",
                        description: "PS2.A: Forces and Motion. Newton's second law..."
                    }
                ]
            },
            {
                title: "Computer Science Objectives",
                Objectives: [
                    {
                        title: "Game Physics",
                        description: "Identify the meaning of the term game physics. Analyze data to determine the values of physics variables and trends in variables."
                    },
                    {
                        title: "Disciplinary Core Ideas",
                        description: "PS2.A: Forces and Motion. Newton's second law..."
                    }
                ]
            }
        ]
    }

    useEffect(() => {
        if (props.assessment) {
            setAssessmentQuestions(props.assessment.assessmentQuestion);
            setSelectedQuestion(props.assessment.assessmentQuestion[0]);
            totalQuestions = props.assessment.totalQuestions;
        }
    }, [])



    const downloadAll = () => {
        addToAmplitude("Download All Files");
        props.materials.forEach(element => {
            var a = document.createElement("a");
            a.setAttribute('href', element.link);
            a.setAttribute('download', element.link);
            a.click();
        });
    }

    const handlerQuestion = (id) => {
        setSelectedQuestion(assessmentQuestions.filter(x => x.id === id)[0]);
    }

    const prevNext = (value) => {
        let currentIndex = assessmentQuestions.findIndex(x => x.id === selectedQuestion.id)
        if (value === 1)
            setSelectedQuestion(assessmentQuestions[currentIndex + 1]);
        else
            setSelectedQuestion(assessmentQuestions[currentIndex - 1]);
    }

    const getIcon = (icon) => {

        if (icon === "ppt")
            return (<img alt="download - ppt" src={pptIcon} className="SM-fileIcon" />);
        else if (icon === "docx" || icon === "doc")
            return (<img alt="download - ppt" src={docIcon} className="SM-fileIcon" />);
        else if (icon === "pdf")
            return (<img alt="download - ppt" src={pdfIcon} className="SM-fileIcon" />);
        else
            return (<img alt="download - ppt" src={docIcon} className="SM-fileIcon" />);
    }
    const addToAmplitude = (material) => {
        props.amplitude.track("Downloaded Learning Materials", { subject: props.subject, class_section_id: props.classId, session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex], file_name: material })
    }

    const trackButton = (btn_name) => {
        props.amplitude.track("Clicked Class Details Button", { subject: props.subject, class_section_id: props.classId, button_name: btn_name, session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex] });
    }
    return (
        <>
            {props.materials && <div className="sessionMaterialDiv">
                <div className="lessonPlanMaterial">
                    <div className="row SM-heading" >
                        <div className="col-12">
                            <span>Lesson Plan Materials</span>
                        </div>
                    </div>
                    <div className="row SM-headtext" >
                        <div className="col-12">
                            {props.materials.length > 0 &&
                                <span>Click to download any of the teacher materials for this session.</span>
                            }
                        </div>
                    </div>

                    <div className="row " style={{ marginBottom: "20px" }} >
                        <div className="row col-9 mobileViewSessionList" style={{ textTransform: "capitalize" }}>
                            <div className="col-6">
                                {props.sessionMaterials && props.sessionMaterials.map((item, index) => {
                                    return <div key={item.name} className={""} style={{ padding: "0px" }}>
                                        <span style={{ marginBottom: "10px" }} key={item.name}><a href={item.link} download={item.link} className="SM-filename" onClick={() => { addToAmplitude(item.name) }}>
                                            {getIcon(item.icon)}
                                            {item.name}
                                        </a></span>
                                    </div>
                                })
                                }
                            </div>
                            <div className="col-6 ">
                                {props.otherSessionMaterials && props.otherSessionMaterials.map((item, index) => {
                                    return <div key={item.name} className={""} style={{ padding: "0px" }}>
                                        <span style={{ marginBottom: "10px" }} key={item.name}><a href={item.link} download={item.link} className="SM-filename" onClick={() => { addToAmplitude(item.name) }}>
                                            {getIcon(item.icon)}
                                            {item.name}
                                        </a></span>
                                    </div>
                                })
                                }
                            </div>
                        </div>
                        {props.materials.length > 0 &&
                            <div className="col-3" style={{ textAlign: "center" }}>
                                <img alt="download-all" style={{ width: "24px", height: "24px" }} src={folderIcon} />
                                <div className="SM-downloadAll" onClick={downloadAll} style={{ marginTop: "7px" }}>Download All</div>
                            </div>
                        }
                    </div>

                    <div className="row" style={{ marginLeft: "12px" }}>
                        <div className="col-12" style={{ borderTop: "1px solid rgb(242, 242, 249)" }}>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingTop: "21px" }}>
                    <div className="col-6 SM-viewStandards">
                        <span className="viewLearningStandardLabel" onClick={() => { trackButton("View Learning Standards"); setIsStandardModal(true) }}>View Learning Standards</span>
                    </div>
                    <div className="col-6 SM-viewSession" style={{ padding: "0px" }}>
                        {props.assessment && props.assessment.assessmentQuestion.length > 0 && <span className="viewSessionAssessmentQue"
                            onClick={() => {
                                setAssessmentQuestions(props.assessment.assessmentQuestion);
                                setSelectedQuestion(props.assessment.assessmentQuestion[0]);
                                setSAQModal(true);
                                trackButton("View Assessment Questions");
                            }}>View Session Assessment Questions</span>}
                    </div>
                </div>

            </div>
            }

            {standards &&
                <Modal id="standardModal" dialogClassName="Modaldialog" className="standardsModal" visible={isStandardModal} onClickBackdrop={() => { setIsStandardModal(false) }}>
                    <div className="modal-body">
                        <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setIsStandardModal(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <div className="standardHeader" style={{ marginTop: "5px" }}>
                                    <img alt="icon" src={props.courseIcon} style={{ width: "60xp", height: "60px" }} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="standardHeader">
                                    <span>{standards.title}</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="offset-1 col-10">
                                <div >
                                    <span className="S-Header-sub" dangerouslySetInnerHTML={{ __html: standards.subTitle }}></span>
                                    <p className="S-text" dangerouslySetInnerHTML={{ __html: standards.description }}></p>
                                </div>
                                {
                                    standards.standards.length > 0 && standards.standards.map((item, i) => {
                                        return <div key={i}>
                                            <div className="S-color-header">
                                                <span dangerouslySetInnerHTML={{ __html: item.title }}></span>
                                            </div>
                                            {
                                                item.Objectives.map((x, index) => {
                                                    return <div key={index}>
                                                        <span className="S-normal-header" dangerouslySetInnerHTML={{ __html: x.title }}></span>
                                                        <p className="S-text" dangerouslySetInnerHTML={{ __html: x.description }}></p>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {
                selectedQuestion && <Modal id="SAQModal" dialogClassName="Modaldialog" className="SAQModal" visible={SAQModal} onClickBackdrop={() => { setSAQModal(false) }}>
                    <div className="modal-body">
                        <div style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setSAQModal(false) }}>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>

                        <div className="row">
                            <div className="col-10" >
                                <div style={{ marginBottom: "15px" }}>
                                    <span className="SAQ-head">{props.sessionName} Assessment Questions</span>
                                </div>
                                <div className="SAQ-question">
                                    <span dangerouslySetInnerHTML={{ __html: selectedQuestion.prompt }}></span>
                                </div>
                            </div>
                        </div>

                        {selectedQuestion.imageUrl && <div className="row row-eq-height" style={{ marginTop: "20px" }}>
                            <div className="col-6">
                                {selectedQuestion.choices
                                    .map((item, index) => {
                                        return <div key={item._id} className={`row ${item.choice.isCorrect === true ? "correctBorder" : "normalBorder"}`} style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                            <div className="col-1 SAQ-optionQ" >
                                                {item.choice.isCorrect && <img alt="" src={correctRadio} />}
                                                {!item.choice.isCorrect && <img alt="" src={offRadio} />}
                                            </div>
                                            <div className="col-11 SAQ-optiontext">
                                                <span dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                            </div>
                                        </div>
                                    })}
                            </div>
                            <div className="col-6" style={{ padding: "0px", textAlign: "end" }}>
                                <img alt="placeholder" src={selectedQuestion.imageUrl || dummyImg} style={{ boxShadow: "-4px 4px #009DDB", width: "425px", height: "425px" }} />
                            </div>
                        </div>}
                        {!selectedQuestion.imageUrl &&
                            <div className="row" style={{ marginTop: "20px", marginBottom: "30px" }}>
                                <div className="col-12">
                                    <div className="row">
                                        {selectedQuestion.choices
                                            .map((item, index) => {
                                                return <div key={item._id} className={`col-5 ${item.choice.isCorrect === true ? "correctBorder" : "normalBorder"}`} style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                                    <div className="row">
                                                        <div className="col-1 SAQ-optionQ" >
                                                            {item.choice.isCorrect && <img alt="" src={correctRadio} />}
                                                            {!item.choice.isCorrect && <img alt="" src={offRadio} />}
                                                        </div>
                                                        <div className="col-11 SAQ-optiontext">
                                                            <span dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            })}
                                    </div>
                                </div>
                            </div>}


                        <div className="row">
                            <div className="col-12"  >
                                <span onClick={() => { prevNext(-1) }} style={{ marginRight: "15px" }} className={`${selectedQuestion.id !== assessmentQuestions[0].id ? "enabledPage" : "disabledPage"}`} >Previous</span>
                                {assessmentQuestions.map((item, index) => {
                                    return <span onClick={() => { handlerQuestion(item.id) }} key={item.id} style={{ margin: "5px", padding: "0 7px", cursor: "pointer" }} className={`${selectedQuestion.id === item.id ? "selectedPageBorder" : ""}`}>{index + 1}</span>
                                })
                                }<span onClick={() => { prevNext(1) }} style={{ margin: "15px" }} className={`${selectedQuestion.id !== assessmentQuestions[assessmentQuestions.length - 1].id ? "enabledPage" : "disabledPage"}`}>Next</span>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default React.memo(SessionMaterials);