import React, { useState, useEffect } from "react";
import Step5 from '../../assets/images/steps_05.png';
import Step2 from '../../assets/images/twosteps_02.png';
import "./AddClass.css";
import { useForm } from "react-hook-form";
import StudentRoster from './studentRoster';
import $ from "jquery";
import { useHistory } from "react-router-dom";
import { ERROR_STRINGS } from "../constants/ErrorStrings"

const FifthManualStep = props => {
    let history = useHistory();
    const { register, setValue, handleSubmit, errors } = useForm({ mode: "onBlur" });
    const [students, setStudents] = useState([]);
    const [isError, setIsError] = useState(false);
    const method = "manual";
    const onSubmit = data => {
        $('#nameInput').blur();
        let arr = students;
        arr.push(data);
        setStudents(arr);
        setValue("email", "");
        setValue("lastname", "");
        setValue("firstname", "");
        $('#emailInput').focus();
    }

    const validateControls = async (email) => {
        let arr = students.filter(val => val.email === email);
        if (arr.length > 0) {
            return false;
        }

        return true;
    }

    const continueHandler = () => {
        if (students.length !== 0)
            props.handleClassStudents(students);
        else
            setIsError(true);
    }

    const backHandler = () => {
        props.backStep(4);
    }

    const skipHandler = () => {
        props.backStep(7);
    }

    const BackToTeacherDashboard = () => {
        history.push("/classdashboard/" + props.classDetails.classSectionId);
    }

    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Add your students" });
    }, [])

    return (
        <>
            <div>
                <div className="row">
                    <div className="col manualImportForm" >
                        <div className="row justify-content-center" >
                            <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                                {props.classDetails === null && <img src={Step5} />}
                                {props.classDetails !== null && <img src={Step2} />}
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                                <span className="addnewclasshead">ADD YOUR STUDENTS</span>
                            </div>
                            <div className="col-12 dateSubHeaderLabel" style={{ marginTop: "6px", marginBottom: "30px" }}>
                                Enter each student’s name and email. Click Finish when done.
                            </div>
                            <div className="col-12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="form-group">
                                            <label>Email</label>
                                            {errors.email && errors.email.type === 'required' && <p></p>}
                                            {errors.email && errors.email.type === 'validate' && <p></p>}
                                            {errors.email && errors.email.type === 'pattern' && <p></p>}
                                            <input type="text" className="form-control" placeholder=" " name="email" id="emailInput" ref={register({ required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, validate: validateControls })} />
                                            {errors.email && errors.email.type === 'required' && <p>{ERROR_STRINGS.requiredField}</p>}
                                            {errors.email && errors.email.type === 'validate' && <p>Email already exist</p>}
                                            {errors.email && errors.email.type === 'pattern' && <p>Enter valid email address</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>LAST NAME</label>
                                            {errors.lastname && errors.lastname.type === 'required' && <p></p>}
                                            <input type="text" className="form-control" placeholder=" " name="lastname" id="lastnameInput" maxLength='40' ref={register({ required: true, pattern: /^[a-zA-Z0-9 ,.'-]+$/i })} />
                                            {errors.lastname && errors.lastname.type === 'required' && <p>{ERROR_STRINGS.requiredField}</p>}
                                            {errors.lastname && errors.lastname.type === 'pattern' && <p>{ERROR_STRINGS.nameSpecialCharsError}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>FIRST NAME</label>
                                            {errors.firstname && errors.firstname.type === 'required' && <p></p>}
                                            <input type="text" className="form-control" placeholder=" " name="firstname" id="nameInput" maxLength='40' ref={register({ required: true, pattern: /^[a-zA-Z0-9 ,.'-]+$/i })} />
                                            {errors.firstname && errors.firstname.type === 'required' && <p>{ERROR_STRINGS.requiredField}</p>}
                                            {errors.firstname && errors.firstname.type === 'pattern' && <p>{ERROR_STRINGS.nameSpecialCharsError}</p>}
                                        </div>
                                        <div className="form-group btn-group">
                                            <button className="btn btn-outline-primary addBtn">ADD</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-12 userRosterTable">

                                {(students.length > 0) &&
                                    <StudentRoster method={method} students={students} setRoster={(value) => {
                                        setStudents(value)
                                    }} />
                                }
                            </div>
                            <div className="col-12" style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                                {isError && students.length === 0 && < span style={{ color: "red" }}>Data not found.</span>}
                            </div>
                            <div className="col-12" style={{ marginBottom: "40px", textAlign: "center", marginTop: "28px" }}>
                                {props.classDetails === null && <button type="button" className="curve-bordered-btn" id="backbtn" style={{ marginRight: "21px", marginLeft: "100px" }} onClick={backHandler}>BACK</button>}
                                <button type="button" className="curve-fill-btn" id="continue" onClick={continueHandler} >FINISH</button>
                                {props.classDetails === null && <span className="skipBtn" onClick={skipHandler} >Skip Step</span>}
                                {props.classDetails !== null && <span className="skipBtn" onClick={BackToTeacherDashboard} >Cancel</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(FifthManualStep);