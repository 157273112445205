import React, { useState, useEffect } from 'react'

import closePNG from '../../assets/images/icons/close.png';
import correctIcon from '../../assets/images/icons/correct.png';

import FbIcon from '../../assets/images/icons/facebook.png';
import InstaIcon from '../../assets/images/icons/instagram.png';
import TwitterIcon from '../../assets/images/icons/twitter.png';
import socialBack from '../../assets/images/icons/socialBack.png';
import linkIcon from '../../assets/images/icons/linkIcon.png';

import Modal from 'react-bootstrap4-modal';

const MyProjectTab = props => {
    const [showRubric, setShowRubric] = useState(false);
    const [selectedCriteria, setSelectedCriteria] = useState(props.rubric.rubricCriteria[0] || null);
    const [projectQuestionAnswer, setProjectQuestionAnswer] = useState([]);
    const copyToClipboard = () => {
        var copyText = document.getElementById("copiedInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied";
        props.amplitude.track("Share My Project", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId, shared_using: "link" });
    }

    const outFunc = () => {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    }

    const shareProjectUrl = (social) => {
        if (social == 'facebook') {
            window.open('https://www.facebook.com/sharer/sharer.php?u=' + process.env.REACT_APP_APPURL + 'viewproject/' + props.shareId + '&quote=Spark', '_blank', 'toolbar=0,location=0,menubar=0');
        }
        else if (social == 'twitter') {
            window.open('https://www.twitter.com/share?url=' + process.env.REACT_APP_APPURL + 'viewproject/' + props.shareId + '&quote=Spark', '_blank', 'toolbar=0,location=0,menubar=0');
        }

        props.amplitude.track("Share My Project", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId, shared_using: social });

    }

    const trackButton = (btn_name) => {
        props.amplitude.track("Clicked Class Details Button", { teacher_user_id: props.teacherId, class_section_id: props.classId, subject: props.subject, button_name: btn_name });
    }

    useEffect(() => {
        if (props.projectQuestion && props.projectQuestion.length > 0) {
            let arr = props.projectQuestion.sort((a, b) => a.id > b.id ? 1 : -1);
            setProjectQuestionAnswer(arr);
        }
    })
    return (
        <>
            <div className="row justify-content-md-center socialRow" >
                <div className="col-12">
                    <span className="boldText16px" style={{ marginRight: "25px" }}>Share My {props.subject} Project:</span>
                    <img alt="" src={FbIcon} style={{ marginRight: "20px", cursor: "pointer", padding: "13px", background: "no-repeat", backgroundImage: `url(${socialBack})` }} onClick={() => { shareProjectUrl('facebook') }} />

                    <img alt="" src={TwitterIcon} style={{ marginRight: "20px", cursor: "pointer", padding: "12px", background: "no-repeat", backgroundImage: `url(${socialBack})` }} onClick={() => { shareProjectUrl('twitter') }} />
                    <span className="copyContainer">
                        <img alt="" src={linkIcon} style={{ marginRight: "10px", marginLeft: "10px" }} />

                        {
                            props.entryPoints && <input spellCheck="false" type="text" id="copiedInput" value={process.env.REACT_APP_APPURL + 'viewproject/' + props.shareId} onChange={() => { }} />

                        }
                        {
                            !props.entryPoints && <input spellCheck="false" type="text" id="copiedInput" value="null" onChange={() => { }} />

                        }

                        <span className="tooltipDiv" style={{ color: "#4C4B5E", position: "relative", zIndex: 1 }}>
                            <span className="underlineLabel" onClick={copyToClipboard} onMouseOut={outFunc}>
                                <span className="tooltiptext" id="myTooltip" >Copy to clipboard</span>Or copy link</span>
                        </span>

                    </span>
                </div>

            </div>
            <div className="row projectContainer" >
                <div className="col-12" style={{}}>

                    <div className="row justify-content-md-center" style={{ marginTop: "30px" }} >
                        <span className="boldText18px SubmittedFinalProject">Submitted Final Project</span>
                    </div>
                    <div className="row " style={{ marginTop: "15px" }} >
                        <span className="normalText14px">Here is your submitted final project along with your final score. Click View Graded Rubric for more details.</span>
                    </div>

                    <div className="row" style={{ marginTop: "35px" }}  >
                        <div className="col-6 finalScoreDiv" style={{ padding: "0", textAlign: "start" }}  >
                            <span className="normalText36px">FINAL SCORE: </span>
                            <span className="normalText36px">{props.rubric.totalScored}</span>
                        </div>
                        <div className="col-6 my-auto"  >
                            <div className="row my-auto" style={{ float: "right" }} >
                                <span className="progressText my-auto underlineLabel ViewProjectRubricSpan" style={{ fontWeight: "bold", float: "right" }} onClick={() => { trackButton("View Graded Rubric"); setShowRubric(true) }}>View Graded Rubric</span>
                            </div>
                        </div>
                    </div>
                    {projectQuestionAnswer !== undefined && projectQuestionAnswer.map((item, index) => {
                        return (<div style={{ marginTop: "30px", textAlign: "start" }} >
                            <div className="row" >
                                <span className="boldText16px" style={{ textAlign: "start" }}>{item.question}</span>
                            </div>
                            <div className="row" style={{ marginTop: "15px" }}>
                                <span className="normalText14px" style={{ textAlign: "start", }}>{item.answer}</span>
                            </div>
                        </div>)
                    })
                    }
                </div>
            </div>



            {
                props.rubric &&
                <Modal id="rubricScored" className="rubricModal" visible={showRubric} onClickBackdrop={() => { setShowRubric(false) }}>
                    <div className="modal-body" >
                        <div className="" style={{ textAlign: "end", position: "relative" }} onClick={() => { setShowRubric(false) }}>
                            <span className="boxSessionName" style={{ textTransform: "none", float: "left", marginTop: "20px" }}>
                                Your Final Project Assessment</span>
                            <br />
                            <img alt="close" className="closeModalPNG" src={closePNG} style={{ position: "absolute", top: "0", right: "0", cursor: "pointer" }} />
                        </div>
                        <div className="row" style={{ width: "85%", marginBottom: "25px" }}>
                            <span className="progressText" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: props.rubric.topText }}>
                            </span>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{ padding: "0px", textAlign: "left" }}>
                                <div className="row" >
                                    {props.rubric.rubricCriteria.map((item) => {
                                        return <span key={item._id} className={` ${selectedCriteria.index === item.index ? "selectedCriteria1" : "criteria"}`}
                                            onClick={() => {
                                                setSelectedCriteria(item)
                                            }}>{item.title}</span>
                                    })}
                                </div>
                                <div className="row" style={{ backgroundColor: "#F2F2F9", padding: "5px" }}>
                                    <div className="col-12" style={{ padding: "25px 20px" }}>
                                        <span className="medium-text" dangerouslySetInnerHTML={{ __html: selectedCriteria.topText }}></span>
                                    </div>
                                    {selectedCriteria.items
                                        .sort((a, b) => b.score - a.score)
                                        .map((option, index) => {
                                            return <div key={index} className={`col-3 ${index !== selectedCriteria.items.length - 1 ? "criteria-rightBorder" : ""}`} >
                                                <div className="row" style={{ marginBottom: "10px" }}>
                                                    <span className="medium-text" style={{ fontWeight: "bold" }}>{option.title} ({option.score})
                                                        {option.isChecked && <img alt="icon" src={correctIcon} className="correctAns" />}
                                                    </span>
                                                </div>
                                                <div className="row" style={{ fontSize: "12px" }}>
                                                    <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
                                                </div>
                                            </div>
                                        })}
                                    {
                                        selectedCriteria.bottomText &&
                                        <div className="col-12" style={{ marginTop: "25px", marginBottom: "25px", borderTop: "1px solid #C7C7D4" }}>
                                            <div className="row" style={{ marginTop: "8px", marginBottom: "10px" }}>
                                                <span className="medium-text" style={{ fontWeight: "bold" }}>Teacher notes:</span><br />
                                            </div>
                                            <div className="row">
                                                <span className="medium-text">{selectedCriteria.bottomText}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }

        </>
    )
}

export default React.memo(MyProjectTab);