import React, { useState, useEffect } from "react";

import sortDown from "../../assets/images/icons/sort_down_bordered.png"
import sortUp from "../../assets/images/icons/sort_up_bordered.png"
import sortUpFilled from "../../assets/images/icons/sort_up_filled.png"
import sortDownFilled from "../../assets/images/icons/sort_down_filled.png"

import "./ClassDetails.css";

const Gradebook = props => {

    let pageLength = 7;
    const [limit, setLimit] = useState(pageLength)
    const [sorted, setSorted] = useState("asc");
    const [flag, setFlag] = useState(true);

    const [studentData, setStudentData] = useState(props.studentRoster || []);

    useEffect(() => {
        setTimeout(() => {
            sortingHandler("asc");
        }, 200);
    }, [])

    const sortingHandler = (value) => {
        setFlag(false);
        if (value === "asc") {
            setStudentData(studentData.sort((a, b) => {
                var nameA = a['lastName'].toString().toLowerCase() + a['firstName'].toString().toLowerCase(), nameB = b['lastName'].toString().toLowerCase() + b['firstName'].toString().toLowerCase();
                return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
            }))
        }
        if (value === "desc") {
            setStudentData(studentData.sort((a, b) => {
                var nameA = a['lastName'].toString().toLowerCase() + a['firstName'].toString().toLowerCase(), nameB = b['lastName'].toString().toLowerCase() + b['firstName'].toString().toLowerCase();
                return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
            }))
        }
        setSorted(value);
        setTimeout(() => {
            setFlag(true);
        }, 0);
    }

    const getAssessmentResult = (list, index) => {

        if (list.length > 0) {
            let currentSession = list.filter(x => x.index === index);
            if (currentSession.length > 0 && currentSession[0].assessment.isAssessmentSubmitted)
                return currentSession[0].assessment.totalCorrectAnswers + " / " + currentSession[0].assessment.totalQuestions;
            else
                return "N / A"
        }
        else
            return "N / A"

    }

    useEffect(() => {
        props.amplitude.track("Visited Class Details", { class_section_id: props.classId, page_section: "Gradebook", session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex] });
    }, []);

    return (
        <>
            <div className="hiddenObjectGrade"></div>
            {
                <div className="w-100 pt-4">
                    <div className="row gradebook-headers" style={{ textAlign: "center", paddingBottom: '8px' }}>
                        <div className="col-3 colName d-flex align-items-center font-weight-bold">
                            <span>Students</span>
                            <div className="d-flex justify-content-between flex-column pr-5 pl-3">
                                <img className="sortUpicon" onClick={() => { sortingHandler("asc") }} style={{ marginBottom: "4px" }} alt="" src={sorted === "asc" ? sortUpFilled : sortUp} />
                                <img className="sortDownicon" onClick={() => { sortingHandler("desc") }} alt="" src={sorted === "desc" ? sortDownFilled : sortDown} />
                            </div>
                        </div>

                        <div className="col-md colName font-weight-bold">
                            Project Culminating Assessment
                        </div>
                        {
                            props.sessions.map((head) => {
                                return head.assessment.assessmentQuestion.length !== 0 && < div key={head.index} className="col-md colName" >
                                    <span>Session {head.index} Formative Assessment</span>
                                </div>
                            })
                        }

                    </div>

                    {studentData.length === 0 && <div style={{ padding: "35px", textAlign: "center" }}><span>No data available</span></div>}

                    {flag && studentData.slice(0, limit).map((x, index) => {
                        return <div key={x.firstName + "-" + index} className="row gradebook-headers align-items-center" style={{ fontSize: "14px", padding: "15px 0", textAlign: "center" }}>
                            <div className="col-3" style={{ margin: "auto", textAlign: "left", }}>
                                <span className="pr-1 text-break text-left trucateText" style={{ minWidth: '90px' }}>{x.lastName}, {x.firstName}</span>
                            </div>

                            <div className="col-md font-weight-bold">
                                {x.rubric.isGraded && <span>{x.rubric.totalScored} / {x.rubric.maxScore}</span>}
                                {!x.rubric.isGraded && <span>N / A</span>}
                            </div>
                            {
                                props.sessions.map((head) => {
                                    return head.assessment && head.assessment.assessmentQuestion && head.assessment.assessmentQuestion.length > 0 && <div key={head.index} className="col-md" >
                                        <span>{getAssessmentResult(x.sessions, head.index)}</span>
                                    </div>
                                })
                            }
                        </div>
                    })}

                    <div className="row show-more-button">
                        {studentData.length > pageLength && studentData.length !== limit &&
                            <button onClick={() => { setLimit(studentData.length) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE ALL</button>
                        }
                        {studentData.length > pageLength && studentData.length === limit &&
                            <button onClick={() => { setLimit(pageLength) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE LESS</button>
                        }
                    </div>
                </div>

            }
        </>
    )
}

export default React.memo(Gradebook);