
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const NoClass = (props) => (
    <SvgIcon  {...props} viewBox="0 0 62 62" >
        <path d="M25.691 39.5386C27.3338 39.7686 29.0015 39.3229 30.2842 38.311C30.7031 37.9912 31.2965 37.9912 31.7153 38.311C32.998 39.3229 34.6657 39.7686 36.3085 39.5386C41.9402 39.5386 46.6961 29.8369 46.6961 23.7435C46.6961 17.3327 42.4646 12.3107 37.0654 12.3107C35.1955 12.1764 33.327 12.5879 31.705 13.4912C31.1289 13.8111 31.1289 13.955 29.5867 13.3374C28.504 12.8639 27.3581 12.5368 26.1818 12.3653C25.4632 12.2706 24.7359 12.4674 24.1746 12.9084C23.6055 13.3591 23.2574 14.0168 23.2136 14.7238C22.8364 20.9758 19.5556 22.2704 17.6362 22.4837C16.2622 22.6283 15.2585 23.7976 15.3732 25.1199C15.9441 30.8611 20.3513 39.5386 25.691 39.5386Z" fill="#9F9FAF" />
        <path d="M37.0113 8.66937C39.4244 6.25619 40.6317 3.55091 39.7077 2.62696C38.7838 1.70301 36.0785 2.91027 33.6653 5.32345C31.2522 7.73663 30.0449 10.4419 30.9689 11.3659C31.8928 12.2898 34.5981 11.0825 37.0113 8.66937Z" fill="#9F9FAF" />
        <path d="M51.6667 42.16H2.58333C1.1566 42.16 0 43.2703 0 44.64C0 46.0096 1.1566 47.12 2.58333 47.12H18.0833C20.9368 47.12 23.25 49.3406 23.25 52.08C23.25 54.8193 20.9368 57.04 18.0833 57.04H2.58333C1.1566 57.04 0 58.1503 0 59.52C0 60.8896 1.1566 62 2.58333 62H51.6667C57.3736 62 62 57.5586 62 52.08C62 46.6013 57.3736 42.16 51.6667 42.16Z" fill="#9F9FAF" />
    </SvgIcon >
);

export default NoClass;
