import React, { useState } from 'react';

import copypasteIcon from '../../assets/images/icons/copy-paste.png';

import './ClassDetails.css'

const LaunchFacilitaion = props => {

    const [classCode, setClassCode] = useState(props.classCode || "ERROR");

    const copyToClipboard = () => {
        var copyText = document.getElementById("copiedInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");

        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copied: " + copyText.value;
    }

    const outFunc = () => {
        var tooltip = document.getElementById("myTooltip");
        tooltip.innerHTML = "Copy to clipboard";
    }

    const changeCopiedVal = () => { }

    return (
        <>
            {props.from === "dashboard" && <div className="facilitationDiv">
                <div className="row Fac-heading" >
                    <div className="col-12">
                        <span>Facilitation</span>
                    </div>
                </div>
                <div className="row Fac-headtext" >
                    <div className="col-12">
                        <span>To access this session's class facilitation tools, click LAUNCH FACILITATION below.</span>
                    </div>
                </div>

                <div className="row " style={{ marginBottom: "25px" }} >
                    <div className="col-12">
                        <button type="button" className="btn launch-fill-btn" onClick={props.facilitationHandler}>Launch Facilitation</button>
                    </div>
                </div>
                {props.strategy.toLowerCase().indexOf("clever") == -1 &&
                    <>
                        <div className="row" >
                            <div className="col-12 classCodeText" >
                                Class Code: <span>{classCode}</span>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-12 classCodeMsg" >
                                <span>If this class isn't showing up on your students' dashboards, they can enter the code above for access.</span>
                            </div>
                        </div>
                    </>
                }
            </div>
            }


            {props.from === "facilitation" && props.strategy.toLowerCase().indexOf("clever") == -1 && <div className="facilitationDiv">
                <div className="row Fac-heading" >
                    <div className="col-12">
                        <span>Class Code</span>
                    </div>
                </div>
                <div className="row Fac-headtext" >
                    <div className="col-12">
                        <span>If this class isn’t showing up on your students’ dashboards, they can enter the code above for access.</span>
                    </div>
                </div>
                <div className="row" style={{ marginBottom: "4px" }}>
                    <div className="col-12 classCodeTocopy" >
                        <input type="text" id="copiedInput" style={{ width: "100%", height: "auto" }} value={classCode} onChange={() => { }} />
                    </div>
                </div>
                <div className="row" >
                    <div className="col-12 classCodeMsg" >
                        <img alt="" src={copypasteIcon} style={{ width: "24px", height: "24px", marginRight: "10px" }} />

                        <span className="tooltipDiv">
                            Click here to <span className="underlineLabel" onClick={copyToClipboard} onMouseOut={outFunc}>
                                <span className="tooltiptext" id="myTooltip">Copy to clipboard</span>copy code</span>
                        </span>
                    </div>
                </div>

            </div>
            }
        </>
    );
}

export default React.memo(LaunchFacilitaion);