import React, { useState, useEffect, useContext } from "react";

import {
    Link
} from "react-router-dom";
import axios from "axios";

import UserContext from "../../shared/context/UserContext";

import IFrame from "../../shared/components/iFrameBox/IFrameBox";
import Switch from "../../shared/components/switch/Switch";

import sortUp from '../../assets/images/icons/sort_up.png';
import sortDown from '../../assets/images/icons/sort_down.png';
import sortDownActive from "../../assets/images/icons/sort_down_bordered.png"
import sortUpActive from "../../assets/images/icons/sort_up_bordered.png"
import sortUpFilled from "../../assets/images/icons/sort_up_filled.png"
import sortDownFilled from "../../assets/images/icons/sort_down_filled.png"
import rightArrow from '../../assets/images/icons/Right.png';
import correctIcon from '../../assets/images/icons/correct.png';
import PlusIcon from '../../assets/images/icons/Plus.png';
import pencil from '../../assets/images/icons/edit.png';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';

import "./ClassDetails.css";

const Projects = props => {

    const auth = useContext(UserContext);
    let pageLength = 7;


    const [studenProjectURL, setStudenProjectURL] = useState("");
    const [sudentProject, setVisibleSudentProject] = useState(false);
    const [limit, setLimit] = useState(pageLength)
    const [sorted, setSorted] = useState({ column: "lastName", sort: "asc" });
    const [selectedCriteria, setSelectedCriteria] = useState(props.studentRoster.length > 0 ? props.studentRoster[0].rubric.rubricCriteria[0] : null);
    const [studentsData, setStudentsData] = useState(props.studentRoster || []);
    const [clikedId, setClikedId] = useState(0);

    const [studentId, setStudentId] = useState(0);
    const [studentName, setStudentName] = useState("");
    const [isFreezed, setIsFreezed] = useState(null)
    const [currentLocation, setCurrentLocation] = useState(null);

    useEffect(() => {
        sortingHandler("lastName", "asc");
    }, [])

    const sortingHandler = (columnValue, sortValue) => {
        setSorted({ column: columnValue, sort: sortValue });
        if (columnValue === "totalScored") {
            if (sortValue === "asc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a.rubric[columnValue].toString().toLowerCase(), nameB = b.rubric[columnValue].toString().toLowerCase()
                    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                }))
            }
            if (sortValue === "desc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a.rubric[columnValue].toString().toLowerCase(), nameB = b.rubric[columnValue].toString().toLowerCase()
                    return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
                }))
            }
        }
        else if (columnValue === "lastName") {
            if (sortValue === "asc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a[columnValue].toString().toLowerCase() + a['firstName'].toString().toLowerCase(), nameB = b[columnValue].toString().toLowerCase() + b['firstName'].toString().toLowerCase();
                    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                }))
            }
            if (sortValue === "desc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a[columnValue].toString().toLowerCase() + a['firstName'].toString().toLowerCase(), nameB = b[columnValue].toString().toLowerCase() + b['firstName'].toString().toLowerCase();
                    return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
                }))
            }
        }
        else {
            if (sortValue === "asc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a[columnValue].toString().toLowerCase(), nameB = b[columnValue].toString().toLowerCase()
                    return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                }))
            }
            if (sortValue === "desc") {
                setStudentsData(studentsData.sort((a, b) => {
                    var nameA = a[columnValue].toString().toLowerCase(), nameB = b[columnValue].toString().toLowerCase()
                    return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
                }))
            }
        }
    }

    const hanlderProjectFreezed = (studentRosterId, index) => {

        let obj = { "isFreezed": !studentsData[index].isFreezed }

        let tempArray = [];
        tempArray = [...studentsData];
        tempArray[index].isFreezed = !tempArray[index].isFreezed;
        setStudentsData(tempArray);

        axios.patch("/studentRoster/" + studentRosterId, obj, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {

            })
            .catch(errror => {
                props.hideLoader();
            })
    }

    const getAchieved = (list) => {
        let denominator = list.length;
        let sum = 0;
        list.forEach(element => {
            let checkedOption = element.items.filter(x => x.isChecked === true)[0];
            if (checkedOption)
                sum += checkedOption.score;
        });

        if (sum === 0)
            return "NA"
        let result = sum / denominator;
        result = Math.round(result)
        return list[0].items.filter(x => x.score === result)[0]["title"];
    }

    const toggleClickId = (id) => {
        if (clikedId == 0)
            setClikedId(id);
        else
            setClikedId(0)
    }

    const getStudentRubricScore = (id, maxscore) => {
        let rosterStudentObj = [];
        let totalScore = 0;
        rosterStudentObj = [...studentsData];
        rosterStudentObj = rosterStudentObj.filter(item => item._id === id);
        if (rosterStudentObj && rosterStudentObj.length > 0) {
            rosterStudentObj = rosterStudentObj[0];
            rosterStudentObj.rubric.rubricCriteria.forEach((item) => {
                if (item && item.items.length > 0) {
                    let arr = item.items.filter(val => val.isChecked == true);
                    if (arr.length > 0) {
                        totalScore = totalScore + parseInt(arr[0].score);
                    }
                }
            });

            return totalScore + '/' + maxscore;
        }
        else {
            return totalScore + '/' + maxscore;
        }
    }

    useEffect(() => {
        props.amplitude.track("Visited Class Details", { class_section_id: props.classId, page_section: "Projects", session_id: props.currentSessionIndex, session_type: props.sessionTypes[props.currentSessionIndex] });
    }, []);
    return (
        <>
            <div className="hiddenObjectProject"></div>
            {
                <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="col-12" style={{ padding: "0px" }} id="myGroup">
                        <div className="row rosterHeader gradebook-headers" style={{ fontWeight: "bold", textAlign: "left", borderBottom: "1px solid #F2F2F9", paddingBottom: '8px' }}>
                            <div className="col-md colName" >
                                <span>Students</span>
                                <div style={{ display: "grid", float: "right", cursor: "pointer" }}>
                                    <img className="sortUpicon" onClick={() => { sortingHandler("lastName", "asc") }} style={{ marginBottom: "6px" }} alt="" src={sorted.column === "lastName" ? (sorted.sort === "asc" ? sortUpFilled : sortUpActive) : sortUp} />
                                    <img className="sortDownicon" onClick={() => { sortingHandler("lastName", "desc") }} alt="" src={sorted.column === "lastName" ? (sorted.sort === "desc" ? sortDownFilled : sortDownActive) : sortDown} />
                                </div>
                            </div>

                            <div className="col-md colName statusCol">
                                <span>Status</span>
                                <div style={{ display: "grid", float: "right", cursor: "pointer" }}>
                                    <img className="sortUpicon" onClick={() => { sortingHandler("status", "asc") }} style={{ marginBottom: "6px" }} alt="" src={sorted.column === "status" ? (sorted.sort === "asc" ? sortUpFilled : sortUpActive) : sortUp} />
                                    <img className="sortDownicon" onClick={() => { sortingHandler("status", "desc") }} alt="" src={sorted.column === "status" ? (sorted.sort === "desc" ? sortDownFilled : sortDownActive) : sortDown} />
                                </div>
                            </div>
                            <div className="col-md colName unfreezeProjectCol" style={{ display: "flex" }}>
                                <span className="" >Unfreeze Project?</span>
                                <div style={{ display: "grid", float: "right", cursor: "pointer", height: "fit-content" }}>
                                    <img className="sortUpicon" onClick={() => { sortingHandler("isFreezed", "asc") }} style={{ marginBottom: "6px" }} alt="" src={sorted.column === "isFreezed" ? (sorted.sort === "asc" ? sortUpFilled : sortUpActive) : sortUp} />
                                    <img className="sortDownicon" onClick={() => { sortingHandler("isFreezed", "desc") }} alt="" src={sorted.column === "isFreezed" ? (sorted.sort === "desc" ? sortDownFilled : sortDownActive) : sortDown} />
                                </div>
                            </div>
                            <div className="col-md-1 colName scoreCol">
                                <span>Score</span>
                                <div style={{ display: "grid", float: "right", cursor: "pointer" }}>
                                    <img className="sortUpicon" onClick={() => { sortingHandler("totalScored", "asc") }} style={{ marginBottom: "6px" }} alt="" src={sorted.column === "totalScored" ? (sorted.sort === "asc" ? sortUpFilled : sortUpActive) : sortUp} />
                                    <img className="sortDownicon" onClick={() => { sortingHandler("totalScored", "desc") }} alt="" src={sorted.column === "totalScored" ? (sorted.sort === "desc" ? sortDownFilled : sortDownActive) : sortDown} />
                                </div>
                            </div>
                            <div className="col-md colName achievedCol">
                                <span>Achieved</span>
                                <div style={{ display: "grid", float: "right", cursor: "pointer" }}>
                                    <img className="sortUpicon" onClick={() => { sortingHandler("Achieved", "asc") }} style={{ marginBottom: "6px" }} alt="" src={sorted.column === "Achieved" ? (sorted.sort === "asc" ? sortUpFilled : sortUpActive) : sortUp} />
                                    <img className="sortDownicon" onClick={() => { sortingHandler("Achieved", "desc") }} alt="" src={sorted.column === "Achieved" ? (sorted.sort === "desc" ? sortDownFilled : sortDownActive) : sortDown} />
                                </div>
                            </div>
                            <div className="col-md colName projectCol">
                                Grade Project
                            </div>
                        </div>

                        {studentsData.length === 0 && <div style={{ padding: "35px", textAlign: "center" }}><span>No data available</span></div>}

                        {studentsData.length > 0 && studentsData.slice(0, limit).map((x, index) => {
                            let disableButton = x.status === "Submitted" ? " btn-primary " : " disabled ";
                            let disableBtn = x.status === "Submitted" ? false : true;
                            let textRed = x.status === "Submitted" ? " text-success " : " text-danger ";

                            return <div key={x._id} className="scoreProject">
                                <div className={`row projectBody gradebook-headers font-14 align-items-center ${x._id === clikedId ? "selectedBackColor" : ""}`} style={{ fontSize: "14px", padding: "8px 0px", textAlign: "left", borderBottom: "1px solid #F2F2F9" }}>
                                    <div className="col-md" >
                                        <span className="pr-1 text-break text-left trucateText">{x.lastName}, {x.firstName}</span>

                                    </div>
                                    <div className={"col-md" + textRed} >
                                        <span >{x.status}</span>
                                    </div>
                                    <div className="col-md customSwitchStyle" >
                                        <Switch
                                            isOn={!x.isFreezed}//isFreezed
                                            handleToggle={() => { hanlderProjectFreezed(x._id, index) }}
                                            sizeClass="md"
                                            isLocked={false}
                                            id={x._id}
                                        />
                                        <span className="switch-label">
                                            {!x.isFreezed ? "Yes" : "No"}
                                        </span>
                                    </div>

                                    <div className="col-md-1 ">
                                        {x.rubric.isGraded ? <span >{x.rubric.totalScored} / {x.rubric.maxScore}</span> : <span >N / A</span>}
                                    </div>
                                    <div className="col-md">
                                        {x.Achieved ? <span>{x.Achieved}</span> : <span>N / A</span>}

                                    </div>
                                    <div className={"col-md"}>
                                        {x._id === clikedId ?
                                            <CancelIcon className="pointer primary-color float-right mr-4" data-toggle="collapse" data-target={`#${x.lastName.trim() + index}`} onClick={() => { toggleClickId(x._id); setSelectedCriteria(x.rubric.rubricCriteria[0]) }} />
                                            :
                                            disableBtn
                                                ?
                                                <span className={"btn btn-sm font-10 font-weight-bold btn-pill b-2 disabled no-event"} >grade project</span>
                                                :
                                                <span className={"btn btn-sm font-10 font-weight-bold btn-pill b-2 btn-primary"} data-toggle="collapse" data-target={`#${x.lastName.trim() + index}`} onClick={() => { toggleClickId(x._id); setSelectedCriteria(x.rubric.rubricCriteria[0]) }}>grade project</span>
                                        }
                                    </div>
                                </div>

                                {selectedCriteria &&
                                    <div id={x.lastName.trim() + index} data-parent="#myGroup" className="row collapse submitted-project-tooltip" style={{ padding: "0px", backgroundColor: " #F2F2F9" }}>
                                        <div className="col-12" style={{ padding: "35px" }}>

                                            <div className="row align-items-end mb-4" >
                                                <button
                                                    className="btn btn-outline-primary btn-pill b-2"
                                                    onClick={() => { setCurrentLocation(x.currentLocation); setIsFreezed(x.isFreezed); setStudentId(x._id); setStudentName(x.lastName + ", " + x.firstName); setStudenProjectURL(x.entryPoints.studentProjectEntry); setVisibleSudentProject(true) }}
                                                    style={{ textTransform: "none" }}
                                                >
                                                    Go to student project <ArrowForwardIcon className="ml-2" />
                                                </button>
                                            </div>

                                            <div className="row" style={{ marginBottom: "15px" }} >
                                                <span className="small-black-heading">
                                                    <p>The rubric below will show the student's final scores once you have graded their project.</p>
                                                </span>
                                            </div>
                                            <div className="row" style={{ position: "relative", top: "8px" }}>
                                                <div className="col-12" style={{ padding: "0px" }}>
                                                    <div className="row rubricProjectTabHead" style={{ display: "inline-block" }}>
                                                        {x.rubric.rubricCriteria.map((item) => {
                                                            return <span key={item.index} className={` ${selectedCriteria.index == item.index ? "selectedCriteria" : "criteria"}`}
                                                                onClick={() => {
                                                                    setSelectedCriteria(item)
                                                                }}>{item.title}</span>
                                                        })}
                                                    </div>
                                                    <div className="row" style={{ marginTop: "7px", backgroundColor: "#fff", padding: "5px 5px 25px 5px " }}>
                                                        <div className="col-12" style={{ padding: "25px 20px" }}>
                                                            <span className="medium-text" dangerouslySetInnerHTML={{ __html: selectedCriteria.topText }}></span>
                                                        </div>
                                                        <div className="col-12 rubricInnerBody" style={{ padding: "0px" }}>
                                                            <div className="row" style={{ margin: 0 }}>
                                                                {selectedCriteria.items
                                                                    .sort((a, b) => b.score - a.score)
                                                                    .map((option, index) => {
                                                                        return <div key={index} className={`col-3 ${index !== selectedCriteria.items.length - 1 ? "criteria-rightBorder" : ""}`} >
                                                                            <div className="row" style={{ marginBottom: "10px" }}>
                                                                                <span className="medium-text" style={{ fontWeight: "bold" }}>{option.title} ({option.score})
                                                                                    {option.isChecked && <img alt="icon" src={correctIcon} className="correctAns" />}
                                                                                </span>
                                                                            </div>
                                                                            <div className="row" style={{ fontSize: "12px" }}>
                                                                                <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                            </div>
                                                        </div>
                                                        {selectedCriteria.bottomText &&
                                                            <div className="col-12" style={{ marginTop: "25px", borderTop: "1px solid #C7C7D4" }}>
                                                                <div className="row" style={{ marginTop: "8px", marginBottom: "10px" }}>
                                                                    <span className="medium-text" style={{ fontWeight: "bold" }}>Teacher notes:</span><br />
                                                                </div>
                                                                <div className="row">
                                                                    <span className="medium-text">{selectedCriteria.bottomText}</span>
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }


                            </div>
                        })}

                    </div>
                    <div className="row show-more-button">
                        {studentsData.length > pageLength && studentsData.length !== limit &&
                            <button onClick={() => { setLimit(studentsData.length) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE ALL</button>
                        }
                        {studentsData.length > pageLength && studentsData.length === limit &&
                            <button onClick={() => { setLimit(pageLength) }} type="button" className="btn btn-primary btn-pill seeAllBtn" id="seeall" style={{ margin: "auto" }}>SEE LESS</button>
                        }
                    </div>
                </div>

            }
            {
                studenProjectURL && studentId && <IFrame
                    mode="grade"
                    courseName={props.courseName}
                    userDetails={{ userID: auth.userId, email: auth.email, username: studentName }}
                    studentRosterID={studentId}
                    isFreezed={isFreezed}
                    sessionDetails={{ sessionName: props.sessionName, sessionIndex: props.currentSessionIndex }}
                    sessions={[]}
                    currentLocation={currentLocation}
                    isVisible={sudentProject}
                    title="Student Project"
                    setVisible={(value) => { setVisibleSudentProject(value); props.hitRefreshPage("PROJECTS"); }}
                    iframeURL={studenProjectURL}
                    sessionTypes={props.sessionTypes}
                    subject={props.subject}
                    districtName={props.districtName}
                    amplitude={props.amplitude}
                    classId={props.classId}
                    teacherId={props.teacherId} />
            }
        </>
    )
}

export default React.memo(Projects);