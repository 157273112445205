import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

import Step2 from '../../assets/images/steps_02.png';
import PhysicsIcon from "../../assets/images/physics_small.png";
import HistoryIcon from "../../assets/images/history_small.png";
import AlgebraIcon from "../../assets/images/algebra_small.png";

import "./AddClass.css";

import $ from "jquery";

const SecondStep = props => {

    const [courseList, setCourseList] = useState([]);

    const { register, handleSubmit, watch, errors } = useForm()
    const [course, setCourse] = useState(props.course || null);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        props.showLoader();
        axios.get("/course")
            .then(res => {
                props.hideLoader();
                const courseList = res.data.sort(function (a, b) {
                    var x = a.subject.toLowerCase();
                    var y = b.subject.toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
                setCourseList(courseList);
            })
    }, []);

    const courseChangeHandler = (value) => {
        $(".courseBox").removeClass("selectedCourseBox");
        $("#" + value._id).addClass("selectedCourseBox");
        setCourse(value);
    }

    const continueHandler = () => {
        if (course && course._id)
            props.handleCourseDetails(course);
        else
            setIsError(true);
    }

    const backhandler = () => {
        props.backStep(1);
    }

    useEffect(() => {
        props.amplitude.track("Visited Add Class", { page_section: "Select your subject" });
    }, [])

    return (
        <>
            <div>
                <div className="row" >
                    <div className="col-12" style={{ textAlign: "center", marginTop: "20px" }}>
                        <img src={Step2} />
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginTop: "37px", marginBottom: "6px" }}>
                        <span className="addnewclasshead">SELECT YOUR SUBJECT</span>
                    </div>
                    <div className="col-12" style={{ textAlign: "center", marginBottom: "64px" }}>
                        <span className="addnewclasstext">Select a subject  for your <strong style={{ textTransform: "uppercase" }}>{props.classname}</strong> class, then click continue.</span>
                    </div>
                    <form style={{ width: "100%" }} className="firstStepForm" onSubmit={handleSubmit(continueHandler)}>

                        <div className="col-12" style={{ textAlign: "center", marginBottom: "65px", display: "inline-block" }}>

                            {(courseList.length > 0) &&
                                courseList.map((item) => {
                                    return <div id={item._id} key={item._id} onClick={() => { courseChangeHandler(item) }} className={`row courseBox ${props.course && item._id === props.course._id ? 'selectedCourseBox' : ''}`} style={{ marginRight: "30px", display: "inherit" }}>
                                        <div className="col-12" style={{ marginTop: "30px", marginBottom: "25px" }}>

                                            <img alt="" src={item.courseIcon} style={{ width: "45px", height: "42px" }} />
                                        </div>
                                        <div className="col-12" >
                                            <span className="courseLabel">{item.subject}</span>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className="col-12" style={{ textAlign: "center", marginBottom: "15px" }}>
                            {isError && < span style={{ color: "red" }}>Please select a subject to continue.</span>}
                        </div>

                        <div className="col-12" style={{ textAlign: "center", marginBottom: "135px" }}>
                            <button onClick={backhandler} type="button" className="curve-bordered-btn" id="back" style={{ marginRight: "20px" }}>BACK</button>
                            <button type="submit" className="curve-fill-btn" id="continue" >CONTINUE</button>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}

export default React.memo(SecondStep);