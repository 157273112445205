import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Share = (props) => (
    <SvgIcon {...props}>
        <path d="m17.254 13.501c-1.0151 7.57e-4 -1.9862 0.4151-2.6892 1.1473l-4.1193-2.06c0.03531-0.19385 0.05511-0.3902 0.05924-0.5872-0.0041-0.197-0.02389-0.39335-0.05924-0.58719l4.1193-2.0601c1.2492 1.2837 3.2328 1.5025 4.7317 0.52185 1.4989-0.98066 2.0929-2.8858 1.417-4.5446-0.67585-1.6588-2.4323-2.6061-4.1897-2.2599-1.7574 0.34627-3.0232 1.8891-3.0194 3.6803 0.0041 0.19725 0.02389 0.39386 0.05924 0.58795l-4.1194 2.0586c-1.0613-1.1007-2.6847-1.4468-4.1026-0.87452-1.4179 0.57224-2.3464 1.9481-2.3464 3.4772 0 1.529 0.92843 2.9049 2.3464 3.4771 1.4179 0.5723 3.0413 0.22627 4.1026-0.87446l4.1194 2.0592c-0.03538 0.19412-0.0552 0.39071-0.05924 0.58802 0 2.0708 1.6788 3.7496 3.7496 3.7496 2.0709 0 3.7498-1.6788 3.7498-3.7496 0-2.0709-1.6788-3.7497-3.7498-3.7497z" />
    </SvgIcon>

);

export default Share;
