import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import Tour from "../../shared/components/tour/Tour";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthImportStep from "./FifthImportStep";
import FifthManualStep from "./FifthManualStep";
import FifthCleverClassStep from "./FifthCleverClassStep";
import FinalStep from "./FinalStep";
import UserContext from "../../shared/context/UserContext";
import { useHistory, useLocation } from "react-router-dom";
import "./AddClass.css";
import SessionSelection from "./SessionSelection";
import { DEFAULT_SESSIONS } from "../constants/CommonConstants"

function AddClass(props) {
    let location = useLocation();
    let history = useHistory();
    const childRef = useRef();
    const tourSteps = [];
    const [step, setStep] = useState(1);
    const auth = useContext(UserContext);
    const [classSectionId, setClassSectionId] = useState(null);
    const [classCourse, setClassCourse] = useState(null);
    const [className, setClassName] = useState("");
    const [classDate, setClassDate] = useState(null);
    const [classStudents, setClassStudents] = useState([]);
    const [classCode, setClassCode] = useState("");
    const [classDetails, setClassDetails] = useState(null);
    const [classSessionTypes, setClassSessionTypes] = useState(DEFAULT_SESSIONS);
    const changeStep = (value) => {
        setStep(value);
    }
    const handleClassname = (name) => {
        setClassName(name);
        setStep(2);
    }

    const handleCourse = (course) => {
        props.showLoader();
        setClassCourse(course);
        if (!classSectionId) {

            axios.post("/classSection/addclasssection",
                {
                    name: className,
                    description: "not defined yet",
                    courseId: course._id,
                    subject: course.subject,
                    courseName: course.name,
                    currentSession: "",
                    isActive: true,
                    isArchived: false,
                    isDeleted: false,
                    autoUnblock: false,
                    totalSessions: course.sessions.length,
                    sessions: course.sessions,
                    standards: course.standards,
                    entryPoints: course.entryPoints,
                    courseImage: course.courseImage,
                    courseIcon: course.courseIcon

                })
                .then(response => {
                    setClassSectionId(response.data._id);
                    setClassCode(response.data.classCode);
                    props.hideLoader();
                    setStep(3);

                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
        else {
            axios.patch("/classSection/updateclasssection/" + classSectionId,
                {
                    name: className,
                    description: "not defined yet",
                    courseId: course._id,
                    subject: course.subject,
                    courseName: course.name,
                    currentSession: "",
                    isActive: true,
                    isArchived: false,
                    isDeleted: false,
                    autoUnblock: false,
                    totalSessions: course.sessions.length,
                    sessions: course.sessions,
                    standards: course.standards,
                    entryPoints: course.entryPoints,
                    courseImage: course.courseImage,
                    courseIcon: course.courseIcon

                })
                .then(response => {
                    setClassSectionId(response.data._id);
                    setClassCode(response.data.classCode);
                    props.hideLoader();
                    setStep(3);
                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
    }

    const handleDate = (value) => {
        props.showLoader();
        setClassDate(value);
        if (classSectionId) {
            axios.patch("/classSection/updateclasssectiondate/" + classSectionId,
                {
                    startDate: value
                })
                .then(response => {
                    props.hideLoader();

                    setStep(9);
                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
    }

    const handleSessionType = (value) => {
        props.showLoader();
        setClassSessionTypes(value);
        if (classSectionId) {
            axios.patch("/classSection/updateclasssectionsessiontype/" + classSectionId,
                {
                    sessionType: value
                })
                .then(response => {
                    props.hideLoader();
                    setStep(4);
                })
                .catch(err => {
                    console.log("failed to add class section :", err);
                })
        }
    }

    const handleRoster = (value) => {
        setStep(value);
    }

    const handleStudents = (value) => {
        props.showLoader();
        setClassStudents(value);
        let rosterData = {
            roster: value,
            classSectionId: classSectionId
        }

        if (classDetails !== undefined && classDetails !== null) {
            rosterData.classSectionId = classDetails.classSectionId;
        }
        axios.post("/studentRoster/create", rosterData)
            .then(res => {
                props.hideLoader();
                if (classDetails !== undefined && classDetails !== null) {
                    history.push("/classdashboard/" + classDetails.classSectionId)
                }
                else {
                    setStep(7);
                }
            })
    }

    const handleCleverStudents = (value) => {
        props.showLoader();
        setClassStudents(value);
        let rosterData = {
            roster: value,
            classSectionId: classSectionId,
            mode: "Clever"
        }

        if (classDetails !== undefined && classDetails !== null) {
            rosterData.classSectionId = classDetails.classSectionId;
        }
        axios.post("/studentRoster/create", rosterData)
            .then(res => {
                props.hideLoader();
                if (classDetails !== undefined && classDetails !== null) {
                    history.push("/classdashboard/" + classDetails.classSectionId)
                }
                else {
                    setStep(7);
                }
            })
    }

    useEffect(() => {
        if (location.classDetails !== undefined) {
            setStep(4);
            setClassDetails(location.classDetails);
        }
        if (location.search === '?7') {
            setStep(7);
        }

    }, []);

    return (
        <>
            <div className="largeScreenDiv minscreenheight">

                {
                    step === 1 &&
                    <FirstStep classname={className} handleClassname={handleClassname} amplitude={props.amplitude} />
                }
                {
                    step === 2 &&
                    <SecondStep backStep={changeStep} classname={className} course={classCourse} handleCourseDetails={handleCourse} showLoader={props.showLoader} hideLoader={props.hideLoader} amplitude={props.amplitude} />
                }
                {
                    step === 3 &&
                    <ThirdStep backStep={changeStep} classname={className} classdate={classDate} handleClassDate={handleDate} amplitude={props.amplitude} />
                }
                {
                    step === 4 &&
                    <FourthStep backStep={changeStep} classname={className} classdate={classDate} handleClassRoster={handleRoster} classDetails={classDetails} amplitude={props.amplitude} />
                }
                {
                    step === 5 &&
                    <FifthManualStep backStep={changeStep} handleClassStudents={handleStudents} classDetails={classDetails} amplitude={props.amplitude} />
                }
                {
                    step === 6 &&
                    <FifthImportStep backStep={changeStep} handleClassStudents={handleStudents} classDetails={classDetails} amplitude={props.amplitude} />
                }
                {
                    step === 7 &&
                    <FinalStep classname={className} classcode={classCode} classSectionId={classSectionId} amplitude={props.amplitude} />
                }
                {
                    step === 8 &&
                    <FifthCleverClassStep backStep={changeStep} handleClassStudents={handleCleverStudents} classDetails={classDetails} hideLoader={props.hideLoader} showLoader={props.showLoader} amplitude={props.amplitude} />
                }
                {
                    step === 9 &&
                    <SessionSelection backStep={changeStep} classname={className} handleSessionType={handleSessionType} classSectionId={classSectionId} classDetails={classDetails} amplitude={props.amplitude} />
                }

            </div>
        </>
    )
}

export default React.memo(AddClass);