import React, { useEffect, useState, useContext } from "react";
import Modal from 'react-bootstrap4-modal';
import closePNG from '../../../assets/images/icons/close.png';
import "./IFrameBox.css";
import UserContext from "../../../shared/context/UserContext";
import ConfirmationBox from '../../../shared/components/confirmationBox/ConfirmationBox';

import dummyImg from '../../../assets/images/placeholderImg.png';
import zoomIcon from "../../../assets/images/icons/zoom.png"
import $ from "jquery";
import axios from "axios";
import config from '../../../assets/config/Config.json';

const URL = process.env.REACT_APP_WEBSOCKETURL.replace('http', 'ws');

let webSocketClient = new WebSocket(URL);

let assessment = {
    isAssessmentSubmitted: true,
    totalCorrectAnswers: 0,
    assessmentQuestion: []
}
let Error = "Error: "

const IFrameBox = props => {

    const auth = useContext(UserContext);
    console.log("IFrame props ::: ", props);
    const [assessmentModal, setAssessmentModal] = useState(false);
    const [assessmentQuestions, setAssessmentQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showMessageBox, setMessageBox] = useState(false);
    const [message, setMessage] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const Spark = {};

    useEffect(() => {
        startWebsocket();
        console.log(props.currentSessionDetails && props.currentSessionDetails.name);

    }, []);

    const startWebsocket = () => {
        const URL = process.env.REACT_APP_WEBSOCKETURL.replace('http', 'ws');
        webSocketClient = new WebSocket(URL);

        webSocketClient.onopen = function () {

        };

        webSocketClient.onclose = function () {
            webSocketClient = null

            setTimeout(startWebsocket, 5000)
        }
    }

    const WSSendMessage = (text) => {
        let messageBody = [
            {
                request: 'PUBLISH',
                message: text,
                data: { studentRoster: props.studentRosterID, classSection: props.classId, teacherId: props.teacherId },
            }
        ];

        text && webSocketClient.send(JSON.stringify(messageBody));
    }

    const getTimeDiff = () => {
        setStartDate(new Date());
        var endDate = new Date();
        return (endDate.getTime() - startDate.getTime()) / 1000;
    }

    const courseName = () => {
        return new Promise(function (resolve, reject) {
            let coursename = props.courseName
            if (coursename);
            else {
                coursename = "err";
                Error = "ERROR: Unable to fetch coursename, Please try again."
            }
            return resolve(coursename);
        });
    }

    const userID = () => {
        return new Promise(function (resolve, reject) {
            let userid = props.studentRosterID;
            if (userid);
            else {
                userid = "err";
                Error = "ERROR: Unable to fetch user id, Please try again."
            }
            resolve(userid);
        })
    }

    const studentID = () => {
        return new Promise(function (resolve, reject) {

            let studentid = props.studentRosterID;
            if (studentid);
            else {
                studentid = "err";
                Error = "ERROR: Unable to fetch student id, Please try again."
            }
            resolve(studentid);
        })

    }

    const userName = () => {
        return new Promise(function (resolve, reject) {

            let username = props.userDetails.username;
            if (username);
            else {
                username = "err";
                Error = "ERROR: Unable to fetch username, Please try again."
            }

            resolve(username);
        })

    }

    const currentSession = () => {
        return new Promise(function (resolve, reject) {

            let sessionindex = props.sessionDetails.sessionIndex;
            if (sessionindex !== undefined || sessionindex !== null);
            else {
                sessionindex = "err";
                Error = "ERROR: Unable to fetch session id, Please try again."
            }

            resolve(sessionindex);
        })

    }

    let gradeCounter = 0;
    let submitGradeCounter = 0;
    const submitProjectGrade = (id, crit, val, txt) => {
        return new Promise(function (resolve, reject) {
            gradeCounter = gradeCounter + 1;
            axios.patch("/studentRoster/submitprojectgrade/" + props.studentRosterID + "/" + crit + "/" + val, { teacherText: txt }, {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    submitGradeCounter = submitGradeCounter + 1;
                    if (gradeCounter === submitGradeCounter) {
                        trackEvent("Graded Student Project", { subject: props.subject, class_section_id: props.classId, student_id: props.studentRosterID });
                        gradeCounter = 0;
                        submitGradeCounter = 0;
                        axios.patch("/studentRoster/updatestudentprojectgrade/" + props.studentRosterID);
                    }
                    resolve(true)
                })
                .catch(errror => {
                    console.log("Something went wrong in submit project grade: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false)
                })
        })
    }

    const getAllProjectGrades = () => {
        return new Promise(function (resolve, reject) {
            gradeCounter = gradeCounter + 1;
            axios.get("/studentRoster/getprojectgrades/" + props.studentRosterID)
                .then(res => {
                    resolve(res.data);
                })
                .catch(errror => {
                    console.log("Something went wrong in getting project grades: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false)
                })
        })
    }


    const mileStoneCompleted = (studentRosterId, sessionID, milestoneID) => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const user_role = userData.roles && userData.roles[0].name;

        return new Promise(function (resolve, reject) {
            axios.patch("/studentRoster/milestonecompleted/" + props.studentRosterID + "/" + sessionID + "/" + milestoneID, {}, {
                headers: { Authorization: "bearer " + auth.token }
            })
                .then(res => {
                    trackEvent("Completed Milestone", { teacher_user_id: props.teacherId, session_id: props.sessionDetails.sessionIndex, session_type: props.sessionTypes[props.sessionDetails.sessionIndex], milestone_id: milestoneID });
                    WSSendMessage("milestoneUpdate");
                    WSSendMessage("courseProgress");

                    resolve(true);
                })
                .catch(errror => {
                    console.log("Something went wrong in submit milestone: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false);
                })
        })
    }

    const isPaused = () => {

    }

    const isProjectLocked = () => {
        return new Promise(function (resolve, reject) {
            let value = props.isFreezed
            if (value !== undefined || value !== null);
            else {
                value = "err"
                Error = "ERROR: Unable to fetch project staus, Please try again."
            }
            resolve(value);
        })
    }

    const submitAssessmentGrade = (id, session, questionID, value) => { return "not implemented" }

    const publishProject = () => {
        return new Promise(function (resolve, reject) {

            if (!props.isFreezed) {
                axios.patch('/studentRoster/' + props.studentRosterID, {
                    isProjectSubmitted: true,
                    isFreezed: true,
                    status: 'Submitted'
                }).then(res => {
                    trackEvent("Submitted Project", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId })
                    WSSendMessage("projectSubmit");
                    resolve(true)
                })
                    .catch(errror => {
                        console.log("Something went wrong in publish Project: " + errror)
                        Error += errror
                        resolve(false);
                    })
            }
            else {
                Error = "ERROR: project is locked for this student."
                resolve(false);
            }
        })
    }

    const showAssessment = () => {
        return new Promise(function (resolve, reject) {
            assessment.isAssessmentSubmitted = true;
            assessment.totalCorrectAnswers = 0;
            assessment.assessmentQuestion = [];
            if (props.sessions.length !== 0) {
                let filteredSession = props.sessions.filter(x => x.index == props.sessionDetails.sessionIndex)[0];
                if (filteredSession) {
                    if (!filteredSession.assessment.isAssessmentSubmitted) {
                        setAssessmentQuestions(filteredSession.assessment.assessmentQuestion);
                        setSelectedQuestion(filteredSession.assessment.assessmentQuestion[0]);
                        setAssessmentModal(true);
                    }
                    else {
                        if (filteredSession.assessment.isAssessmentSubmitted == true) {
                            setMessage('You have already completed the assessment.');
                            setMessageBox(true);
                            Error = "ERROR: Assessment is already submitted."
                            resolve(false);
                        }
                        else if (filteredSession.assessment.isAssessmentReleased == false) {
                            setMessage('Assessment is not released by your teacher yet.');
                            setMessageBox(true);
                            Error = "ERROR: Assessment is not released."
                            resolve(false);
                        } else {
                            Error = "ERROR: Assessment is already submitted."
                            resolve(false);
                        }
                    }
                }
            }
            else {
                console.log("something went wrong : ")
                Error = "ERROR: Assessment is not released for this student or sessions data not loaded."
                resolve(false);
            }
        })
    }

    const getCurrentMode = () => {
        return new Promise(function (resolve, reject) {
            if (props.mode)
                resolve(props.mode)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: mode is not set."
                resolve(false);
            }

        })
    }

    const setCurrentLocation = (location) => {
        return new Promise(function (resolve, reject) {
            axios.put("/studentRoster/update", { _id: props.studentRosterID, currentLocation: location })
                .then(res => {
                    resolve(true);
                })
                .catch(errror => {
                    console.log("something went wrong in setCourseData: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false);
                })
        });
    }

    const getCurrentLocation = () => {

        return new Promise(function (resolve, reject) {
            axios.get("/studentRoster/" + props.studentRosterID,
                {},
                { headers: { Authorization: "bearer " + auth.token } })
                .then(res => {
                    let location = res.data.rosterData[0].currentLocation;
                    resolve(location);
                })
                .catch(errror => {
                    console.log("something went wrong in getcurrent location: " + errror)
                    Error = "ERROR: " + errror
                    return resolve(false);
                })
        });
    }

    const setCourseData = (id, obj) => {
        return new Promise(function (resolve, reject) {
            axios.patch('/unNamedData/setCourseData', { studentId: props.studentRosterID, courseData: obj }, {
                headers: { Authorization: "bearer " + auth.token }
            }
            ).then(res => {
                resolve(true)
            })
                .catch(errror => {
                    console.log("something went wrong in setCourseData: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false)
                })
        });
    }

    const getCourseData = (id) => {
        return new Promise(function (resolve, reject) {
            axios.get('/unNamedData/getCourseData/' + props.studentRosterID, {}, {
                headers: {
                    Authorization: "bearer " + auth.token
                }
            }
            ).then(res => {
                if (res.data)
                    resolve(res.data)
                else
                    resolve(null);
            })
                .catch(errror => {
                    console.log("something went wrong in getCourseData: " + errror)
                    Error = "ERROR: " + errror
                    resolve(null);
                })
        });
    }

    const setGlobalVariable = (id, name, value) => {

        let data = {
            studentId: props.studentRosterID,
            key: name, value: value
        }

        return new Promise(function (resolve, reject) {
            axios.patch('/unNamedData/setGlobalData', data, {
                headers: { Authorization: "bearer " + auth.token }
            }
            ).then(res => {
                resolve(true)
            })
                .catch(errror => {
                    console.log("something went wrong in setGlobalVariable: " + errror)
                    Error = "ERROR: " + errror
                    resolve(false)
                })
        });
    }

    const getGlobalVariable = (id, name) => {
        return new Promise(function (resolve, reject) {
            axios.get('/unNamedData/getGlobalData/' + props.studentRosterID + "/" + name, {}, {
                headers: {
                    Authorization: "bearer " + auth.token
                }
            }
            ).then(res => {
                if (res.data.length > 0)
                    resolve(res.data[0].value)
                else
                    resolve(null)
            })
                .catch(errror => {
                    console.log("something went wrong in getGlobalVariable: " + errror)
                    Error = "ERROR: " + errror
                    resolve(null);
                })
        });
    }

    const clearData = (id) => {
        return new Promise(function (resolve, reject) {
            axios.delete('/unNamedData/' + props.studentRosterID, {}, {
                headers: {
                    Authorization: "bearer " + auth.token
                }
            }
            ).then(res => {
                resolve(true);
            })
                .catch(errror => {
                    console.log("something went wrong in clear data: " + errror)
                    Error = "ERROR: " + errror;
                    resolve(false);
                })
        })
    }

    const BackToClass = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const user_role = userData.roles && userData.roles[0].name;
        props.setVisible(false);

        return new Promise(function (resolve, reject) {
            if (user_role === "Student") {
                axios.patch("/studentroster/updatemilestoneprogress/" + props.studentRosterID + "/" + props.sessionDetails.sessionIndex, {}, {
                    headers: { authorization: "bearer " + auth.token }
                }).then(res => {
                    axios.patch("/studentroster/updatecourseprogress/" + props.studentRosterID, {}, {
                        headers: { authorization: "bearer " + auth.token }
                    }).then(res1 => {
                        console.log("res: " + res1);
                        WSSendMessage("courseProgress")
                        props.setVisible(false);
                    }).catch(errror => {
                        props.setVisible(false);
                        console.log("something went wrong in updatecourseprogress: " + errror);
                        Error = "ERROR: " + errror;
                    })
                }).catch(errror => {
                    props.setVisible(false);
                    console.log("something went wrong in updatemilestoneprogress: " + errror);
                    Error = "ERROR: " + errror;
                })
                resolve(true);
            } else {
                props.setVisible(false);
                resolve(true);
            }
        })
    }

    const getLastError = () => {
        return new Promise(function (resolve, reject) {
            if (Error !== "Error: ")
                resolve(Error)
            else
                resolve(null)
        })
    }

    const isAssessmentReleased = () => {
        return new Promise(function (resolve, reject) {
            let filteredSession = props.sessions.filter(x => x.index == props.sessionDetails.sessionIndex)[0];
            if (filteredSession) {
                if (filteredSession.assessment.isAssessmentSubmitted)
                    resolve(false);
                else
                    resolve(filteredSession.assessment.isAssessmentReleased);
            }
            resolve(false);
        })
    }

    const getAllStatusValues = () => {
        let tempObj = {
            isProjectLocked: props.isFreezed,
            isAssessmentReleased: false
        }

        return new Promise(function (resolve, reject) {
            let filteredSession = props.sessions.filter(x => x.index == props.sessionDetails.sessionIndex)[0];
            if (filteredSession) {
                if (filteredSession.assessment.isAssessmentSubmitted)
                    tempObj.isAssessmentReleased = false;
                else
                    tempObj.isAssessmentReleased = filteredSession.assessment.isAssessmentReleased;
                resolve(tempObj);
            }
            resolve(tempObj);
        })
    }

    const submitProjectAnswer = (qid, qtxt, anstxt) => {
        if (qid !== undefined) {
            qid = parseInt(qid);
        }
        return new Promise(function (resolve, reject) {
            axios.patch('/studentRoster/updatestudentprojectquestion/' + props.studentRosterID, { id: qid, question: qtxt, answer: anstxt }, {
                headers: {
                    Authorization: "bearer " + auth.token
                }
            }
            ).then(res => {
                resolve(true);
            })
                .catch(errror => {
                    console.log("something went wrong in clear data: " + errror)
                    Error = "ERROR: " + errror;
                    resolve(false);
                })
        })
    }

    const getAllSessionTypes = () => {
        return new Promise(function (resolve, reject) {
            if (props.sessionTypes)
                resolve(props.sessionTypes)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: SessionTypes are not available for this class."
                resolve(false);
            }

        })
    }

    const getDistrictName = () => {
        return new Promise(function (resolve, reject) {
            if (props.districtName)
                resolve(props.districtName)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: DistrictName is not available for this class."
                resolve(false);
            }

        })
    }

    const getSubject = () => {
        return new Promise(function (resolve, reject) {
            if (props.subject)
                resolve(props.subject)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: Subject not available for this class."
                resolve(false);
            }

        })
    }

    const getTeacherID = () => {
        return new Promise(function (resolve, reject) {
            if (props.teacherId)
                resolve(props.teacherId)
            else {
                console.log("something went wrong : ")
                Error = "ERROR: Teacher Id not available for this class."
                resolve(false);
            }

        })
    }


    const trackEvent = (event, dataObject) => {
        return new Promise(function (resolve, reject) {
            const trackData = {
                ...dataObject,
                subject: props.subject,
                class_section_id: props.classId
            }
            props.amplitude.track(event, trackData)
            resolve(true);
        })
    }

    Spark.courseName = courseName;
    Spark.userID = userID;
    Spark.studentID = studentID;
    Spark.userName = userName;
    Spark.currentSession = currentSession;
    Spark.submitProjectGrade = submitProjectGrade;
    Spark.mileStoneCompleted = mileStoneCompleted;
    Spark.isPaused = isPaused;
    Spark.submitAssessmentGrade = submitAssessmentGrade;
    Spark.showAssessment = showAssessment;
    Spark.publishProject = publishProject;
    Spark.getCurrentMode = getCurrentMode;
    Spark.setCurrentLocation = setCurrentLocation;
    Spark.getCurrentLocation = getCurrentLocation;
    Spark.setCourseData = setCourseData;
    Spark.getCourseData = getCourseData;
    Spark.setGlobalVariable = setGlobalVariable;
    Spark.getGlobalVariable = getGlobalVariable;
    Spark.clearData = clearData;
    Spark.BackToClass = BackToClass;
    Spark.isProjectLocked = isProjectLocked;
    Spark.getLastError = getLastError;
    Spark.isAssessmentReleased = isAssessmentReleased;
    Spark.getAllStatusValues = getAllStatusValues;
    Spark.submitProjectAnswer = submitProjectAnswer;
    Spark.getAllSessionTypes = getAllSessionTypes;
    Spark.getDistrictName = getDistrictName;
    Spark.getSubject = getSubject;
    Spark.getAllProjectGrades = getAllProjectGrades;
    Spark.getTeacherID = getTeacherID;
    Spark.trackEvent = trackEvent;





    window.getSpark = () => { return Spark; }
    window.Spark = Spark;

    const checkLimit = (limit, event, item) => {
        const button = document.getElementById("submitBTN");
        if (limit == 1) {
            selectedQuestion.answer = [];
            if (event.target.checked) {
                if (item.isCorrect) {
                    let temp = {};
                    temp["id"] = item.id;
                    temp["selectedAnswer"] = item.text
                    temp["isCorrect"] = true
                    selectedQuestion.answer.push(temp)
                }
                else {
                    let temp = {};
                    temp["id"] = item.id;
                    temp["selectedAnswer"] = item.text
                    temp["isCorrect"] = false
                    selectedQuestion.answer.push(temp)
                }

                button.classList.remove("disabledSubmitBtn");

            }
        }
        else {
            if (event.target.checked) {
                if (item.isCorrect) {
                    assessment.totalCorrectAnswers += 1;
                    let temp = {};
                    temp["id"] = item.id;
                    temp["selectedAnswer"] = item.text
                    temp["isCorrect"] = true
                    selectedQuestion.answer.push(temp)
                }
                else {
                    let temp = {};
                    temp["id"] = item.id;
                    temp["selectedAnswer"] = item.text
                    temp["isCorrect"] = false
                    selectedQuestion.answer.push(temp)
                }

                button.classList.remove("disabledSubmitBtn");
            }
            else {
                let ind = selectedQuestion.answer.findIndex(x => x.id == item.id);
                if (selectedQuestion.answer[ind].isCorrect)
                    assessment.totalCorrectAnswers -= 1;
                selectedQuestion.answer.splice(ind, 1);
                if (selectedQuestion.answer.length == 0) {

                    button.classList.add("disabledSubmitBtn");
                }
            }
        }
    }

    const nextQuestion = () => {
        const button = document.getElementById("submitBTN");
        if (selectedQuestion.answer.length == 0) {
            return;
        }
        assessment.assessmentQuestion.push(selectedQuestion);
        if (selectedQuestion.allowedChoices != 1) {
            if ((selectedQuestion.answer.length == assessment.totalCorrectAnswers) == (selectedQuestion.choices.filter(x => x.isCorrect == true).length))
                assessment.totalCorrectAnswers -= assessment.totalCorrectAnswers + 1;
            else
                assessment.totalCorrectAnswers -= assessment.totalCorrectAnswers;
        }
        else {
            if (selectedQuestion.answer[0].isCorrect)
                assessment.totalCorrectAnswers += 1;
        }

        button.classList.add("disabledSubmitBtn");
        let filterIndex = assessmentQuestions.findIndex(x => x.id == selectedQuestion.id)

        setSelectedQuestion(assessmentQuestions[filterIndex + 1]);

    }

    const submitAssessment = () => {
        const button = document.getElementById("submitBTN");
        button.classList.add("disabledSubmitBtn");
        if (selectedQuestion.answer.length == 0) {
            return;
        }
        else {
            setAssessmentModal(false);
        }

        assessment.assessmentQuestion.push(selectedQuestion);
        if (selectedQuestion.allowedChoices != 1) {
            if ((selectedQuestion.answer.length == assessment.totalCorrectAnswers) == (selectedQuestion.choices.filter(x => x.isCorrect == true).length))
                assessment.totalCorrectAnswers -= assessment.totalCorrectAnswers + 1;
            else
                assessment.totalCorrectAnswers -= assessment.totalCorrectAnswers;
        }
        else {
            if (selectedQuestion.answer[0].isCorrect)
                assessment.totalCorrectAnswers += 1;

        }

        axios.patch("/studentRoster/submitassessment/" + props.studentRosterID + "/" + props.sessionDetails.sessionIndex, assessment, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                assessment.isAssessmentSubmitted = true;
                assessment.totalCorrectAnswers = 0;
                assessment.assessmentQuestion = [];
                trackEvent("Completed Assessment", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId, session_id: props.sessionDetails.sessionIndex, session_type: props.sessionTypes[props.sessionDetails.sessionIndex], assessment_id: props.sessionDetails.sessionIndex });
                props.sessions.filter(x => x.index == props.sessionDetails.sessionIndex)[0].assessment.isAssessmentSubmitted = true;

                WSSendMessage("assessmentSubmitted");

            })
            .catch(errror => {
                alert("FAILED:: milestone updated")
            })
    }

    const zoomToggle = () => {
        let imgID = document.getElementById("QuestionImg");
        imgID.classList.toggle("zoomedInImg");
        let zoomOutIconID = document.getElementById("zoomOutIcon");
        zoomOutIconID.classList.toggle("hideIcon");
        let zoomInIconID = document.getElementById("zoomInIcon");
        zoomInIconID.classList.toggle("showIcon");
    }

    const [zoomed, setZoomed] = useState(false);
    return (
        <>
            {props.isVisible && <Modal className="iframeModal" visible={props.isVisible} onClickBackdrop={() => { props.setVisible(false) }}>
                <div className="modal-body">
                    <iframe src={props.iframeURL} title="Course"></iframe>
                </div>
            </Modal>
            }

            {
                selectedQuestion &&
                <Modal id="assessmentModal" dialogClassName="Modaldialog" className="assessmentModal" visible={assessmentModal} onClickBackdrop={() => { }}>
                    <div className="modal-body">
                        <div style={{ padding: "5px", position: "relative", textAlign: "end", cursor: "pointer", paddingLeft: "15px", marginBottom: "15px" }} onClick={() => { setAssessmentModal(false) }}>
                            <span style={{ float: "left" }} className="SAQ-head">{props.sessionDetails.sessionName} Assessment, Question {selectedQuestion.id}</span>
                            <img alt="close" className="closeModalPNG" src={closePNG} />
                            <span className="bottomLine"></span>
                        </div>

                        <div className="row" style={{ textAlign: "start", marginBottom: "50px" }}>
                            <div className={`${selectedQuestion.imageUrl == "" ? "col-12 " : "col-7"}`} >
                                <div className="row">

                                    <div className="col-12 SAQ-question" style={{ fontSize: "18px", padding: "0px" }}>
                                        <span dangerouslySetInnerHTML={{ __html: selectedQuestion.prompt }}></span>
                                    </div>
                                    <div className="col-12" style={{ marginTop: "20px", color: "#4C4B5E", fontSize: "16px", padding: "0px" }}>
                                        <span>Instruction: Select your answer and then click Submit.</span>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "20px" }}>
                                    <div className="col-12" style={{ padding: "0" }}>
                                        <div className="row">
                                            {selectedQuestion.randomized && selectedQuestion.choices
                                                .map((a) => ({ sort: Math.random(), value: a }))
                                                .sort((a, b) => a.sort - b.sort)
                                                .map((item, index) => {
                                                    return <div key={item.value._id} className="col-5 SAQ-optiontext normalBorder" style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                                        <div className="radio">
                                                            {selectedQuestion.allowedChoices == 1 &&
                                                                <label className="containerRadio">
                                                                    <div className="radio-button">
                                                                        <input id={`option${item.value.choice.id}`} onChange={(event) => { checkLimit(selectedQuestion.allowedChoices, event, item.value.choice) }} type="radio" name="optionsRadio" style={{ cursor: "pointer" }} />
                                                                        <span className="checkmark"><span className="centerCircle"></span></span>
                                                                    </div>
                                                                    <span className="radio-label" dangerouslySetInnerHTML={{ __html: item.value.choice.text }}></span>
                                                                </label>}

                                                            {selectedQuestion.allowedChoices != 1 &&
                                                                <label className="containerRadio ">

                                                                    <div className="radio-button">
                                                                        <input id={`option${item.value.choice.id}`} onChange={(event) => { checkLimit(selectedQuestion.allowedChoices, event, item.value.choice) }} type="checkbox" name={`${item.value.choice.id}`} style={{ cursor: "pointer" }} />
                                                                        <span className="checkmark"><span className="centerCircle"></span></span>
                                                                    </div>
                                                                    <span className="radio-label" dangerouslySetInnerHTML={{ __html: item.value.choice.text }}></span>
                                                                </label>}
                                                        </div>
                                                    </div>

                                                })}
                                            {!selectedQuestion.randomized && selectedQuestion.choices
                                                .map((item, index) => {
                                                    return <div key={item._id} className="selectedOption col-5 SAQ-optiontext normalBorder" style={{ marginBottom: "10px", marginRight: "20px", borderRadius: "4px", padding: "20px" }}>
                                                        <div className="radio">
                                                            {selectedQuestion.allowedChoices == 1 &&
                                                                <label className="containerRadio ">
                                                                    <div className="radio-button">
                                                                        <input id={`option${item.choice.id}`} onChange={(event) => { checkLimit(selectedQuestion.allowedChoices, event, item.choice) }} type="radio" name="optionsRadio" style={{ cursor: "pointer" }} />
                                                                        <span className="checkmark"><span className="centerCircle"></span></span>
                                                                    </div>
                                                                    <span className="radio-label" dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                                                </label>
                                                            }
                                                            {selectedQuestion.allowedChoices != 1 &&
                                                                <label className="containerRadio ">
                                                                    <div className="radio-button">
                                                                        <input id={`option${item.choice.id}`} onChange={(event) => { checkLimit(selectedQuestion.allowedChoices, event, item.choice) }} type="checkbox" name={`${item.choice.id}`} style={{ cursor: "pointer" }} />
                                                                        <span className="checkmark"><span className="centerCircle"></span></span>
                                                                    </div>
                                                                    <span className="radio-label" dangerouslySetInnerHTML={{ __html: item.choice.text }}></span>
                                                                </label>
                                                            }
                                                        </div>
                                                    </div>

                                                })}
                                        </div>
                                    </div>

                                </div>
                                <div className="row" style={{ marginTop: "15px" }}>
                                    <div className="col-12" style={{ textAlign: "initial", padding: "0" }}>
                                        {assessmentQuestions[assessmentQuestions.length - 1].id != selectedQuestion.id &&
                                            <button id="submitBTN" style={{ padding: "8px 20px" }} className="round-filled-btn disabledSubmitBtn" onClick={nextQuestion}><span>Submit and Go to Next Question</span></button>
                                        }
                                        {assessmentQuestions[assessmentQuestions.length - 1].id == selectedQuestion.id &&
                                            <button id="submitBTN" style={{ padding: "8px 20px" }} className="round-filled-btn disabledSubmitBtn" onClick={submitAssessment}><span>Submit and Close Assessment</span></button>
                                        }
                                    </div>
                                </div>

                            </div>
                            {selectedQuestion.imageUrl != "" &&
                                <div className="col-5" style={{ height: "425px", padding: "0px", textAlign: "end" }}>
                                    <img id="zoomOutIcon" alt="close" src={closePNG} className="hideIcon" onClick={zoomToggle} style={{ display: "block", width: "30PX", height: "30px", position: "absolute", right: "-5px", top: "-35px", zIndex: "999", cursor: "pointer" }} />
                                    <img id="QuestionImg" alt="placeholder" onClick={zoomToggle} src={selectedQuestion.imageUrl} className="zoomedOutImg" style={{ boxShadow: "-4px 4px #009DDB" }} />
                                    <img id="zoomInIcon" alt="zoom" src={zoomIcon} className="showIcon" onClick={zoomToggle} style={{ display: "none", width: "30px", height: "30px", position: "absolute", right: "5px", bottom: "5px", cursor: "pointer" }} />
                                </div>
                            }
                        </div>
                    </div>
                </Modal>
            }

            <ConfirmationBox
                isVisible={showMessageBox}
                customClass='confirmationBoxHeight'
                setVisible={(value) => { setMessageBox(value) }}
                confirmationHeader={message}
                confirmationText=""
                handleOK={() => { setMessageBox(false); }}
                okLabel="OK"
                cancelLabel="Cancel"
                hideCancel={true} />

        </>
    );
};

export default React.memo(IFrameBox);