import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Profile = (props) => (
    <SvgIcon {...props}>
        <path d="m3 12c0-4.9706 4.0294-9 9-9 4.9683 0.00537 8.9946 4.0317 9 9 0 4.9706-4.0294 9-9 9-4.9706 0-9-4.0294-9-9zm1.875 0c0 3.935 3.19 7.125 7.125 7.125 3.9332-0.0046 7.1204-3.1918 7.125-7.125 0-3.935-3.19-7.125-7.125-7.125-3.935 0-7.125 3.19-7.125 7.125z" clipRule="evenodd" fillRule="evenodd" />
        <path d="m12 12.75c1.6568 0 3-1.3432 3-3 0-1.6569-1.3432-3-3-3-1.6569 0-3 1.3431-3 3 0 1.6568 1.3431 3 3 3z" />
        <path d="m12 13.326c-1.9068 0.0071-3.6414 1.105-4.464 2.8252-0.055372 0.11625-0.04722 0.25282 0.021585 0.36165s0.18865 0.17475 0.31742 0.1746h8.25c0.12878 1.5e-4 0.24862-0.06578 0.3174-0.1746 0.06885-0.10882 0.07695-0.2454 0.0216-0.36165-0.8226-1.7203-2.5571-2.8181-4.464-2.8252z" />
    </SvgIcon>

);

export default Profile;
