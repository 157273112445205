import React, { useContext, forwardRef, useState, useEffect } from "react";
import {
    Link,
    useHistory
} from 'react-router-dom';
import axios from "axios";

import UserContext from "../../shared/context/UserContext";

const DownloadFile = forwardRef((props, ref) => {
    const auth = useContext(UserContext);
    let history = useHistory();
    const axiosInstance = axios.create();

    let fileToken = (new URL(window.location)).searchParams.get("key");
    let content = (new URL(window.location)).searchParams.get("content");

    const [link, setLink] = useState();

    useEffect(() => {

        download();

    }, []);

    const download = () => {
        const fileHash = { key: fileToken, content };
        axios.get(process.env.REACT_APP_APIURL + "/classSection/downloadfile", { params: fileHash }, {
            headers: { Authorization: "bearer " + auth.token }
        })
            .then(res => {
                setLink(res.data);
                if (res.data) {
                    window.open(res.data, "_self", "");

                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <>
            <div style={{ minHeight: "75vh", background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)", }}>
                <div className="row text-center" style={{ padding: '50px', textAlign: 'center' }}>
                    <div className="col-12 mt-5 mb-2">
                        <h3 style={{ fontWeight: 600 }}>Thank you for downloading the teacher material.</h3>
                    </div>
                    <div className="col-12 mt-3">
                        Your download will start shortly. If your download does not begin, <a href={link} download={link} className="" style={{ textDecoration: "underline" }}>click here to retry.</a>
                    </div>
                </div>
            </div>
        </>
    )
})

export default React.memo(DownloadFile);