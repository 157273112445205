import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const Export = (props) => (
    <SvgIcon {...props}>
        <path d="m14.708 10.542c-0.11508 0-0.20833 0.09333-0.20833 0.20833v9.3748h-10.833v-10.833h2.7108c0.11376-0.00137 0.20538-0.093732 0.20583-0.2075 0.00553-0.40589 0.053857-0.81006 0.14416-1.2058 0.0136-0.061732-0.00145-0.12631-0.040933-0.17566-0.039491-0.049366-0.099182-0.078215-0.1624-0.078499h-2.8574c-0.92046 3e-7 -1.6666 0.74618-1.6666 1.6666v10.833c0 0.92048 0.74618 1.6666 1.6666 1.6666h10.833c0.92048 0 1.6666-0.74615 1.6666-1.6666v-9.3732c0-0.115-0.09325-0.20833-0.20833-0.20833z" />
        <path d="m18.143 2.3472c-0.11533-0.1284-0.29791-0.17242-0.45908-0.11069-0.16116 0.061731-0.26758 0.21643-0.26758 0.38902v1.4591c1.67e-4 0.055324-0.02167 0.10844-0.06083 0.14756-0.03908 0.039116-0.09225 0.060991-0.1475 0.060766h-4.4749c-2.7026 9.167e-4 -4.8944 2.1891-4.9 4.8916-0.00394 0.92878 0.26275 1.8386 0.76749 2.6183 0.08754 0.13716 0.24691 0.21075 0.4081 0.18858 0.16119-0.02225 0.2947-0.13625 0.34189-0.29191 0.45685-1.429 1.7822-2.4011 3.2825-2.4075h4.5749c0.05533 0 0.1085 0.022066 0.14758 0.061316 0.03908 0.039249 0.06092 0.092457 0.06075 0.14785v1.4583c-3.33e-4 0.17266 0.10592 0.32774 0.26716 0.38966 0.16125 0.06192 0.34399 0.018 0.45949-0.1105l3.7499-4.1666c0.14258-0.15846 0.14258-0.39903 0-0.55749z" />
    </SvgIcon>

);

export default Export;
