import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom"
import UserContext from "../../context/UserContext";
import {
    useHistory, useLocation
} from "react-router-dom";
import axios from "axios";
import logo from '../../../assets/images/spark-logo-corored.svg';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./Header.css";

function Header(props) {
    let location = useLocation();

    let history = useHistory();
    const auth = useContext(UserContext);
    const [Firstname, setFirstname] = useState('');
    const [Lastname, setLastname] = useState('');
    const logout = () => {
        auth.logout();
    }

    useEffect(() => {
        if (auth.token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
            setFirstname(auth.firstname);
            setLastname(auth.lastname);
        }
    }, [auth]);

    function redirectToDashboard() {
        if (auth.token) {
            if (auth.role[0].name === "Teacher") {
                history.push("/teacherdashboard");
            }
            else if (auth.role[0].name === 'Student') {
                history.push("/studentdashboard");
            }
        }
        else {
            history.push('/signin');
        }

    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" style={{ cursor: "pointer" }} onClick={redirectToDashboard} ><img src={logo} alt="logo" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                    <ul className="navbar-nav ml-auto">
                        {
                            auth.token && auth.role[0].name === "Student" &&
                            <li className="nav-item myClass ml-4">
                                <Link style={{ color: "#4C4B5E", fontWeight: "bold" }} to="/studentdashboard">My Classes</Link>
                            </li>
                        }
                        {
                            auth.token && auth.role[0].name === "Teacher" && auth.strategy && auth.strategy.toLowerCase().indexOf("clever") == -1 &&
                            <li className="nav-item addClassSection-1 lsp-09">
                                <Link to="/addclass" className="add-a-class-top addClass font-weight-bold b-2 font-14 d-flex align-items-center" onClick={() => { props.amplitude.track("Clicked Add a Class Button", { button_type: "Button", page_name: location.pathname }); props.amplitude.track("Visited Add Class", { page_section: "Add new class" }); }}  >
                                    <AddIcon /> Create a Class
                                </Link>
                            </li>
                        }
                        {
                            !location.pathname.includes("studentClass") && !location.pathname.includes("profile") && auth.token && auth.role[0].name === "Student" && auth.strategy && auth.strategy.toLowerCase().indexOf("clever") == -1 &&
                            <li className="nav-item addClass d-flex ml-4" onClick={() => { if (document.getElementById("classCodeInput")) { document.getElementById("classCodeInput").scrollIntoView(); document.getElementById("classCodeInput").focus(); } }}>
                                <div className="d-flex align-items-center" style={{ color: "#4C4B5E" }} onClick={() => { props.amplitude.track("Clicked Add Class Code Button", { page_name: location.pathname }); }}>
                                    <AddIcon /> Join a Class
                                </div>
                            </li>
                        }
                        {auth.token && <li className="nav-item ml-4 dropdown d-flex">
                            <div className="dropdown-toggle d-flex align-items-center profile-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="d-flex align-items-center dropdown-button pointer lsp-03">
                                    {((Firstname + " " + Lastname).length >= 40) ? Firstname.substr(0, 20) + '... ' + Lastname.substr(0, 20) + '...' : Firstname + " " + Lastname}
                                    <ExpandMoreIcon fontSize="large" className="dropwdown-arrow ml-1" />
                                </span>
                            </div>
                            <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                                <Link className="dropdown-item" to="/profile">Profile</Link>
                                <button className="dropdown-item" onClick={logout}>Logout</button>
                            </div>
                        </li>}
                    </ul>

                </div>
            </nav>

        </>
    )
}

export default Header;