import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import "./ClassSectionRow.css";

import Algebra from '../Icons/Algebra';
import History from '../Icons/History';
import Physics from '../Icons/Physics';

import SessionTypes from "../SessionType/SessionTypes";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { SettingsSharp } from '@material-ui/icons';

const ClassSectionRow = props => {
    let history = useHistory();
    const startDateFormat = (date) => {
        const startDate = new Date(date);
        let m = startDate.getMonth() + 1;
        let d = startDate.getDate();
        if (m < 10) {
            m = "0" + m;
        }
        if (d < 10) {
            d = "0" + d;
        }
        return m + "/" + d;
    }

    return (
        props.filteredData.map((course, index) => (

            <div className={"item-row d-flex align-items-center first-class-item-" + index} key={"class-item-" + index} id={"class-item-" + course._id}>
                <div className="w-100 item-content" onClick={() => { history.push('/classdashboard/' + course._id); }}>
                    <div className="row mb-3">
                        <div className="d-flex justify-content-between w-100">
                            <span className="d-flex align-items-center classTitle p-0">

                                {course.subject === "Algebra" && <Algebra className="mr-2 flex-shrink-0" height={25} width={25} />}
                                {course.subject === "Physics" && <Physics className="mr-2 flex-shrink-0" height={25} width={25} />}
                                {course.subject === "History" && <History className="mr-2 flex-shrink-0" height={25} width={25} />}
                                <h4 className="m-0 font-weight-bold font-16 text-left">{course.name}</h4>
                            </span>
                            <SessionTypes type={course.currentSession && course.sessionType} className="p-0" /> </div>
                    </div>
                    <div className="row">
                        <div className="d-flex w-100">
                            <div className="course-progress">
                                <h4 className="font-weight-bold font-16 m-0">{course.courseProgress + "%"}</h4>
                                {course.currentSession === null && course.startDate && <div className="classStarDate text-uppercase">
                                    <span >Start: {startDateFormat(course.startDate)}</span>
                                </div>}
                            </div>
                            <div className="w-100 text-uppercase font-weight-bold">
                                <div className="progress-background w-100 mb-2">
                                    <div className="progress-bar" style={{ width: course.courseProgress + "%" }}></div>
                                    <div className="row row-vertical-divider">
                                        <div className={"col"} >

                                        </div>
                                        <div className="col">

                                        </div>
                                        <div className="col">

                                        </div>
                                        <div className="col">

                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>
                                </div>
                                <div className="row session-name font-10">
                                    <div className="col text-center">
                                        Session 1
                                    </div>
                                    <div className="col text-center">
                                        Session 2
                                    </div>
                                    <div className="col text-center">
                                        Session 3
                                    </div>
                                    <div className="col text-center">
                                        Session 4
                                    </div>
                                    <div className="col text-center">
                                        Session 5
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-arrow" >
                    <div className="dropdown option-menu">
                        <div className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <MoreVertIcon className="pointer" />
                        </div>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {
                                props.filterValue === "Archived"
                                    ? <button className="dropdown-item" onClick={() => { props.setCurrentItemId(course._id); props.setConfirmUnArchive(true); }}>Unarchive</button>
                                    : <button className="dropdown-item" onClick={() => { props.setCurrentItemId(course._id); props.setConfirmArchive(true); }}>Archive</button>
                            }
                            <button className="dropdown-item" onClick={() => { props.setCurrentItemId(course._id); props.setConfirmDelete(true) }}>Delete</button>
                        </div>
                    </div>
                    <ArrowForwardIosIcon className="pointer arrow-icon" onClick={() => { history.push('/classdashboard/' + course._id); }} />
                </div>
            </div>

        ))
    );
}

export default React.memo(ClassSectionRow);