import React, { useContext, useState, useEffect } from "react";
import {
    Link,
    useHistory
} from "react-router-dom";

import axios from "axios";

import ExternalSSO from '../../shared/components/externalSSO/ExternalSSO';

import "./ForgotPassword.css";
import UserContext from "../../shared/context/UserContext";

import logo from '../../assets/images/spark-logo-corored.svg';

function ForgotPassword(props) {
    const auth = useContext(UserContext);
    let history = useHistory();
    const [emailId, setEmailId] = useState();
    const [flag, setFlag] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState("")

    const [ForgotPasswordMsg, setForgotPasswordMsg] = useState();

    const [emailSentMsg, setEmailSentMsg] = useState("");
    const [emailsentError, setEmailsentError] = useState(false);

    useEffect(() => {
        props.amplitude.track("Visited Forgot Password Page", { page_name: "" })

        axios.get("/staticContent/ForgotPassword")
            .then(res => {
                setForgotPasswordMsg(res.data.Data.subHeader)
            })
    }, []);

    const sendPassword = () => {

        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let rex = regex.test(emailId);
        if (rex !== true) {
            setEmailErrorMsg('Please enter valid email.');
            return;
        }

        axios.post("/user/forgotpassword", { email: emailId })
            .then(res => {
                setFlag(true);
                setEmailSentMsg(res.data.message);
                setEmailsentError(false);
                props.amplitude.track("Sent Reset Password Link", { page_name: "" })

            })
            .catch(error => {
                if (error) {
                    let message = error.response.data.message;
                    message = message.replace("Error: ", "");
                    setEmailSentMsg(message);
                    setEmailsentError(true);
                }
            })
    }

    const emailChangeHandler = (event) => {
        setEmailId(event.target.value);

    }

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }

    return (
        <>
            <div className="minscreenheightOut" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)", paddingBottom: "52px" }}>
                <div className="row header margin0">
                    <div className="col-4">
                        <img src={logo} alt="spark" onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="forgotPasswordBox">
                    <div className="row " style={{ textAlign: "center" }}>
                        <div className="col">
                            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                                <div className="col-12">
                                    <span className="forgotpassMsg">FORGOT PASSWORD</span>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                                <div className="col-12">
                                    <p className="forgotpassDesc" dangerouslySetInnerHTML={{
                                        __html: ForgotPasswordMsg
                                    }}>
                                    </p>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ marginTop: "20px" }}>
                                <div className="col-12">
                                    <form className="signinform">
                                        <div className="form-group mobileSignINInput" style={{ margin: "0 150px", textAlign: "left" }}>
                                            <label>EMAIL</label>
                                            <input type="email" className="form-control" id="email" onChange={emailChangeHandler} />
                                            {emailErrorMsg != "" &&
                                                <span style={{ color: "red" }}>{emailErrorMsg}</span>
                                            }
                                        </div>
                                        <div className="form-group" style={{ textAlign: "center", marginTop: "35px" }}>
                                            <button type="button" onClick={sendPassword} className="curve-fill-btn">send reset link</button>
                                        </div>
                                    </form>

                                    {<div style={{ margin: "20px auto" }}>
                                        {emailsentError
                                            ? <span className='text-danger'>{emailSentMsg}</span>
                                            : <span className='text-success'>{emailSentMsg}</span>
                                        }
                                    </div>}
                                </div>

                                {/*<div className="col-12 d-flex align-items-center justify-content-center" style={{ marginTop: "30px" }}>*/}
                                {/*    <div className="signin-decoration"></div>*/}
                                {/*    <span className="emailSignInLabel">*/}
                                {/*        <nobr>Or sign in using your account on:</nobr>*/}
                                {/*    </span>*/}
                                {/*    <div className="signin-decoration"></div>*/}
                                {/*</div>*/}

                                {/*<div className="row justify-content-center" style={{ marginTop: "30px" }}>*/}
                                {/*    <div className="col-12">*/}
                                {/*        <ExternalSSO />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div className="row justify-content-center" style={{ margin: "52px auto 26px auto" }}>
                                    <div className="col-12">
                                        <span className="notHaveAcc">Don't have an account? <Link className="underlineLabel" style={{ color: "#4C4B5E" }} to="/createaccount">Get Started</Link> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ForgotPassword);