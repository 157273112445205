import React, { useState, useContext, useEffect } from "react";
import {
    Link, useHistory
} from "react-router-dom";
import { useForm } from "react-hook-form";

import axios from "axios";
import ExternalSSO from '../../shared/components/externalSSO/ExternalSSO';
import UserContext from "../../shared/context/UserContext";
import {
    browserName, osVersion, osName, fullBrowserVersion, deviceType
} from "react-device-detect";
import "./SignIn.css";

import logo from '../../assets/images/spark-logo-corored.svg'


function SignIn(props) {
    const auth = useContext(UserContext);
    let history = useHistory();
    const { register, handleSubmit, watch, errors } = useForm();
    const [email, setEmail] = useState();
    const [IpAddr, setIpAddr] = useState('');
    const [password, setPassword] = useState();
    const [flag, setFlag] = useState(false);
    const [signingInFlag, setSigningInFlag] = useState(false);
    props.hideLoader();

    useEffect(() => {
        props.amplitude.track("Visited Sign In Page", { page_name: "" })

        if (localStorage.getItem('IpAddress') !== null) {
            setIpAddr(localStorage.getItem('IpAddress').IPv4);
        }
    }, []);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setFlag(false);
    }

    const handlePasswordChange = (event) => {
        setFlag(false);
        setPassword(event.target.value);
    }

    const signIn = (event) => {

        setSigningInFlag(true);

        axios.post("/user/login", { email: email, password: password })
            .then(res => {
                props.amplitude.track("Signed In Successfully", { page_name: "" });
                localStorage.removeItem('userData');
                localStorage.setItem("district", res.data.district);
                const IsPageVisited = res.data.isWelcomePageVisited;
                auth.login(res.data.userId, res.data.token, res.data.emailId, res.data.firstName, res.data.lastName, res.data.roles, "Custom Account");
                let role = res.data.roles;
                axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
                axios.get("/user/get-state-district-name-by-userid/" + res.data.userId).then(response => {
                    localStorage.setItem("stateDistrict", JSON.stringify(response.data));
                    axios.post("user/audit", { userId: res.data.userId, email: res.data.emailId, role: res.data.roles[0].name, signinMethod: "Custom Account", platform: deviceType, osName: osName, osVersion: osVersion, browser: browserName, browserVersion: fullBrowserVersion, loginTimestamp: new Date(), ipAddress: IpAddr }).then(response => {

                        if (window.screen.width > 767) {
                            if (IsPageVisited) {
                                if (role[0].name === "Teacher") {
                                    history.push("/teacherdashboard");
                                }
                                else if (role[0].name === 'Student') {
                                    history.push("/studentdashboard");
                                }
                            }
                            else {
                                history.push("/welcomepage");
                            }
                        }
                        else {
                            history.push("/mobileWelcome");
                        }
                    })
    
                })
               
               
            })
            .catch(errror => {
                setSigningInFlag(false)
                setFlag(true);
            })
    }

    const redirectToHome = () => {
        if (auth.token) {
            history.push('/teacherdashboard');
        }
        else {
            history.push('/signin');
        }
    }


    const validateEmail = async (val) => {
        let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let rex = regex.test(val);
        if (rex === true) {
            return true;
        }
        else {
            return false;
        }
    }
    return (
        <>
            <div className="minscreenheightOut" style={{ background: "linear-gradient(180deg, #FFFFFF 0%, #EDEDF9 100%)", paddingBottom: "20px" }}>
                <div className="row header margin0">
                    <div className="col-4">
                        <img src={logo} alt="spark" onClick={redirectToHome} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <div className="signInBox">
                    <div className="row " style={{ textAlign: "center" }}>
                        <div className="col">
                            <div className="row justify-content-center" style={{ marginTop: "25px" }}>
                                <div className="col-12">
                                    <span className="signInMsg">WELCOME BACK</span>
                                </div>
                            </div>
                            {/*<div className="row justify-content-center" style={{ marginTop: "20px" }}>*/}
                            {/*    <div className="col-12">*/}
                            {/*        <span className="signInDesc">Sign in using your account on:</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="row justify-content-center" style={{ marginTop: "25px" }}>*/}
                            {/*    <div className="col-12">*/}
                            {/*        <ExternalSSO />*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="col-12 d-flex align-items-center justify-content-center" style={{ marginTop: "26px" }}>*/}
                            {/*    <div className="signin-decoration"></div>*/}
                            {/*    <span className="emailSignInLabel">*/}
                            {/*        <nobr>Or sign in using your email:</nobr>*/}
                            {/*    </span>*/}
                            {/*    <div className="signin-decoration"></div>*/}
                            {/*</div>*/}

                            <div className="row" style={{ marginTop: "18px", textAlign: "left" }}>
                                <div className="col-12">
                                    <form className="signinform" onSubmit={handleSubmit(signIn)}>
                                        <div className="form-group mobileSignINInput" style={{ margin: "0 150px" }}>
                                            <label>EMAIL</label>
                                            <input type="text" className="form-control" name="emailId" onChange={handleEmailChange} ref={register({ required: true, validate: validateEmail })} />
                                            {errors.emailId && errors.emailId.type === "required" && <span style={{ color: "red" }}>This field is required</span>}
                                            {errors.emailId && errors.emailId.type === "validate" && <span style={{ color: "red" }}>Please enter valid email address.</span>}
                                        </div>
                                        <div className="form-group mobileSignINInput" style={{ margin: "29px 150px 0 150px" }}>
                                            <label>PASSWORD</label>
                                            <input type="password" className="form-control" name="password" onChange={handlePasswordChange} ref={register({ required: true })} />
                                            {errors.password && errors.password.type === "required" && <span style={{ color: "red" }}>This field is required</span>}
                                        </div>
                                        <div className="form-group" style={{ textAlign: "center", marginTop: "20px" }}>
                                            <button type="submit" className="curve-fill-btn">SIGN IN</button>
                                        </div>
                                        {flag && <div style={{ margin: "20px auto", textAlign: "center", color: "red" }}><span>Please check your credentials.</span></div>}
                                        {signingInFlag && <div style={{ margin: "20px auto", textAlign: "center" }}><span>Signing you in...</span></div>}
                                    </form>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 forgotPasswordLabel">
                                    <Link to="/forgotpassword" className="underlineLabel" style={{ color: "#4C4B5E" }}>Forgot your password?</Link>
                                </div>
                            </div>
                            <div className="row justify-content-center" style={{ margin: "17px auto 53px auto" }}>
                                <div className="col-7">
                                    <span className="notHaveAcc">Don't have an account? <Link className="underlineLabel" style={{ color: "#4C4B5E" }} to="/createaccount">Get Started</Link> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(SignIn);