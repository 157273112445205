import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie9'
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'
//import "./style.css"
import axios from "axios";

//const config = require("./assets/config/Config.json");

axios.defaults.baseURL = process.env.REACT_APP_APIURL;

axios.interceptors.request.use(request => {
  return request;
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error)
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
