import React, { useState, useContext, useEffect } from 'react'
import UserContext from "../../shared/context/UserContext";

import rightArrow from '../../assets/images/icons/Right.png';
import closePNG from '../../assets/images/icons/close.png';
import DisabledRight from '../../assets/images/icons/DisabledRight.png';

import Modal from 'react-bootstrap4-modal';

import AssessmentBox from "../../shared/components/assessmentBox/AssessmentBox";
import IFrame from "../../shared/components/iFrameBox/IFrameBox";

import Progress from 'react-circle-progress-bar'
import SessionTypes from "../SessionType/SessionTypes";


const SessionsTab = props => {
    console.log("sessions tab props ::: ", props);

    const auth = useContext(UserContext);

    const [milesStoneVisible, setMilesStoneVisible] = useState(false);
    const [mileStones, setmileStones] = useState({ sessionName: "", list: [] })
    const [mileStoneBullets, setMileStoneBullets] = useState({ sessionName: "", list: [] })

    const [SAQModal, setSAQModal] = useState(false);

    const [assessmentQuestions, setAssessmentQuestions] = useState(null);

    const [visibleProject, setVisibleProject] = useState(false);

    const [showRubric, setShowRubric] = useState(false);

    const [selectedCriteria, setSelectedCriteria] = useState(props.rubric.rubricCriteria[0] || null);


    const getSessionProgress = (index) => {

        let value = 0;
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0)
            return filtered[0].sessionProgress;
        else
            return value
    }


    const isSessionUnlocked = (index) => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0)
            return true;
        else
            return false
    }

    const showMilestones = index => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0) {
            setmileStones({ sessionName: filtered[0].name, list: filtered[0].milestones });
            setMileStoneBullets(filtered[0].milestoneBullets)
            setMilesStoneVisible(true);
        }
    }

    const getTotalScored = index => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0 && filtered[0].assessment.isAssessmentSubmitted) {
            return filtered[0].assessment.totalCorrectAnswers + " / " + filtered[0].assessment.totalQuestions
        }
        else {
            return "N / " + props.classSessions.filter(x => x.index === index)[0].assessment.totalQuestions
        }
    }

    const isAssignmentSubmitted = index => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0 && filtered[0].assessment.isAssessmentSubmitted)
            return true
        else
            return false
    }

    const setAssessment = index => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0 && filtered[0].assessment.isAssessmentSubmitted) {
            setAssessmentQuestions(filtered[0].assessment);
            setSAQModal(true);
        }
    }

    const [sessionDetails, setSessionDetails] = useState({ sessionName: "", sessionIndex: null });
    const setSessionData = index => {
        let sessionId = index;
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0) {
            setSessionDetails({ sessionName: filtered[0].name, sessionIndex: filtered[0].index });
            props.amplitude.track("Clicked Class Details Button", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId, button_name: "Session Go", session_id: sessionId, session_type: props.sessionTypes[sessionId] });
        }
    }

    const getSessionBorder = (index) => {
        let filtered = props.sessions.filter(x => x.index === index)
        if (filtered.length > 0) {
            if (props.sessions[props.sessions.length - 1].index == index)
                return "yellowSession";
            else
                return "orangeSession";
        }
        return "greySession";
    }

    useEffect(() => {
        if (sessionDetails.sessionIndex !== null) {
            setVisibleProject(true);
        }
    }, [sessionDetails])

    const trackButton = (btn_name, page_section) => {
        props.amplitude.track("Clicked Class Details Button", { teacher_user_id: props.teacherId, subject: props.subject, class_section_id: props.classId, button_name: btn_name, session_id: sessionDetails.sessionIndex, session_type: props.sessionTypes[sessionDetails.sessionIndex] });
    }



    return (
        <>  <div className="row sessionTabPad7per" >
            <div className="col-12" style={{}}>

                <div className="row classProgressTitle" style={{}}>
                    <div className="col-12" style={{}}>
                        <span>Your Class Progress</span>
                    </div>
                </div>
                <div className="row progressText" style={{ marginTop: "15px", marginBottom: "45px" }}>
                    <div className="col-12" style={{}}>
                        <span>Follow your Class Progress below. Click Go to enter the project work for a session.</span><br /><span> Missed a session or part of a session? Connect with your teacher for guidance on catching up. </span>
                    </div>
                </div>
                <div className="row" style={{}}>
                    <div className="col-12 progressBarDiv" style={{ textAlign: "left" }}>
                        <div className="row" style={{}}>
                            <span className="classProgressTitle" style={{ marginTop: "0", textTransform: "uppercase", fontSize: "9px" }}> Course progress</span>
                        </div>
                        <div className="row" style={{}}>
                            <div style={{ width: "6%", textAlign: "left" }}>
                                <span className="classProgressTitle" style={{ fontSize: "16px" }}>{`${props.courseProgress}%`}</span>
                            </div>
                            <div style={{ width: "94%" }} className="my-auto">
                                <span className="progress"> <span className="progress-bar" role="progressbar" aria-valuenow={props.courseProgress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${props.courseProgress}%` }}></span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "30px", marginBottom: "30px" }}>
                    <div className="col-12 " style={{ textAlign: "left" }}>
                        <span className="classProgressTitle">Session</span>
                        <span className="viewProjectRubricSpan progressText underlineLabel" style={{ fontWeight: "bold", float: "right" }} onClick={() => { trackButton("View Project Rubric", "Sessions"); setShowRubric(true) }}>View Project Rubric</span>
                    </div>
                </div>

                <div className="row" style={{}}>
                    <div className="col-12 ">


                        {props.classSessions.map(item => {

                            return <div key={item.index} className={`row justify-content-between sessionBox ${getSessionBorder(item.index)}`} style={{ padding: "30px" }}>
                                <div className="my-auto boxSessionName" style={{ padding: "0" }}>
                                    <span>{item.name}</span>
                                </div>
                                <SessionTypes type={props.sessionTypes && props.sessionTypes[item.index]} single={true} />
                                {
                                    isSessionUnlocked(item.index) ?
                                        <span className="circularProgressBarSpan d-flex align-items-center">
                                            <Progress progress={getSessionProgress(item.index)} strokeWidth={20} ballStrokeWidth={0} gradient={[{ stop: 0.0, color: '#FEE04D' }, { stop: 1, color: '#F94B4B' }]} hideValue={true} reduction={0} />
                                            <span className="sessionProgress">Progress:<br /> <strong>{getSessionProgress(item.index)}%</strong>
                                            </span>

                                        </span>
                                        :
                                        <span className="disabledText circularProgressBarSpan d-flex align-items-center" style={{ textDecoration: "none" }}>
                                            <Progress progress={0} strokeWidth={20} ballStrokeWidth={0} gradient={[{ stop: 0.0, color: '#FEE04D' }, { stop: 1, color: '#F94B4B' }]} hideValue={true} reduction={0} />
                                            <span className="sessionProgress" style={{ color: "#C7C7D4" }}>Progress: <br /><strong>0%</strong></span>

                                        </span>}

                                <div className="my-auto boxUnderlineText seeMilestonesLabel" style={{ padding: "0" }}>
                                    {isSessionUnlocked(item.index) ? <span onClick={() => { showMilestones(item.index); trackButton("See Milestones", item.name); }} style={{ cursor: "pointer" }}>See Tasks</span> : <span className="disabledText">See Tasks</span>}
                                </div>
                                <div className="my-auto boxUnderlineText assignmentLabel" style={{ padding: "0" }}>
                                    {item.assessment && item.assessment.assessmentQuestion && item.assessment.assessmentQuestion.length > 0 && (isSessionUnlocked(item.index) && isAssignmentSubmitted(item.index) ? <span onClick={() => { setAssessment(item.index); trackButton("See Assessment", item.name); }} style={{ cursor: "pointer" }}><span>Assessment: </span><span><strong>{getTotalScored(item.index)}</strong></span></span> : <span className="disabledText"><span>Assessment: </span><span><strong>{getTotalScored(item.index)}</strong></span></span>)}
                                </div>
                                {
                                    isSessionUnlocked(item.index) &&
                                    <button onClick={() => { setSessionData(item.index) }} type="button" className="saveChangesBtn goBtnLabel" style={{ width: "auto", height: "auto", padding: "7px 20px" }} >Go
                                        <img alt="" src={rightArrow} style={{ width: "18px", height: "14px", verticalAlign: "sub", marginLeft: "10px" }} />
                                    </button>
                                }
                                {
                                    !isSessionUnlocked(item.index) &&
                                    <button type="button" className="saveChangesBtn goBtnLabel" style={{ pointerEvents: "none", color: "#C7C7D4", border: "1px solid #C7C7D4", width: "auto", height: "auto", padding: "7px 20px" }} >Go
                                        <img alt="" src={DisabledRight} style={{ width: "18px", height: "14px", verticalAlign: "sub", marginLeft: "10px" }} />
                                    </button>
                                }

                            </div>
                        })
                        }

                    </div>
                </div>
                <Modal className="milestoneModal" visible={milesStoneVisible} onClickBackdrop={() => { setMilesStoneVisible(false) }}>
                    <div className="modal-body" >
                        <div className="" style={{ textAlign: "end", cursor: "pointer" }} onClick={() => { setMilesStoneVisible(false) }}>
                            <span className="boxSessionName" style={{ paddingLeft: "20px", textTransform: "none", float: "left", marginTop: "20px" }}>
                                {mileStones.sessionName} Tasks
                            </span>

                            <img alt="close" className="closeModalPNG" src={closePNG} />
                        </div>
                        <div className="row" style={{ marginTop: "30px" }}>

                            <span style={{ textAlign: "initial" }} dangerouslySetInnerHTML={{ __html: mileStoneBullets }}></span>

                        </div>


                    </div>
                </Modal>

                {
                    assessmentQuestions && <AssessmentBox
                        assessment={assessmentQuestions}
                        isshow={SAQModal}
                        showmodal={(value) => { setSAQModal(value) }} />
                }


                {props.entryPoints.studentProjectEntry && sessionDetails.sessionIndex !== null &&
                    <IFrame
                        mode="normal"
                        courseName={props.courseName}
                        userDetails={{ userID: auth.userId, email: auth.email, username: auth.firstname }}
                        studentRosterID={props.studentRosterId}
                        isFreezed={props.isFreezed}
                        isProjectSubmitted={props.isProjectSubmitted}
                        sessionDetails={{ sessionName: sessionDetails.sessionName, sessionIndex: sessionDetails.sessionIndex }}
                        sessions={props.sessions}
                        currentLocation={props.currentLocation}
                        isVisible={visibleProject}
                        title="Project"
                        setVisible={(value) => { props.refreshCom(); setVisibleProject(value) }}
                        iframeURL={props.entryPoints.studentCourseEntry}
                        sessionTypes={props.sessionTypes}
                        subject={props.subject}
                        districtName={props.districtName}
                        amplitude={props.amplitude}
                        classId={props.classId}
                        teacherId={props.teacherId}
                    />
                }

                {props.rubric &&
                    <Modal id="rubricNotScored" className="rubricModal" visible={showRubric} onClickBackdrop={() => { setShowRubric(false) }}>
                        <div className="modal-body" >
                            <div className="" style={{ textAlign: "end", position: "relative" }} onClick={() => { setShowRubric(false) }}>

                                <br />
                                <img alt="close" className="closeModalPNG" src={closePNG} style={{ position: "absolute", top: "0", right: "0", cursor: "pointer" }} />
                            </div>
                            <div className="row" style={{ width: "85%", marginBottom: "25px" }}>
                                <span className="progressText" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: props.rubric.topText }}>
                                </span>

                            </div>
                            <div className="row">
                                <div className="col-12" style={{ padding: "0px", textAlign: "left" }}>
                                    <div className="row" >
                                        {props.rubric.rubricCriteria.map((item) => {
                                            return <span key={item.index} className={` ${selectedCriteria.index === item.index ? "selectedCriteria1" : "criteria"}`}
                                                onClick={() => {
                                                    setSelectedCriteria(item)
                                                }}>{item.title}</span>
                                        })}
                                    </div>
                                    <div className="row" style={{ backgroundColor: "#F2F2F9", padding: "5px" }}>
                                        <div className="col-12" style={{ padding: "25px 20px" }}>
                                            <span className="medium-text" dangerouslySetInnerHTML={{ __html: selectedCriteria.topText }}></span>
                                        </div>
                                        {selectedCriteria.items
                                            .sort((a, b) => b.score - a.score)
                                            .map((option, index) => {
                                                return <div key={index} className={`col-3 ${index !== selectedCriteria.items.length - 1 ? "criteria-rightBorder" : ""}`} >
                                                    <div className="row" style={{ marginBottom: "10px" }}>
                                                        <span className="medium-text" style={{ fontWeight: "bold" }}>{option.title} ({option.score})
                                                        </span>
                                                    </div>
                                                    <div className="row" style={{ fontSize: "12px" }}>
                                                        <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
                                                    </div>
                                                </div>
                                            })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }
            </div>
        </div>
        </>
    )
}

export default React.memo(SessionsTab);